import theme from 'config/theme';
import DataGrid, {
    Column,
    GroupPanel,
    Grouping,
    HeaderFilter,
    Paging,
    SearchPanel,
    Format,
    Pager,
    Item as ToolbarItem,
    Toolbar,
    FilterPanel,
    ColumnChooser,
    Export,
    FilterRow,
    SortByGroupSummaryInfo,
    TotalItem,
    Summary,
    GroupItem,
    MasterDetail,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { ExportingEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import React, { useContext, useMemo, useRef, useState } from 'react';
import {
    CellInfoType,
    DelinquencyData,
    Note,
    NoteReferenceType,
    PropertyType,
    SelectedDataLevel,
    SelectOptions,
} from 'waypoint-types';
import { customSummaryDataFields } from './utils';
import {
    useDataGridHeight,
    useGetGenericEntityReportMetadataNotes,
    useGetUserData,
    useSortByGroupSummaryInfo,
} from 'waypoint-hooks';
import { CalculateCustomSummaryOptions } from 'waypoint-utils/dev-extreme/weighted-averages';
import { AgedReceivablesDetail } from './AgedReceivablesDetail';
import { css } from 'emotion';
import AgedReceivablesActionsCell from './AgedReceivablesActionsCell';
import AgedReceivablesNoteModal from './AgedReceivablesNoteModal';
import {
    exportExcelFromDevExtremeDataGrid,
    onContentReady,
} from 'waypoint-utils';
import { safeDivision } from 'shared-types';
import { formatInTimeZone } from 'date-fns-tz';
import {
    Button,
    Dropdown,
    MenuProps,
    Popconfirm,
    Tooltip,
    message,
} from 'antd';
import { MenuOutlined, MoreOutlined } from '@ant-design/icons';
import { deleteNote } from 'waypoint-requests';
import { CustomizeCell } from 'waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import { ExpandAndCollapseButton } from 'waypoint-react';
import { stringSort } from 'utils/tables/sorters';
import { AttributeFromAPI, EntityAttributesContext } from 'contexts';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import { getPropertyOptions } from 'waypoint-utils/entity';
import DelinquentTenantsDrawer from './AgedReceivablesDrawer';
import { Dictionary } from 'ts-essentials';
import { connect, RootStateOrAny } from 'react-redux';
import { selectProperties } from 'state/properties/selectors';
import { AgedReceivablesExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/AgedReceivablesExportableGrid';

interface AgedReceivableTableProps {
    properties: Dictionary<PropertyType>;
    delinquencies: DelinquencyData[];
    entityCodes: string[];
    isPropertyProfilePage: boolean;
    attributeSelection?: AttributeFromAPI | null;
    groupingSelection?: EntityDataGroupingKeys;
    isPDFExport?: boolean;
    isRollupPage?: boolean;
    selectedDataLevel?: SelectedDataLevel;
    period?: string;
    isReportWidget?: boolean;
}

const detailSizeStyles = css`
    .dx-master-detail-cell div div {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
`;

const AGED_RECEIVABLES_NOTES_TYPE = 'aged-receivables';

const AgedReceivablesTable = ({
    properties,
    delinquencies,
    entityCodes,
    attributeSelection,
    groupingSelection,
    isPropertyProfilePage,
    isPDFExport,
    selectedDataLevel,
    period,
    isReportWidget,
}: AgedReceivableTableProps) => {
    const delinquencyGrid = useRef<DataGridRef | null>(null);

    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);
    const [isNotesModalVisible, setIsNotesModalVisible] =
        useState<boolean>(false);
    const [noteReferenceId, setNoteReferenceId] = useState<string | null>(null);
    const [selectedNote, setSelectedNote] = useState<Note | null>(null);

    const { sortSelection, sortOrderAscending } = useSortByGroupSummaryInfo();
    const { userId, isAdmin } = useGetUserData();

    const [isHistoryModalVisible, setIsHistoryModalVisible] =
        useState<boolean>(false);
    const [entityCodeSelected, setEntityCodeSelected] = useState<string | null>(
        null,
    );

    const isAttributeGroupingSelection =
        groupingSelection === EntityDataGroupingKeys.Attributes;

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const { data: notes, mutate: mutateNotes } =
        useGetGenericEntityReportMetadataNotes(
            entityCodes[0],
            NoteReferenceType.AgedReceivables,
            AGED_RECEIVABLES_NOTES_TYPE,
            {
                revalidateOnFocus: true,
            },
        );

    const groupCell = (options: CellInfoType) => {
        return <div>{options.value}</div>;
    };

    const propertyOptions = getPropertyOptions(properties, entityCodes);

    const entityAttributesContext = useContext(EntityAttributesContext);
    const attributeDefinitions =
        entityAttributesContext?.data?.attributeDefinitions;

    const selectedAttribute =
        attributeSelection &&
        attributeDefinitions?.find(
            (attr) => attr.attribute_code === attributeSelection.key,
        );

    const groupedData = selectedAttribute
        ? delinquencies?.map((d) => {
              const values = selectedAttribute.attributeValues?.filter(
                  (attr) => attr.entity_code === d.entity_code,
              );

              const valueKey = values?.length
                  ? values.map((val) => val.value).join(', ')
                  : 'Unassigned';

              return {
                  ...d,
                  [attributeSelection.key]: valueKey
                      ? valueKey ?? 'Unassigned'
                      : 'Unassigned',
              };
          })
        : delinquencies;

    // for Excel and PDF exports
    const renderNotesAsString = (noteArray: Note[]) => {
        return noteArray
            .map(
                (note: Note) =>
                    `${getFormattedNoteDate(note.timestamps.updated_at)} - ${
                        note.text
                    }`,
            )
            .join('\r\n');
    };

    const getFormattedNoteDate = (dateString: string) => {
        return formatInTimeZone(new Date(dateString), 'UTC', 'MM/dd/yyyy');
    };

    const onDeleteNote = async (noteId: string) => {
        if (!noteId || !notes) {
            return;
        }

        const updatedNotes = [...notes.filter((n) => n.id !== noteId)];

        await mutateNotes(
            async () => {
                try {
                    await deleteNote(noteId);
                    return updatedNotes;
                } catch (e) {
                    message.error('An error occurred while deleting note');

                    return notes;
                }
            },
            {
                optimisticData: updatedNotes,
                rollbackOnError: true,
            },
        );
    };

    const getNoteReferenceId = (data: DelinquencyData) => {
        return `${data.entity_code}_${data.tenant_code}_${data.lease_code}_${data.leasable_space_code}`;
    };

    useMemo(() => {
        delinquencies.map((delinquency) => {
            const delinquencyReferenceId = getNoteReferenceId(delinquency);
            const delinquencyNotes =
                notes
                    ?.filter(
                        (note) => note.reference_id === delinquencyReferenceId,
                    )
                    .sort((a: Note, b: Note) =>
                        stringSort(
                            a.timestamps.updated_at,
                            b.timestamps.updated_at,
                        ),
                    ) ?? [];
            if (isPDFExport) {
                delinquency.notes_string =
                    renderNotesAsString(delinquencyNotes);
            } else {
                delinquency.notes = delinquencyNotes;
            }
            return delinquency;
        });
    }, [notes, delinquencies, isPDFExport]);

    useMemo(() => {
        const groupIndex =
            groupingSelection === EntityDataGroupingKeys.Attributes &&
            delinquencyGrid
                ? 0
                : -1;
        delinquencyGrid?.current
            ?.instance()
            .columnOption(0, 'groupIndex', groupIndex);
    }, [delinquencyGrid, groupingSelection, attributeSelection]);

    const onExporting = async (e: ExportingEvent) => {
        const customizeCell: CustomizeCell = ({ gridCell, excelCell }) => {
            if (
                gridCell?.rowType === 'data' &&
                gridCell?.column?.dataField === 'notes'
            ) {
                excelCell.value = renderNotesAsString(gridCell.value);
            }
        };
        await exportExcelFromDevExtremeDataGrid(
            e,
            {
                worksheetName: 'Aged_Receivables',
                filename: 'Aged_Receivables.xlsx',
            },
            customizeCell,
        );
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        const isGrouped = e.rowType === 'group';
        const isExpanded = e.isExpanded && e.rowType !== 'group';
        const isHeader = e.rowType === 'header';
        if (isGrouped) {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (isExpanded) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.backgroundColor = theme.colors.blues.focusRow;
            e.rowElement.style.borderColor = theme.colors.grays.background;
        }
        if (isHeader) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.textDecorationColor = theme.colors.grays.text;
            e.rowElement.style.color = theme.colors.grays.text;
        }
    };

    const dataGridHeight = useDataGridHeight({ topBarHeight: 160 });

    const totalSum = delinquencies.reduce((sum, item) => sum + item.total, 0);

    const calculateCustomSummary = (
        options: CalculateCustomSummaryOptions<boolean, any>,
    ) => {
        if (customSummaryDataFields.includes(options.name)) {
            switch (options.summaryProcess) {
                case 'start': {
                    options.totalValue = {
                        [options.name]: 0,
                    };
                    break;
                }
                case 'calculate': {
                    options.totalValue[options.name] += options.value ? 1 : 0;
                    break;
                }
                case 'finalize': {
                    options.totalValue = options.totalValue[options.name];
                    break;
                }
            }
        }
    };

    const renderActionCell = (data: DelinquencyData) => {
        const selectedRowNoteReferenceId = getNoteReferenceId(data);

        const openHistoryModal = () => {
            setEntityCodeSelected(data.entity_code);
            setIsHistoryModalVisible(!isHistoryModalVisible);
        };

        const items = [
            {
                key: '1',
                label: 'Show Tenant Detail',
                onClick: openHistoryModal,
            },
        ];

        return isPropertyProfilePage ? (
            <AgedReceivablesActionsCell
                isVisible={isNotesModalVisible}
                setIsVisible={setIsNotesModalVisible}
                agedReceivablesNoteReferenceId={selectedRowNoteReferenceId}
                setAgedReceivablesNoteReferenceId={setNoteReferenceId}
            />
        ) : (
            <div>
                <Dropdown
                    trigger={['click']}
                    placement={'bottomRight'}
                    menu={{ items }}
                >
                    <span
                        style={{
                            fontSize: '13px',
                            fontWeight: 'normal',
                            textTransform: 'none',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        <MenuOutlined />
                    </span>
                </Dropdown>
            </div>
        );
    };

    const DetailTemplate = ({
        data,
    }: {
        data: { data: { detail_rows: DelinquencyData[] } };
    }) => {
        const transactions = data?.data?.detail_rows;
        return (
            <div
                style={{
                    padding: '10px',
                }}
            >
                <div
                    style={{
                        display: 'block',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        lineHeight: '1.25',
                        marginRight: '10%',
                        marginLeft: '10%',
                    }}
                >
                    <p>
                        <AgedReceivablesDetail delinquencies={transactions} />
                    </p>
                </div>
            </div>
        );
    };

    const notesRender = (data: DelinquencyData) => {
        const getItems = (note: Note): MenuProps['items'] => {
            return [
                {
                    label: (
                        <div
                            onClick={() => {
                                setSelectedNote(note);
                                setIsNotesModalVisible(true);
                            }}
                        >
                            <div>Edit</div>
                        </div>
                    ),
                    key: '1',
                },
                {
                    label: (
                        <Popconfirm
                            placement="top"
                            title={'Are you sure you want to delete this item?'}
                            okType="danger"
                            okText="Delete"
                            onConfirm={() => onDeleteNote(note.id)}
                            cancelText="Cancel"
                        >
                            <div>Delete</div>
                        </Popconfirm>
                    ),
                    key: '2',
                },
            ];
        };

        return data?.notes?.map((note: Note) => {
            const canDeleteOrEdit = isAdmin || note?.author.id === userId;
            return (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip
                        placement="topLeft"
                        title={`Created by ${note?.author?.firstname ?? ''} ${
                            note?.author?.lastname ?? ''
                        }`}
                    >
                        <p
                            style={{
                                textAlign: 'left',
                                marginBottom: 0,
                                padding: '4px 0',
                            }}
                        >
                            {getFormattedNoteDate(note.timestamps.updated_at)}
                            {' - '}
                            {note.text}
                        </p>
                    </Tooltip>
                    {canDeleteOrEdit ? (
                        <Dropdown
                            placement={'bottomRight'}
                            menu={{ items: getItems(note) }}
                        >
                            <Button
                                style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                }}
                            >
                                <MoreOutlined />
                            </Button>
                        </Dropdown>
                    ) : null}
                </div>
            );
        });
    };

    if (isPDFExport) {
        return <AgedReceivablesExportableGrid data={delinquencies} />;
    }

    return (
        <>
            {isHistoryModalVisible &&
                entityCodeSelected &&
                selectedDataLevel && (
                    <DelinquentTenantsDrawer
                        isVisible={isHistoryModalVisible}
                        setIsVisible={setIsHistoryModalVisible}
                        entityCode={entityCodeSelected}
                        selectedDataLevel={selectedDataLevel}
                        period={period}
                        propertyName={
                            propertyOptions?.find(
                                (property: SelectOptions) =>
                                    property.value === entityCodeSelected,
                            )?.label ?? ''
                        }
                    />
                )}
            <AgedReceivablesNoteModal
                selectedNote={selectedNote}
                setSelectedNote={setSelectedNote}
                isVisible={isNotesModalVisible}
                setIsVisible={setIsNotesModalVisible}
                agedReceivablesNoteReferenceId={noteReferenceId ?? ''}
                setAgedReceivablesNoteReferenceId={setNoteReferenceId}
                entityCode={entityCodes[0]}
                mutateReportNotes={mutateNotes}
            />
            <div className={detailSizeStyles}>
                <DataGrid
                    ref={delinquencyGrid}
                    wordWrapEnabled={!isPDFExport}
                    hoverStateEnabled={true}
                    dataSource={groupedData}
                    allowColumnReordering={!isReportWidget}
                    sorting={{ mode: isReportWidget ? 'none' : 'single' }}
                    height={dataGridHeight}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    data-testid="aged-receivables-table"
                    onRowPrepared={onRowPrepared}
                    onContentReady={(e) =>
                        onContentReady({ e, toggleFunc: setExpandButtonEnable })
                    }
                    columnMinWidth={100}
                    onExporting={onExporting}
                >
                    {isPropertyProfilePage && (
                        <MasterDetail
                            enabled={true}
                            component={DetailTemplate}
                        />
                    )}
                    <ColumnChooser
                        allowSearch={true}
                        enabled={true}
                        mode={'select'}
                        height={350}
                    >
                        <Position
                            my="right top"
                            at="right bottom"
                            of=".dx-datagrid-column-chooser-button"
                        />
                        <ColumnChooserSelection
                            allowSelectAll={true}
                            recursive={true}
                        />
                    </ColumnChooser>

                    <HeaderFilter
                        allowSelectAll={true}
                        visible={true}
                        allowSearch={true}
                        height={350}
                    />
                    <FilterRow visible={!isReportWidget} applyFilter="auto" />
                    <FilterPanel visible={!isReportWidget} />
                    <SortByGroupSummaryInfo
                        summaryItem={sortSelection}
                        sortOrder={sortOrderAscending ? 'asc' : 'desc'}
                    />
                    <Grouping
                        contextMenuEnabled={!isReportWidget}
                        autoExpandAll={expanded}
                    />
                    {isPropertyProfilePage && (
                        <GroupPanel visible={isReportWidget ? false : 'auto'} />
                    )}
                    <ColumnChooser
                        allowSearch={true}
                        enabled={true}
                        mode={'select'}
                        height={350}
                    >
                        <Position
                            my="right top"
                            at="right bottom"
                            of=".dx-datagrid-column-chooser-button"
                        />
                        <ColumnChooserSelection
                            allowSelectAll={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    <Paging enabled={true} defaultPageSize={20} />
                    <Pager
                        visible
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        allowedPageSizes={[20, 40, 60, 80, 100]}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} items)"
                    />
                    <Export allowExportSelectedData={false} enabled={true} />
                    <SearchPanel
                        width={240}
                        placeholder="Search..."
                        visible={true}
                    />
                    <Toolbar>
                        <ToolbarItem location="before" name="groupPanel" />
                        <ToolbarItem
                            location="before"
                            visible={!isReportWidget}
                        >
                            <ExpandAndCollapseButton
                                expanded={expanded}
                                toggleExpanded={toggleExpanded}
                                expandButtonEnable={expandButtonEnable}
                            />
                        </ToolbarItem>
                        <ToolbarItem name="addRowButton" />
                        <ToolbarItem name="exportButton" />
                        <ToolbarItem
                            location="after"
                            name="columnChooserButton"
                        />
                        <ToolbarItem name="searchPanel" locateInMenu="auto" />
                    </Toolbar>

                    <Column
                        fixed
                        visible={isAttributeGroupingSelection}
                        showInColumnChooser={isAttributeGroupingSelection}
                        minWidth={180}
                        dataField={attributeSelection?.key}
                        name={attributeSelection?.key}
                        caption={attributeSelection?.title ?? ''}
                        width={'auto'}
                        allowHiding={false}
                        groupCellRender={groupCell}
                    />

                    <Column
                        dataField="entity_code"
                        caption="Property"
                        dataType="string"
                        fixed={true}
                        fixedPosition="left"
                        width={'auto'}
                        minWidth={200}
                        visible={!isPropertyProfilePage}
                        showInColumnChooser={!isPropertyProfilePage}
                        customizeText={({ value }: { value: string }) =>
                            propertyOptions?.find(
                                (property: SelectOptions) =>
                                    property.value === value,
                            )?.label ?? ''
                        }
                    />
                    <Column
                        dataField={'tenant_name'}
                        caption={'Tenant'}
                        allowHeaderFiltering={isPropertyProfilePage}
                        allowGrouping
                        minWidth={300}
                        width={'auto'}
                        visible={isPropertyProfilePage}
                        showInColumnChooser={isAttributeGroupingSelection}
                    />
                    <Column
                        dataField={'tenant_code'}
                        caption={'Tenant Code'}
                        alignment={'center'}
                        allowHeaderFiltering={isPropertyProfilePage}
                        allowGrouping
                        minWidth={120}
                        visible={false}
                        calculateCellValue={({
                            tenant_code,
                        }: DelinquencyData) =>
                            tenant_code.split('_').slice(-1)[0]
                        }
                    />
                    <Column
                        allowHeaderFiltering={isPropertyProfilePage}
                        dataField="unit"
                        caption="Unit #"
                        alignment="center"
                        allowGrouping
                        visible={isPropertyProfilePage}
                        showInColumnChooser={isPropertyProfilePage}
                    />

                    <Column
                        dataField={'unit_count'}
                        caption={'Units'}
                        allowHeaderFiltering={isPropertyProfilePage}
                        allowGrouping
                        width={100}
                        alignment={'center'}
                        visible={!isPropertyProfilePage}
                        showInColumnChooser={!isPropertyProfilePage}
                    />
                    <Column
                        dataField={'delinquent_unit'}
                        caption={'Units w/ Balance'}
                        allowHeaderFiltering={isPropertyProfilePage}
                        allowGrouping
                        width={100}
                        alignment={'center'}
                        visible={!isPropertyProfilePage}
                        showInColumnChooser={!isPropertyProfilePage}
                    />
                    <Column
                        dataField="source_system_lease_code"
                        caption="Lease Code"
                        name="source_system_lease_code"
                        allowHeaderFiltering={isPropertyProfilePage}
                        allowGrouping
                        width={120}
                        alignment={'center'}
                        visible={isPropertyProfilePage}
                        showInColumnChooser={isPropertyProfilePage}
                    />
                    <Column
                        allowHeaderFiltering={isPropertyProfilePage}
                        dataField="active_lease"
                        caption="Status"
                        dataType="string"
                        alignment="center"
                        customizeText={({ value }: { value: boolean }) =>
                            value ? 'Active' : 'Inactive'
                        }
                        allowGrouping
                        visible={isPropertyProfilePage}
                        showInColumnChooser={isPropertyProfilePage}
                    />

                    <Column caption="Balance Summary" alignment={'center'}>
                        <Column
                            dataField="balance"
                            caption="Current"
                            allowHeaderFiltering={false}
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            dataField="prepaid_balance"
                            caption="Prepaid"
                            allowHeaderFiltering={false}
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            dataField="overdue_balance"
                            caption="Overdue"
                            allowHeaderFiltering={false}
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            dataField="total"
                            caption="Total"
                            allowHeaderFiltering={false}
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>

                        <Column
                            dataField="percentage_total"
                            caption="% of Total"
                            allowHeaderFiltering={false}
                            allowGrouping={true}
                            width={120}
                            calculateCellValue={(e: DelinquencyData) =>
                                safeDivision(e.total, totalSum)
                            }
                            visible={!isPropertyProfilePage}
                            showInColumnChooser={!isPropertyProfilePage}
                            format={{ type: 'percent', precision: 1 }}
                        />
                    </Column>
                    <Column caption="Aging Period" alignment={'center'}>
                        <Column
                            dataField="thirty_days_due_balance"
                            caption="1-30 Days"
                            allowHeaderFiltering={false}
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            dataField="sixty_days_due_balance"
                            caption="31-60 Days"
                            allowHeaderFiltering={false}
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            dataField="ninety_days_due_balance"
                            caption="61-90 Days"
                            allowHeaderFiltering={false}
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            dataField="ninety_more_days_due_balance"
                            caption="> 90 Days"
                            allowHeaderFiltering={false}
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                    </Column>
                    {!isPDFExport ? (
                        <Column
                            dataField="notes"
                            caption="Notes"
                            alignment="left"
                            minWidth={100}
                            width={300}
                            cellRender={(e) => notesRender(e.data)}
                            allowGrouping={false}
                            allowHeaderFiltering={false}
                            visible={isPropertyProfilePage}
                            showInColumnChooser={isPropertyProfilePage}
                        />
                    ) : (
                        <Column
                            dataField="notes_string"
                            caption="Notes"
                            alignment="left"
                            minWidth={100}
                            width={'auto'}
                            allowGrouping={false}
                            allowHeaderFiltering={false}
                        />
                    )}
                    <Column
                        caption=""
                        name={'actions'}
                        fixed={true}
                        fixedPosition="right"
                        allowExporting={false}
                        showInColumnChooser={false}
                        cellRender={(e) => {
                            const isGroupRow = e.rowType === 'group';
                            const isDetailRowWithinGroup =
                                !isGroupRow && isAttributeGroupingSelection;

                            if (isGroupRow || isDetailRowWithinGroup) {
                                return renderActionCell(e.data);
                            }

                            return isGroupRow ? null : renderActionCell(e.data);
                        }}
                        width={40}
                        minWidth={40}
                        alignment={'center'}
                        allowGrouping={false}
                    />
                    <Summary calculateCustomSummary={calculateCustomSummary}>
                        <GroupItem
                            name="group_total_properties"
                            column="property"
                            summaryType="count"
                            displayFormat="{0} Properties"
                            alignByColumn
                            showInColumn="property"
                        />
                        <GroupItem
                            name="group_total_tenants"
                            column="tenant_name"
                            summaryType="count"
                            displayFormat="{0} Tenants"
                            alignByColumn
                            showInColumn="tenant_name"
                        />
                        <GroupItem
                            name="group_total_units"
                            column="unit_count"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="unit_count"
                        />
                        <GroupItem
                            name="group_total_units"
                            column="unit"
                            summaryType="count"
                            displayFormat="{0} Units"
                            alignByColumn
                            showInColumn="unit"
                        />
                        <GroupItem
                            name="group_source_system_lease_code"
                            column="source_system_lease_code"
                            summaryType="count"
                            displayFormat="{0} Leases"
                            alignByColumn
                            showInColumn="lease_code"
                        />
                        <GroupItem
                            name="group_status"
                            column="active_lease"
                            summaryType="custom"
                            displayFormat="{0} Active"
                            alignByColumn
                            showInColumn="active_lease"
                        />
                        <GroupItem
                            name="group_total"
                            column="total"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="total"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <GroupItem
                            name="group_balance"
                            column="balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="balance"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <GroupItem
                            name="group_prepaid_balance"
                            column="prepaid_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="prepaid_balance"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <GroupItem
                            name="group_overdue_balance"
                            column="overdue_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="overdue_balance"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <GroupItem
                            name="group_thirty_days_due_balance"
                            column="thirty_days_due_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="thirty_days_due_balance"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <GroupItem
                            name="group_sixty_days_due_balance"
                            column="sixty_days_due_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="sixty_days_due_balance"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <GroupItem
                            name="group_ninety_days_due_balance"
                            column="ninety_days_due_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="ninety_days_due_balance"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <GroupItem
                            name="group_ninety_more_days_due_balance"
                            column="ninety_more_days_due_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="ninety_more_days_due_balance"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <GroupItem
                            name="delinquent_unit"
                            column="delinquent_unit"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="delinquent_unit"
                        />
                        <GroupItem
                            name="group_percentage_total"
                            column="percentage_total"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                            showInColumn="percentage_total"
                            valueFormat={{ type: 'percent', precision: 1 }}
                        />
                        <TotalItem
                            name="total_property_name"
                            column="property"
                            summaryType="count"
                            displayFormat="{0} Properties"
                        />
                        <TotalItem
                            name="total_tenants"
                            column="tenant_name"
                            summaryType="count"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            name="total_lease_codes"
                            column="source_system_lease_code"
                            summaryType="count"
                            displayFormat="{0} Leases"
                        />
                        <TotalItem
                            name="total_units"
                            column="unit_count"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn={'unit_count'}
                        />
                        <TotalItem
                            name="total_units"
                            column="unit"
                            summaryType="count"
                            displayFormat="{0} Units"
                            showInColumn={'unit'}
                        />
                        <TotalItem
                            name="total_status"
                            column="active_lease"
                            summaryType="custom"
                            displayFormat="{0} Active"
                        />
                        <TotalItem
                            name="total"
                            column="total"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="total_balance"
                            column="balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="total_prepaid_balance"
                            column="prepaid_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="total_overdue_balance"
                            column="overdue_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="total_thirty_days_due_balance"
                            column="thirty_days_due_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="total_sixty_days_due_balance"
                            column="sixty_days_due_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="total_ninety_days_due_balance"
                            column="ninety_days_due_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="total_ninety_more_days_due_balance"
                            column="ninety_more_days_due_balance"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />
                        <TotalItem
                            name="total_property_name"
                            column="property"
                            summaryType="count"
                            displayFormat="{0} Properties"
                        />
                        <TotalItem
                            name="total_delinquent_unit"
                            column="delinquent_unit"
                            summaryType="sum"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            name="total_percentage_total"
                            column="percentage_total"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'percent', precision: 1 }}
                        />
                    </Summary>
                </DataGrid>
            </div>
        </>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);
    return {
        properties,
    };
};

export default connect(mapState)(AgedReceivablesTable);
