import React, {
    useState,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
} from 'react';
import { EditOutlined } from '@ant-design/icons';
import { ContentRenderer } from 'waypoint-react';
import { updateOrInsertEntityProfile } from 'waypoint-requests';
import { format, isValid, parseISO } from 'date-fns';
import { Card, Tooltip, Button } from 'antd';
import { css } from 'emotion';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { message } from 'antd';
import KendoEditor from 'waypoint-react/kendo/Editor';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { EntityProfile } from 'waypoint-types/properties/types';
import {
    useGetDocumentUploadListByReferenceParams,
    useGetEntityProfile,
} from 'waypoint-hooks';
import { DocumentUploadReferenceType } from 'components/uploads/DocumentUploadsUtils';
import { updateImageSrcWithSignedURLs } from 'waypoint-react/kendo/Utils';

const centeredContent = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`;

const wraperTooltip = css`
    width: 380px !important;
    max-width: 380px !important;
`;

const noBorderStyle = (content: string) => css`
    display: ${content.length ? 'block' : 'none'};
    border: none !important;
    .ant-card-head {
        display: none !important;
    }
`;

interface PropertyProfileExecutiveSummaryProps {
    entity_code: string;
    widgetId?: string;
    isPDFExport?: boolean;
}

const MAXIMUM_LENGTH_EXECUTIVE_SUMMARY_CONTENT = 10000;
const PropertyProfileExecutiveSummary = React.forwardRef<
    PDFExportable,
    PropertyProfileExecutiveSummaryProps
>(
    (
        {
            entity_code,
            widgetId,
            isPDFExport,
        }: PropertyProfileExecutiveSummaryProps,
        ref,
    ) => {
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [entityProfile, setEntityProfile] =
            useState<EntityProfile | null>(null);
        const [content, setContent] = useState<string>('');
        const [oldContent, setOldContent] = useState<string>('');
        const [isEditing, setIsEditing] = useState<boolean>(false);
        const [isSaving, setIsSaving] = useState<boolean>(false);

        const { data: entityProfileData } = useGetEntityProfile(entity_code);

        const { data: uploadedImages, mutate } =
            useGetDocumentUploadListByReferenceParams(
                DocumentUploadReferenceType.EntityExecutiveSummary,
                entity_code,
            );

        const widgetExportId = widgetId
            ? `narrativePDFWrapper_executive_summary_${widgetId}`
            : 'propertyExecutiveSummaryContainer';

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !isLoading;
            },
        }));

        useEffect(() => {
            if (isEditing) {
                setOldContent(content);
            }
        }, [isEditing]);

        useEffect(() => {
            const fetchExecutiveSummaryData = async () => {
                try {
                    setIsLoading(true);
                    setEntityProfile(entityProfileData ?? null);
                    setContent(entityProfileData?.executive_summary ?? '');
                } catch (e) {
                    message.error(
                        'An error occurred while requesting executive summaries',
                    );
                } finally {
                    setIsLoading(false);
                }
            };

            fetchExecutiveSummaryData();
        }, [entityProfileData]);

        useEffect(() => {
            updateImageSrcWithSignedURLs(
                content,
                uploadedImages ?? [],
                onUpdateContent,
            );
        }, [uploadedImages]);

        // on page enter, force the images request to get the most recent S3 signed urls
        useLayoutEffect(() => {
            mutate();
        }, []);

        // In case the user stays in the page the S3 15 minute expiration span,
        // refetch the image request to trigger url update before
        useEffect(() => {
            const intervalId = setInterval(() => {
                mutate();
            }, 780 * 1000);

            return () => clearInterval(intervalId);
        }, [mutate]);

        const postExecutiveSummary = async () => {
            setIsSaving(true);

            if (oldContent === content) {
                setIsEditing(false);
                return;
            }

            if (content.length > MAXIMUM_LENGTH_EXECUTIVE_SUMMARY_CONTENT) {
                message.error(
                    `Error: Executive summary cannot be longer than ${MAXIMUM_LENGTH_EXECUTIVE_SUMMARY_CONTENT.toLocaleString()} characters`,
                );
                return;
            }
            try {
                setIsLoading(true);
                const updatedEntityProfile: EntityProfile =
                    await updateOrInsertEntityProfile({
                        entity_code,
                        executive_summary: content,
                    });
                setEntityProfile(updatedEntityProfile);
            } catch (e) {
                message.error(
                    'Request Failed: An error occurred while saving your executive summary.',
                );
            } finally {
                setIsLoading(false);
                setIsEditing(false);
                setIsSaving(false);
                message.success('Executive summary saved');
            }
        };

        const onUpdateContent = (text: string) => {
            setContent(
                text.length > MAXIMUM_LENGTH_EXECUTIVE_SUMMARY_CONTENT
                    ? text.slice(
                          0,
                          MAXIMUM_LENGTH_EXECUTIVE_SUMMARY_CONTENT - 1,
                      )
                    : text,
            );
        };

        const onCancel = () => {
            setContent(oldContent);
            setIsEditing(false);
        };

        const showLastEditedBy = (): boolean => {
            return (
                entityProfile?.last_author !== undefined &&
                entityProfile?.executive_summary_updated_at !== undefined &&
                isValid(
                    parseISO(
                        entityProfile.executive_summary_updated_at as string,
                    ),
                )
            );
        };

        const toolTipText = (
            <>
                {entityProfile?.executive_summary ? (
                    <div>
                        <div>
                            Click to {isEditing ? 'close' : 'edit'} the
                            Executive Summary {isEditing ? 'editor' : ''}
                        </div>
                        {showLastEditedBy() && (
                            <div>
                                Last edited by{' '}
                                {entityProfile?.last_author?.firstname}{' '}
                                {entityProfile?.last_author?.lastname} on{' '}
                                {format(
                                    new Date(
                                        entityProfile?.executive_summary_updated_at,
                                    ),
                                    'MMM dd, yyyy',
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        Add the executive summary (Maximum{' '}
                        {MAXIMUM_LENGTH_EXECUTIVE_SUMMARY_CONTENT.toLocaleString()}{' '}
                        characters)
                    </div>
                )}
            </>
        );
        const renderEditorSection = () => (
            <>
                <KendoEditor
                    height={390}
                    content={content}
                    referenceType={
                        DocumentUploadReferenceType.EntityExecutiveSummary
                    }
                    onChange={onUpdateContent}
                    isLoading={isLoading}
                    data-testid="executive-summary-editor"
                />
                <div className={centeredContent}>
                    <Button
                        onClick={() => postExecutiveSummary()}
                        loading={isLoading}
                        style={{ marginRight: 5 }}
                        type="primary"
                    >
                        Save
                    </Button>
                    <Button onClick={() => onCancel()} disabled={isSaving}>
                        Cancel
                    </Button>
                </div>
            </>
        );

        return (
            <PermissionedWrapper
                featureKey={AppFeaturePermissions.ExecutiveSummary}
            >
                <Card
                    title={
                        <div>
                            <span
                                style={{
                                    display: isPDFExport
                                        ? 'none'
                                        : 'inline-block',
                                }}
                            >
                                Executive Summary
                            </span>
                            <Tooltip
                                overlayClassName={wraperTooltip}
                                placement="topLeft"
                                title={toolTipText}
                            >
                                <span
                                    style={{
                                        display: isPDFExport
                                            ? 'none'
                                            : 'inline-block',
                                        marginLeft: 6,
                                        cursor: 'pointer',
                                    }}
                                    onClick={
                                        isEditing
                                            ? () => onCancel()
                                            : () => setIsEditing(!isEditing)
                                    }
                                >
                                    <EditOutlined />
                                </span>
                            </Tooltip>
                        </div>
                    }
                    id="executive-summary"
                    style={{ margin: '20px 0' }}
                    data-testid="property-executive-summary-card"
                    className={isPDFExport ? noBorderStyle(content) : ''}
                >
                    {isEditing ? (
                        renderEditorSection()
                    ) : (
                        <div id={widgetExportId}>
                            <ContentRenderer
                                content={content ?? ''}
                                data-testid="executive-summary-markdown-renderer"
                                isLoading={isLoading}
                                onClick={() => setIsEditing(true)}
                                canInvokeEdit
                                isPDFExport={!!isPDFExport}
                                placeholder="Click to add an Executive Summary..."
                            />
                        </div>
                    )}
                </Card>
            </PermissionedWrapper>
        );
    },
);

export default PropertyProfileExecutiveSummary;
