import { createContext, useContext } from 'react';
import { PermissionGroupsContextType } from './PermissionGroupsTypes';

export const PermissionsGroupsContext =
    createContext<PermissionGroupsContextType>({
        featurePermissions: [],
        financialMenuSettings: {},
        analyticsMenuSettings: {},
        leasingMenuSettings: {},
        planningMenuSettings: {},
        workflowMenuSettings: {},
        isPermissionGroupsLoading: false,
        isPermissionGroupsNetworkError: false,
    });

export const usePermissions = () => useContext(PermissionsGroupsContext);
