import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VirtualPDFRenderer } from 'waypoint-utils/pdf/VirtualPDFRenderer';
import { useGetReportById } from 'waypoint-hooks';
import {
    getVirtualPDFComponentFactories,
    getWidgetListItemsFromReport,
    userWorkflowRole,
} from 'components/reports/ReportUtils';
import { PropertyType, WorkflowRoleList } from 'waypoint-types';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { WidgetTypes } from '../constants';
import { PermissionsGroupsProvider, usePermissions } from 'contexts';

import { allAccessPermissions } from 'shared-types';

interface ReportExportRendererProps {
    reportId: string;
    entityCode: string;
    widgetIndices?: number[];
    entity: PropertyType | undefined;
    asOfDate: Date | undefined;
    userId: number;
    isAdmin: boolean;
}

export function ReportExportRenderer({
    reportId,
    entityCode,
    widgetIndices,
    entity,
    asOfDate,
    userId,
    isAdmin,
}: ReportExportRendererProps) {
    const { featurePermissions } = usePermissions();
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [workflowRole, setWorkflowRole] = useState<WorkflowRoleList>([]);

    function exportDocument() {
        setTimeout(() => {
            setIsExporting(true);
            // we need a non-state variable to control the loop here...
            // otherwise there's a risk that the document ref will change
            let hasStartedExport = false;

            const pollingInterval = 200; //ms
            console.info('Starting export...');
            // a 'polling' loop to wait until the document is ready for export
            const cancelInterval = setInterval(async () => {
                if (
                    !exportDocumentRef.current ||
                    !exportDocumentRef.current.isReadyToExport() ||
                    hasStartedExport
                ) {
                    return;
                }

                hasStartedExport = true;
                clearInterval(cancelInterval);
                try {
                    if (!exportDocumentRef.current.exportToPDF) {
                        return;
                    }
                    const builder =
                        await exportDocumentRef.current.exportToPDF();
                    await builder.save(
                        `${reportId}_${entityCode}_${(widgetIndices ?? []).join(
                            ',',
                        )}`,
                    );

                    console.info(`Finished indices ${widgetIndices} export`);
                } catch (e) {
                    console.error(
                        `Something went wrong for indices ${widgetIndices}: ${e}`,
                    );
                } finally {
                    setIsExporting(false);
                }
            }, pollingInterval);
        }, 1000);
    }

    const { data: report } = useGetReportById(reportId);

    const factories = useMemo(() => {
        const coverPageSettings = report?.reportSettings.find(
            (setting) => setting.settings_type === WidgetTypes.CoverPage,
        );

        const includeTableOfContents =
            report?.reportSettings.find(
                (setting) =>
                    setting.settings_type === WidgetTypes.TableOfContents,
            )?.enabled ?? false;

        const reportLogo = report?.reportSettings.find(
            (setting) => setting.settings_type === WidgetTypes.Footer,
        );

        const widgetsToExport = report
            ? getWidgetListItemsFromReport(
                  entityCode,
                  report,
                  coverPageSettings,
                  includeTableOfContents,
              )
            : [];

        const filteredWidgets =
            !widgetIndices || !widgetIndices.length
                ? widgetsToExport
                : widgetsToExport.filter((widget, widgetIndex) => {
                      return widgetIndices.includes(widgetIndex);
                  });

        const entityReport =
            report?.entityReports.find(
                (entityReport) => entityReport.entity_code === entityCode,
            ) ?? null;

        setWorkflowRole(userWorkflowRole({ userId, entityReport }));

        return getVirtualPDFComponentFactories({
            widgetsToExport: filteredWidgets,
            entityName: entity?.name ?? 'unknown',
            entity,
            asOfDate,
            entityCode,
            entityReport,
            report,
            reportHasLogo:
                reportLogo?.enabled &&
                !!reportLogo?.settings?.document_upload_id,
        });
    }, [report, entityCode]);

    const exportDocumentRef = useRef<PDFExportable>(null);

    useEffect(() => {
        if (report && factories.length) {
            exportDocument();
        }
    }, [report, factories.length]);

    if (!factories.length) {
        return null;
    }

    const userPermissions =
        isAdmin || workflowRole.length > 0
            ? allAccessPermissions
            : featurePermissions;

    return (
        <>
            <PermissionsGroupsProvider userPermissions={userPermissions}>
                <VirtualPDFRenderer
                    ref={exportDocumentRef}
                    componentFactories={factories}
                    visible={true}
                />
            </PermissionsGroupsProvider>
        </>
    );
}
