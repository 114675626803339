export enum AppFeaturePermissions {
    MyReports = 'my_reports',
    AgedReceivables = 'aged_receivables',
    PropertyValueDebt = 'property_value_debt',
    Ranking = 'ranking',
    BalanceSheetOverview = 'balance_sheet_overview',
    BalanceSheet = 'balance_sheet',
    Crosstab = 'crosstab',
    VarianceReporting = 'variance_reporting',
    PerformanceOverview = 'performance_overview',
    RentRoll = 'rent_roll',
    ExpirationSchedule = 'expiration_schedule',
    Tenants = 'tenants',
    TopTenants = 'top_tenants',
    OccupancyTrend = 'occupancy_trend',
    UnitMix = 'unit_mix',
    CapitalProjects = 'capital_projects',
    PrioritiesAndObjectives = 'priorities_and_objectives',
    HoldSell = 'hold_sell',
    MarketOverview = 'market_overview',
    Swot = 'swot',
    LeasingGuidelines = 'leasing_guidelines',
    LeasingPlan = 'leasing_plan',
    Contracts = 'contracts',
    Attributes = 'attributes',
    FinancialOverview = 'financial_overview',
    PortfolioSummary = 'portfolio_summary',
    IncomeStatement = 'income_statement',
    ExecutiveSummary = 'executive_summary',
    PropertyInformation = 'property_information',
    Valuations = 'valuations',
    Documents = 'documents',
    KPIs = 'kpis',
}

export const allAccessPermissions = Object.values(AppFeaturePermissions);

/**
 * FEATURE FLAGS
 */
export const FEATURE_NATIVE_ANALYTICS = 'FEATURE_NATIVE_ANALYTICS';
export const FEATURE_PORTFOLIO_SEGMENTATION = 'FEATURE_PORTFOLIO_SEGMENTATION';
export const FEATURE_BALANCE_SHEET = 'FEATURE_BALANCE_SHEET';
export const FEATURE_BALANCE_SHEET_OVERVIEW = 'FEATURE_BALANCE_SHEET_OVERVIEW';
export const FEATURE_OPPORTUNITIES = 'FEATURE_OPPORTUNITIES';
export const LEASES_FEATURE_FLAG = 'FEATURE_LEASES';
export const FEATURE_PROPERTY_ATTRIBUTES = 'FEATURE_PROPERTY_ATTRIBUTES';
export const FEATURE_TENANTS = 'FEATURE_TENANTS';
export const FEATURE_CROSSTAB = 'FEATURE_CROSSTAB';
export const FEATURE_OCCUPANCY_TREND = 'FEATURE_OCCUPANCY_TREND';
export const FEATURE_VARIANCE_REPORT_DASHBOARD =
    'FEATURE_VARIANCE_REPORT_DASHBOARD';
export const FEATURE_CAPITAL_TRACKER = 'FEATURE_CAPITAL_TRACKER';
export const FEATURE_REPORT_TEMPLATE = 'FEATURE_REPORT_TEMPLATE';
export const FEATURE_PLANNING_SECTION = 'FEATURE_PLANNING_SECTION';
export const FEATURE_EXPIRATION_SCHEDULE = 'FEATURE_EXPIRATION_SCHEDULE';
export const FEATURE_RENT_ROLL = 'FEATURE_RENT_ROLL';
export const FEATURE_UNIT_MIX = 'FEATURE_UNIT_MIX';
export const FEATURE_AGED_RECEIVABLES = 'FEATURE_AGED_RECEIVABLES';
export const FEATURE_BUDGET_UPLOAD = 'FEATURE_BUDGET_UPLOAD';
export const FEATURE_VALUE_AND_DEBT = 'FEATURE_VALUE_AND_DEBT';
export const FEATURE_OWNERSHIP_PERCENTAGE = 'FEATURE_OWNERSHIP_PERCENTAGE';
export const FEATURE_DOCUMENTS = 'FEATURE_DOCUMENTS';
export const FEATURE_KPIS = 'FEATURE_KPIS';
