import { useContext, useMemo, useState } from 'react';
import { css } from 'emotion';
import { EntityAttributesContext } from 'contexts/entity-attributes/EntityAttributesContext';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import Card from 'components/style/Card';
import SegmentationCard from 'components/analytics/portfolioSegmentation/dashboard/SegmentationCard';
import SegmentationPeriodPicker from 'components/analytics/portfolioSegmentation/filters/period/SegmentationPeriodPicker';
import LoadingTable from 'components/style/LoadingTable';
import {
    PROPERTY_VALUE_KEY,
    SEGMENTATION_CARD_OFFSET,
} from 'components/analytics/portfolioSegmentation/constants';
import { usePermissions } from 'contexts';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import theme from 'config/theme';

const loadingCard = css`
    margin: 20px;
    width: calc(100% - 40px);
`;
const headerDashboardStyle = css`
    width: 100%;
    overflow: visible;
    padding: 10px !important;
`;

const filterSectionStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;

const headerStyle = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
`;

interface ConfigProp {
    group_by: string;
    metric: string;
}

const Dashboard = () => {
    const [selectedPeriod, setSelectedPeriod] = useState<string | undefined>(
        undefined,
    );

    const { featurePermissions } = usePermissions();

    const [activeTabKey, setActiveTabKey] = useState(PROPERTY_VALUE_KEY);
    const [config, setConfig] = useState<ConfigProp | null>(null);
    const entityCodes = useGetSelectedFilteredEntityCodes();
    const entityAttributesContext = useContext(EntityAttributesContext);
    const isLoadingEntities = entityAttributesContext?.isAttributesLoading;
    const hasPortfolioSummaryPermission = featurePermissions.includes(
        AppFeaturePermissions.PortfolioSummary,
    );

    const handleActiveTab = (activeKey: string) => {
        setActiveTabKey(activeKey);
    };

    const handleConfig = (config: ConfigProp) => {
        setConfig(config);
    };

    const handlePeriodChange = (selectedPeriod: string) => {
        setSelectedPeriod(selectedPeriod);
    };

    const isDependenciesLoaded = !isLoadingEntities && selectedPeriod;

    const HEIGHT = useMemo(
        () => window.innerHeight - SEGMENTATION_CARD_OFFSET,
        [window.innerHeight],
    );

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.PortfolioSummary}
            showDisabledView={true}
        >
            <div style={{ width: '100%', backgroundColor: theme.colors.white }}>
                {hasPortfolioSummaryPermission ? (
                    <Card className={headerDashboardStyle}>
                        <div className={filterSectionStyle}>
                            <div className={headerStyle}>
                                <h1 style={{ margin: 0, padding: 0 }}>
                                    Portfolio Summary
                                </h1>
                                <SegmentationPeriodPicker
                                    period={selectedPeriod}
                                    entityCodes={entityCodes}
                                    handlePeriodChange={handlePeriodChange}
                                />
                            </div>
                        </div>
                    </Card>
                ) : (
                    <></>
                )}

                {isDependenciesLoaded ? (
                    <SegmentationCard
                        period={selectedPeriod}
                        entityCodes={entityCodes}
                        isDependenciesLoading={isLoadingEntities}
                        setActiveTab={handleActiveTab}
                        activeTabKey={activeTabKey}
                        saveConfig={handleConfig}
                        config={config}
                    />
                ) : (
                    <Card className={loadingCard}>
                        <LoadingTable rowCount={Math.round(HEIGHT / 70) || 7} />
                    </Card>
                )}
            </div>
        </PermissionedWrapper>
    );
};

export default Dashboard;
