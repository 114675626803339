import React, { useState } from 'react';
import { Button, Tabs } from 'antd';
import type { TabsProps } from 'antd';

import { connect, RootStateOrAny } from 'react-redux';
import { selectProperties } from 'state/properties/selectors';
import { Dictionary } from 'ts-essentials';
import {
    CapitalProject,
    PropertyType,
    SavedConfiguration,
} from 'waypoint-types';
import PlanningCapitalProjectsTable from './PlanningCapitalProjectsTable';
import PlanningCapitalProjectPlan from './PlanningCapitalProjectPlan';
import { css } from 'emotion';
import ResponsiveBox, {
    Col,
    Item,
    Location,
    Row,
} from 'devextreme-react/responsive-box';
import { planningContainerTabsKeys } from './PlanningCapitalProjectUtils';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

const tabsStyle = css`
    margin: 14px !important;
    background-color: #fff;
    .ant-tabs-nav-wrap {
        margin-left: -2px;
    }
`;

const DEFAULT_HEIGHT_OFFSET = 210;

interface PlanningCapitalProjectsProps {
    properties: Dictionary<PropertyType>;
    entityCode?: string;
    widgetActiveTab?: string;
    yearsSelectedForWidget?: number[];
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    setIsReadyForPDFExport?: (isReady: boolean) => void;
    hideRangePickerAndViews?: boolean;
    savedConfigData?: SavedConfiguration | null;
    isPDFExport?: boolean;
    heightOffset?: number;
}

const PlanningCapitalProjectsContainer = ({
    properties,
    entityCode,
    widgetActiveTab,
    yearsSelectedForWidget,
    dataGridRef,
    setIsReadyForPDFExport,
    hideRangePickerAndViews,
    savedConfigData,
    isPDFExport,
    heightOffset = DEFAULT_HEIGHT_OFFSET,
}: PlanningCapitalProjectsProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isCapitalPlanModalVisible, setIsCapitalPlanModalVisible] =
        useState<boolean>(false);
    const [isSettingsModalVisible, setIsSettingsModalVisible] =
        useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] =
        useState<CapitalProject | null>(null);

    const [activeTab, setActiveTab] = useState<string>(
        widgetActiveTab ?? planningContainerTabsKeys.trackerTable,
    );

    const tabsItem: TabsProps['items'] = [
        {
            key: planningContainerTabsKeys.trackerTable,
            label: 'Capital Projects',
            children: (
                <PlanningCapitalProjectsTable
                    isSettingsModalVisible={isSettingsModalVisible}
                    setIsSettingsModalVisible={setIsSettingsModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    isModalVisible={isModalVisible}
                    properties={properties}
                    activeTab={activeTab}
                    entityCode={entityCode}
                    dataGridRef={dataGridRef}
                    hideRangePickerAndViews={!!hideRangePickerAndViews}
                    yearsSelectedForWidget={yearsSelectedForWidget}
                    configSelectedForWidget={savedConfigData}
                    selectedRowData={selectedRowData}
                    setSelectedRowData={setSelectedRowData}
                    setIsReadyForPDFExport={setIsReadyForPDFExport}
                    isPDFExport={isPDFExport}
                    heightOffset={heightOffset}
                />
            ),
        },
        {
            key: planningContainerTabsKeys.planTable,
            label: 'Capital Plan',
            children: (
                <PlanningCapitalProjectPlan
                    isModalVisible={isCapitalPlanModalVisible}
                    setIsModalVisible={setIsCapitalPlanModalVisible}
                    isSettingsModalVisible={isSettingsModalVisible}
                    setIsSettingsModalVisible={setIsSettingsModalVisible}
                    activeTab={activeTab}
                    properties={properties}
                    entityCode={entityCode}
                    dataGridRef={dataGridRef}
                    hideRangePickerAndViews={!!hideRangePickerAndViews}
                    yearsSelectedForWidget={yearsSelectedForWidget}
                    configSelectedForWidget={savedConfigData}
                    selectedRowData={selectedRowData}
                    setSelectedRowData={setSelectedRowData}
                    setIsReadyForPDFExport={setIsReadyForPDFExport}
                    isPDFExport={isPDFExport}
                    heightOffset={heightOffset}
                />
            ),
        },
    ];

    const onClickAddRow = () => {
        setSelectedRowData(null);
        setIsModalVisible(true);
    };

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.CapitalProjects}
            showDisabledView={true}
        >
            <div
                style={{
                    left: '10px',
                    right: '10px',
                    top: '140px',
                    bottom: 0,
                }}
            >
                <ResponsiveBox>
                    <Row />;
                    <Col />;
                    <Item>
                        <Location row={0} col={0} colspan={1} />
                        <Tabs
                            className={tabsStyle}
                            type="card"
                            defaultActiveKey={activeTab}
                            onChange={(tabKey) => setActiveTab(tabKey)}
                            tabBarStyle={
                                widgetActiveTab ? { display: 'none' } : {}
                            }
                            items={tabsItem}
                            tabBarExtraContent={
                                <div style={{ padding: 8 }}>
                                    <Button
                                        style={{
                                            marginRight: 10,
                                            marginLeft: 10,
                                        }}
                                        onClick={onClickAddRow}
                                        type="primary"
                                        icon={<PlusOutlined />}
                                    >
                                        Add Project
                                    </Button>

                                    <Button
                                        style={{ marginRight: 3 }}
                                        onClick={() =>
                                            setIsSettingsModalVisible(
                                                !isSettingsModalVisible,
                                            )
                                        }
                                        type="default"
                                    >
                                        <SettingOutlined />
                                    </Button>
                                </div>
                            }
                        />
                    </Item>
                </ResponsiveBox>
            </div>
        </PermissionedWrapper>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);
    return {
        properties,
    };
};

export default connect(mapState)(PlanningCapitalProjectsContainer);
