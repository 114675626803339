import { Skeleton } from 'antd';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { connect, RootStateOrAny } from 'react-redux';
import { PlanningYearSelection } from 'components/planning/components';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { getYearMetadata } from 'waypoint-requests';
import React, { useCallback, useState } from 'react';
import { HoldSellStackedBar } from './HoldSellStackedBar';
import {
    EntityProfileCardYearMetadata,
    PropertyInfoSummary,
} from 'waypoint-types/properties';
import {
    HoldSellGridDataSourceType,
    MetadataType,
    PlanningHoldSellContainerProps,
} from './types';
import { HoldSellGrid } from './HoldSellGrid';
import { HoldSellAnalysis } from 'components/holdSellAnalysis/HoldSellAnalysis';
import ResponsiveBox, {
    Row,
    Col,
    Location,
    Item,
} from 'devextreme-react/responsive-box';
import useSWR from 'swr';
import { stringSort } from 'utils/tables/sorters';
import { css } from 'emotion';
import { EntityProfileCardYearMetadataWithCommentCount } from 'waypoint-types/properties/types';
import { CommentsPaneWrapper } from 'waypoint-react';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const PlanningHoldSellContainer = ({
    properties,
}: PlanningHoldSellContainerProps) => {
    const [selectedRowRecommendation, setSelectedRowRecommendation] =
        useState<string>('');
    const [yearFilter, setYearFilter] = useState<number | null>(null);

    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    const globalFilterProperties = properties.filter((property) =>
        entityCodes.includes(property.code),
    );

    const yearMetadataCacheKey = `/planning/hold-sell/year-metadata/${entityCodes.join(
        ',',
    )}`;

    const {
        data: yearMetadataWithCommentCount,
        mutate: mutateYearMetadataWithCommentCount,
    } = useSWR(
        yearMetadataCacheKey,
        () => getYearMetadata(entityCodes, 'holdSell'),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    React.useEffect(() => {
        if (!yearMetadataWithCommentCount) {
            return;
        }

        const validYears = yearMetadataWithCommentCount.map(
            (item) => item.year,
        );
        const needToSetDefaultYearFilter =
            !yearFilter || !validYears.includes(yearFilter);

        const currentYear = new Date().getFullYear();

        if (needToSetDefaultYearFilter) {
            const maxYear = validYears.length
                ? Math.max(...validYears)
                : currentYear + 1;

            if (maxYear && maxYear !== yearFilter) {
                setYearFilter(maxYear);
            }
        }

        const needToSetDefaultSelectedRow =
            !selectedRowRecommendation ||
            !entityCodes.includes(selectedRowRecommendation);

        if (needToSetDefaultSelectedRow) {
            const entitiesSortedByName = globalFilterProperties.sort(
                (propertyA, propertyB) => {
                    return stringSort(propertyB.name, propertyA.name);
                },
            );

            if (entitiesSortedByName.length) {
                setSelectedRowRecommendation(entitiesSortedByName[0].code);
            }
        }
    }, [yearMetadataWithCommentCount]);

    const recommendationDataSourceCallback = useCallback(() => {
        if (!yearMetadataWithCommentCount) {
            return [];
        }

        return globalFilterProperties.map<HoldSellGridDataSourceType>(
            (property: PropertyInfoSummary) => {
                const filteredMetadata = yearMetadataWithCommentCount.filter(
                    (metadata) => metadata.year === yearFilter,
                );

                const matchingYearMetadataForEntity = filteredMetadata.filter(
                    (item: EntityProfileCardYearMetadata) =>
                        item.entity_code === property.code,
                );

                if (!matchingYearMetadataForEntity.length) {
                    return {
                        entityCode: property.code,
                        entityName: property.name,
                        recommendation: 'Undecided',
                        commentThreadId: null,
                        commentCount: 0,
                    };
                }

                const yearMetadataForEntity = matchingYearMetadataForEntity[0];
                const recommendation = yearMetadataForEntity.metadata.find(
                    (m: MetadataType) => m.key === 'recommendation',
                )?.value;
                const targetedDisposition = yearMetadataForEntity.metadata.find(
                    (m: MetadataType) => m.targetedSaleYear !== null,
                )?.targetedSaleYear;

                return {
                    entityCode: property.code,
                    entityName: property.name,
                    recommendation: recommendation
                        ? recommendation.charAt(0).toUpperCase() +
                          recommendation.slice(1)
                        : 'Undecided',
                    targetedDisposition: targetedDisposition,
                    commentThreadId: yearMetadataForEntity.comment_thread_id,
                    commentCount: yearMetadataForEntity.comment_count,
                };
            },
        );
    }, [globalFilterProperties, yearMetadataWithCommentCount, yearFilter]);

    if (!yearMetadataWithCommentCount) {
        return <Skeleton />;
    }

    const getEntityNameByCode = (entityCode: string) => {
        return properties.find(
            (property: PropertyInfoSummary) => property.code === entityCode,
        )?.name;
    };

    if (!yearFilter) {
        return <Skeleton />;
    }

    const CHART_HEIGHT = 230;
    const SELECT_HEIGHT = 80;
    const tableHeight = window.innerHeight - CHART_HEIGHT - SELECT_HEIGHT - 80;
    const holdSellHeight = window.innerHeight - SELECT_HEIGHT - 80;

    // This is required to get things scrolling. Due to the actual site's overall layout not being responsive,
    // the ResponsiveBox doesn't seem to pick up on 100% heights in certain cases. This gets the backed in
    // grid item column scrolling.
    const fixDxItemHeightStyle = css`
        .dx-item.dx-box-item {
            height: 100%;
            background-color: #fff;
        }
    `;

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.HoldSell}
            showDisabledView={true}
        >
            <div
                className={fixDxItemHeightStyle}
                style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    height: '97%',
                }}
            >
                <CommentsPaneWrapper>
                    <ResponsiveBox>
                        <Row ratio={0} baseSize={SELECT_HEIGHT} />
                        <Row ratio={0} baseSize={CHART_HEIGHT} />
                        <Row />

                        <Col ratio={1} />
                        <Col ratio={2} />

                        <Item>
                            <Location row={0} col={0} colspan={2} />
                            <div
                                style={{
                                    borderBottom: '1px solid lightgrey',
                                    marginBottom: '10px',
                                }}
                            >
                                <PlanningYearSelection
                                    yearFilter={yearFilter}
                                    setYearFilter={setYearFilter}
                                    yearMetadata={yearMetadataWithCommentCount}
                                />
                            </div>
                        </Item>

                        <Item>
                            <Location row={1} col={0} />
                            <div
                                style={{
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    border: '1px solid lightgrey',
                                }}
                            >
                                <HoldSellStackedBar
                                    data={recommendationDataSourceCallback()}
                                />
                            </div>
                        </Item>

                        <Item>
                            <Location row={2} col={0} />

                            <div
                                style={{
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}
                            >
                                <HoldSellGrid
                                    height={tableHeight}
                                    dataSource={recommendationDataSourceCallback()}
                                    selectedRowRecommendation={
                                        selectedRowRecommendation
                                    }
                                    setSelectedRowRecommendation={
                                        setSelectedRowRecommendation
                                    }
                                />
                            </div>
                        </Item>

                        <Item>
                            <Location row={1} col={1} rowspan={2} />

                            <div
                                style={{
                                    overflowY: 'auto',
                                    marginRight: '10px',
                                    boxShadow:
                                        'rgba(0, 0, 0, 0.12) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.24) 0px 0px 0px 1px',
                                    height: holdSellHeight,
                                }}
                            >
                                {selectedRowRecommendation === '' ? (
                                    <Skeleton />
                                ) : (
                                    <HoldSellAnalysis
                                        title={`${getEntityNameByCode(
                                            selectedRowRecommendation,
                                        )}`}
                                        entityCode={selectedRowRecommendation}
                                        enableYearFilter={false}
                                        enableYearMetadata={false}
                                        selectedYear={[yearFilter]}
                                        isCollapse={false}
                                        enableAddYearButton={true}
                                        isRollupPage={true}
                                        onYearMetadataUpdated={(
                                            year,
                                            result,
                                        ) => {
                                            mutateYearMetadataWithCommentCount(
                                                async () => {
                                                    const updatedRow =
                                                        yearMetadataWithCommentCount.find(
                                                            (item) =>
                                                                item.year &&
                                                                item.entity_code ===
                                                                    selectedRowRecommendation,
                                                        );

                                                    const resultWithCount = {
                                                        ...(result as EntityProfileCardYearMetadataWithCommentCount),
                                                        comment_count: 0,
                                                    };

                                                    if (!updatedRow) {
                                                        if (result) {
                                                            return [
                                                                ...yearMetadataWithCommentCount,
                                                                resultWithCount,
                                                            ];
                                                        }

                                                        return [
                                                            ...yearMetadataWithCommentCount,
                                                        ];
                                                    }

                                                    const filteredYearMetadata =
                                                        yearMetadataWithCommentCount.filter(
                                                            (item) =>
                                                                item.entity_code !==
                                                                updatedRow.entity_code,
                                                        );

                                                    if (!result) {
                                                        return [
                                                            ...filteredYearMetadata,
                                                        ];
                                                    }

                                                    return [
                                                        ...filteredYearMetadata,
                                                        resultWithCount,
                                                    ];
                                                },
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        </Item>
                    </ResponsiveBox>
                </CommentsPaneWrapper>
            </div>
        </PermissionedWrapper>
    );
};

const mapState = (s: RootStateOrAny): PlanningHoldSellContainerProps => {
    const properties = Object.entries(s.properties).map((property: any) => ({
        name: property[1].name,
        code: property[1].id,
    }));

    return {
        properties,
    };
};

export default connect(mapState)(PlanningHoldSellContainer);
