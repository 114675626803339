import React from 'react';
import { css } from 'emotion';
import { RootStateOrAny, connect } from 'react-redux';
import { Input, Select, Checkbox, Dropdown, Button } from 'antd';
import {
    EllipsisOutlined,
    DragOutlined,
    DownOutlined,
} from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useGetReportSettings } from 'waypoint-hooks';
import { COMPONENTS_LIST, componentFeatureFlagMapping } from '../constants';
import ResponsiveBox, {
    Col,
    Item,
    Row,
    Location,
} from 'devextreme-react/responsive-box';
import { LocalReportItem } from 'waypoint-types';
import ReportWidgetSettingsButton from 'components/reports/report-widget/ReportWidgetSettingsButton';
import { usePermissions } from 'contexts';
import { AppFeaturePermissions, allAccessPermissions } from 'shared-types';

const alignAntDCheckBox = css`
    margin-right: 6px;
    input[type='checkbox'] {
        position: absolute;
        margin-right: 6px !important;
        margin-left: unset;
        cursor: pointer;
        width: auto;
    }
`;

const alignItem = css`
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 12px;
`;

const styleInput = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

interface ReportItemProps {
    reportItem: LocalReportItem;
    onDeleteReportItem: (sortIndex: number) => void;
    onUpdateReportItem: (item: LocalReportItem) => void;
    showModal: (item: LocalReportItem) => void;
    addNewReportItem: () => void;
    toggleIsWidgetSettingsModalOpen: (
        selectedReportItem: LocalReportItem,
    ) => void;
    appAndUserState: RootStateOrAny;
}

const ReportItem = ({
    reportItem,
    onDeleteReportItem,
    onUpdateReportItem,
    showModal,
    addNewReportItem,
    toggleIsWidgetSettingsModalOpen,
    appAndUserState,
}: ReportItemProps) => {
    const { featurePermissions } = usePermissions();
    const { data } = useGetReportSettings();

    const isComponentDisabled = (component: string) => {
        const componentDisabledOptions = ['narrative'];

        return componentDisabledOptions.includes(component);
    };

    const menu = [
        {
            key: 'deleteItem',
            label: 'Delete',
            onClick: () => onDeleteReportItem(reportItem.sort_index),
        },
        {
            key: 'addSectionItem',
            label: 'Add Section',
            onClick: () => addNewReportItem(),
        },
    ];

    const filteredComponentsList = COMPONENTS_LIST.filter((component) => {
        const featureFlagSelector =
            componentFeatureFlagMapping[component.value];

        const hasPermission =
            !allAccessPermissions.includes(
                component.value as AppFeaturePermissions,
            ) || featurePermissions.includes(component.value);

        // Edge cases
        if (
            (component.value === 'capital_plan' &&
                !featurePermissions.includes(
                    AppFeaturePermissions.CapitalProjects,
                )) ||
            (component.value === 'recurring_charge_summary' &&
                !featurePermissions.includes(AppFeaturePermissions.RentRoll))
        ) {
            return false;
        }

        return (
            hasPermission &&
            (!featureFlagSelector || featureFlagSelector(appAndUserState))
        );
    });

    const categories = Array.from(
        new Set(filteredComponentsList.map((component) => component.category)),
    );

    const items = categories
        .map((category) => {
            const children = filteredComponentsList
                .filter((c) => c.category === category)
                .map((c) => ({
                    key: c.value,
                    label: c.label,
                    onClick: () =>
                        onUpdateReportItem({
                            ...reportItem,
                            component: c.value,
                            settings_inputs: null,
                        }),
                }));

            return children.length
                ? {
                      key: category,
                      label: category,
                      children,
                  }
                : null;
        })
        .filter((item) => item !== null);

    const cascaderDisplayRender = (label: string) => {
        return COMPONENTS_LIST.find((component) => component.value === label)
            ?.label;
    };

    const settingsDisabled = reportItem.component === 'attachment';
    const conditionalSettingsStyle = settingsDisabled
        ? { display: 'none' }
        : {};

    return (
        <div data-testid="report-item" style={{ boxShadow: 'none' }}>
            <ResponsiveBox>
                <Row />
                <Col ratio={1.75} />
                <Col ratio={2} />
                <Col ratio={0.3} />
                <Col ratio={0.75} />
                <Col ratio={2} />
                <Col ratio={1} />
                <Col ratio={0.65} />
                <Col ratio={0.7} />
                <Col ratio={0.2} />

                <Item>
                    <Location row={0} col={0} />
                    <div className={alignItem}>
                        <div style={{ cursor: 'grabbing', marginRight: 5 }}>
                            <DragOutlined />
                        </div>
                        <Dropdown menu={{ items }} trigger={['hover']}>
                            <Button
                                style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    height: 24,
                                    padding: '0 15px',
                                }}
                                block
                                onClick={(e) => e.preventDefault()}
                            >
                                {reportItem.component
                                    ? cascaderDisplayRender(
                                          reportItem.component,
                                      )
                                    : 'Select a component'}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </Item>

                <Item>
                    <Location row={0} col={1} />

                    <div className={alignItem}>
                        <Input
                            size={'small'}
                            style={{ lineHeight: 1.58 }}
                            onChange={(e) =>
                                onUpdateReportItem({
                                    ...reportItem,
                                    name: e.target.value,
                                })
                            }
                            value={reportItem.name}
                        />
                    </div>
                </Item>

                <Item>
                    <Location row={0} col={2} />
                    <ReportWidgetSettingsButton
                        reportItem={reportItem}
                        toggleIsDrawerOpen={toggleIsWidgetSettingsModalOpen}
                    />
                </Item>

                <Item>
                    <Location row={0} col={3} />
                    <div className={alignItem} style={conditionalSettingsStyle}>
                        <div className={alignAntDCheckBox}>
                            <Checkbox
                                disabled={isComponentDisabled(
                                    reportItem.component,
                                )}
                                checked={reportItem.narrativeEnabled}
                                onChange={(e: CheckboxChangeEvent) =>
                                    onUpdateReportItem({
                                        ...reportItem,
                                        narrativeEnabled: e.target.checked,
                                        narrative:
                                            reportItem.narrative ?? 'above',
                                    })
                                }
                            />
                        </div>
                        <Select
                            size={'small'}
                            disabled={
                                !reportItem.narrativeEnabled ||
                                isComponentDisabled(reportItem.component)
                            }
                            defaultValue="above"
                            value={reportItem.narrative ?? 'above'}
                            style={{ width: '120px' }}
                            options={data?.narrativeOptions ?? []}
                            onChange={(value) =>
                                onUpdateReportItem({
                                    ...reportItem,
                                    narrative: value,
                                })
                            }
                        />
                    </div>
                </Item>

                <Item>
                    <Location row={0} col={4} />

                    <div className={alignItem}>
                        <div className={alignAntDCheckBox}>
                            <Checkbox
                                checked={reportItem.instructionsEnabled}
                                onChange={(e: CheckboxChangeEvent) =>
                                    onUpdateReportItem({
                                        ...reportItem,
                                        instructionsEnabled: e.target.checked,
                                    })
                                }
                            />
                        </div>
                        <Input
                            size={'small'}
                            disabled={!reportItem.instructionsEnabled}
                            placeholder="Click to add instructions"
                            maxLength={500}
                            style={{ width: '100%', lineHeight: 1.58 }}
                            onClick={() => showModal(reportItem)}
                            value={reportItem.instructions}
                            className={styleInput}
                        />
                    </div>
                </Item>

                <Item>
                    <Location row={0} col={5} />
                    <div className={alignItem} style={conditionalSettingsStyle}>
                        <Select
                            size={'small'}
                            popupMatchSelectWidth={false}
                            defaultValue="landscape"
                            value={reportItem.orientation as string}
                            style={{ width: '120px' }}
                            options={data?.paperOrientation ?? []}
                            onChange={(value) =>
                                onUpdateReportItem({
                                    ...reportItem,
                                    orientation: value,
                                })
                            }
                        />
                    </div>
                </Item>
                <Item>
                    <Location row={0} col={6} />
                    <div style={{ display: 'flex' }}>
                        <div className={alignItem}>
                            <Select
                                size={'small'}
                                defaultValue="letter"
                                value={reportItem.paper_size as string}
                                style={conditionalSettingsStyle}
                                options={data?.paperSize ?? []}
                                onChange={(value) =>
                                    onUpdateReportItem({
                                        ...reportItem,
                                        paper_size: value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </Item>

                <Item>
                    <Location row={0} col={7} />

                    <div
                        className={alignAntDCheckBox}
                        style={{
                            ...conditionalSettingsStyle,
                            marginLeft: '35%',
                        }}
                    >
                        <Checkbox
                            checked={reportItem.page_break}
                            onChange={(e: CheckboxChangeEvent) =>
                                onUpdateReportItem({
                                    ...reportItem,
                                    page_break: e.target.checked,
                                })
                            }
                        />
                    </div>
                </Item>

                <Item>
                    <Location row={0} col={8} />
                    <Dropdown menu={{ items: menu }}>
                        <EllipsisOutlined
                            style={{
                                cursor: 'pointer',
                                marginTop: '5px',
                                marginRight: '3px',
                            }}
                        />
                    </Dropdown>
                </Item>
            </ResponsiveBox>
        </div>
    );
};

const mapState = (s: RootStateOrAny) => ({
    appAndUserState: s,
});

export default connect(mapState)(ReportItem);
