import { API_URL } from 'config/constants';
import { TransformedUnitMix, SelectedDataLevel } from 'waypoint-types';

const getUnitMix = async (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel,
): Promise<TransformedUnitMix[]> => {
    const response = await fetch(`${API_URL}/leases/unit-mix`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
            selected_data_level: selectedDataLevel,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();

    return data;
};

export default getUnitMix;
