import React, {
    useCallback,
    useState,
    useMemo,
    useRef,
    useContext,
    useEffect,
} from 'react';
import DataGrid, {
    Summary,
    HeaderFilter,
    Paging,
    Pager,
    StateStoring,
    FilterRow,
    ColumnChooser,
    Export,
    Grouping,
    Toolbar,
    Item,
    GroupPanel,
    SortByGroupSummaryInfo,
    FilterPanel,
    DataGridRef,
    ColumnChooserSelection,
    Position,
    Column,
} from 'devextreme-react/data-grid';
import {
    ExpandAndCollapseButton,
    SortByGroupSummarySortSelect,
} from 'waypoint-react';
import { ExportingEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { TABLE_OFFSET } from 'components/analytics/portfolioSegmentation/constants';
import theme from 'config/theme';
import {
    columns,
    getGroupTotalItems,
    getTotalItems,
    groupingOptions,
    IPropertyValueTableData,
} from 'components/analytics/portfolioSegmentation/propertyValues/utils';
import LoadingTable from 'components/style/LoadingTable';
import { buildWeightedAverageDevExtremeConfig } from 'waypoint-utils/dev-extreme/weighted-averages';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import { AttributeFromAPI, EntityAttributesContext } from 'contexts';
import {
    useGetClientData,
    useGetGroupableAttributes,
    useSortByGroupSummaryInfo,
} from 'waypoint-hooks';
import { AttributesGroupBySelect } from 'components/attributesGroupBySelect/AttributesGroupBySelect';

interface ICalculateSummaryResult {
    debt_own: number;
    gross_value_own: number;
    debt_100: number;
    gross_value_100: number;
}

interface IPropertyValueTableProps {
    dataSource: IPropertyValueTableData[];
    error: boolean;
    isLoading: boolean;
}

const PropertyValueTable = ({
    dataSource,
    isLoading,
}: IPropertyValueTableProps) => {
    const dataGrid = useRef<DataGridRef>(null);

    const clientDisplayName: string = useGetClientData('display_name');
    const [expanded, setExpanded] = useState(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState(true);

    const {
        sortSelection,
        setSortSelection,
        sortOrderAscending,
        sortExcludedColumns,
        toggleSortOrder,
        toggleSortSettings,
    } = useSortByGroupSummaryInfo();

    const groupableAttributes = useGetGroupableAttributes();

    const [groupingSelection, setGroupingSelection] =
        useState<EntityDataGroupingKeys | null>(null);
    const [attributeSelection, setAttributeSelection] =
        useState<AttributeFromAPI | null>(null);

    const onExporting = useCallback(async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Portfolio Summary',
            filename: 'Crestpoint - Portfolio Summary.xlsx',
        });
    }, []);

    useEffect(() => {
        if (groupingSelection !== EntityDataGroupingKeys.Attributes) {
            setExpandButtonEnable(true);
        }
    }, [groupingSelection]);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        if (e.rowType === 'group') {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
    };

    const entityAttributesContext = useContext(EntityAttributesContext);
    const attributeDefinitions =
        entityAttributesContext?.data?.attributeDefinitions;

    const isAttributeGroupingSelection =
        groupingSelection === EntityDataGroupingKeys.Attributes;

    const selectedAttribute =
        attributeSelection &&
        attributeDefinitions?.find(
            (attr) => attr.attribute_code === attributeSelection.key,
        );

    const groupedData =
        isAttributeGroupingSelection && selectedAttribute
            ? dataSource?.map((d) => {
                  const values = selectedAttribute.attributeValues?.filter(
                      (attr) => attr.entity_code === d.entity_code,
                  );

                  const valueKey = values?.length
                      ? values.map((val) => val.value).join(', ')
                      : 'Unassigned';

                  return {
                      ...d,
                      [attributeSelection.key]: valueKey
                          ? (valueKey ?? 'Unassigned')
                          : 'Unassigned',
                  };
              })
            : dataSource;

    useMemo(() => {
        const groupIndex =
            groupingSelection === EntityDataGroupingKeys.Attributes && dataGrid
                ? 0
                : -1;
        dataGrid?.current?.instance().columnOption(0, 'groupIndex', groupIndex);
    }, [dataGrid, groupingSelection, attributeSelection]);

    useMemo(() => {
        const group = attributeSelection?.key;
        if (group) {
            const isAttributeGroupingSelection =
                groupingSelection === EntityDataGroupingKeys.Attributes;

            const groupIndex =
                isAttributeGroupingSelection && dataGrid ? 0 : -1;

            setExpandButtonEnable(isAttributeGroupingSelection ? !group : true);

            dataGrid?.current
                ?.instance()
                .columnOption(0, 'groupIndex', groupIndex);
            dataGrid?.current?.instance().refresh();
        }
    }, [dataGrid, groupingSelection, attributeSelection]);

    const weightedMetrics = [
        {
            metricKey: 'wale_years',
            scaleKey: 'sf_occupied_100',
        },
        {
            metricKey: 'wale_years_custom_calc_own',
            scaleKey: 'sf_occupied_own',
        },
        {
            metricKey: 'base_rent_psf',
            scaleKey: 'sf_occupied_100',
        },
        {
            metricKey: 'base_rent_own_psf',
            scaleKey: 'sf_occupied_own',
        },
    ];

    const { calculateCustomSummary: weightedCalc } =
        buildWeightedAverageDevExtremeConfig(weightedMetrics, {
            displayFormat: '{0}',
        });

    const calculateCustomSummary = (options: {
        name: string;
        component: any;
        summaryProcess: string;
        value: any;
        totalValue: number;
        groupIndex: number;
        totalDisplayValue?: ICalculateSummaryResult;
    }) => {
        if (
            [
                'wale_years',
                'wale_years_custom_calc_own',
                'base_rent_psf',
                'base_rent_own_psf',
            ].includes(options.name)
        ) {
            weightedCalc(options);
        } else {
            switch (options.summaryProcess) {
                case 'start': {
                    options.totalValue = 0;
                    options.totalDisplayValue = {
                        debt_own: 0,
                        gross_value_own: 0,
                        debt_100: 0,
                        gross_value_100: 0,
                    };
                    break;
                }
                case 'calculate': {
                    if (options.totalDisplayValue) {
                        options.totalDisplayValue.debt_own += Number(
                            options.value.debt_own,
                        );
                        options.totalDisplayValue.gross_value_own += Number(
                            options.value.gross_value_own,
                        );
                        options.totalDisplayValue.debt_100 += Number(
                            options.value.debt_100,
                        );
                        options.totalDisplayValue.gross_value_100 += Number(
                            options.value.gross_value_100,
                        );
                    }
                    break;
                }
                case 'finalize': {
                    if (
                        options.name === 'ltv_own' &&
                        options.totalDisplayValue
                    ) {
                        options.totalValue =
                            options.totalDisplayValue.debt_own /
                            options.totalDisplayValue.gross_value_own;
                    } else if (
                        options.name === 'ltv_100' &&
                        options.totalDisplayValue
                    ) {
                        options.totalValue =
                            options.totalDisplayValue.debt_100 /
                            options.totalDisplayValue.gross_value_100;
                    }
                    break;
                }
            }
        }
    };

    const TABLE_HEIGHT = useMemo(
        () => window.innerHeight - TABLE_OFFSET,
        [window.innerHeight],
    );

    if (isLoading) {
        return (
            <div data-testid-loading-table style={{ height: TABLE_HEIGHT }}>
                <LoadingTable rowCount={Math.round(TABLE_HEIGHT / 50) || 7} />
            </div>
        );
    }

    return (
        <DataGrid
            ref={dataGrid}
            wordWrapEnabled={false}
            dataSource={groupedData}
            onExporting={onExporting}
            height={TABLE_HEIGHT}
            allowColumnReordering={true}
            rowAlternationEnabled={false}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            columns={columns}
            onRowPrepared={onRowPrepared}
            onOptionChanged={toggleSortSettings}
        >
            <Column
                fixed
                visible={isAttributeGroupingSelection}
                showInColumnChooser={isAttributeGroupingSelection}
                minWidth={180}
                dataField={attributeSelection?.key}
                name={attributeSelection?.key}
                caption={attributeSelection?.title ?? ''}
                width={'auto'}
                allowHiding={false}
            />
            <Summary
                totalItems={getTotalItems}
                calculateCustomSummary={calculateCustomSummary}
                groupItems={getGroupTotalItems}
            />
            <SortByGroupSummaryInfo
                summaryItem={sortSelection}
                sortOrder={sortOrderAscending ? 'asc' : 'desc'}
            />
            <HeaderFilter
                visible={true}
                allowSearch={true}
                allowSelectAll={true}
                height={450}
                width={300}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <FilterPanel visible={true} />
            <GroupPanel visible={!expandButtonEnable} />

            <Grouping autoExpandAll={expanded} />
            <StateStoring
                enabled={true}
                type="localStorage"
                storageKey={`${clientDisplayName}-property-value-table`}
            />
            <ColumnChooser
                allowSearch={true}
                enabled={true}
                mode={'select'}
                height={450}
            >
                <Position
                    my="right top"
                    at="right bottom"
                    of=".dx-datagrid-column-chooser-button"
                />
                <ColumnChooserSelection
                    allowSelectAll={true}
                    recursive={true}
                />
            </ColumnChooser>
            <Toolbar>
                <Item location="after" name="columnChooserButton" />
                <Item name="exportButton" />
                <Item name="searchPanel" locateInMenu="auto" />
                <Item location="before">
                    <div
                        style={{
                            marginLeft: '-11px',
                        }}
                    >
                        <AttributesGroupBySelect
                            key={`${groupingSelection}_${
                                attributeSelection?.key ?? ''
                            }`}
                            groupingSelection={groupingSelection}
                            setGroupingSelection={setGroupingSelection}
                            attributeSelection={attributeSelection}
                            attributes={groupableAttributes}
                            setAttributeSelection={setAttributeSelection}
                        />
                    </div>
                </Item>
                <Item name="groupPanel" />
                <Item location="before">
                    <ExpandAndCollapseButton
                        expanded={expanded}
                        toggleExpanded={toggleExpanded}
                        expandButtonEnable={expandButtonEnable}
                    />
                </Item>
                <Item location="before" visible={!!groupingSelection}>
                    <SortByGroupSummarySortSelect
                        groupingOptions={groupingOptions}
                        sortExcludedColumns={sortExcludedColumns}
                        sortSelection={sortSelection}
                        setSortSelection={setSortSelection}
                        sortOrderAscending={sortOrderAscending}
                        toggleSortOrder={toggleSortOrder}
                    />
                </Item>
            </Toolbar>
            <Export enabled={true} allowExportSelectedData={false} />

            <Paging enabled={true} defaultPageSize={60} />
            <Pager
                visible
                displayMode={'full'}
                showPageSizeSelector={true}
                allowedPageSizes={[20, 40, 60, 80, 100]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page {0} of {1} ({2} items)"
            />
        </DataGrid>
    );
};

export default PropertyValueTable;
