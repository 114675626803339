import DataGrid, {
    Column,
    ColumnChooser,
    ColumnChooserSelection,
    FilterPanel,
    FilterRow,
    Format,
    HeaderFilter,
    Pager,
    Paging,
    StateStoring,
    Summary,
    TotalItem,
    LoadPanel,
    MasterDetail,
    Toolbar,
    Item,
    Grouping,
    GroupItem,
    GroupPanel,
    SortByGroupSummaryInfo,
    DataGridRef,
    Position,
} from 'devextreme-react/data-grid';
import dxDataGrid, {
    InitializedEvent,
    OptionChangedEvent,
    RowPreparedEvent,
} from 'devextreme/ui/data_grid';
import {
    exportExcelFromDataGridWithMasterDetail,
    onContentReady,
} from 'waypoint-utils';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { buildWeightedAverageDevExtremeConfig } from 'waypoint-utils/dev-extreme/weighted-averages';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import {
    performanceOverviewSortByGroupingOptions,
    weightedAverageConfigs,
} from './utils';
import { RecurringChargeGrid } from 'components/leases/components/recurring-charge/RecurringChargeGrid';
import {
    CellInfoType,
    RecurringCharge,
    SavedConfiguration,
    SavedConfigurationState,
} from 'waypoint-types';
import { getRecurringChargeRollupByEntityCode } from 'components/leases/components/recurring-charge/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { PerfOverviewRecurringChargesMasterDetail } from 'waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import theme from 'config/theme';
import { AttributeFromAPI } from 'contexts';
import {
    ExpandAndCollapseButton,
    SortByGroupSummarySortSelect,
} from 'waypoint-react';
import { useSortByGroupSummaryInfo } from 'waypoint-hooks';
import {
    applyStoredConfiguration,
    createSavedConfigurationPayload,
} from 'components/saved-configurations';

interface LeasesPerformanceOverviewTableProps {
    groupingSelection?: EntityDataGroupingKeys | null;
    config: any;
    storageKey: string;
    recurringCharges: RecurringCharge[];
    idTable: string;
    attributeSelected?: AttributeFromAPI;
    selectedConfiguration: SavedConfiguration | null;
    setGridConfig: (config: { [x: string]: any } | null) => void;
    setLocalConfig: (config: { [x: string]: any } | null) => void;
    selectedChargeCode: string[];
    setSelectedChargeCode: (values: string[]) => void;
}

const LeasesPerformanceOverviewTable = ({
    groupingSelection,
    config,
    storageKey,
    recurringCharges,
    idTable,
    attributeSelected,
    selectedConfiguration,
    setGridConfig,
    setLocalConfig,
    selectedChargeCode,
    setSelectedChargeCode,
}: LeasesPerformanceOverviewTableProps) => {
    const performanceOverviewGrid = useRef<DataGridRef>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);

    const isAttributeGroupingSelection =
        groupingSelection === EntityDataGroupingKeys.Attributes;

    const groupCell = (options: CellInfoType) => {
        return <div>{options.value}</div>;
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        const isGrouped = e.rowType === 'group';
        const isExpanded = e.isExpanded && e.rowType !== 'group';
        const isHeader = e.rowType === 'header';
        if (isGrouped) {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (isExpanded) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.backgroundColor = theme.colors.blues.focusRow;
            e.rowElement.style.borderColor = theme.colors.grays.background;
        }
        if (isHeader) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.textDecorationColor = theme.colors.grays.text;
            e.rowElement.style.color = theme.colors.grays.text;
        }
    };

    const {
        sortSelection,
        setSortSelection,
        sortOrderAscending,
        setSortOrderAscending,
        sortVisible,
        sortExcludedColumns,
        toggleSortOrder,
        toggleSortSettings,
    } = useSortByGroupSummaryInfo();

    useEffect(() => {
        setLocalConfig({
            sortSelection,
            sortOrderAscending,
            expanded,
            selectedChargeCode,
            groupingSelection,
            attributeSelection: attributeSelected,
        });
    }, [sortSelection, sortOrderAscending, expanded, selectedChargeCode]);

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration.filters_json.local_config;
            !!config['sortSelection'] &&
                setSortSelection(config['sortSelection']);
            config['sortOrderAscending'] !== undefined &&
                setSortOrderAscending(config['sortOrderAscending']);
            config['expanded'] !== undefined && setExpanded(config['expanded']);
            !!config['selectedChargeCode'] &&
                setSelectedChargeCode &&
                setSelectedChargeCode(config['selectedChargeCode']);
            return;
        }
        setSortSelection(undefined);
        setSortOrderAscending(true);
        setExpanded(false);
        setSelectedChargeCode([]);
    }, [selectedConfiguration]);

    useMemo(() => {
        if (
            groupingSelection === EntityDataGroupingKeys.Attributes &&
            performanceOverviewGrid
        ) {
            performanceOverviewGrid.current
                ?.instance()
                ?.columnOption(0, 'groupIndex', 0);
        } else {
            performanceOverviewGrid.current
                ?.instance()
                ?.columnOption(0, 'groupIndex', -1);
        }
    }, [performanceOverviewGrid, groupingSelection, attributeSelected]);

    const { summaryItems, calculateCustomSummary } =
        buildWeightedAverageDevExtremeConfig(weightedAverageConfigs, {
            displayFormat: '{0}',
        });

    const saveState = (state: SavedConfigurationState) => {
        const config = createSavedConfigurationPayload(state);
        config && setGridConfig(config);
    };

    const DetailTemplate = ({ data }: any) => {
        const includedEntityCodes =
            data.entity_code === '' ? data.entity_codes : [data.entity_code];
        const masterDetailData = getRecurringChargeRollupByEntityCode(
            recurringCharges,
            includedEntityCodes,
            data.occupied_sq_ft,
            data.total_units,
        );

        return (
            <div
                style={{
                    padding: '10px',
                }}
            >
                <div
                    style={{
                        display: 'block',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        lineHeight: '1.25',
                        marginRight: '10%',
                        marginLeft: '10%',
                    }}
                >
                    <p>Charge Summary - {data.property_name}</p>
                    <RecurringChargeGrid
                        data={masterDetailData}
                        height={'auto'}
                        isMasterDetail={false}
                        performanceOverviewConfig={true}
                    />
                </div>
            </div>
        );
    };

    const exportExcelMenu = (
        component: dxDataGrid | undefined,
        recurringCharges: RecurringCharge[],
    ) => (
        <Menu>
            <Menu.Item
                key={'performance-overview'}
                onClick={() =>
                    exportExcelFromDataGridWithMasterDetail({
                        worksheetName: 'Performance Overview Summary',
                        filename: 'Leasing_Performance_Overview_Summary.xlsx',
                        component: component,
                    })
                }
            >
                Export Summary
            </Menu.Item>
            <Menu.Item
                key={'performance-overview-detail-grid'}
                onClick={() =>
                    exportExcelFromDataGridWithMasterDetail({
                        worksheetName: 'Performance Overview Charges',
                        filename: 'Leasing_Performance_Overview_Charges.xlsx',
                        component: component,
                        firstColumName: 'property_name',
                        masterDetailType:
                            PerfOverviewRecurringChargesMasterDetail,
                        masterDetailData: recurringCharges,
                        masterDetailHeaderColumns: [
                            { key: 'charge_name', name: 'Charge' },
                            {
                                key: 'monthly',
                                name: 'Monthly',
                                dataType: 'currency',
                            },
                            {
                                key: 'annual',
                                name: 'Annual',
                                dataType: 'currency',
                            },
                            {
                                key: 'monthly_per_sq_ft',
                                name: 'Monthly / Occ. SF',
                                dataType: 'currency',
                            },
                            {
                                key: 'annual_per_sq_ft',
                                name: 'Annual / Occ. SF',
                                dataType: 'currency',
                            },
                            {
                                key: 'monthly_per_unit',
                                name: 'Monthly / Unit',
                                dataType: 'currency',
                            },
                            {
                                key: 'annual_per_unit',
                                name: 'Annual / Unit',
                                dataType: 'currency',
                            },
                        ],
                    })
                }
            >
                Export w/ Charges
            </Menu.Item>
        </Menu>
    );

    return (
        <div data-testid="performance-overview-table">
            <DataGrid
                ref={performanceOverviewGrid}
                {...config}
                elementAttr={{ id: idTable }}
                onRowPrepared={onRowPrepared}
                onContentReady={(e) =>
                    onContentReady({ e, toggleFunc: setExpandButtonEnable })
                }
                onOptionChanged={(e: OptionChangedEvent) => {
                    if (e.name !== 'hoveredElement') {
                        toggleSortSettings(e);
                    }
                }}
                onInitialized={(e: InitializedEvent) => {
                    if (selectedConfiguration && e?.component) {
                        applyStoredConfiguration(
                            e.component,
                            selectedConfiguration,
                        );
                    }
                }}
            >
                <MasterDetail enabled={true} render={DetailTemplate} />
                <Column
                    fixed
                    visible={isAttributeGroupingSelection}
                    showInColumnChooser={isAttributeGroupingSelection}
                    minWidth={180}
                    dataField={attributeSelected?.key}
                    name={attributeSelected?.key}
                    caption={attributeSelected?.title ?? ''}
                    width={'auto'}
                    allowHiding={false}
                    groupCellRender={groupCell}
                />
                <Column
                    fixed
                    minWidth={180}
                    dataField="property_name"
                    caption={'Property'}
                    width={'auto'}
                    allowHiding={false}
                    allowGrouping={false}
                />
                <Column
                    fixed
                    dataField="entity_display_code"
                    width={120}
                    dataType="string"
                    caption={'Property Code'}
                    allowGrouping={false}
                />
                <Column caption="Units" name="units" alignment={'center'}>
                    <Column
                        dataField="occupied_units"
                        caption="Occupied"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    />
                    <Column
                        dataField="vacant_units"
                        caption="Vacant"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    />
                    <Column
                        dataField="total_units"
                        caption="Total"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    />
                </Column>
                <Column
                    caption="Square Footage"
                    name="sq_ft"
                    alignment={'center'}
                >
                    <Column
                        dataField="occupied_sq_ft"
                        caption="Occupied"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="fixedPoint" />
                    </Column>
                    <Column
                        dataField="vacant_area"
                        caption="Vacant"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="fixedPoint" />
                    </Column>
                    <Column
                        dataField="rentable_sq_ft"
                        caption="Total"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="fixedPoint" />
                    </Column>
                </Column>
                <Column
                    caption="Leasing & Occupancy"
                    name="leasing_occupancy"
                    alignment={'center'}
                >
                    <Column
                        allowSearch={false}
                        dataField="lease_count"
                        caption="Leases"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    />
                    <Column
                        dataField="occupancy_rate"
                        caption="% Occupied"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="percent" precision={1} />
                    </Column>
                    <Column
                        dataField="wale"
                        caption="WALE (yrs)"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="fixedPoint" precision={1} />
                    </Column>
                </Column>
                <Column
                    caption="Total Charges"
                    name="total_charges"
                    alignment={'center'}
                >
                    <Column
                        dataField="total_monthly"
                        caption="Monthly"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="total_annual"
                        caption="Annual"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                </Column>
                <Column
                    caption="Charges Per Occ. SF"
                    name="charges_per_occ_sq_ft"
                    alignment={'center'}
                >
                    <Column
                        dataField="total_monthly_per_sq_ft"
                        caption="Monthly"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="total_annual_per_sq_ft"
                        caption="Annual"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                </Column>
                <Column
                    caption="Charges Per Unit"
                    name="charges_per_unit"
                    alignment={'center'}
                >
                    <Column
                        dataField="total_monthly_per_unit"
                        caption="Monthly"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        dataField="total_annual_per_unit"
                        caption="Annual"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                </Column>
                <Column
                    caption="Charges Per Occ. Unit"
                    name="charges_per_occ_unit"
                    alignment={'center'}
                    visible={false}
                >
                    <Column
                        visible={true}
                        dataField="total_monthly_per_occupied_unit"
                        caption="Monthly"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                    <Column
                        visible={true}
                        dataField="total_annual_per_occupied_unit"
                        caption="Annual"
                        allowHeaderFiltering={false}
                        alignment={'center'}
                        allowGrouping={false}
                    >
                        <Format type="currency" precision={2} />
                    </Column>
                </Column>
                <Grouping contextMenuEnabled={false} autoExpandAll={expanded} />
                <GroupPanel
                    visible={
                        isAttributeGroupingSelection && !!attributeSelected
                    }
                    emptyPanelText={
                        'Drag selected attribute column header to group by selected attribute'
                    }
                />
                <Summary calculateCustomSummary={calculateCustomSummary}>
                    {summaryItems.map((props) => (
                        <GroupItem {...props} alignByColumn key={props.name} />
                    ))}
                    <GroupItem
                        column="property_name"
                        summaryType="count"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="entity_display_code"
                        summaryType="count"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="occupied_units"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="vacant_units"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="total_units"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="occupied_sq_ft"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="vacant_area"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="rentable_sq_ft"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="total_monthly"
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="total_annual"
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    <GroupItem
                        column="lease_count"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                        displayFormat="{0}"
                    />
                    {summaryItems.map((props) => (
                        <TotalItem {...props} key={props.name} />
                    ))}
                    {isAttributeGroupingSelection && (
                        <TotalItem
                            column="total_properties"
                            summaryType="sum"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                            displayFormat="{0}"
                        />
                    )}
                    <TotalItem
                        column="property_name"
                        summaryType="count"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="lease_count"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="occupied_units"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="vacant_units"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="total_units"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="occupied_sq_ft"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="vacant_area"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="rentable_sq_ft"
                        summaryType="sum"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="total_annual"
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="total_monthly"
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                </Summary>
                <SortByGroupSummaryInfo
                    summaryItem={sortSelection}
                    sortOrder={sortOrderAscending ? 'asc' : 'desc'}
                />

                <HeaderFilter
                    allowSelectAll={true}
                    visible={true}
                    allowSearch={true}
                />

                <FilterRow visible={true} applyFilter="auto" />
                <FilterPanel visible={true} />

                <ColumnChooser
                    height={400}
                    allowSearch={true}
                    enabled={true}
                    mode={'select'}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <Toolbar>
                    <Item location="after">
                        <Dropdown
                            overlay={exportExcelMenu(
                                performanceOverviewGrid?.current?.instance(),
                                recurringCharges,
                            )}
                        >
                            <i
                                className="dx-icon dx-icon-xlsxfile"
                                style={{ fontSize: '18px', marginTop: '3px' }}
                            ></i>
                        </Dropdown>
                    </Item>
                    <Item location="after" name="columnChooserButton" />
                    <Item location="before" name="groupPanel" />
                    <Item location="before">
                        <ExpandAndCollapseButton
                            expanded={expanded}
                            toggleExpanded={toggleExpanded}
                            expandButtonEnable={expandButtonEnable}
                        />
                    </Item>
                    <Item location="before" visible={sortVisible}>
                        <SortByGroupSummarySortSelect
                            groupingOptions={
                                performanceOverviewSortByGroupingOptions
                            }
                            sortExcludedColumns={sortExcludedColumns}
                            sortSelection={sortSelection}
                            setSortSelection={setSortSelection}
                            sortOrderAscending={sortOrderAscending}
                            toggleSortOrder={toggleSortOrder}
                        />
                    </Item>
                </Toolbar>

                <Paging enabled={true} defaultPageSize={20} />
                <Pager
                    visible
                    showPageSizeSelector={true}
                    allowedPageSizes={[20, 40, 60, 80, 100]}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} items)"
                />
                <LoadPanel enabled={false} />

                <StateStoring
                    enabled={true}
                    type="custom"
                    savingTimeout={100}
                    customSave={saveState}
                />
            </DataGrid>
        </div>
    );
};

export default LeasesPerformanceOverviewTable;
