import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    Card,
    Tabs,
    message,
    TabsProps,
    DatePicker,
    Empty,
    Button,
    Popconfirm,
    Dropdown,
    MenuProps,
} from 'antd';
import {
    createLeasingGuidelines,
    updateLeasingGuidelines,
    updateLeasingGuidelinesIndexes,
    deleteLeasingGuidelines,
    createEntityProfileCardYearMetadata,
    deleteEntityProfileCardYearMetadata,
} from 'waypoint-requests';
import { LeasingGuideline } from 'waypoint-types';
import { LeasingGuidelinesTable } from './LeasingGuidelinesTable';
import { css } from 'emotion';
import theme from 'config/theme';
import { DisabledDashboard } from 'waypoint-react';
import {
    useEntityProfileYearMetadata,
    useGetLeasingGuidelines,
} from 'waypoint-hooks';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';
import { Moment } from 'moment';

interface LeasingGuidelinesProps {
    entityCode: string;
    widgetYear?: number;
    widgetId?: string;
    narrativePosition?: string;
    widgetType?: string;
    isPDFExport?: boolean;
}

const defaultButtonStyle = css`
    &.ant-btn-primary:enabled {
        color: ${theme.colors.white} !important;
        background-color: ${theme.colors.blues.primary} !important;
        border-color: ${theme.colors.blues.outline} !important;
    }
`;

export const LEASING_GUIDELINES_CARD_TYPE = 'leasingGuidelines';
export const LeasingGuidelines = React.forwardRef<
    PDFExportable,
    LeasingGuidelinesProps
>(
    (
        {
            widgetId,
            entityCode,
            widgetYear,
            narrativePosition,
            widgetType,
            isPDFExport,
        }: LeasingGuidelinesProps,
        ref,
    ) => {
        const type = 'leasing-guidelines';
        const isReportWidget = !!widgetId;
        const dataGridRef = useRef<DataGridRef<any, any>>(null);
        const [gridData, setGridData] = useState<
            Record<number, LeasingGuideline[]>
        >([]);
        const [activeYearKey, setActiveYearKey] = useState<number>(
            widgetYear ?? 0,
        );
        const [isEditModalVisible, setIsEditModalVisible] =
            useState<boolean>(false);

        const [selectedRowData, setSelectedRowData] =
            useState<LeasingGuideline | null>(null);

        const { data, isError, mutate } = useGetLeasingGuidelines(
            entityCode,
            widgetYear,
        );

        const { yearMetadata, mutateYearMetadata } =
            useEntityProfileYearMetadata(entityCode, type);

        const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;
        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return true;
            },
        }));

        useEffect(() => {
            if (!data) {
                return;
            }
            const flatYears = yearMetadata?.flatMap((year) => year.year).sort();
            const years = widgetYear
                ? flatYears?.filter((year) => year === widgetYear) ?? []
                : flatYears;

            const yearToGuidelinesMapArray =
                (years ?? []).map((year) => ({
                    [year]: data.filter(
                        (guideline: LeasingGuideline) =>
                            guideline.year === year,
                    ),
                })) ?? [];

            const yearToGuidelinesMap = Object.assign(
                {},
                ...yearToGuidelinesMapArray,
            );
            setActiveYearKey(
                !activeYearKey
                    ? Number(years?.[0])
                    : widgetYear ?? activeYearKey,
            );
            setGridData(
                isReportWidget
                    ? yearToGuidelinesMapArray[0]
                    : yearToGuidelinesMap,
            );
        }, [data, yearMetadata, isReportWidget, widgetYear]);

        const addYearMetadata = async (year: number) => {
            if (!yearMetadata) {
                return;
            }

            const newYearMetadata = {
                year,
                metadata: [],
            };

            await mutateYearMetadata(
                async () => {
                    try {
                        const result =
                            await createEntityProfileCardYearMetadata(
                                entityCode,
                                type,
                                newYearMetadata,
                            );

                        message.success('Year added successfully!');
                        setActiveYearKey(newYearMetadata.year);
                        setGridData({
                            ...gridData,
                            [newYearMetadata.year]: [],
                        });
                        return [...yearMetadata, result];
                    } catch (e) {
                        message.error('Error adding year');

                        return [...yearMetadata];
                    }
                },
                {
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
        };

        if (isError || !yearMetadata || (isReportWidget && !widgetYear)) {
            return (
                <DisabledDashboard
                    text={
                        !widgetYear
                            ? 'Please select a year to view data'
                            : 'There was an error loading your data.'
                    }
                />
            );
        }

        if (!gridData) {
            return (
                <Card
                    id={`${type}-entityProfileCards`}
                    style={{ margin: '20px 0' }}
                    bodyStyle={{ padding: '5px' }}
                >
                    <div style={{ padding: '10px' }}>
                        <Empty description={<span>No data for this year</span>}>
                            {widgetYear && !isPDFExport && (
                                <Button
                                    onClick={() => addYearMetadata(widgetYear)}
                                    type="primary"
                                    shape="round"
                                    icon={<PlusOutlined />}
                                >
                                    {'Add data for this year'}
                                </Button>
                            )}
                        </Empty>
                    </div>
                </Card>
            );
        }

        const onReorder = async (e: {
            component: Record<any, any>;
            fromIndex: number;
            toIndex: number;
        }) => {
            let newData: LeasingGuideline[] = [...gridData[activeYearKey]];
            const visibleRows = e.component.getVisibleRows();
            const movedItem = newData.find(
                (item) => item.id === visibleRows[e.fromIndex].data.id,
            );
            if (movedItem) {
                newData = newData.filter((item) => item.id !== movedItem.id);
                newData.splice(e.toIndex, 0, movedItem);
                const sorted_leasing_guidelines_ids = newData.map(
                    (item) => item.id,
                );
                await updateLeasingGuidelinesIndexes({
                    entityCode,
                    activeYearKey,
                    sorted_leasing_guidelines_ids,
                });

                setGridData({
                    ...gridData,
                    [activeYearKey]: newData,
                });

                mutate();
            }
        };

        const onDeleteRow = async (id: string) => {
            mutate(
                async () => {
                    try {
                        await deleteLeasingGuidelines(id);
                        message.success(`Row deleted successfully`);
                    } catch (e) {
                        message.error(`Failed to delete Leasing Guidelines`);
                    }

                    return gridData[activeYearKey];
                },
                {
                    optimisticData: gridData[activeYearKey].filter(
                        (guideline: LeasingGuideline) => guideline.id === id,
                    ),
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
        };

        const onSaved = async (data: LeasingGuideline) => {
            const leasingGuidelinesData = {
                ...data,
                leasing_commissions_type: !data.leasing_commissions_type
                    ? '$'
                    : data.leasing_commissions_type,
                entity_code: entityCode,
                year: activeYearKey,
            };
            mutate(
                async () => {
                    try {
                        data.id
                            ? await updateLeasingGuidelines(
                                  leasingGuidelinesData,
                              )
                            : await createLeasingGuidelines(
                                  leasingGuidelinesData,
                              );

                        message.success(
                            `Leasing Guidelines ${
                                data.id ? 'updated' : 'added'
                            } successfully`,
                        );
                    } catch (e) {
                        message.error(
                            `Failed to ${
                                data.id ? 'update' : 'add'
                            } Leasing Guidelines`,
                        );

                        return [
                            ...gridData[activeYearKey],
                            leasingGuidelinesData,
                        ];
                    } finally {
                        setIsEditModalVisible(false);
                    }
                },
                {
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
        };

        const onDeleteYear = (year: number) => {
            const newGridData = { ...gridData };
            mutateYearMetadata(
                async () => {
                    try {
                        await deleteEntityProfileCardYearMetadata(
                            entityCode,
                            type,
                            year,
                        );
                        deleteLeasingGuidelines(year);
                        if (newGridData.hasOwnProperty(year)) {
                            delete newGridData[year];
                        }
                        const newYearMetadata = [
                            ...yearMetadata.filter((ym) => ym.year !== year),
                        ];
                        setActiveYearKey(newYearMetadata[0].year);
                        setGridData(newGridData);
                        message.success(`Year deleted successfully`);

                        return newYearMetadata;
                    } catch (e) {
                        message.error('Error deleting year');

                        return [...yearMetadata];
                    }
                },
                {
                    optimisticData: [
                        ...yearMetadata.filter((ym) => ym.year !== year),
                    ],
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
        };

        const tabsItem: TabsProps['items'] = Object.keys(gridData).map(
            (year) => ({
                label: year,
                key: year,
                children: (
                    <LeasingGuidelinesTable
                        data={gridData[Number(year)]}
                        onReorder={onReorder}
                        onSaved={onSaved}
                        isEditModalVisible={isEditModalVisible}
                        setIsEditModalVisible={setIsEditModalVisible}
                        onDeleted={onDeleteRow}
                        selectedRowData={selectedRowData}
                        setSelectedRowData={setSelectedRowData}
                        dataGridRef={dataGridRef}
                        isPDFExport={isPDFExport}
                    />
                ),
            }),
        );

        const addYearDateRange = () => {
            const disabledYears = yearMetadata.map(
                (yearMetadata) => yearMetadata.year,
            );

            return (
                <div style={{ position: 'relative', bottom: 1.5 }}>
                    <DatePicker
                        disabledDate={(date) =>
                            disabledYears.includes(date.year())
                        }
                        picker="year"
                        onChange={(value: Moment | null) => {
                            if (value) {
                                addYearMetadata(value.year());
                            }
                        }}
                        value={null}
                        placeholder="Add year"
                    />
                </div>
            );
        };
        const extraButtons = () => {
            const items: MenuProps['items'] = [
                {
                    label: (
                        <Popconfirm
                            placement="top"
                            title={'Are you sure you want to delete this item?'}
                            onConfirm={(event) => {
                                event?.stopPropagation();
                                onDeleteYear(activeYearKey);
                            }}
                            onCancel={(event) => {
                                event?.stopPropagation();
                            }}
                            okType="danger"
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <div>Delete</div>
                        </Popconfirm>
                    ),
                    key: '1',
                },
            ];

            if (isPDFExport) {
                return null;
            }

            return (
                <PermissionedWrapper
                    featureKey={AppFeaturePermissions.LeasingGuidelines}
                >
                    <div style={{ display: 'flex', gap: 8 }}>
                        <Button
                            className={defaultButtonStyle}
                            onClick={() => setIsEditModalVisible(true)}
                        >
                            <PlusOutlined />
                            Guideline
                        </Button>
                        {!isReportWidget && (
                            <Dropdown
                                placement={'bottomRight'}
                                menu={{ items }}
                            >
                                <Button className={defaultButtonStyle}>
                                    <MoreOutlined />
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                </PermissionedWrapper>
            );
        };

        return (
            <Card
                title="Leasing Guidelines"
                extra={!isReportWidget && addYearDateRange()}
            >
                {!Object.keys(gridData).length ? (
                    <Empty />
                ) : (
                    <Tabs
                        tabBarExtraContent={extraButtons()}
                        activeKey={String(activeYearKey)}
                        onChange={(key: string) =>
                            setActiveYearKey(Number(key))
                        }
                        items={tabsItem}
                    />
                )}
            </Card>
        );
    },
);
