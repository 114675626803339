import React, { useMemo, useState } from 'react';
import { Button, Drawer, List, Popconfirm } from 'antd';
import { fullPageDrawerZIndex } from 'components/app/layout/AppLayoutUtils';
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { AttributeDefinition } from 'shared-types';
import AttributesEditDrawer from './AttributesEditDrawer';
import { stringSort } from 'utils/tables/sorters';
import { KeyedMutator } from 'swr';
import { GetAttributesV2Response } from 'waypoint-requests/attributes/getAttributesV2';
import deleteAttributeDefinition from 'waypoint-requests/attributes/deleteAttributeDefinition';
import theme from 'config/theme';
import { listStyle } from './utils';

interface AttributesDrawerProps {
    isDrawerOpen: boolean;
    setIsDrawerOpen: (value: boolean) => void;
    attributes: AttributeDefinition[];
    mutateAttributes: KeyedMutator<GetAttributesV2Response>;
    setDownloadModalIsOpen: (value: boolean) => void;
    setUploadModalIsOpen: (value: boolean) => void;
}

const AttributesDrawer = ({
    isDrawerOpen,
    setIsDrawerOpen,
    attributes,
    mutateAttributes,
    setDownloadModalIsOpen,
    setUploadModalIsOpen,
}: AttributesDrawerProps) => {
    const clientAttributes = useMemo(
        () =>
            attributes
                .filter((attribute) => attribute.client_id !== null)
                .sort((a, b) => stringSort(b.name, a.name)),
        [attributes],
    );
    const [isChildrenDrawerOpen, setIsChildrenDrawerOpen] = useState(false);

    const [selectedAttribute, setSelectedAttribute] = useState<
        AttributeDefinition | undefined
    >();

    const onEdit = (selectedAttribute: AttributeDefinition) => {
        setSelectedAttribute(selectedAttribute);
        setIsChildrenDrawerOpen(true);
    };

    const onDelete = async (attributeDefinitionId: string) => {
        try {
            await deleteAttributeDefinition(attributeDefinitionId);
            mutateAttributes();
        } catch (e) {
            throw new Error('Failed to delete attribute definition');
        }
    };

    return (
        <Drawer
            title="Manage Custom Attributes"
            zIndex={fullPageDrawerZIndex}
            placement="right"
            open={isDrawerOpen}
            onClose={() => {
                setSelectedAttribute(undefined);
                setIsDrawerOpen(false);
            }}
            footer={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                    }}
                >
                    <Button
                        onClick={() => setDownloadModalIsOpen(true)}
                        style={{
                            color: theme.colors.blues.activeBlue,
                            borderColor: theme.colors.blues.activeBlue,
                        }}
                    >
                        <i
                            className="fa fa-download"
                            style={{ marginRight: '5px' }}
                        />
                        Template
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setUploadModalIsOpen(true)}
                    >
                        <i
                            className="fa fa-upload"
                            style={{ marginRight: '5px' }}
                        />
                        Template
                    </Button>
                </div>
            }
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: 16,
                }}
            >
                <Button
                    style={{ width: 150 }}
                    icon={<PlusOutlined />}
                    size="middle"
                    type="primary"
                    onClick={() => {
                        setIsChildrenDrawerOpen(true);
                    }}
                    ghost
                >
                    Attribute
                </Button>
            </div>
            <List
                dataSource={clientAttributes}
                renderItem={(item) => (
                    <List.Item
                        className={listStyle}
                        actions={[
                            <Button
                                type="link"
                                icon={<EditFilled />}
                                onClick={() => onEdit(item)}
                                style={{ color: 'gray', paddingRight: 0 }}
                            />,
                            <Popconfirm
                                placement="top"
                                title={'Delete Attribute'}
                                description="Are you sure you want to delete this attribute? This action can not be undone"
                                okType="danger"
                                okText="Delete"
                                onConfirm={() => onDelete(item.id)}
                                cancelText="Cancel"
                            >
                                <Button
                                    type="link"
                                    icon={<DeleteFilled />}
                                    style={{ color: 'gray' }}
                                />
                            </Popconfirm>,
                        ]}
                    >
                        {item.name}
                    </List.Item>
                )}
            />
            <AttributesEditDrawer
                isDrawerOpen={isChildrenDrawerOpen}
                setIsDrawerOpen={setIsChildrenDrawerOpen}
                attributes={attributes}
                mutateAttributes={mutateAttributes}
                selectedAttribute={selectedAttribute}
                setSelectedAttribute={setSelectedAttribute}
            />
        </Drawer>
    );
};

export default AttributesDrawer;
