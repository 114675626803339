import React from 'react';
import {
    EntityDataGroupingKeys,
    EntityDataGroupingOptions,
} from 'utils/EntityDataGroupingConstants';
import { AttributeFromAPI } from 'contexts';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { css } from 'emotion';

const attributeGroupingDropdownStyle = css`
    min-width: 200px;
`;

export interface AttributesGroupBySelectProps {
    groupingSelection: EntityDataGroupingKeys | null;
    setGroupingSelection: (option: EntityDataGroupingKeys | null) => void;
    attributeSelection: AttributeFromAPI | null;
    attributes: AttributeFromAPI[] | undefined;
    setAttributeSelection: (option: AttributeFromAPI) => void;
}

export const AttributesGroupBySelect = ({
    groupingSelection,
    setGroupingSelection,
    attributeSelection,
    attributes,
    setAttributeSelection,
}: AttributesGroupBySelectProps) => {
    if (!attributes) {
        return null;
    }

    const onGroupByValueChanged = (newValue: string) => {
        const attribute = attributes.find(
            (attribute) => attribute.dataIndex === newValue,
        );

        if (attribute) {
            setAttributeSelection(attribute);
        }
    };

    const attributesItems = EntityDataGroupingOptions.filter(
        (typeOption) => typeOption.value === EntityDataGroupingKeys.Attributes,
    ).map((typeOption) => {
        const attributesByCategory = attributes.reduce<{
            [key: string]: AttributeFromAPI[];
        }>((result, attribute) => {
            result[attribute.category] = result[attribute.category] || [];
            result[attribute.category].push(attribute);

            return result;
        }, {});

        const categoryOptions = Object.keys(attributesByCategory)
            .sort()
            .map((category) => {
                return {
                    key: category,
                    label: category,
                    children: attributesByCategory[category].map(
                        (attribute) => {
                            return {
                                key: attribute.key,
                                label: attribute.title,
                            };
                        },
                    ),
                };
            });

        return {
            key: typeOption.value,
            label: typeOption.label,
            children: categoryOptions,
            selectable: true,
        };
    });

    const items = [...attributesItems];

    const onClick: MenuProps['onClick'] = (e) => {
        const containsProperty = e.keyPath.includes('property');

        if (containsProperty) {
            setGroupingSelection(null);
            return;
        }

        setGroupingSelection(EntityDataGroupingKeys.Attributes);
        onGroupByValueChanged(e.key as string);
    };

    const showClearButton = !!groupingSelection;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: '10px',
            }}
        >
            <Dropdown
                className={attributeGroupingDropdownStyle}
                trigger={['hover']}
                menu={{
                    items,
                    selectable: true,
                    onClick,
                    selectedKeys: groupingSelection
                        ? [groupingSelection]
                        : undefined,
                }}
            >
                <Button
                    style={{
                        textAlign: 'left',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 15px',
                        width: 'auto',
                    }}
                    onClick={(e) => e.preventDefault()}
                    data-testid="group-by-dropdown"
                >
                    <span>
                        {groupingSelection
                            ? `Group By: ${attributeSelection?.title}`
                            : 'Group By: Select Attribute'}
                    </span>
                    <DownOutlined />
                </Button>
            </Dropdown>
            {showClearButton && (
                <Button danger onClick={() => setGroupingSelection(null)}>
                    Clear Grouping
                </Button>
            )}
        </div>
    );
};
