import { Tag } from 'antd';
import { DataGrid } from 'devextreme-react';
import { Column, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import {
    ContentReadyEvent,
    FocusedRowChangedEvent,
} from 'devextreme/ui/data_grid';
import React, { useEffect, useState } from 'react';
import theme from 'config/theme';
import { HoldSellGridDataSourceType } from './types';
import { Dictionary } from 'ts-essentials';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { useGetMentionableUsers } from 'waypoint-hooks';
import { CommentsBadge } from 'waypoint-react/comment/CommentsBadge';
import { DASH_DASH } from 'config/constants';

interface HoldSellGridProps {
    dataSource: HoldSellGridDataSourceType[];
    selectedRowRecommendation: string;
    setSelectedRowRecommendation: (selectedRowRecommendation: string) => void;
    height: number;
}

export const HoldSellGrid = ({
    dataSource,
    selectedRowRecommendation,
    setSelectedRowRecommendation,
    height,
}: HoldSellGridProps): JSX.Element | null => {
    const [shouldDefaultFocus, setShouldDefaultFocus] = useState<boolean>(true);

    const userMentionOptions = useGetMentionableUsers([
        selectedRowRecommendation,
    ]);

    useEffect(() => {
        updateCommentsPane({
            userMentionOptions,
        });
    }, [userMentionOptions]);

    const {
        update: updateCommentsPane,
        initializeComments,
        closeComments,
    } = useCommentsPane();

    const onContentReady = (e: ContentReadyEvent) => {
        const hasFilters = e.component.getCombinedFilter();
        if (hasFilters && shouldDefaultFocus) {
            setSelectedRowRecommendation(
                e.component.getDataSource().items()[0].entityCode,
            );
            if (hasFilters) {
                setShouldDefaultFocus(false);
            }
        }
        if (!hasFilters) {
            setShouldDefaultFocus(true);
        }
    };

    const onFocusedRowChanged = (e: FocusedRowChangedEvent) => {
        const entityCode = e?.row?.data.entityCode ?? '';
        setSelectedRowRecommendation(entityCode);
        const commentThreadId = e?.row?.data.commentThreadId;
        if (commentThreadId) {
            initializeComments(commentThreadId);
        } else {
            closeComments();
        }
    };

    const renderTag = (cellInfo: Dictionary<HoldSellGridDataSourceType>) => {
        const recommendation = cellInfo.data.recommendation;

        if (recommendation === 'Hold') {
            return (
                <Tag
                    color={theme.colors.red}
                    style={{
                        width: 80,
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        textAlign: 'center',
                    }}
                >
                    Hold
                </Tag>
            );
        }

        if (recommendation === 'Sell') {
            return (
                <Tag
                    color={theme.colors.green}
                    style={{
                        width: 80,
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        textAlign: 'center',
                    }}
                >
                    Sell
                </Tag>
            );
        }

        return (
            <Tag
                color={theme.colors.grays.medium}
                style={{
                    width: 80,
                    borderRadius: '8px',
                    color: '#FFFFFF',
                    textAlign: 'center',
                }}
            >
                Undecided
            </Tag>
        );
    };

    const renderCommentThread = (
        cellInfo: Dictionary<HoldSellGridDataSourceType>,
    ) => {
        return (
            <CommentsBadge
                commentThreadId={cellInfo.data.commentThreadId}
                commentsCount={cellInfo.data.commentCount}
            />
        );
    };

    return (
        <DataGrid
            dataSource={dataSource}
            keyExpr={'entityCode'}
            height={height}
            allowColumnReordering={true}
            showColumnLines={false}
            rowAlternationEnabled={false}
            hoverStateEnabled={true}
            focusedRowEnabled={true}
            focusedRowKey={selectedRowRecommendation}
            onFocusedRowChanged={onFocusedRowChanged}
            showBorders={true}
            paging={{ enabled: false }}
            onContentReady={onContentReady}
        >
            <HeaderFilter visible={true} allowSearch={true} height={250} />

            <Column dataField="entityCode" visible={false} />

            <Column
                dataField="entityName"
                caption="Property"
                sortOrder={'asc'}
                allowFiltering={true}
                minWidth={100}
            />

            <Column
                dataField="recommendation"
                cellRender={renderTag}
                allowFiltering={true}
                alignment="center"
                width={200}
            />
            <Column
                dataField="targetedDisposition"
                caption="Disposition Year"
                allowFiltering={true}
                alignment="center"
                width={200}
                cellRender={({ value }: { value: number }) =>
                    value ? value : DASH_DASH
                }
            />
            <Column
                dataField="commentCount"
                caption=""
                allowFiltering={false}
                cellRender={renderCommentThread}
                alignment={'center'}
                width={50}
            />

            <Scrolling mode="virtual" />
        </DataGrid>
    );
};
