import { Card, Skeleton } from 'antd';
import { useRef } from 'react';
import { ValuationsTable } from './ValuationsTable';
import { DataGridRef } from 'devextreme-react/data-grid';
import { useGetValuations } from 'waypoint-hooks';
import { DisabledDashboard } from 'waypoint-react';
import { selectProperties } from 'state/properties/selectors';
import { RootStateOrAny, connect } from 'react-redux';
import { Dictionary } from 'ts-essentials';
import { PropertyType, SelectedDataLevel } from 'waypoint-types';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface ValuationsCardProps {
    entityCode: string;
    selectedDataLevel: SelectedDataLevel;
    isPropertyProfilePage: boolean;
    properties: Dictionary<PropertyType>;
}

const ValuationsCard = ({
    entityCode,
    selectedDataLevel,
    isPropertyProfilePage,
    properties,
}: ValuationsCardProps) => {
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const { data, isError, mutate } = useGetValuations(entityCode);

    if (!data) {
        return (
            <Skeleton loading={true} active={true} paragraph={{ rows: 1 }} />
        );
    }

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.Valuations}>
            <Card title="Valuations" data-testid="valuations-card">
                {!isError ? (
                    <ValuationsTable
                        dataGridRef={dataGridRef}
                        data={data}
                        mutate={mutate}
                        entityCode={entityCode}
                        selectedDataLevel={selectedDataLevel}
                        properties={properties}
                        isPropertyProfilePage={isPropertyProfilePage}
                    />
                ) : (
                    <DisabledDashboard text="There was an error loading valuations" />
                )}
            </Card>
        </PermissionedWrapper>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);
    return {
        properties,
    };
};

export default connect(mapState)(ValuationsCard);
