import React, { ReactElement } from 'react';
import {
    menuItemStyle,
    MenuSections,
    overrideMenuItems,
    sidebarDrawerMenuTitle,
} from 'components/app/layout/AppLayoutUtils';
import {
    Button,
    Collapse,
    Divider,
    Menu,
    Space,
    Typography,
    CollapseProps,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RouteUrls } from 'routes/RouteConstants';
import { Link, NavLink } from 'react-router-dom';
import { useGetSavedConfigurations, useGetUserData } from 'waypoint-hooks';
import { SavedConfiguration } from 'waypoint-types';

export const KPIsMenu = ({ pathname }: { pathname: string }): ReactElement => {
    const { userId, clientId } = useGetUserData();
    const { data: savedGrids } = useGetSavedConfigurations(
        userId,
        clientId,
        'kpis',
    );

    const savedViewToMenuItem = (config: SavedConfiguration) => {
        const url = RouteUrls.KPI_GRID.replace(':gridId', config.id);
        return (
            <Menu.Item
                key={`kpi-grid-${config.id}`}
                style={menuItemStyle(pathname, [url])}
            >
                <Link to={url}>{config.name}</Link>
            </Menu.Item>
        );
    };

    const myViewsItems = savedGrids
        ?.filter(
            (config) =>
                config.reference_type === 'user' &&
                config.reference_id === userId.toString(),
        )
        .map(savedViewToMenuItem);

    const sharedViewsItems = savedGrids
        ?.filter((config) => config.reference_type === 'client')
        .map(savedViewToMenuItem);

    const collapseItems: CollapseProps['items'] = [
        ...(myViewsItems?.length
            ? [
                  {
                      key: 'myViews',
                      label: <Typography.Text strong>My Views</Typography.Text>,
                      children: (
                          <div style={{ marginTop: '-16px', marginLeft: 6 }}>
                              {myViewsItems}
                          </div>
                      ),
                  },
              ]
            : []),
        ...(sharedViewsItems?.length
            ? [
                  {
                      key: 'sharedViews',
                      label: (
                          <Typography.Text strong>Shared Views</Typography.Text>
                      ),
                      children: (
                          <div style={{ marginTop: '-16px', marginLeft: 6 }}>
                              {sharedViewsItems}
                          </div>
                      ),
                  },
              ]
            : []),
    ];

    return (
        <Menu mode="vertical" className={overrideMenuItems}>
            {sidebarDrawerMenuTitle(MenuSections.KPIs, {
                marginBottom: '10px',
            })}

            <div style={{ marginLeft: '13px', marginRight: '13px' }}>
                <Space direction="vertical">
                    <Typography.Text type="secondary">
                        Create, analyze, and share your data story.
                    </Typography.Text>

                    <NavLink to={RouteUrls.KPI_GRID_EDITOR_NEW}>
                        <Button
                            style={{
                                width: '100%',
                                textAlign: 'left',
                                justifyContent: 'flex-start',
                            }}
                            type="primary"
                        >
                            <PlusOutlined /> Create KPI Grid
                        </Button>
                    </NavLink>
                </Space>
            </div>

            <Divider style={{ margin: '8px' }} />

            <div style={{ marginLeft: '13px', marginRight: '13px' }}>
                <Space>
                    <NavLink to={RouteUrls.KPI_GRID_INDEX}>
                        <Button
                            style={{
                                width: '100%',
                                textAlign: 'left',
                                justifyContent: 'flex-start',
                            }}
                            type="text"
                        >
                            View All KPI Grids
                        </Button>
                    </NavLink>
                </Space>
            </div>

            <Divider style={{ margin: '8px' }} />

            <Collapse
                ghost
                size="small"
                items={collapseItems}
                style={{ marginTop: 0, marginBottom: 0 }}
                defaultActiveKey={'myViews'}
            />
        </Menu>
    );
};
