import { stringSort } from 'utils/tables/sorters';

export const arrayToCapitalizedString = (arrayOfStrings: string[]) =>
    arrayOfStrings
        .map((word) => {
            return typeof word === 'string' && word.length > 0
                ? capitalizeFirstLetter(word)
                : '';
        })
        .sort((a: string | undefined, b: string | undefined) =>
            stringSort(b ?? '', a ?? ''),
        )
        .join(', ');

export const capitalizeFirstLetter = (text?: string) => {
    return text
        ?.split(' ')
        .map(
            (text) =>
                text.charAt(0).toUpperCase() + text.slice(1).toLowerCase(),
        )
        .join(' ');
};
