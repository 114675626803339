import { API_URL } from 'config/constants';
import { GetKPIsResponse, KPIRequest, KPIResponse } from 'shared-types';
import { SelectedDataLevel } from 'waypoint-types';

export interface GetKPIPayload {
    entityCodes: string[];
    selected_data_level: SelectedDataLevel;
    kpi_requests: KPIRequest[];
    date: string;
}

export async function getKPIs(
    payload: GetKPIPayload,
): Promise<GetKPIsResponse> {
    const response = await fetch(`${API_URL}/kpis`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        throw new Error('Failed to get KPIs');
    }

    const { data } = await response.json();

    return data as GetKPIsResponse;
}
