import { Table } from 'antd';
import React from 'react';
import { currencyRenderer, percentageRenderer } from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    convertColumnsToAntd,
    getColumnTotalCells,
    headerClass,
} from './GridExportConversionUtils';
import { RecurringChargeRollUp } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import { css } from 'emotion';
import { safeDivision } from 'shared-types';

const calculatePercentage = (
    data: RecurringChargeRollUp[],
    { monthly }: RecurringChargeRollUp,
) => {
    const totalMonthly = data.reduce((total, item) => total + item.monthly, 0);
    return safeDivision(Number(monthly), totalMonthly);
};

export const recurringChargeBaseColumns = [
    {
        title: 'Category',
        dataIndex: 'bucket_name',
        key: 'bucket_name',
    },
    {
        title: 'Charge (Code)',
        dataIndex: 'charge_name',
        key: 'charge_name',
        width: 'auto',
        allowGrouping: false,
        minWidth: 150,
    },
    {
        title: '% of Total',
        dataIndex: 'percentage',
        key: 'percentage',
        align: 'center',
        render: percentageRenderer,
    },
    {
        title: 'Monthly',
        dataIndex: 'monthly',
        key: 'monthly',
        align: 'center',
        dataType: 'number',
        render: currencyRenderer,
    },
    {
        title: 'Annual',
        dataIndex: 'annual',
        key: 'annual',
        align: 'center',
        dataType: 'number',
        render: currencyRenderer,
    },
    {
        title: 'Annual / SF',
        dataIndex: 'annual_per_sq_ft',
        key: 'annual_per_sq_ft',
        align: 'center',
        dataType: 'number',
        render: currencyRenderer,
    },
    {
        title: 'Annual / Unit',
        dataIndex: 'annual_per_unit',
        key: 'annual_per_unit',
        align: 'center',
        dataType: 'number',
        render: currencyRenderer,
    },
];

const recurringChargesTableSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> =
    {
        percentage: {
            summaryType: 'sum',
            render: percentageRenderer,
        },
        monthly: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        annual: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        monthly_per_sq_ft: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        annual_per_sq_ft: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        monthly_per_unit: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        annual_per_unit: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
    };

export const RecurringChargeExportableGrid = ({
    recurringCharges,
}: {
    recurringCharges: RecurringChargeRollUp[];
}) => {
    const { gridColumns } = convertColumnsToAntd(
        recurringChargeBaseColumns as ColumnDescriptor[],
        true,
        null,
    );

    const recurringChargeDataWithCalculatedPercentages = recurringCharges.map(
        (rc) => {
            rc.percentage = calculatePercentage(recurringCharges, rc);
            return rc;
        },
    );

    return (
        <Table
            dataSource={recurringChargeDataWithCalculatedPercentages}
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={css`
                font-size: 12px;
            `}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    recurringChargeDataWithCalculatedPercentages,
                    recurringChargesTableSummaryFormatters,
                )
            }
        />
    );
};
