import { RentRollTable } from 'components/leases/expirations';
import {
    useGetSavedConfigurationById,
    useRecurringCharges,
    useRentRoll,
    useRentSteps,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import { filterRecurringCharges } from 'components/leases/components/recurring-charge/utils';
import { RecurringCharge } from 'waypoint-types';
import RentRollRecurringChargeSummary from 'components/leases/expirations/cards/rent-roll/RentRollRecurringChargeSummary';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import React, { useImperativeHandle, useState } from 'react';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';
import { useRentRollTotals } from 'waypoint-hooks/data-access/useRentRollTotals';
import { Skeleton } from 'antd';

interface RentRollTableEntityReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetSettings: { [key: string]: any };
    isPDFExport: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

export const RentRollGridEntityReportWidget = React.forwardRef<
    PDFExportable,
    RentRollTableEntityReportWidgetParams
>(
    (
        {
            entityCode,
            widgetId,
            widgetSettings,
            isPDFExport,
            pdfSettings,
        }: RentRollTableEntityReportWidgetParams,
        ref,
    ) => {
        const [isChartReadyToExport, setIsChartReadyToExport] =
            useState<boolean>(
                widgetSettings?.includeRecurringChargeSummary ? false : true,
            );

        const selectedDataLevel = useSelectedDataLevel();

        const { data: rentRollTotals, isLoading: isLoadingTotals } =
            useRentRollTotals([entityCode], selectedDataLevel);

        const { data: rentRoll, isLoading } = useRentRoll([entityCode], {
            stakeholder: null,
            percentageType: null,
        });

        const {
            data: charges,
            isLoading: isRecurringChargeLoading,
            isValidating: isRecurringChargeValidating,
        } = useRecurringCharges([entityCode], {
            stakeholder: null,
            percentageType: null,
        });

        const { data: rentSteps } = useRentSteps(
            [entityCode],
            selectedDataLevel,
        );

        const {
            data: savedConfigData,
            isLoading: isLoadingSavedConfig,
            isError: savedConfigError,
        } = useGetSavedConfigurationById(widgetSettings?.savedConfigId ?? '');

        const savedConfigIsReady = savedConfigData
            ? savedConfigError || !isLoadingSavedConfig
            : true;

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return (
                    !isLoading &&
                    !isRecurringChargeLoading &&
                    !isRecurringChargeValidating &&
                    isChartReadyToExport &&
                    savedConfigIsReady
                );
            },
        }));

        if (!rentRoll || !charges || !savedConfigIsReady) {
            return <Skeleton />;
        }

        const { recurringCharges, rollUp, summary } = charges;
        const selectedChargeCodes =
            savedConfigData?.filters_json?.local_config['selectedChargeCode'] ??
            widgetSettings?.selectedChargeCodes ??
            [];

        return (
            <div style={{ marginRight: '10px' }}>
                {widgetSettings?.includeRecurringChargeSummary && (
                    <div style={{ marginBottom: '15px' }}>
                        <RentRollRecurringChargeSummary
                            rollUp={rollUp}
                            selectedChargeCode={selectedChargeCodes}
                            isPDFExport={isPDFExport}
                            isLandscape={!!pdfSettings?.landscape}
                            widgetId={widgetId}
                            setIsChartReadyToExport={
                                isPDFExport
                                    ? setIsChartReadyToExport
                                    : undefined
                            }
                        />
                    </div>
                )}
                <RentRollTable
                    key={savedConfigData?.id ?? ''}
                    entityCodes={[entityCode]}
                    rentRoll={rentRoll}
                    recurringCharges={
                        filterRecurringCharges(
                            recurringCharges,
                            selectedChargeCodes,
                        ) as RecurringCharge[]
                    }
                    columnSelection={
                        savedConfigData?.filters_json?.local_config
                            ?.columnSelection ?? widgetSettings?.selectedViewKey
                    }
                    selectedConfiguration={savedConfigData ?? null}
                    hidePropertyColumns
                    selectedChargeCode={selectedChargeCodes}
                    isPDFExport={isPDFExport}
                    isReportWidget={true}
                    selectedDataLevel={selectedDataLevel}
                    rentSteps={rentSteps}
                    rentRollTotals={rentRollTotals}
                    summary={summary}
                />
            </div>
        );
    },
);
