import React from 'react';
import { IncomeStatementProvider } from 'components/financials/comparative-income-statement/IncomeStatementProvider';
import { ComparativeIncomeStatementTableEntityReportWidget } from './ComparativeIncomeStatementTableEntityReportWidget';
import { getDefaultFieldValuesFor } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { BalanceSheetTableTableEntityReportWidget } from './BalanceSheetTableEntityReportWidget';
import PropertyProfileExecutiveSummary from 'components/propertyProfile/property-profile-components/PropertyProfileExecutiveSummary';
import { ExpirationScheduleEntityReportWidget } from './ExpirationScheduleEntityReportWidget';
import { RentRollGridEntityReportWidget } from './RentRollGridEntityReportWidget';
import { PropertyAttributesEntityReportWidget } from './PropertyAttributesEntityReportWidget';
import { EntityProfileCardEntityReportWidget } from './EntityProfileCardEntityReportWidget';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { RecurringChargeSummaryEntityReportWidget } from './RecurringChargeSummaryEntityReportWidget';
import { planningContainerTabsKeys } from 'components/planning/capital-projects/PlanningCapitalProjectUtils';
import { CapitalProjectContainerWidget } from './CapitalProjectContainerWidget';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';
import { LeasingGuidelines } from 'components/leasingGuidelines/LeasingGuidelines';
import { AttachmentDocumentUploadEntityReportWidget } from './AttachmentDocumentUploadEntityReportWidget';
import { NarrativeEntityReportWidget } from './NarrativeEntityReportWidget';
import { toISO } from 'components/dates/utils';
import moment from 'moment';
import { OccupancyTrendReportWidget } from './OccupancyTrendReportWidget';
import { PropertyInformationWidget } from './PropertyInformationWidget';
import { EntityReport, PropertyType, Report, User } from 'waypoint-types';
import TopTenantsReportWidget from './TopTenantsReportWidget';
import CoverPageReportWidget from './CoverPageReportWidget';
import { TypeOfContractsView } from 'components/contracts/interfaces';
import { ContractsContainerWidget } from './ContractsContainerWidget';
import UnitMixTable from 'components/unitMix/UnitMixTable';
import { AgedReceivablesEntityReportWidget } from './AgedReceivablesEntityReportWidget';
import { LeasingPlanEntityReportWidget } from './LeasingPlanEntityReportWidget';
import { TableOfContentsWidget } from './TableOfContentsWidget';

interface SelectedEntityReportWidgetParams {
    asOfDate: Date;
    entityCode: string;
    widgetId: string;
    widgetType: string;
    widgetSettings: { [key: string]: any };
    narrativePosition?: string;
    narrativeText?: string;
    exportRef?: React.RefObject<PDFExportable>;
    isPDFExport?: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
    setIsUploading?: (isUploading: boolean) => void;
    isUploading?: boolean;
    propertyInfo?: PropertyType;
    entityReport: EntityReport | null;
    report?: Report;
    reportHasLogo?: boolean;
}

export const SelectedEntityReportWidget = (
    props: SelectedEntityReportWidgetParams,
) => {
    const {
        asOfDate,
        entityCode,
        widgetId,
        widgetType,
        widgetSettings,
        narrativePosition,
        narrativeText,
        exportRef,
        isPDFExport,
        pdfSettings,
        setIsUploading,
        isUploading,
        propertyInfo,
        entityReport,
        report,
    } = props;
    const getIncomeStatementWidget = () => {
        const incomeStatementSettings = widgetSettings.comparison_selections
            ? widgetSettings
            : {
                  comparison_selections: getDefaultFieldValuesFor(
                      asOfDate as Date,
                  ),
                  is_hide_null: widgetSettings.is_hide_null ?? false,
                  is_hide_notes: widgetSettings.is_hide_notes ?? false,
                  show_per_sf: widgetSettings?.show_per_sf ?? false,
                  show_per_unit: widgetSettings?.show_per_unit ?? false,
              };
        if (!widgetSettings.export_settings) {
            incomeStatementSettings.export_settings = 'summary';
            incomeStatementSettings.account_selections = null;
        }

        return (
            <IncomeStatementProvider
                entityCodes={[entityCode]}
                cacheId={widgetId}
                selectedDataLevel={{
                    percentageType: null,
                    stakeholder: null,
                }}
            >
                <ComparativeIncomeStatementTableEntityReportWidget
                    key={widgetId}
                    entityCode={entityCode ?? ''}
                    widgetId={widgetId}
                    widgetType={widgetType}
                    widgetSettings={incomeStatementSettings}
                    isPDFExport={!!isPDFExport}
                    ref={exportRef}
                />
            </IncomeStatementProvider>
        );
    };

    const getBalanceSheetWidget = () => {
        const getDefaultSettings = () => {
            const primaryDefault = toISO(asOfDate);
            const secondaryDefault = moment(asOfDate)
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD');
            return {
                is_hide_notes: false,
                is_hide_null: false,
                periods: [primaryDefault, secondaryDefault],
                export_settings: 'summary',
                account_selections: null,
            };
        };
        const balanceSheetSettings =
            widgetSettings.periods && widgetSettings.periods.length === 2
                ? widgetSettings
                : getDefaultSettings();

        if (!widgetSettings.export_settings) {
            balanceSheetSettings.export_settings = 'summary';
            balanceSheetSettings.account_selections = null;
        }

        return (
            <BalanceSheetTableTableEntityReportWidget
                key={widgetId}
                entityCode={entityCode ?? ''}
                widgetId={widgetId}
                widgetType={widgetType}
                widgetSettings={balanceSheetSettings}
                isPDFExport={!!isPDFExport}
                ref={exportRef}
            />
        );
    };

    const getExecutiveSummaryWidget = () => {
        return (
            <PropertyProfileExecutiveSummary
                isPDFExport={!!isPDFExport}
                entity_code={entityCode}
                widgetId={widgetId}
                ref={exportRef}
            />
        );
    };

    const getExpirationScheduleWidget = () => {
        return (
            <ExpirationScheduleEntityReportWidget
                entityCode={entityCode}
                widgetId={widgetId}
                widgetType={widgetType}
                widgetSettings={widgetSettings}
                ref={exportRef}
                isPDFExport={!!isPDFExport}
                pdfSettings={pdfSettings}
            />
        );
    };

    const getEntityProfileCardWidget = () => {
        return (
            <EntityProfileCardEntityReportWidget
                entityCode={entityCode}
                widgetId={widgetId}
                widgetSettings={widgetSettings}
                widgetType={widgetType}
                ref={exportRef}
                isPDFExport={!!isPDFExport}
            />
        );
    };

    const getRentRollGridWidget = () => {
        return (
            <RentRollGridEntityReportWidget
                entityCode={entityCode}
                widgetId={widgetId}
                widgetSettings={widgetSettings}
                ref={exportRef}
                isPDFExport={!!isPDFExport}
                pdfSettings={pdfSettings}
            />
        );
    };

    const getPropertyAttributesWidget = () => {
        return (
            <PropertyAttributesEntityReportWidget
                entityCode={entityCode}
                ref={exportRef}
                widgetId={widgetId}
                isPDFExport={!!isPDFExport}
                narrativePosition={narrativePosition}
            />
        );
    };

    const getRecurringChargeSummaryGridWidget = () => {
        return (
            <RecurringChargeSummaryEntityReportWidget
                entityCode={entityCode}
                widgetId={widgetId}
                widgetSettings={widgetSettings}
                ref={exportRef}
                isPDFExport={!!isPDFExport}
                pdfSettings={pdfSettings}
            />
        );
    };

    const getCapitalProjectsWidget = () => {
        return (
            <CapitalProjectContainerWidget
                key={planningContainerTabsKeys.trackerTable}
                entityCode={entityCode}
                widgetActiveTab={planningContainerTabsKeys.trackerTable}
                ref={exportRef}
                widgetId={widgetId}
                narrativePosition={narrativePosition}
                isPDFExport={!!isPDFExport}
                widgetSettings={widgetSettings}
            />
        );
    };

    const getCapitalPlanWidget = () => {
        return (
            <CapitalProjectContainerWidget
                key={planningContainerTabsKeys.planTable}
                entityCode={entityCode}
                widgetActiveTab={planningContainerTabsKeys.planTable}
                widgetSettings={widgetSettings}
                ref={exportRef}
                widgetId={widgetId}
                narrativePosition={narrativePosition}
                isPDFExport={!!isPDFExport}
            />
        );
    };

    const getAttachmentUploadWidget = () => {
        return (
            <AttachmentDocumentUploadEntityReportWidget
                widgetId={widgetId}
                ref={exportRef}
                isPDFExport={!!isPDFExport}
                setIsUploading={setIsUploading}
                isUploading={isUploading}
            />
        );
    };

    const getNarrativeWidget = () => {
        return (
            <NarrativeEntityReportWidget
                widgetId={widgetId}
                ref={exportRef}
                widgetType={widgetType}
            />
        );
    };
    const getOccupancyTrendWidget = () => {
        const occupancyTrendSettings = widgetSettings.accountMappingSelection
            ? widgetSettings
            : {
                  accountMappingSelection: { name: null, code: null },
                  periodRange: null,
              };
        return (
            <OccupancyTrendReportWidget
                key={planningContainerTabsKeys.planTable}
                entityCode={entityCode}
                ref={exportRef}
                widgetSettings={occupancyTrendSettings}
                widgetType={widgetType}
                widgetId={widgetId}
                narrativePosition={narrativePosition}
                narrativeText={narrativeText}
                pdfSettings={pdfSettings}
                isPDFExport={!!isPDFExport}
            />
        );
    };

    const getPropertyInformationWidget = () => {
        const propertyInformationSettings =
            Object.keys(widgetSettings).length > 0
                ? widgetSettings
                : {
                      includeMap: true,
                      includePhoto: true,
                      imgMapPosition: 'above',
                  };

        return (
            <PropertyInformationWidget
                widgetId={widgetId}
                ref={exportRef}
                widgetType={widgetType}
                entityCode={entityCode}
                propertyInfo={propertyInfo}
                widgetSettings={propertyInformationSettings}
                pdfSettings={pdfSettings}
                isPDFExport={!!isPDFExport}
            />
        );
    };

    const getTopNTenantsWidget = () => {
        const topTenantsDefaultSettings =
            Object.keys(widgetSettings).length > 0
                ? widgetSettings
                : {
                      widgetMetric: 'rentable_sf',
                      widgetTopNTenants: 10,
                      widgetSelectedChargeCode: [],
                      widgetVisualOption: 'chart_table',
                  };

        return (
            <TopTenantsReportWidget
                widgetId={widgetId}
                ref={exportRef}
                widgetType={widgetType}
                entityCode={entityCode}
                widgetSettings={topTenantsDefaultSettings}
                pdfSettings={pdfSettings}
                isPDFExport={!!isPDFExport}
            />
        );
    };

    const getLeasingGuidelinesWidget = () => {
        return (
            <LeasingGuidelines
                entityCode={entityCode}
                ref={exportRef}
                widgetId={widgetId}
                widgetYear={
                    widgetSettings?.selectedYear
                        ? widgetSettings?.selectedYear[0]
                        : undefined
                }
                narrativePosition={narrativePosition}
                widgetType={widgetType}
                isPDFExport={!!isPDFExport}
            />
        );
    };

    const getContractsWidget = () => {
        return (
            <ContractsContainerWidget
                ref={exportRef}
                widgetId={widgetId}
                widgetSettings={widgetSettings}
                narrativePosition={narrativePosition}
                typeOfView={TypeOfContractsView.PropertyProfilePage}
                entityCode={entityCode}
                isReportWidget={true}
                isPDFExport={isPDFExport}
            />
        );
    };

    const getCoverPageWidget = () => {
        return (
            <CoverPageReportWidget
                ref={exportRef}
                propertyInfo={propertyInfo}
                widgetType={widgetType}
                widgetId={widgetId}
                entityCode={entityCode}
                isPDFExport={!!isPDFExport}
                pdfSettings={pdfSettings}
                entityReport={entityReport}
                report={report}
            />
        );
    };

    const getTableOfContentsWidget = () => {
        return (
            <TableOfContentsWidget
                ref={exportRef}
                reportWidgets={report?.reportWidgets ?? null}
                pdfSettings={pdfSettings}
                propertyName={propertyInfo?.display_name ?? ''}
            />
        );
    };

    const getUnitMixWidget = () => {
        return (
            <UnitMixTable
                isReportWidget
                entityCodes={[entityCode]}
                selectedDataLevel={{
                    stakeholder: null,
                    percentageType: null,
                }}
                widgetSettings={widgetSettings}
                isPDFExport={!!isPDFExport}
                widgetType={widgetType}
                widgetId={widgetId}
                ref={exportRef}
                narrativePosition={narrativePosition}
                hidePropertyColumns
                selectedChargeCode={
                    widgetSettings?.widgetSelectedChargeCode ?? []
                }
            />
        );
    };

    const getAgedReceivablesEntityReportWidget = () => {
        return (
            <AgedReceivablesEntityReportWidget
                entityCode={entityCode}
                widgetId={widgetId}
                widgetSettings={widgetSettings}
                ref={exportRef}
                isPDFExport={!!isPDFExport}
                pdfSettings={pdfSettings}
            />
        );
    };

    const getLeasingPlanEntityReportWidget = () => {
        return (
            <LeasingPlanEntityReportWidget
                entityCode={entityCode}
                widgetId={widgetId}
                widgetSettings={widgetSettings}
                ref={exportRef}
                isPDFExport={!!isPDFExport}
                narrativePosition={narrativePosition}
                narrativeText={narrativeText}
                pdfSettings={pdfSettings}
            />
        );
    };

    const widgetRenderMapping = {
        income_statement: getIncomeStatementWidget,
        balance_sheet: getBalanceSheetWidget,
        executive_summary: getExecutiveSummaryWidget,
        expiration_schedule: getExpirationScheduleWidget,
        priorities_and_objectives: getEntityProfileCardWidget,
        hold_sell: getEntityProfileCardWidget,
        market_overview: getEntityProfileCardWidget,
        swot: getEntityProfileCardWidget,
        rent_roll: getRentRollGridWidget,
        attributes: getPropertyAttributesWidget,
        recurring_charge_summary: getRecurringChargeSummaryGridWidget,
        capital_projects: getCapitalProjectsWidget,
        capital_plan: getCapitalPlanWidget,
        attachment: getAttachmentUploadWidget,
        narrative: getNarrativeWidget,
        occupancy_trend: getOccupancyTrendWidget,
        property_information: getPropertyInformationWidget,
        top_tenants: getTopNTenantsWidget,
        leasing_guidelines: getLeasingGuidelinesWidget,
        contracts: getContractsWidget,
        cover_page: getCoverPageWidget,
        table_of_contents: getTableOfContentsWidget,
        unit_mix: getUnitMixWidget,
        aged_receivables: getAgedReceivablesEntityReportWidget,
        leasing_plan: getLeasingPlanEntityReportWidget,
    };

    if (Object.keys(widgetRenderMapping).includes(widgetType)) {
        return widgetRenderMapping[
            widgetType as keyof typeof widgetRenderMapping
        ]();
    }

    return <div>Report widget not found</div>;
};
