import theme from 'config/theme';
import { css } from 'emotion';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import TopTenantsCard from './TopTenantsCard';
import { connect, RootStateOrAny } from 'react-redux';
import { hasTenantsEnabled } from 'state/user/selectors';
import { DisabledDashboard } from 'waypoint-react';

const pageStyle = css`
    display: flex;
    background-color: ${theme.colors.grays.background};
    overflow-y: scroll;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
`;

interface TopTenantsDashboarddProps {
    tenantsEnabled: string;
}

const TopTenantsDashboard = ({ tenantsEnabled }: TopTenantsDashboarddProps) => {
    if (!tenantsEnabled) {
        return <DisabledDashboard text={'Tenants module is disabled.'} />;
    }

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.TopTenants}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                <div style={{ padding: '12px' }}>
                    <TopTenantsCard isPropertyProfilePage={false} />
                </div>
            </div>
        </PermissionedWrapper>
    );
};

const mapState = (state: RootStateOrAny) => {
    const tenantsEnabled = hasTenantsEnabled(state);

    return {
        tenantsEnabled,
    };
};

export default connect(mapState)(TopTenantsDashboard);
