import { API_URL } from 'config/constants';
import { StandardKPI } from 'shared-types';

export async function getUnavailableKPIs(): Promise<StandardKPI[]> {
    const response = await fetch(`${API_URL}/kpis/unavailable`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to get unavailable KPIs');
    }

    const { data } = await response.json();

    return data as StandardKPI[];
}
