import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { css } from 'emotion';
import theme from 'config/theme';
import {
    useGetGroupableAttributes,
    useGetLeasesPerformanceOverviewConfig,
    useGridConfigSettings,
    useRecurringCharges,
} from 'waypoint-hooks';
import {
    propertyDetailsValues,
    PropertyDetailsValuesType,
} from 'components/leases/constants';
import { Button, Skeleton } from 'antd';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import {
    AttributeFromAPI,
    EntityAttributesContext,
    FilterReferenceTypes,
} from 'contexts';
import { AppFeaturePermissions } from 'shared-types';
import { SelectionsBanner } from 'waypoint-react';
import {
    PropertyDetailsProps,
    RecurringCharge,
    SavedConfigFilterType,
} from 'waypoint-types';
import { numericalSort, stringSort } from 'utils/tables/sorters';
import ResponsiveBox, {
    Col,
    Item,
    Location,
    Row,
} from 'devextreme-react/responsive-box';
import { filterRecurringCharges } from 'components/leases/components/recurring-charge/utils';
import { groupByAttribute } from 'components/leases/expirations/cards/leases-performance-overview/utils';
import {
    SavedConfigEditor,
    SavedConfigManageDropdown,
    SavedGridConfigSelect,
} from 'components/saved-configurations';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { AttributesGroupBySelect } from 'components/attributesGroupBySelect/AttributesGroupBySelect';
import { PieChartOutlined } from '@ant-design/icons';
import LeaseSummaryCardSection from 'components/leases/expirations/cards/lease-summary/LeaseSummaryCardSection';
import { LeasesPerformanceOverviewCard } from 'components/leases/expirations/index';
import LeasesPerformanceOverviewChart from 'components/leases/expirations/cards/leases-performance-overview/LeasesPerformanceOverviewChart';
import { connect, RootStateOrAny } from 'react-redux';
import { selectClientConfigurations } from 'state/user/selectors';
import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';

const chartButtonStyle = css`
    color: ${theme.colors.grays.light} !important;
    border-color: ${theme.colors.grays.light} !important;
    background: ${theme.colors.white} !important;
    text-shadow: none !important;
    margin-bottom: 3px;
`;

const chartButtonStyleActive = css`
    border-color: ${theme.colors.blues.focusRow} !important;
    background: ${theme.colors.blues.focusRow} !important;
    color: ${theme.colors.blues.antBlue} !important;
    text-shadow: none !important;
    margin-bottom: 3px;
`;

const leasesPerformanceOverviewLayout = {
    columns: 1,
    rows: 4,
};

const pageStyle = css`
    display: flex;
    background-color: ${theme.colors.grays.background};
    overflow-y: scroll;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
`;

const marginStyle = { margin: '12px 7.5px' };

interface ExpirationDashboardProps {
    tenantsEnabled: boolean;
}
/**
 * Handles the logic for the lease summary and renders a grid
 */
const ExpirationDashboard = ({
    tenantsEnabled,
}: ExpirationDashboardProps): JSX.Element | null => {
    const {
        data: leasesPerformanceOverview,
        isLoading: isLeasesPerformanceOverviewLoading,
        isError: isLeasesPerformanceOverviewError,
        leftAxis,
        setLeftAxis,
        rightAxis,
        setRightAxis,
        sortAxis,
        setSortAxis,
        sortDirection,
        setSortDirection,
        propertyLeasesTableStorageKey,
        entitiesFilteredByEntitiesCode,
        entityCodes,
        propertyLevelLeaseSummaryData,
        setPropertyLevelLeaseSummaryData,
        selectedDataLevel,
    } = useGetLeasesPerformanceOverviewConfig();

    const groupableAttributes = useGetGroupableAttributes();
    const entityAttributesContext = useContext(EntityAttributesContext);
    const entityAttributes =
        entityAttributesContext?.data?.attributeDefinitions;

    const { data: recurringCharges, isLoading: isLoadingRecurringCharges } =
        useRecurringCharges(entityCodes, selectedDataLevel);

    const [groupingSelection, setGroupingSelection] =
        useState<EntityDataGroupingKeys | null>(null);

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const [attributeSelection, setAttributeSelection] =
        useState<AttributeFromAPI | null>(groupableAttributes[0] ?? null);

    const [data, setData] = useState<PropertyDetailsProps[]>(
        leasesPerformanceOverview ?? [],
    );

    const [chartData, setChartData] = useState<PropertyDetailsProps[]>(
        leasesPerformanceOverview ?? [],
    );

    const [selectedChargeCode, setSelectedChargeCode] = useState<string[]>([]);

    const [rowData, setRowData] = useState<string>('');

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        setGridConfig,
        localConfig,
        setLocalConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.PerformanceOverview);

    const isAttributeSelected =
        groupingSelection === EntityDataGroupingKeys.Attributes &&
        attributeSelection?.key !== null;

    useEffect(() => {
        if (!entitiesFilteredByEntitiesCode) {
            return;
        }

        if (
            groupingSelection === EntityDataGroupingKeys.Attributes &&
            attributeSelection
        ) {
            const selectedAttribute = entityAttributes?.find(
                (attr) => attr.attribute_code === attributeSelection.key,
            );

            if (!selectedAttribute) {
                return;
            }

            const groupedData = groupByAttribute(
                entityCodes,
                leasesPerformanceOverview ?? [],
                selectedAttribute,
            )
                .filter((g) => g.entity_codes.length)
                .sort((a, b) =>
                    numericalSort(b.total_properties, a.total_properties),
                );

            setChartData(groupedData);
        } else {
            setChartData(leasesPerformanceOverview ?? []);
        }

        setData(leasesPerformanceOverview ?? []);
        setPropertyLevelLeaseSummaryData(leasesPerformanceOverview ?? []);

        if (!localConfig) {
            setLocalConfig({
                groupingSelection,
                attributeSelection,
            });
            return;
        }
        setLocalConfig({
            ...localConfig,
            groupingSelection,
            attributeSelection,
        });
    }, [groupingSelection, attributeSelection, leasesPerformanceOverview]);

    useEffect(() => {
        if (isAttributeSelected) {
            setRowData(data[0]?.property_name ?? '');
        } else {
            setAttributeSelection(groupableAttributes[0]);
        }
    }, [isAttributeSelected, data, groupableAttributes]);

    useMemo(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration?.filters_json?.local_config;
            config['groupingSelection'] &&
                setGroupingSelection(config['groupingSelection']);
            config['attributeSelection'] &&
                setAttributeSelection(config['attributeSelection']);
            return;
        }
        setGroupingSelection(null);
        setAttributeSelection(groupableAttributes[0] ?? null);
    }, [selectedConfiguration, configKey]);

    const filteredRecurringCharges = useMemo(() => {
        const recurringChargesData = recurringCharges?.recurringCharges ?? [];
        return filterRecurringCharges(
            recurringChargesData,
            selectedChargeCode,
        ) as RecurringCharge[];
    }, [recurringCharges, selectedChargeCode]);

    const onChange = useCallback(
        (value: string[]) => {
            setSelectedChargeCode(value);
        },
        [setSelectedChargeCode],
    );

    const options = Object.keys(propertyDetailsValues)
        .map((key) => ({
            value: key,
            label: propertyDetailsValues[
                key as keyof PropertyDetailsValuesType
            ],
        }))
        .sort((a, b) => stringSort(b.label, a.label));

    const dataPropertyDetails =
        chartData?.length === 0 ? propertyLevelLeaseSummaryData : chartData;

    const occupiedUnits =
        leasesPerformanceOverview?.reduce(
            (acc, item) => acc + item.occupied_units,
            0,
        ) ?? 0;

    if (!data || !recurringCharges || isLoadingRecurringCharges) {
        return <Skeleton paragraph={{ rows: 15 }} />;
    }

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.PerformanceOverview}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                <ResponsiveBox style={{ width: '100%' }}>
                    {Array.from({
                        length: leasesPerformanceOverviewLayout.rows,
                    }).map((_, index) => {
                        return <Row />;
                    })}

                    {Array.from({
                        length: leasesPerformanceOverviewLayout.columns,
                    }).map(() => {
                        return <Col />;
                    })}
                    <Item>
                        <Location row={0} col={0} colspan={1} />
                        <SelectionsBanner
                            isCollapsed={false}
                            data-testid="leases-performance-overview-selections-banner"
                            bannerInlineStyles={{
                                padding: 16,
                                boxShadow: 'none',
                                ...marginStyle,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                    flexWrap: 'wrap',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    <SavedGridConfigSelect
                                        savedConfigurationsData={
                                            savedConfigurationsData
                                        }
                                        selectedConfiguration={
                                            selectedConfiguration
                                        }
                                        setSelectedConfiguration={
                                            setSelectedConfiguration
                                        }
                                    />
                                    <span>
                                        <SavedConfigManageDropdown
                                            onUpdateConfig={onUpdateConfig}
                                            onDeleteConfig={onDeleteConfig}
                                            resetSelectedConfiguration={
                                                resetSelectedConfiguration
                                            }
                                            hasUserAccess={
                                                !isAdmin &&
                                                selectedConfiguration?.reference_type !==
                                                    FilterReferenceTypes.USER
                                            }
                                            allowDeleteOnly={saveConfigDisabled}
                                            defaultConfigSelected={
                                                !selectedConfiguration
                                            }
                                            setIsEditorOpen={setIsEditorOpen}
                                        />
                                    </span>
                                    {isEditorOpen && (
                                        <SavedConfigEditor
                                            handleClose={() =>
                                                setIsEditorOpen(false)
                                            }
                                            onSave={onSaveConfig}
                                            isAdmin={isAdmin}
                                            existingConfigNames={
                                                existingConfigNames
                                            }
                                        />
                                    )}
                                </div>
                                <div
                                    style={{
                                        width: '450px',
                                        marginRight: '35px',
                                        marginLeft: '18px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    <AttributesGroupBySelect
                                        key={`${groupingSelection}_${
                                            attributeSelection?.key ?? ''
                                        }`}
                                        groupingSelection={groupingSelection}
                                        setGroupingSelection={
                                            setGroupingSelection
                                        }
                                        attributeSelection={attributeSelection}
                                        attributes={groupableAttributes}
                                        setAttributeSelection={
                                            setAttributeSelection
                                        }
                                    />
                                </div>

                                <div
                                    style={{
                                        paddingBottom: '3px',
                                        marginLeft: 'auto',
                                        marginRight: 12,
                                    }}
                                >
                                    <RecurringChargeSelect
                                        key={selectedChargeCode.join(',')}
                                        selectedChargeCodes={selectedChargeCode}
                                        recurringCharges={
                                            recurringCharges?.summary.buckets ??
                                            []
                                        }
                                        onChange={onChange}
                                        style={{
                                            width: 'auto',
                                            minWidth: '250px',
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        type="primary"
                                        className={
                                            isCollapsed
                                                ? chartButtonStyle
                                                : chartButtonStyleActive
                                        }
                                        onClick={() =>
                                            setIsCollapsed(!isCollapsed)
                                        }
                                    >
                                        <PieChartOutlined
                                            style={{ fontSize: '20px' }}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </SelectionsBanner>
                    </Item>

                    <Item>
                        <Location row={1} col={0} colspan={1} />
                        <div style={marginStyle}>
                            <LeaseSummaryCardSection
                                recurringCharges={filteredRecurringCharges}
                                occupiedUnits={occupiedUnits}
                                selectedDataLevel={selectedDataLevel}
                            />
                        </div>
                    </Item>

                    <Item>
                        <Location row={2} col={0} colspan={1} />
                        <div
                            style={{
                                height: isCollapsed ? 0 : 'auto',
                                margin: isCollapsed ? 0 : '12px 7.5px',
                                overflow: isCollapsed ? 'hidden' : 'auto',
                                transition: 'height 0.5s',
                            }}
                        >
                            <LeasesPerformanceOverviewChart
                                sortAxis={sortAxis}
                                sortDirection={sortDirection}
                                setSortAxis={setSortAxis}
                                setSortDirection={setSortDirection}
                                leftAxis={leftAxis}
                                rightAxis={rightAxis}
                                setLeftAxis={setLeftAxis}
                                setRightAxis={setRightAxis}
                                options={options}
                                propertyDetails={dataPropertyDetails}
                                isLeasesPerformanceOverviewLoading={
                                    isLeasesPerformanceOverviewLoading
                                }
                                isLeasesPerformanceOverviewError={
                                    isLeasesPerformanceOverviewError
                                }
                                recurringCharges={filteredRecurringCharges}
                            />
                        </div>
                    </Item>

                    <Item>
                        <Location row={3} col={0} colspan={1} />
                        <div style={marginStyle}>
                            <LeasesPerformanceOverviewCard
                                attributeSelection={attributeSelection}
                                attributeDefinitions={entityAttributes}
                                groupingSelection={groupingSelection}
                                isAttributeSelected={isAttributeSelected}
                                propertyLeasesTableStorageKey={
                                    propertyLeasesTableStorageKey
                                }
                                isLeasesPerformanceOverviewLoading={
                                    isLeasesPerformanceOverviewLoading
                                }
                                isLeasesPerformanceOverviewError={
                                    isLeasesPerformanceOverviewError
                                }
                                data={data}
                                recurringCharges={filteredRecurringCharges}
                                rowData={rowData}
                                setRowData={setRowData}
                                selectedConfiguration={selectedConfiguration}
                                setGridConfig={setGridConfig}
                                setLocalConfig={setLocalConfig}
                                configKey={configKey ?? ''}
                                selectedChargeCode={selectedChargeCode}
                                setSelectedChargeCode={setSelectedChargeCode}
                            />
                        </div>
                    </Item>
                </ResponsiveBox>
            </div>
        </PermissionedWrapper>
    );
};

const mapState = (state: RootStateOrAny) => {
    const clientConfig = selectClientConfigurations(state);
    const { FEATURE_TENANTS: tenantsEnabled } = clientConfig;
    return {
        tenantsEnabled,
    };
};

export default connect(mapState)(ExpirationDashboard);
