import { css } from 'emotion';
import moment, { Moment } from 'moment';
import { DASH_DASH } from 'config/constants';
import {
    TypeOption,
    CapitalProject,
    CapitalProjectMonthlyCost,
    UnpackedMonthlyCosts,
    CellInfoType,
    PropertyDetailsProps,
    CapitalProjectGrid,
    FixAnyType,
    CustomizeCellInfoType,
} from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import { currencyRenderer } from 'utils/tables/renderers';
import theme from 'config/theme';
import { isEqual, omit } from 'lodash';
import { Tag } from 'antd';
import { inRange } from 'waypoint-utils';
import { safeDivision } from 'shared-types';
import memoizeOne from 'memoize-one';

interface MonthlyCostRenderParams {
    value: number | string | undefined;
    columnIndex: number;
    data: {
        year: number;
    };
}

export const planningContainerTabsKeys = {
    trackerTable: 'trackerTable',
    planTable: 'planTable',
};
export const defaultMonthObjects = {
    january: 0,
    february: 0,
    march: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    september: 0,
    october: 0,
    november: 0,
    december: 0,
};

export const createYearArray = (startYear: number, endYear: number) => {
    return Array.from(
        { length: endYear - startYear + 1 },
        (_, index) => index + startYear,
    );
};

export const packMonthlyCosts = (
    data: UnpackedMonthlyCosts,
    capitalProjectId?: string,
) => {
    const costtype = Object.keys(data);
    const monthlyCosts: CapitalProjectMonthlyCost[] = [];
    costtype.map((type) => {
        return data[type as keyof UnpackedMonthlyCosts].map(
            (monthlyCost: Dictionary<number>) => {
                return Object.entries(monthlyCost).map(([key, value]) => {
                    if (key !== 'year') {
                        monthlyCosts.push({
                            type,
                            year: monthlyCost.year,
                            month: moment().month(key).format('M'),
                            value: value,
                            capital_project_id: capitalProjectId ?? null,
                        });
                    }
                });
            },
        );
    });

    return monthlyCosts;
};

export const unpackMonthlyCosts = (
    data: CapitalProject,
    range?: [Moment, Moment] | null,
) => {
    const datagridArray: UnpackedMonthlyCosts = {
        actual: [],
        budget: [],
    };

    let startDateMoment;
    let endDateMoment;

    if (!data.estimated_start_date || !data.estimated_completion_date) {
        if (range) {
            startDateMoment = moment(range[0]);
            endDateMoment = moment(range[1]);
        } else {
            return datagridArray;
        }
    } else {
        startDateMoment = moment(data.estimated_start_date);
        endDateMoment = moment(data.estimated_completion_date);
    }

    const projectYears = createYearArray(
        startDateMoment.year(),
        endDateMoment.year(),
    );

    const costsData = data.monthly_costs;
    const costsObject = projectYears.reduce(
        (acc: Dictionary<Dictionary<number>>, year: number) => {
            acc[`actual_${year}`] = { year: year };
            acc[`budget_${year}`] = { year: year };
            return acc;
        },
        {},
    );

    const costKeys = Object.keys(costsObject);

    const datagrid = costsData.reduce(
        (
            acc: Dictionary<Dictionary<number>>,
            monthlyCost: CapitalProjectMonthlyCost,
        ) => {
            const key = `${monthlyCost.type}_${monthlyCost.year}`;

            if (monthlyCost.value > 0 && costKeys.includes(key)) {
                acc[key][
                    moment()
                        .month(Number(monthlyCost.month) - 1)
                        .format('MMMM')
                        .toLowerCase()
                ] = monthlyCost.value;
            }
            return acc;
        },
        costsObject,
    );

    Object.entries(datagrid).map(([key, value]) => {
        const type = key.slice(0, key.indexOf('_'));
        if (!datagridArray[type as keyof UnpackedMonthlyCosts]) {
            datagridArray[type as keyof UnpackedMonthlyCosts] = [];
        }
        datagridArray[type as keyof UnpackedMonthlyCosts].push(value);
    });

    return datagridArray;
};

export const canShowColorOptionOrPicker = (typeCategory: string) =>
    ['priority', 'status'].includes(typeCategory);

export const typeOptionStyle = css`
    display: flex;
    align-items: center;
    padding: 6px;
    border: 1px dashed #d9d9d9;
    margin-top: 6px;
    border-radius: 2px;
    transition: box-shadow 0.3s ease-in;
    &:hover {
        cursor: pointer;
    }
`;

export const typeOptionSelectStyle = css`
    display: flex;
    align-items: center;
    padding: 2px 6px;
`;

export const colorOfTheTypeSelect = (color: string) => css`
    margin-right: 8px;
    background-color: ${color};
    height: 9px;
    width: 9px;
    border-radius: 50%;
`;

export const colorOfTheType = (color: string) => css`
    margin-right: 10px;
    background-color: ${color};
    width: 20px;
    height: 20px;
    border-radius: 6px;
    cursor: pointer;
`;

export const wordWrapColumn = css`
    white-space: normal;
    word-wrap: break-word;
`;

export const optionIsInUse = (
    option: TypeOption | null,
    tableData: CapitalProject[],
) => {
    const setOfDefinedValueInUse =
        tableData && tableData.map((row) => row.client_defined_values).flat();

    const definedValueIsInUse =
        setOfDefinedValueInUse &&
        setOfDefinedValueInUse.filter((valueInUse) => {
            return (
                valueInUse?.type === option?.type &&
                valueInUse?.value === option?.label
            );
        });

    return definedValueIsInUse;
};

export const getDateRangeLimits = (dateRange: [Moment, Moment]) => {
    return {
        startYear: dateRange[0].year(),
        endYear: dateRange[1].year(),
        startMonth: dateRange[0].month(),
        endMonth: dateRange[1].month(),
    };
};

export const filterMonthlyCostsByProjectDateRange = (
    projectRowData: { [key: string]: number | string },
    projectDateRangeStartYear: number,
    projectDateRangeEndYear: number,
    projectDateRangeStartMonth: number,
    projectDateRangeEndMonth: number,
) => {
    const filteredMonthlyCostData = omit(projectRowData, ['year']);
    const monthList = Object.keys(defaultMonthObjects);

    const shouldIncludeCost = (monthIndex: number) => {
        if (projectDateRangeStartYear === projectDateRangeEndYear) {
            return (
                monthIndex >= projectDateRangeStartMonth &&
                monthIndex <= projectDateRangeEndMonth
            );
        }
        if (
            (Number(projectRowData.year) === projectDateRangeStartYear &&
                monthIndex >= projectDateRangeStartMonth) ||
            (Number(projectRowData.year) === projectDateRangeEndYear &&
                monthIndex <= projectDateRangeEndMonth) ||
            (Number(projectRowData.year) > projectDateRangeStartYear &&
                Number(projectRowData.year) < projectDateRangeEndYear)
        ) {
            return true;
        }
        return false;
    };

    return Object.keys(filteredMonthlyCostData).reduce((acc, mc) => {
        const monthIndex = monthList.indexOf(mc);
        if (monthIndex !== -1) {
            if (shouldIncludeCost(monthIndex)) {
                acc[mc] = Number(projectRowData[mc]);
            }
        }
        return acc;
    }, {} as Dictionary<number>);
};

export const monthlyCostCellRender = (
    e: MonthlyCostRenderParams,
    dateRange: [Moment, Moment] | null,
) => {
    if (!dateRange) {
        return currencyRenderer(e.value);
    }

    const { startYear, endYear, startMonth, endMonth } =
        getDateRangeLimits(dateRange);

    if (
        (e.data.year === startYear && e.columnIndex < startMonth + 1) ||
        (e.data.year === endYear && e.columnIndex > endMonth + 1)
    ) {
        return (
            <div
                style={{
                    backgroundColor: theme.colors.grays.disabled,
                    width: '100%',
                    height: '100%',
                }}
            >
                {DASH_DASH}
            </div>
        );
    }
    return e.value !== 0 ? currencyRenderer(e.value) : DASH_DASH;
};

export const tagRender = (
    data: CellInfoType,
    typeKey: 'category' | 'status' | 'subcategory' | 'priority',
) => {
    if (!data) {
        return <></>;
    }

    const tag = data.data[typeKey as keyof CapitalProject];

    if (!tag) {
        return <div>{DASH_DASH}</div>;
    }

    return (
        <Tag color={tag?.tag_color} style={{ marginRight: 3 }}>
            {tag?.value}
        </Tag>
    );
};

export const getTotal = (
    rowData: { [key: string]: number | string },
    startYear: number,
    endYear: number,
    startMonth: number,
    endMonth: number,
    shouldRender = true,
) => {
    const dataToSum = filterMonthlyCostsByProjectDateRange(
        rowData,
        startYear,
        endYear,
        startMonth,
        endMonth,
    );

    const totalCost = Object.values(dataToSum).reduce(
        (a, b) => Number(a) + Number(b),
        0,
    );

    if (shouldRender) {
        return currencyRenderer(totalCost);
    }
    return totalCost;
};

export const getTotalRow = (
    data: CapitalProject,
    costType: string,
    year: number | null = null,
    selectedYears: number[] | null = null,
) => {
    if (
        !data.estimated_start_date ||
        !data.estimated_completion_date ||
        !data.monthly_costs
    ) {
        return 0;
    }
    const { startYear, endYear, startMonth, endMonth } = getDateRangeLimits([
        moment(data.estimated_start_date),
        moment(data.estimated_completion_date),
    ]);
    const unpackedMonthlyCostsByType =
        unpackMonthlyCosts(data)[costType as keyof UnpackedMonthlyCosts];
    const projectTotalByType = unpackedMonthlyCostsByType.reduce(
        (acc: number, t: Dictionary<string | number>) => {
            if (year && year !== t.year) {
                return acc;
            }
            if (selectedYears && selectedYears.length) {
                if (
                    (t.year as number) < selectedYears[0] ||
                    (t.year as number) > selectedYears[selectedYears.length - 1]
                ) {
                    return acc;
                }
            }
            const total = getTotal(
                t,
                startYear,
                endYear,
                startMonth,
                endMonth,
                false,
            );
            const totalValue = isNaN(Number(total)) ? 0 : Number(total);

            return acc + totalValue;
        },
        0,
    );

    return projectTotalByType;
};

export const getRowCostTotal = memoizeOne(getTotalRow, isEqual);

export const colorPickerOptions = [
    '#B80000',
    '#DB3E00',
    '#F44E3B',
    '#FE9200',
    '#FCCB00',
    '#008B02',
    '#006B76',
    '#1273DE',
    '#004DCF',
    '#5300EB',
    '#DBDF00',
    '#A4DD00',
    '#68CCCA',
    '#73D8FF',
    '#AEA1FF',
    '#E27300',
    '#B0BC00',
    '#68BC00',
    '#16A5A5',
    '#009CE0',
    '#7B64FF',
    '#FA28FF',
    '#666666',
    '#194D33',
    '#0C797D',
    '#0062B1',
    '#653294',
    '#AB149E',
];

export const getGroupedItemCount = (options: CellInfoType | any) => {
    if (!options) {
        return 0;
    }
    if (options?.totalItem?.isExpanded) {
        return options?.totalItem?.data?.items?.length ?? 0;
    }
    return options?.totalItem?.data?.collapsedItems?.length ?? 0;
};

export const groupCell = (options: CellInfoType, unit = 'projects') => {
    return (
        <div>
            {options.value} ({getGroupedItemCount(options)} {unit})
        </div>
    );
};

export const groupCellBoolean = (
    options: CellInfoType | any,
    trueString = 'True',
    falseString = 'False',
    defaultUnit = 'projects',
) => {
    return (
        <div>
            {options.value ? trueString : falseString} (
            {getGroupedItemCount(options) ?? 0} {defaultUnit})
        </div>
    );
};

export const getTextValueOrEmpty = (cellInfo: CustomizeCellInfoType) => {
    return cellInfo.valueText ? cellInfo.valueText : DASH_DASH;
};

export function filterCapitalProjects(
    capitalProjectsData: CapitalProject[],
    entityCodes: string[],
    filteredYears: number[],
    leasesData: PropertyDetailsProps[] | undefined,
): CapitalProjectGrid[] | undefined {
    const baseCapitalProjectsData = capitalProjectsData?.filter(
        (capitalProject: CapitalProject) =>
            entityCodes.includes(capitalProject.entity_code),
    );

    const leaseMap = new Map();
    leasesData?.forEach((lease) => {
        leaseMap.set(lease.entity_code, lease.rentable_sq_ft);
    });

    const baseCapitalProjectsDataWithSqFt = baseCapitalProjectsData?.map(
        (capitalProject: CapitalProject) => {
            const rentable_square_footage =
                leaseMap.get(capitalProject.entity_code) || 0;
            return {
                ...capitalProject,
                rentable_square_footage,
                actual_psf: safeDivision(
                    getRowCostTotal(capitalProject, 'actual'),
                    rentable_square_footage ?? 0,
                ),
                budget_psf: safeDivision(
                    getRowCostTotal(capitalProject, 'budget'),
                    rentable_square_footage ?? 0,
                ),
            };
        },
    );

    if (!filteredYears.length) {
        return baseCapitalProjectsDataWithSqFt;
    }

    return baseCapitalProjectsDataWithSqFt?.filter((item) => {
        const lowerYearProject = item.estimated_start_date
            ? new Date(item.estimated_start_date).getFullYear()
            : 0;
        const upperYearProject = item.estimated_completion_date
            ? new Date(item.estimated_completion_date).getFullYear()
            : 0;

        const isLowerYearInRange = inRange(
            lowerYearProject,
            filteredYears[0],
            filteredYears[filteredYears.length - 1],
        );

        const isUpperYearInRange = inRange(
            upperYearProject,
            filteredYears[0],
            filteredYears[filteredYears.length - 1],
        );

        const noStartDateAndCompletionDate =
            !item.estimated_start_date && !item.estimated_completion_date;

        return (
            isLowerYearInRange ||
            isUpperYearInRange ||
            noStartDateAndCompletionDate
        );
    });
}

export const generateDefaultYears = () =>
    Array.from({ length: 6 }, (_, i) => new Date().getFullYear() + i);

export const convertNumbersToRangeValues = (
    numbers: number[],
): FixAnyType | undefined => {
    if (!numbers.length) {
        return;
    }

    const rangeValue: FixAnyType = [
        moment(numbers[0], 'YYYY'),
        moment(numbers[numbers.length - 1], 'YYYY'),
    ];

    return rangeValue;
};
