export const DEFAULT_PERIOD_RANGE = 'trailing_12';

// NOTE (Daniel): Top level settings menu items
export const PERSONAL_PROFILE_URL_SEGMENT = 'personal';
export const USER_MANAGEMENT_URL_SEGMENT = 'users';
export const CLIENT_SETTINGS_URL_SEGMENT = 'client';
export const PERMISSIONS_URL_SEGMENT = 'permission-groups';
export const COMPANY_URL_SEGMENT = 'company';
export const ADMIN_SETTINGS_URL_SEGMENT = '/settings/admin';
export const USER_PROFILE_SETTINGS_SEGMENT = '/settings/user/personal';
// NOTE (Daniel): Several status strings
export const STATUS_ACTIVE = 'active';

// NOTE (Daniel): User Roles
export const roles = {
    CLIENT_ADMIN: 'ClientAdmin',
    CLIENT_USER: 'ClientUser',
    WAYPOINT_SYSADMIN: 'WaypointSystemAdministrator',
    WAYPOINT_ASSOCIATE: 'WaypointAssociate',
};

export const PROPERTY_CACHE_KEY = 'PropertySlim_';

export const PROPERTY_GROUP_CACHE_KEY = 'PropertyGroup_';

export const DASH_DASH = '––';
export const API_URL = process.env.NX_PUBLIC_API_URL;
