import { API_URL } from 'config/constants';
import { AttributeDefinition } from 'shared-types';

const upsertAttributeDefinition = async (
    body: Partial<AttributeDefinition>,
): Promise<AttributeDefinition> => {
    const response = await fetch(`${API_URL}/attributes/definition`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to save attribute definition');
    }

    const { data } = await response.json();

    return data as AttributeDefinition;
};

export default upsertAttributeDefinition;
