import theme from 'config/theme';
import { css } from 'emotion';
import RentRollCard from 'components/leases/expirations/cards/rent-roll/RentRollCard';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const pageStyle = css`
    display: flex;
    background-color: ${theme.colors.grays.background};
    overflow-y: scroll;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
`;

const RentRollDashboard = () => {
    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.RentRoll}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                <div style={{ padding: '12px', height: '100%' }}>
                    <RentRollCard />
                </div>
            </div>
        </PermissionedWrapper>
    );
};

export default RentRollDashboard;
