import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { css, cx } from 'emotion';
import { BALANCE_SHEET_TITLE } from 'components/financials/balanceSheet/constants';
import BalanceSheetTable from 'components/financials/balanceSheet/components/table/BalanceSheetTable';
import BalanceSheetSelectionBanner from 'components/financials/balanceSheet/components/banner/BalanceSheetSelectionBanner';
import { SelectedPeriodType } from 'components/financials/balanceSheet/types';
import { filterAccountGraph } from 'waypoint-utils';
import { ActionsCard, DownloadButton, DisabledDashboard } from 'waypoint-react';
import { HIDE_NULLS } from 'waypoint-utils/account-graphs/constants';
import { useGetMentionableUsers } from 'waypoint-hooks';
import { saveAs } from 'file-saver';
import { getFilenameFromResponse } from 'waypoint-utils';
import { getBalanceSheetSubheading } from 'components/comments';
import { cardStyle } from 'components/financials/common';
import { useBalanceSheet } from 'components/financials/balanceSheet/components/BalanceSheetProvider';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { requestBalanceSheet } from 'waypoint-requests/balance-sheet/getBalanceSheet';
import { Button, message } from 'antd';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { useCommentsContextManager } from 'contexts/comments/CommentsManagerContext';

const actionButtons = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

interface BalanceSheetProps {
    isPropertyProfile?: boolean;
}

const BalanceSheetCard = ({
    isPropertyProfile = false,
}: BalanceSheetProps): JSX.Element => {
    const commentPaneCtx = useCommentsPane();

    const {
        openComments,
        closeComments,
        initializeComments,
        update: updateCommentsPane,
        isVisible,
    } = commentPaneCtx;

    const { registerCommentThread } = useCommentsContextManager();

    const {
        balanceSheetNodes,
        entityCodes: selectedEntityCodes,
        selectedDataLevel,
        dateSelections,
        setDateSelections,
        hideNull,
        setHideNull,
        hideNotes,
        setHideNotes,
        commentIdQueryParam,
        reportMetadata,
        isLoading,
        isError,
        reportNotes,
        mutateReportNotes,
        mentionableAccounts: accountMentionOptions,
    } = useBalanceSheet();

    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] =
        useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);
    const [selectedAccountMappingId, setSelectedAccountMappingId] = useState<
        string | null
    >(null);
    const [isBannerCollapsed, setIsBannerCollapsed] = useState<boolean>(false);

    const entityCodeCommentIdRef = useRef<string[] | null>(null);
    const entityCodesRef = useRef(selectedEntityCodes);

    const componentKey = useRef(new Date().toISOString());

    useEffect(() => {
        if (isPropertyProfile) {
            registerCommentThread(commentPaneCtx, componentKey.current);
        }
    }, [commentPaneCtx]);

    useEffect(() => {
        if (
            entityCodeCommentIdRef.current &&
            !isEqual(entityCodeCommentIdRef.current, entityCodesRef.current) &&
            isVisible
        ) {
            closeComments();
            entityCodeCommentIdRef.current = entityCodesRef.current;
        }
    }, [selectedEntityCodes, closeComments, isVisible]);

    if (!isEqual(entityCodesRef.current, selectedEntityCodes)) {
        entityCodesRef.current = selectedEntityCodes;
    }
    const entityCodes = entityCodesRef.current;
    const userMentionOptions = useGetMentionableUsers(entityCodes);

    useEffect(() => {
        updateCommentsPane({
            userMentionOptions,
        });
    }, [userMentionOptions]);

    useEffect(() => {
        if (entityCodes.length > 1) {
            closeComments();
        }
    }, [entityCodes]);

    useEffect(() => {
        if (!reportMetadata?.comment_thread_id) {
            return;
        }

        initializeComments(reportMetadata?.comment_thread_id);

        updateCommentsPane({
            heading: 'Balance Sheet',
            scrollToCommentId: commentIdQueryParam ?? undefined,
        });

        if (commentIdQueryParam && entityCodes.length === 1) {
            openComments();
            entityCodeCommentIdRef.current = entityCodesRef.current;
        }
    }, [reportMetadata?.comment_thread_id, entityCodes.length]);

    useEffect(() => {
        updateCommentsPane({
            accountMentionOptions,
        });
    }, [accountMentionOptions]);

    useEffect(() => {
        updateCommentsPane({
            accountGraph: balanceSheetNodes,
        });
    }, [balanceSheetNodes]);

    useEffect(() => {
        updateCommentsPane({
            subheading: getBalanceSheetSubheading(
                dateSelections.primary,
                dateSelections.secondary,
            ),
        });
    }, [dateSelections]);

    const resetDefault = () => {
        setDateSelections({
            ...dateSelections,
            primary: dateSelections.primaryDefault,
            secondary: dateSelections.secondaryDefault,
        });

        setHideNull(false);
        setHideNotes(false);
    };

    const downloadBalanceSheet = async () => {
        setIsDownloading(true);

        try {
            const balanceSheetResponse = await requestBalanceSheet(
                {
                    entityCodes,
                    selectedDataLevel,
                    primaryPeriod: dateSelections.primary,
                    secondaryPeriod: dateSelections.secondary,
                    isHideNull: hideNull,
                    reportMetadataId:
                        reportMetadata && !hideNotes ? reportMetadata.id : null,
                },
                true,
            );

            const filename = getFilenameFromResponse(balanceSheetResponse);
            const file = await balanceSheetResponse.blob();

            saveAs(file, filename);
            setIsDownloading(false);
        } catch (e) {
            setIsDownloading(false);

            message.error('An error occurred while downloading the file');
        }
    };

    const onApply = ({
        selectedPrimaryPeriod,
        selectedSecondaryPeriod,
    }: SelectedPeriodType) => {
        setDateSelections({
            ...dateSelections,
            primary: selectedPrimaryPeriod,
            secondary: selectedSecondaryPeriod,
        });
    };

    const filterArray: string[] = [];

    if (hideNull) {
        filterArray.push(HIDE_NULLS);
    }

    // style only exists when coming from property profile section
    const heightOffset = isPropertyProfile ? 300 : 250;

    if (!entityCodes.length) {
        return (
            <DisabledDashboard text={'No properties selected or available'} />
        );
    }

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.BalanceSheet}>
            <ActionsCard
                id="financials-balance-sheet"
                size="default"
                title={
                    <h2
                        style={{
                            marginBottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {BALANCE_SHEET_TITLE}{' '}
                        <div style={{ marginLeft: '14px' }}>
                            <Button
                                type="primary"
                                onClick={() => setIsSettingsModalOpen(true)}
                            >
                                New Comparison
                            </Button>
                        </div>
                    </h2>
                }
                className={cx([cardStyle])}
                extra={
                    <div className={actionButtons}>
                        <BalanceSheetSelectionBanner
                            onReset={resetDefault}
                            onApply={onApply}
                            dataAvailabilityDate={dateSelections}
                            isPropertyProfile={isPropertyProfile}
                            entityCodes={entityCodes}
                            isBannerCollapsed={isBannerCollapsed}
                            isSettingsModalOpen={isSettingsModalOpen}
                            setIsSettingsModalOpen={setIsSettingsModalOpen}
                        />
                        <DownloadButton
                            onClick={downloadBalanceSheet}
                            disabled={isDownloading}
                        />
                    </div>
                }
                style={
                    isPropertyProfile
                        ? { height: '80vh', marginBottom: '20px' }
                        : undefined
                }
            >
                <BalanceSheetTable
                    balanceSheet={filterAccountGraph(
                        balanceSheetNodes ?? null,
                        filterArray,
                    )}
                    isLoading={isLoading}
                    periods={[dateSelections.primary, dateSelections.secondary]} // YYYY-MM-DD
                    error={isError}
                    reportNotes={reportNotes ?? []}
                    mutateReportNotes={mutateReportNotes}
                    hideNotes={hideNotes}
                    entityCodes={entityCodes}
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                    selectedAccountMappingId={selectedAccountMappingId ?? ''}
                    setSelectedAccountMappingId={setSelectedAccountMappingId}
                    setAccountFilterSelection={(accountMappingId: string) => {
                        updateCommentsPane({
                            accountFilterSelection: accountMappingId,
                        });
                    }}
                    heightOffset={heightOffset}
                />
            </ActionsCard>
        </PermissionedWrapper>
    );
};

export default BalanceSheetCard;
