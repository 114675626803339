import { useGetQueryParam, useSelectedDataLevel } from 'waypoint-hooks';
import IncomeStatementContainer from 'components/financials/comparative-income-statement/card/IncomeStatementContainer';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const VarianceReportingContainer = () => {
    const entityCode = useGetQueryParam('entity_code');
    const selectedDataLevel = useSelectedDataLevel();

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.VarianceReporting}
            showDisabledView={true}
        >
            <IncomeStatementContainer
                entityCodes={[entityCode ?? '']}
                selectedDataLevel={selectedDataLevel}
                isPropertyProfile={false}
                isVarianceReporting={true}
            />
        </PermissionedWrapper>
    );
};

export default VarianceReportingContainer;
