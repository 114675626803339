import { css } from 'emotion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Collapse, Select, Radio, RadioChangeEvent } from 'antd';
import {
    useGetEntityReportWidgets,
    useGetMyReports,
    useGetQueryParam,
    useGetReportApprovals,
    useGetSelectedFilteredEntityCodes,
} from 'waypoint-hooks';
import {
    MyReportSections,
    groupingOptions,
    reportStatus,
    statusOptions,
} from './constants';
import { RootStateOrAny, connect } from 'react-redux';
import { selectProperties } from 'state/properties/selectors';
import { selectUsers } from 'state/users/selectors';
import { PropertyType, User, IDataGrid } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import { EntityReportTable } from './EntityReportTable';
import { selectCurrentUser } from 'state/user/selectors';
import {
    REPORT_ID_KEY,
    applyGrouping,
    calculateEntityItems,
    calculatedProgress,
    clearGrouping,
    decorateReportsForGrid,
    filterReportsByEntityCodes,
    filterReportsByUserRole,
    getEntityReportWidgetsForSelectedReport,
} from './ReportUtils';
import ReportEmptyState from './components/ReportEmptyState';
import theme from 'config/theme';
import MyReportHeader from './components/MyReportHeader';
import { ExpandAndCollapseButton } from 'waypoint-react';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

const { Panel } = Collapse;

const pageStyle = css`
    height: 100%;
    background-color: ${theme.colors.white};
    overflow-y: scroll;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

const headerStyle = css`
    display: flex;
    align-items: center;
    flex-direction: wrap;
    padding: 10px 30px 0 25px;
    background-color: #ffffff;
    border-bottom: 1px solid #e5e5e5;
    h1 {
        margin: 0;
    }
`;

const collapseHeaderStyle = (isReviewerCollapse?: boolean) => css`
    .ant-collapse-item {
        background-color: rgba(0, 0, 0, 0);
        border: 1.5px solid #ccc;
        border-left: 3px solid
            ${isReviewerCollapse
                ? theme.colors.blues.antBlue
                : theme.colors.workflowReportStatus.approved};
        border-radius: 5px !important;

        .ant-collapse-header {
            align-items: center !important;

            .ant-collapse-header-text {
                font-weight: 600;
                font-size: 2rem;
                color: ${isReviewerCollapse
                    ? theme.colors.blues.antBlue
                    : theme.colors.workflowReportStatus.approved};
                p {
                    font-size: 1.5rem;
                    color: #ccc;
                }
            }
        }

        .ant-collapse-expand-icon {
            color: ${isReviewerCollapse
                ? theme.colors.blues.antBlue
                : theme.colors.workflowReportStatus.approved};
            bottom: 3px;
            position: relative;
        }

        &.ant-collapse-item-active {
            border: none;
            border-radius: 0 !important;
            background-color: rgba(0, 0, 0, 0);
            .ant-collapse-header-text {
                p {
                    font-size: 0;
                    color: #ccc;
                }
            }
        }

        .ant-collapse-content-box {
            padding: 10px 50px;
        }
    }

    .dx-widget {
        border-left: 3px solid
            ${isReviewerCollapse
                ? theme.colors.blues.antBlue
                : theme.colors.workflowReportStatus.approved};
    }
`;

const bodyStyle = css`
    padding: 2rem 1rem;
    padding-bottom: 5rem;
`;

const selectStyle = css`
    .ant-select-selector {
        padding-left: 0 !important;
    }
`;

interface MyReportGridProps {
    properties: Dictionary<PropertyType>;
    users: User[];
    user: User;
}

const MyReportGrid = ({ users, properties, user }: MyReportGridProps) => {
    const reportIdParam = useGetQueryParam(REPORT_ID_KEY);
    const [statusFilter, setStatusFilter] = useState<string>('active');
    const [activeKeyAssignee, setActiveKeyAssignee] = useState<string>('');
    const [activeKeyReviewer, setActiveKeyReviewer] = useState<string>('');

    const reviewerDataGrid = useRef<DataGridRef>(null);
    const assigneeDataGrid = useRef<DataGridRef>(null);

    const [grouping, setGrouping] = useState<string | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);

    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const { data, isLoading } = useGetMyReports(entityCodes);

    const entityReportIds = (data ?? []).flatMap((report) =>
        report.entityReports
            ? report.entityReports?.map((entityReport) => entityReport.id)
            : [],
    );

    const {
        data: entityReportWidgets,
        isLoading: isLoadingEntityReportWidgets,
    } = useGetEntityReportWidgets({
        entityReportIds,
        entityCodes,
    });

    const { data: approvals, isValidating: isValidatingApprovals } =
        useGetReportApprovals({
            entityReportIds,
        });

    const filteredReports = useMemo(
        () =>
            filterReportsByUserRole(
                filterReportsByEntityCodes(data ?? [], entityCodes),
                user.id,
                statusFilter,
            ),
        [data, user, statusFilter],
    );

    useEffect(() => {
        groupChanged(grouping ?? '');
    }, [data, entityCodes]);

    useEffect(() => {
        if (reportIdParam && data?.length) {
            const report = data.find((r) => r.id === reportIdParam);

            setStatusFilter(report?.state ?? reportStatus.active);
        }
    }, [reportIdParam, data]);

    useEffect(() => {
        if (filteredReports?.reviewerReports?.length === 0) {
            setActiveKeyReviewer('');
            return;
        }
        setActiveKeyReviewer(MyReportSections.reviewerReports);
    }, [filteredReports]);

    useEffect(() => {
        if (filteredReports?.assigneeReports?.length === 0) {
            setActiveKeyAssignee('');
            return;
        }
        setActiveKeyAssignee(MyReportSections.assigneeReports);
    }, [filteredReports]);

    const openAssigneeCollapse = () => {
        setActiveKeyAssignee(
            activeKeyAssignee === MyReportSections.assigneeReports
                ? ''
                : MyReportSections.assigneeReports,
        );
    };

    const openReviewerCollapse = () => {
        setActiveKeyReviewer(
            activeKeyReviewer === MyReportSections.reviewerReports
                ? ''
                : MyReportSections.reviewerReports,
        );
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const groupChanged = (group: string) => {
        if (!group) {
            return;
        }
        applyGrouping(reviewerDataGrid, group);
        applyGrouping(assigneeDataGrid, group);
        setExpandButtonEnable(group === 'none');
        setGrouping(group);
    };

    const clearAllGroupings = () => {
        clearGrouping(reviewerDataGrid);
        clearGrouping(assigneeDataGrid);
        setExpandButtonEnable(true);
        setExpanded(false);
        setGrouping(null);
    };

    const reportsData = useMemo(() => {
        if (entityReportWidgets) {
            return decorateReportsForGrid(
                data ?? [],
                entityCodes,
                entityReportWidgets,
            );
        }
        return [];
    }, [data, entityCodes, entityReportWidgets]);

    const entityReportWidgetsForData = getEntityReportWidgetsForSelectedReport(
        reportsData,
        entityReportWidgets ?? [],
    );

    const assigneeItems = calculateEntityItems(
        filteredReports?.assigneeReports,
        properties,
        entityReportWidgetsForData,
    );

    const reviewerItems = calculateEntityItems(
        filteredReports?.reviewerReports,
        properties,
        entityReportWidgetsForData,
    );

    const onChangeFilterStatus = (e: RadioChangeEvent) => {
        setStatusFilter(e.target.value);
    };

    if (
        isLoading ||
        isLoadingEntityReportWidgets ||
        !reportsData ||
        isValidatingApprovals
    ) {
        return <ReportEmptyState text="Loading..." />;
    }

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.MyReports}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                <div className={headerStyle}>
                    <h1>
                        My Reports
                        <div
                            style={{
                                marginTop: '6px',
                                marginBottom: '2px',
                                display: 'flex',
                            }}
                        >
                            <Select
                                allowClear
                                bordered={false}
                                className={selectStyle}
                                style={{
                                    width: '150px',
                                    marginLeft: '0',
                                    marginRight: '0',
                                }}
                                defaultValue={grouping}
                                onChange={groupChanged}
                                options={groupingOptions}
                                onClear={clearAllGroupings}
                                placeholder="Group by"
                            />

                            <ExpandAndCollapseButton
                                expanded={expanded}
                                toggleExpanded={toggleExpanded}
                                expandButtonEnable={expandButtonEnable}
                            />
                        </div>
                    </h1>

                    <Radio.Group
                        onChange={onChangeFilterStatus}
                        value={statusFilter}
                        buttonStyle="solid"
                        style={{
                            marginLeft: 'auto',
                        }}
                    >
                        {statusOptions
                            .filter((option) => option.value !== 'draft')
                            .map((option) => (
                                <Radio.Button
                                    key={option.color}
                                    value={option.value}
                                >
                                    {option.label}
                                </Radio.Button>
                            ))}
                    </Radio.Group>
                </div>
                <div className={bodyStyle}>
                    <Collapse
                        ghost
                        activeKey={activeKeyAssignee}
                        defaultActiveKey={
                            filteredReports?.assigneeReports?.length > 0
                                ? MyReportSections.assigneeReports
                                : ''
                        }
                        className={collapseHeaderStyle()}
                        onChange={openAssigneeCollapse}
                    >
                        <Panel
                            header={
                                <MyReportHeader
                                    title="Assignee"
                                    count={assigneeItems.length}
                                    percentage={calculatedProgress(
                                        assigneeItems,
                                    )}
                                />
                            }
                            key={MyReportSections.assigneeReports}
                            collapsible={
                                filteredReports?.assigneeReports?.length > 0
                                    ? 'header'
                                    : 'disabled'
                            }
                        >
                            <EntityReportTable
                                isMyReportsTable
                                reports={filteredReports?.assigneeReports}
                                properties={properties}
                                users={users}
                                entityReportWidgets={entityReportWidgetsForData}
                                reportUserRef={reviewerDataGrid}
                                expanded={expanded}
                                approvals={approvals ?? []}
                            />
                        </Panel>
                    </Collapse>
                    <Collapse
                        ghost
                        activeKey={activeKeyReviewer}
                        defaultActiveKey={
                            filteredReports?.reviewerReports?.length > 0
                                ? MyReportSections.reviewerReports
                                : ''
                        }
                        style={{ marginTop: '12px' }}
                        onChange={openReviewerCollapse}
                        className={collapseHeaderStyle(true)}
                    >
                        <Panel
                            header={
                                <MyReportHeader
                                    title="Reviewer"
                                    count={reviewerItems.length}
                                    percentage={calculatedProgress(
                                        reviewerItems,
                                    )}
                                />
                            }
                            key={MyReportSections.reviewerReports}
                            collapsible={
                                filteredReports?.reviewerReports?.length > 0
                                    ? 'header'
                                    : 'disabled'
                            }
                        >
                            <EntityReportTable
                                isMyReportsTable
                                reports={filteredReports?.reviewerReports}
                                properties={properties}
                                users={users}
                                entityReportWidgets={entityReportWidgetsForData}
                                reportUserRef={assigneeDataGrid}
                                expanded={expanded}
                                approvals={approvals ?? []}
                            />
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </PermissionedWrapper>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);
    const users = selectUsers(s);
    const user = selectCurrentUser(s);

    return {
        properties,
        users,
        user,
    };
};

export default connect(mapState)(MyReportGrid);
