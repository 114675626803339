import { LoadOptions, SortDescriptor } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import {
    groupByGridFields,
    rentRollWithTotalCharges,
} from 'components/leases/expirations/cards/rent-roll/utils';
import { safeDivision } from 'shared-types';
import {
    ChargesFields,
    getChargesTotal,
    getSortedFilteredCharge,
} from './CustomDataGridStore.utils';
import { BucketTotalsType, RecurringCharge } from 'waypoint-types';

const isNotEmpty = (value: string | undefined | null) => {
    return value !== undefined && value !== null && value !== '';
};

type RequestCall<TParams, TResult> = (params: TParams) => Promise<TResult>;

interface DataResult {
    data: any[];
    totalCount: number;
    summary?: number[];
    groupCount?: number;
}

const ChargeCategories = ['cam', 'taxes_insurance', 'rent', 'other'];

type RentRollExtraProps = {
    summary?: BucketTotalsType;
    charges: RecurringCharge[];
    totals?: number[];
    selectedChargeCode?: string[];
    customFilter?: string;
};

export const CustomDataGridStore = <
    TParams extends object,
    TResult extends DataResult,
>(
    requestCall: RequestCall<TParams & { queryString: string }, TResult>,
    requestParams: TParams,
    props: RentRollExtraProps,
) => {
    return new CustomStore({
        key: 'key',
        async load(loadOptions: LoadOptions) {
            const paramNames: (keyof LoadOptions)[] = [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ];

            // A custom filter is passed from a parent component to rent roll table
            // We need to inject the custom filter into the queryString
            let filter = loadOptions.filter;
            const { selectedChargeCode, customFilter } = props;

            if (customFilter) {
                if (filter && filter.length > 0) {
                    filter = [JSON.parse(customFilter), 'and', filter];
                } else {
                    filter = JSON.parse(customFilter);
                }
            }

            let queryString = paramNames
                .filter((paramName) => isNotEmpty(loadOptions[paramName]))
                .map((paramName) => {
                    const value =
                        paramName === 'filter'
                            ? filter
                            : loadOptions[paramName];
                    return `${paramName}=${encodeURIComponent(JSON.stringify(value))}`;
                })
                .join('&');

            queryString =
                customFilter && !loadOptions.filter
                    ? `${queryString}&filter=${encodeURIComponent(customFilter)}&requireTotalCount=true`
                    : queryString;

            const skip = loadOptions.skip || 0;
            const take = loadOptions.take || 10;
            const sort = loadOptions.sort;

            const hasSelectedChargeCode =
                selectedChargeCode && selectedChargeCode.length > 0;

            // Performance-wise, when there's interaction with charges,
            // filter the charges object and pass the lease code to the request
            const hasChargeFilters =
                filter && filter.length > 0
                    ? filter.some((f: any) => {
                          if (Array.isArray(f)) {
                              return ChargesFields.includes(f[0]);
                          }
                          return ChargesFields.includes(f);
                      })
                    : null;

            const result = hasChargeFilters
                ? getChargesTotal(props.charges, ChargeCategories)
                : null;

            const sortedFilteredCharge = result
                ? getSortedFilteredCharge(
                      result,
                      filter,
                      (sort as any)?.[0],
                      skip,
                      take,
                  )
                : null;

            const mergedParams = {
                ...requestParams,
                selectedChargeCode,
                sortedFilteredCharge,
                queryString,
            };

            try {
                const result = await requestCall(mergedParams);

                // Type for loadOptions.group is not defined in devextreme
                const groupSelectors =
                    loadOptions.group && Array.isArray(loadOptions.group)
                        ? loadOptions.group.map(({ selector }: any) => selector)
                        : null;

                const chargeTotals = hasSelectedChargeCode
                    ? { ...result.summary }
                    : { ...props.summary };

                const gridTotals =
                    filter && Array.isArray(filter)
                        ? result.summary
                        : { ...props.totals, ...chargeTotals };

                const totals = { ...gridTotals } as Record<string, number>;

                const totalSummary =
                    loadOptions.totalSummary &&
                    Array.isArray(loadOptions.totalSummary)
                        ? loadOptions.totalSummary.map((total: any) => {
                              // The  _per_sq_ft totals need to be calculated with the rentable_sq_ft from the totals
                              if (
                                  [
                                      'rent_annual_per_sq_ft',
                                      'rent_monthly_per_sq_ft',
                                      'cam_annual_per_sq_ft',
                                      'cam_monthly_per_sq_ft',
                                      'taxes_insurance_annual_per_sq_ft',
                                      'taxes_insurance_monthly_per_sq_ft',
                                      'other_annual_per_sq_ft',
                                      'other_monthly_per_sq_ft',
                                      'total_annual_per_sq_ft',
                                      'total_monthly_per_sq_ft',
                                  ].includes(total.selector)
                              ) {
                                  return safeDivision(
                                      totals[
                                          `${total.selector.split('_per_sq_ft')[0]}`
                                      ],
                                      totals['occupied_sq_ft'],
                                  );
                              }
                              return totals[`${total.selector}`];
                          })
                        : undefined;

                return {
                    data: groupSelectors
                        ? groupByGridFields(result.data, props.charges)
                        : rentRollWithTotalCharges(result.data, props.charges),
                    totalCount: result.totalCount,
                    summary: totalSummary,
                    groupCount: result.groupCount,
                };
            } catch (err: any) {
                throw new Error(err.message);
            }
        },
    });
};
