import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { RootStateOrAny, connect } from 'react-redux';
import { RouteUrls } from 'routes/RouteConstants';
import {
    hasAgedReceivablesEnabled,
    hasAttributesEnabled,
    hasBalanceSheetOverviewEnabled,
    hasBudgetUploadEnabled,
    hasCapitalTrackerEnabled,
    hasNativeAnalyticsFeature,
    hasPlanningSectionEnabled,
    hasSegmentationDashboard,
    hasUnitMixEnabled,
} from 'state/user/selectors';
import {
    MenuSections,
    menuItemStyle,
    overrideMenuItems,
    sidebarDrawerMenuTitle,
    sidebarDrawerMenuSectionTitle,
} from 'components/app/layout/AppLayoutUtils';
import { Dictionary } from 'ts-essentials';

interface DashboardsMenuParams {
    analyticsMenuSettings: Dictionary<boolean>;
    leasingMenuSettings: Dictionary<boolean>;
    planningMenuSettings: Dictionary<boolean>;
    pathname: string;
    hasAttributes: boolean;
    hasNativeAnalytics: boolean;
    hasPlanningSection: boolean;
    hasAgedReceivables: boolean;
    hasUnitMix: boolean;
    hasPortfolioSegmentationEnabled: boolean;
    hasBalanceSheetOverview: boolean;
    hasNativeAnalyticsRanking: boolean;
    hasTenants: boolean;
    hasExpirationSchedule: boolean;
    hasRentRoll: boolean;
    hasBudgetUpload: boolean;
    hasCapitalTracker: boolean;
}

const DashboardsMenu = ({
    analyticsMenuSettings,
    leasingMenuSettings,
    planningMenuSettings,
    pathname,
    hasAttributes,
    hasNativeAnalytics,
    hasPlanningSection,
    hasAgedReceivables,
    hasUnitMix,
    hasPortfolioSegmentationEnabled,
    hasBalanceSheetOverview,
    hasNativeAnalyticsRanking,
    hasTenants,
    hasExpirationSchedule,
    hasRentRoll,
    hasBudgetUpload,
    hasCapitalTracker,
}: DashboardsMenuParams) => {
    const showMenu = (menu: Dictionary<boolean>) => {
        return Object.values(menu).some((menuItem) => menuItem);
    };
    const showAnalyticsMenu = showMenu(analyticsMenuSettings);
    const showLeasingMenu = showMenu(leasingMenuSettings);
    const showPlanningMenu = showMenu(planningMenuSettings);

    return (
        <Menu mode="vertical" className={overrideMenuItems}>
            {sidebarDrawerMenuTitle(MenuSections.Dashboards)}
            {showAnalyticsMenu && hasNativeAnalytics && (
                <>
                    {sidebarDrawerMenuSectionTitle(MenuSections.Analytics)}

                    {analyticsMenuSettings?.showPortfolioSummary &&
                        hasPortfolioSegmentationEnabled && (
                            <Menu.Item
                                key="a1"
                                style={menuItemStyle(pathname, [
                                    RouteUrls.SEGMENTATION,
                                ])}
                            >
                                <Link to={RouteUrls.SEGMENTATION}>
                                    Portfolio Summary
                                </Link>
                            </Menu.Item>
                        )}
                    {analyticsMenuSettings?.showFinancialOverview && (
                        <Menu.Item
                            key="a2"
                            style={menuItemStyle(pathname, [
                                RouteUrls.ANALYTICS_FINANCIAL_OVERVIEW,
                            ])}
                        >
                            <Link to={RouteUrls.ANALYTICS_FINANCIAL_OVERVIEW}>
                                Financial Overview
                            </Link>
                        </Menu.Item>
                    )}
                    {analyticsMenuSettings?.showBalanceSheetOverview &&
                        hasBalanceSheetOverview && (
                            <Menu.Item
                                key="a3"
                                style={menuItemStyle(pathname, [
                                    RouteUrls.ANALYTICS_BALANCE_SHEET_OVERVIEW,
                                ])}
                            >
                                <Link
                                    to={
                                        RouteUrls.ANALYTICS_BALANCE_SHEET_OVERVIEW
                                    }
                                >
                                    Balance Sheet Overview
                                </Link>
                            </Menu.Item>
                        )}
                    {analyticsMenuSettings?.showRanking &&
                        hasNativeAnalyticsRanking && (
                            <Menu.Item
                                key="a4"
                                style={menuItemStyle(pathname, [
                                    RouteUrls.ANALYTICS_RANKING,
                                ])}
                            >
                                <Link to={RouteUrls.ANALYTICS_RANKING}>
                                    Ranking
                                </Link>
                            </Menu.Item>
                        )}
                </>
            )}
            {showLeasingMenu && (
                <>
                    {sidebarDrawerMenuSectionTitle(MenuSections.Leasing)}

                    {leasingMenuSettings?.showPerformanceOverview && (
                        <Menu.Item
                            key="l1"
                            style={menuItemStyle(pathname, [
                                RouteUrls.LEASES_PERFORMANCE_OVERVIEW,
                            ])}
                        >
                            <Link to={RouteUrls.LEASES_PERFORMANCE_OVERVIEW}>
                                Performance Overview
                            </Link>
                        </Menu.Item>
                    )}

                    {leasingMenuSettings?.showRentRoll && hasRentRoll && (
                        <Menu.Item
                            key="l2"
                            style={menuItemStyle(pathname, [
                                RouteUrls.LEASE_RENT_ROLL,
                            ])}
                        >
                            <Link to={RouteUrls.LEASE_RENT_ROLL}>
                                Rent Roll
                            </Link>
                        </Menu.Item>
                    )}

                    {leasingMenuSettings?.showExpirationSchedule &&
                        hasExpirationSchedule && (
                            <Menu.Item
                                key="l3"
                                style={menuItemStyle(pathname, [
                                    RouteUrls.LEASES_EXPIRATIONS_SCHEDULE,
                                ])}
                            >
                                <Link
                                    to={RouteUrls.LEASES_EXPIRATIONS_SCHEDULE}
                                >
                                    Expiration Schedule
                                </Link>
                            </Menu.Item>
                        )}

                    {leasingMenuSettings?.showTenants && hasTenants && (
                        <>
                            <Menu.Item
                                key="l5"
                                style={menuItemStyle(pathname, [
                                    RouteUrls.LEASES_TOP_TENANTS,
                                ])}
                            >
                                <Link to={RouteUrls.LEASES_TOP_TENANTS}>
                                    Top Tenants
                                </Link>
                            </Menu.Item>
                        </>
                    )}

                    {leasingMenuSettings?.showAgedReceivables &&
                        hasAgedReceivables && (
                            <Menu.Item
                                key="l6"
                                style={menuItemStyle(pathname, [
                                    RouteUrls.AGED_RECEIVABLES,
                                ])}
                            >
                                <Link to={RouteUrls.AGED_RECEIVABLES}>
                                    Aged Receivables
                                </Link>
                            </Menu.Item>
                        )}

                    {leasingMenuSettings?.showUnitMix && hasUnitMix && (
                        <Menu.Item
                            key="l6"
                            style={menuItemStyle(pathname, [
                                RouteUrls.UNIT_MIX,
                            ])}
                        >
                            <Link to={RouteUrls.UNIT_MIX}>Unit Mix</Link>
                        </Menu.Item>
                    )}
                </>
            )}

            {hasPlanningSection && showPlanningMenu && (
                <>
                    {sidebarDrawerMenuSectionTitle('Strategy & Planning')}
                    {planningMenuSettings?.showCapitalProjects &&
                        hasCapitalTracker && (
                            <Menu.Item
                                key="p1"
                                style={menuItemStyle(pathname, [
                                    RouteUrls.PLANNING_CAPITAL_TRACKER,
                                ])}
                            >
                                <Link to={RouteUrls.PLANNING_CAPITAL_TRACKER}>
                                    Capital Projects
                                </Link>
                            </Menu.Item>
                        )}
                    {planningMenuSettings?.showPrioritiesAndObjectives && (
                        <Menu.Item
                            key="p2"
                            style={menuItemStyle(pathname, [
                                RouteUrls.PLANNING_PRIORITIES_AND_OBJECTIVES,
                            ])}
                        >
                            <Link
                                to={
                                    RouteUrls.PLANNING_PRIORITIES_AND_OBJECTIVES
                                }
                            >
                                Priorities & Objectives
                            </Link>
                        </Menu.Item>
                    )}
                    {planningMenuSettings?.showServiceContracts && (
                        <Menu.Item
                            key="p3"
                            style={menuItemStyle(pathname, [
                                RouteUrls.PLANNING_SERVICE_CONTRACTS,
                            ])}
                        >
                            <Link to={RouteUrls.PLANNING_SERVICE_CONTRACTS}>
                                Service Contracts
                            </Link>
                        </Menu.Item>
                    )}
                    {planningMenuSettings?.showHoldSell && (
                        <Menu.Item
                            key="p4"
                            style={menuItemStyle(pathname, [
                                RouteUrls.PLANNING_HOLD_SELL,
                            ])}
                        >
                            <Link to={RouteUrls.PLANNING_HOLD_SELL}>
                                Hold/Sell
                            </Link>
                        </Menu.Item>
                    )}
                    {planningMenuSettings?.showAttributes && hasAttributes && (
                        <Menu.Item
                            key="p5"
                            style={menuItemStyle(pathname, [
                                RouteUrls.ATTRIBUTES,
                            ])}
                        >
                            <Link to={RouteUrls.ATTRIBUTES}>Attributes</Link>
                        </Menu.Item>
                    )}
                    {hasBudgetUpload && (
                        <Menu.Item
                            key="p6"
                            style={menuItemStyle(pathname, [
                                RouteUrls.BUDGET_UPLOAD,
                            ])}
                        >
                            <Link to={RouteUrls.BUDGET_UPLOAD}>
                                Budget Upload
                            </Link>
                        </Menu.Item>
                    )}
                </>
            )}
        </Menu>
    );
};

const mapState = (state: RootStateOrAny) => {
    const {
        user: { configuration: config },
    } = state;

    const {
        FEATURE_NATIVE_ANALYTICS_RANKING: hasNativeAnalyticsRanking,
        FEATURE_TENANTS: hasTenants,
        FEATURE_EXPIRATION_SCHEDULE: hasExpirationSchedule,
        FEATURE_RENT_ROLL: hasRentRoll,
    } = config;

    return {
        hasAttributes: hasAttributesEnabled(state),
        hasNativeAnalytics: hasNativeAnalyticsFeature(state),
        hasPlanningSection: hasPlanningSectionEnabled(state),
        hasAgedReceivables: hasAgedReceivablesEnabled(state),
        hasUnitMix: hasUnitMixEnabled(state),
        hasPortfolioSegmentationEnabled: hasSegmentationDashboard(state),
        hasBalanceSheetOverview: hasBalanceSheetOverviewEnabled(state),
        hasNativeAnalyticsRanking,
        hasTenants,
        hasExpirationSchedule,
        hasRentRoll,
        hasBudgetUpload: hasBudgetUploadEnabled(state),
        hasCapitalTracker: hasCapitalTrackerEnabled(state),
    };
};
export default connect(mapState)(DashboardsMenu);
