import useSWR from 'swr';
import { DataHookResponse } from './DataHookResponse';
import { StandardKPI } from 'shared-types';
import { getUnavailableKPIs } from 'waypoint-requests/kpis/getUnavailableKPIs';

export const useGetUnavailableKPIs = (): DataHookResponse<StandardKPI[]> => {
    const { data, error, mutate, isValidating } = useSWR(
        `/kpis/unavailable`,
        () => getUnavailableKPIs(),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
