import { DASH_DASH } from 'config/constants';
import { formatMoney, formatPercentage } from 'utils/formatters';
import {
    CellInfoType,
    CustomizeCellInfoType,
} from 'waypoint-types/devgrid-extreme';
import { getVarianceColor } from 'utils/tables/renderers';
import { capitalizeFirstLetter } from 'waypoint-utils';

export const percentageFormat = (
    cellInfo: CellInfoType,
    decimalPlaces = 2,
): string => {
    const { value } = cellInfo;
    return typeof value === 'number'
        ? formatPercentage((value as number) * 100, decimalPlaces)
        : `${DASH_DASH}%`;
};

export const currencyFormat = (cellData: CellInfoType): string => {
    const { value } = cellData;
    return value || value === 0 ? formatMoney(Number(value)) : DASH_DASH;
};

export const varianceRenderer = (
    gridData: CellInfoType,
    formatter: (gridData: CellInfoType) => string,
    decimalPlaces?: number,
) => {
    const varianceColor: string =
        getVarianceColor(gridData.data['variance_is_good']) ?? '';
    return (
        <span style={{ color: varianceColor }}>
            {decimalPlaces
                ? percentageFormat(gridData, decimalPlaces)
                : formatter(gridData)}
        </span>
    );
};

export const cellCapitalizeFirstLetter = (cellInfo: CustomizeCellInfoType) => {
    if (typeof cellInfo.value !== 'string') {
        return cellInfo.value;
    }

    return capitalizeFirstLetter(cellInfo.value);
};

export const customizeBoolean = (cellInfo: CustomizeCellInfoType) => {
    return cellInfo.value === true || cellInfo.value === 'true' ? 'Yes' : 'No';
};
