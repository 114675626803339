import { css } from 'emotion';
import { numericalSort } from 'utils/tables/sorters';
import { RentRollProps } from 'waypoint-types';

export interface TenantData {
    tenant: string;
    tenant_code: string;
    suites: string[];
    rentable_sq_ft: number;
    percentage_occ_sq_ft: number | null;
    percentage_rentable_sq_ft: number | null;
    gross_rent: number;
    total_annual: number;
    total_monthly: number | null;
    total_monthly_per_sq_ft: number | null;
    total_annual_per_sq_ft: number;
    rent_per_sq_ft: number | null;
    industry: string[];
    total_weight: number;
    total_annual_percentage: number;
    space_occupancy_status?: string;
    properties: string[];
}

export type TenantsDict = Record<string, TenantData>;

export enum TopTenantsMetricsEnum {
    RentableSF = 'rentable_sf',
    AnnualCharges = 'annual_charges',
    AnnualChargesPerSqFt = 'annual_charges_per_sq_ft',
}

export const cardTitle = css`
    padding: 5px 0;
    margin-bottom: 0;
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

export const textLoadingStyle = {
    height: '12px',
    width: '150px',
    marginTop: '0px',
};

export const metricOptions = [
    {
        value: 'rentable_sf',
        label: 'Rentable SF',
    },
    {
        value: 'annual_charges',
        label: 'Annual Charges',
    },
    {
        value: 'annual_charges_per_sq_ft',
        label: 'Annual Charges / SF',
    },
];

export const topNOptions = [
    {
        value: '5',
        label: '5',
    },
    {
        value: '10',
        label: '10',
    },
    {
        value: '15',
        label: '15',
    },
    {
        value: '20',
        label: '20',
    },
    {
        value: '25',
        label: '25',
    },
    {
        value: '50',
        label: '50',
    },
];

export const parseMetric = (metric: string): string => {
    if (metric === 'rentable_sf') {
        return 'rentable_sq_ft';
    }

    if (metric === 'annual_charges') {
        return 'total_annual';
    }

    if (metric === 'annual_charges_per_sq_ft') {
        return 'total_annual_per_sq_ft';
    }

    return 'rentable_sq_ft';
};

export const sortTenantsByMetric = (
    metric: TopTenantsMetricsEnum,
    tenantsTableData: TenantData[],
) => {
    if (!tenantsTableData) {
        return [];
    }

    return tenantsTableData.sort((a, b) => {
        if (metric === TopTenantsMetricsEnum.RentableSF) {
            return numericalSort(b.rentable_sq_ft, a.rentable_sq_ft);
        }

        if (metric === TopTenantsMetricsEnum.AnnualCharges) {
            return numericalSort(b.total_annual ?? 0, a.total_annual ?? 0);
        }

        if (metric === TopTenantsMetricsEnum.AnnualChargesPerSqFt) {
            return numericalSort(
                b.total_annual_per_sq_ft,
                a.total_annual_per_sq_ft,
            );
        }

        return 0;
    });
};

export const sortedDataArray = (metric: string, data: RentRollProps[]) => {
    if (!data) {
        return [];
    }

    return data.sort((a, b) => {
        if (metric === 'rentable_sf') {
            return numericalSort(b.rentable_sq_ft, a.rentable_sq_ft);
        }

        if (metric === 'annual_charges') {
            return numericalSort(b.total_annual ?? 0, a.total_annual ?? 0);
        }

        if (metric === 'annual_charges_per_sq_ft') {
            return numericalSort(
                b.total_annual_per_sq_ft ?? 0,
                a.total_annual_per_sq_ft ?? 0,
            );
        }

        return 0;
    });
};
