import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';
import React, { useCallback, useState, useEffect } from 'react';
import { useRecurringCharges, useGridConfigSettings } from 'waypoint-hooks';
import {
    ReportWidgetSettingsBuilderProps,
    SavedConfigFilterType,
} from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import { Checkbox, DisabledDashboard } from 'waypoint-react';
import { SavedGridConfigSelect } from 'components/saved-configurations';

const UnitMixSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps) => {
    const [selectedChargeCode, setSelectedChargeCode] = useState<string[]>(
        currentSettings?.widgetSelectedChargeCode ?? [],
    );

    const [hideSummaryCards, setHideSummaryCards] = useState<boolean>(
        currentSettings?.hideSummaryCards ?? false,
    );

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        savedConfigurationsData,
    } = useGridConfigSettings(SavedConfigFilterType.UnitMix);

    useEffect(() => {
        if (currentSettings?.savedConfigId) {
            setSelectedConfiguration(
                savedConfigurationsData?.find(
                    (config) => config.id === currentSettings.savedConfigId,
                ) ?? null,
            );
        }
    }, [currentSettings, savedConfigurationsData]);

    const { data: charges, isLoading } = useRecurringCharges(entityCodes, {
        stakeholder: null,
        percentageType: null,
    });

    const buildReportWidgetSettingsInputs = () => {
        return {
            widgetSelectedChargeCode: selectedChargeCode,
            hideSummaryCards,
            savedConfigId: selectedConfiguration?.id ?? '',
        };
    };

    const onChange = useCallback(
        (value: string[]) => {
            setSelectedChargeCode(value);
        },
        [setSelectedChargeCode],
    );

    if (!charges) {
        return <DisabledDashboard text={'Loading'} />;
    }

    const { summary } = charges;

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                Saved Grid Configuration
            </div>
            <SavedGridConfigSelect
                key={selectedConfiguration?.id ?? null}
                savedConfigurationsData={savedConfigurationsData}
                selectedConfiguration={selectedConfiguration}
                setSelectedConfiguration={setSelectedConfiguration}
                excludeUserConfigs
            />
            <div
                style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    marginTop: '15px',
                }}
            >
                <div>Selected Charge:</div>
                <RecurringChargeSelect
                    recurringCharges={summary.buckets}
                    selectedChargeCodes={selectedChargeCode}
                    onChange={onChange}
                    style={{
                        width: '100%',
                        minWidth: '200px',
                    }}
                    loading={isLoading}
                />
            </div>
            <div style={{ marginTop: 25 }}>
                <Checkbox
                    checked={hideSummaryCards}
                    onChange={(ev) => setHideSummaryCards(ev.target.checked)}
                >
                    <span>Hide Summary Cards</span>
                </Checkbox>
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default UnitMixSettingsBuilder;
