import { Button, Card, Popconfirm, Table, Tooltip, message } from 'antd';
import { css } from 'emotion';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import theme from 'config/theme';
import { useGetPermissionGroups } from 'waypoint-hooks';
import { deletePermissionGroup } from 'waypoint-requests';
import { ADMIN_SETTINGS_URL_SEGMENT } from 'config/constants';
import { FeaturePermission, PermissionGroup, User } from 'waypoint-types';
import { RootStateOrAny, connect } from 'react-redux';
import { selectClientId, selectCurrentUser } from 'state/user/selectors';

const pageContainer = css`
    background-color: #ededed;
    margin: 0;
    padding: 22px 20px 20px 16px;
    overflow: visible;
    position: relative;
    min-height: 85%;
`;
const header = css`
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: #5e5e5e;
`;
const headerContainer = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
        margin: 0;
    }
`;

interface PermissionsGroupsProps {
    clientId: number;
    userId: number;
}

const PermissionsGroups = ({ clientId, userId }: PermissionsGroupsProps) => {
    const { data, isLoading, mutate } = useGetPermissionGroups(
        clientId,
        userId,
    );
    const history = useHistory();

    const onDeletePermissionGroup = async (id: string) => {
        try {
            await deletePermissionGroup({ permissionGroupId: id, clientId });
            message.success(`Row deleted successfully`);

            const newData = data?.filter(
                (permissionGroup) => permissionGroup.id !== id,
            );

            await mutate(newData, false);
        } catch (e) {
            message.error(`Failed to delete Leasing Guidelines`);
        }
    };

    const renderDeleteButton = (record: PermissionGroup) => {
        if (record?.users?.length > 0) {
            return (
                <Tooltip title="Permission Groups with active users cannot be deleted">
                    <i
                        style={{
                            color: theme.colors.grays.disabled,
                            fontSize: '14px',
                            marginLeft: 14,
                        }}
                        className="fa-solid fa-trash"
                    />
                </Tooltip>
            );
        }
        return (
            <Popconfirm
                title="Delete this permission group"
                description="Are you sure you want to delete this permission group?"
                onConfirm={() => onDeletePermissionGroup(record?.id)}
                okText="Yes"
                cancelText="No"
            >
                <i
                    style={{
                        color: theme.colors.red,
                        cursor: 'pointer',
                        fontSize: '14px',
                        marginLeft: 14,
                    }}
                    className="fa-solid fa-trash"
                />
            </Popconfirm>
        );
    };

    const columns = [
        {
            title: 'Group',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Group Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Widget/Features',
            dataIndex: 'feature_permissions',
            key: 'feature_permissions',
            render: (featurePermissions: FeaturePermission[]) =>
                featurePermissions?.length,
            width: 150,
        },
        {
            title: '# Users',
            dataIndex: 'users',
            key: 'users',
            render: (users: User[]) => users?.length,
            width: 150,
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as const,
            width: 100,
            render: (text: string, record: PermissionGroup) => (
                <div>
                    <Link
                        to={`${ADMIN_SETTINGS_URL_SEGMENT}/edit-permission-group/${record.id}`}
                    >
                        <i
                            style={{
                                color: theme.colors.blues.linkBlue,
                                cursor: 'pointer',
                                fontSize: '14px',
                            }}
                            className="fa-solid fa-pencil"
                        />
                    </Link>
                    {renderDeleteButton(record)}
                </div>
            ),
        },
    ];

    return (
        <div className={pageContainer}>
            <Card
                loading={isLoading}
                title={
                    <div className={headerContainer}>
                        <h1 className={header}>Permissions Groups</h1>
                        <Button
                            onClick={() =>
                                history.push(
                                    `${ADMIN_SETTINGS_URL_SEGMENT}/new-permission-group`,
                                )
                            }
                            type="primary"
                        >
                            Add Group
                        </Button>
                    </div>
                }
            >
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={data ?? []}
                    bordered
                    size="middle"
                    scroll={{
                        x: 'calc(700px + 50%)',
                        y: window.innerHeight - 300,
                    }}
                />
            </Card>
        </div>
    );
};

const mapToState = (state: RootStateOrAny) => {
    const clientId = selectClientId(state);
    const userId = selectCurrentUser(state);
    return {
        clientId,
        userId,
    };
};

export default connect(mapToState)(PermissionsGroups);
