import { API_URL } from 'config/constants';
import { SavedConfiguration, SavedConfigurationObject } from 'waypoint-types';
import { KPIGridConfiguration } from 'shared-types';

interface CreateSavedConfigurationParams {
    name: string;
    reference_type: string;
    filters_json: SavedConfigurationObject | KPIGridConfiguration;
    filter_type: string;
}

const createSavedConfiguration = async (
    filter: CreateSavedConfigurationParams,
): Promise<SavedConfiguration> => {
    const response = await fetch(`${API_URL}/global-filters`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            name: filter.name,
            filters_json: filter.filters_json,
            reference_type: filter.reference_type,
            filter_type: filter.filter_type ?? null,
        } as CreateSavedConfigurationParams),
    });

    if (!response.ok) {
        throw new Error('Failed to create saved configuration');
    }

    const { data } = await response.json();

    return data as SavedConfiguration;
};

export default createSavedConfiguration;
