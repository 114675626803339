import { message } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { uploadEntityPhoto } from '../waypoint-requests';
import {
    PropertyDetailsProps,
    PropertyType,
    SelectedDataLevel,
} from 'waypoint-types';

import { AttributeValue } from 'shared-types';
import { useGetAttributes } from './data-access/useGetAttributes';
import {
    useGetClientAndEntityAttributeValues,
    useGetDataAvailabilityDate,
    useGetLeasesPerformanceOverview,
    useGetPropertyValuation,
    useGetValuations,
    useGetValueAndDebt,
} from 'waypoint-hooks';
import { stringSort } from 'utils/tables/sorters';
import {
    PropertyDetailAttributeEnum,
    PropertyProfileGavSources,
} from 'components/propertyProfile/PropertyProfileConstants';
import { KPIMode } from 'components/propertyProfile/PropertyProfileUtils';
import { getDateRangeForPeriod } from 'waypoint-utils';
import moment from 'moment';

interface UsePropertyInformationParams {
    entityCode: string;
    propertyInfo?: PropertyType;
    periodSelectedTiming?: string;
    valueDebtPeriod?: Date;
    selectedDataLevel: SelectedDataLevel;
}

export const usePropertyInformation = ({
    entityCode,
    selectedDataLevel,
    propertyInfo,
    periodSelectedTiming,
    valueDebtPeriod,
}: UsePropertyInformationParams) => {
    const [isImageError, setImageError] = useState(false);
    const [isImageFocused, setImageFocused] = useState(false);
    const [entityPhoto, setEntityPhoto] = useState<string | null>(null);
    const [photoIsUploading, setPhotoIsUploading] = useState(false);
    const [localImage, setLocalImage] = useState<any | null>(null);

    const [selectedAmenityValues, setSelectedAmenityValues] = useState<
        string[]
    >([]);
    const [entityAmenities, setEntityAmenities] = useState<AttributeValue[]>(
        [],
    );
    const [clientAmenityOptions, setClientAmenityOptions] = useState<string[]>(
        [],
    );

    const [portfolioManager, setPortfolioManager] = useState<AttributeValue[]>(
        [],
    );

    const [assetManager, setAssetManager] = useState<AttributeValue[]>([]);

    const [analyst, setAnalyst] = useState<AttributeValue[]>([]);

    const [propertyManager, setPropertyManager] = useState<AttributeValue[]>(
        [],
    );

    const [managementCompany, setManagementCompany] = useState<
        AttributeValue[]
    >([]);

    const [clientTeamOptions, setClientTeamOptions] = useState<string[]>([]);

    const [selectedPortfolioManager, setSelectedPortfolioManager] = useState<
        string[]
    >([]);

    const [selectedAssetManager, setSelectedAssetManager] = useState<string[]>(
        [],
    );

    const [selectedAnalyst, setSelectedAnalyst] = useState<string[]>([]);

    const [selectedPropertyManager, setSelectedPropertyManager] = useState<
        string[]
    >([]);

    const [selectedManagementCompany, setSelectedManagementCompany] = useState<
        string[]
    >([]);

    const [clientParkingSpaces, setClientParkingSpaces] = useState<
        number | undefined
    >(undefined);

    const [clientParkingNotes, setClientParkingNotes] = useState<string>('');

    const [debtNotes, setDebtNotes] = useState<string>('');

    const [clientPurchasePrice, setClientPurchasePrice] = useState<
        number | undefined
    >(undefined);

    const [clientPurchaseDate, setClientPurchaseDate] = useState<string>('');
    const [clientKpiPreferences, setClientKpiPreferences] = useState<KPIMode>(
        KPIMode.PSF,
    );
    const [clientTimingPreferences, setClientTimingPreferences] =
        useState<string>('ytd');

    const [clientYearBuilt, setClientYearBuilt] = useState<string[]>([]);
    const [clientLastRenovated, setClientLastRenovated] = useState<string[]>(
        [],
    );
    const [clientSiteArea, setClientSiteArea] = useState<number | undefined>(
        undefined,
    );

    const [clientFinancialYearEnding, setClientFinancialYearEnding] =
        useState<string>('');
    const [clientCertificationOptions, setClientCertificationOptions] =
        useState<string[]>([]);
    const [selectedCertificationValues, setSelectedCertificationValues] =
        useState<string[]>([]);
    const [entityCertifications, setEntityCertifications] = useState<
        AttributeValue[]
    >([]);

    const [acquisitionCapRate, setAcquisitionCapRate] = useState<
        number | undefined
    >(undefined);
    const [levered, setLevered] = useState<boolean>(true);
    const [lender, setLender] = useState<string>('');
    const [clientRateType, setClientRateType] = useState<string>('');
    const [interestRate, setInterestRate] = useState<string>('');
    const [debtMaturityDate, setDebtMaturityDate] = useState<string>('');

    const [floors, setFloors] = useState<number | undefined>(undefined);
    const [buildingCount, setBuildingCount] = useState<number | undefined>(
        undefined,
    );
    const [constructionType, setConstructionType] = useState<string>('');

    const [propertyDetailsModalIsOpen, setPropertyDetailsModalIsOpen] =
        useState<boolean>(false);

    const [periodSelected, setPeriodSelected] = useState<Date | null>(null);

    const [gavSource, setGavSource] = useState<PropertyProfileGavSources>(
        PropertyProfileGavSources.BALANCE_SHEET,
    );

    const [leasePropertyData, setLeasePropertyData] = useState<
        PropertyDetailsProps | undefined
    >(undefined);

    const { data: asOfDate, isLoading: isLoadingAsOfDate } =
        useGetDataAvailabilityDate([entityCode]);
    const { data: propertyValuations } = useGetPropertyValuation(entityCode);
    const { data: valuations } = useGetValuations(entityCode);

    const getTiming = () => {
        if (periodSelectedTiming) {
            return periodSelectedTiming;
        }

        return clientTimingPreferences;
    };

    const getSelectedPeriod = () => {
        if (valueDebtPeriod) {
            return valueDebtPeriod;
        }

        if (periodSelected) {
            return periodSelected;
        }

        return asOfDate ?? new Date();
    };

    const valuationForThePeriod =
        propertyValuations?.find((item) => {
            return (
                moment(item.period).format('yyyy-MM') ===
                moment(getSelectedPeriod()).format('yyyy-MM')
            );
        })?.valuation_id ?? '';

    const selectedValuation =
        valuations?.valuations.find((v) => v.id === valuationForThePeriod)
            ?.value ?? 0;

    const [start, end] = getDateRangeForPeriod(
        getTiming(),
        getSelectedPeriod(),
    );

    const {
        data: valueAndDebt,
        isLoading: isLoadingValueAndDebt,
        mutate: mutateValueAndDebt,
    } = useGetValueAndDebt(
        [entityCode],
        [start, end],
        selectedDataLevel,
        isLoadingAsOfDate,
    );

    const {
        data: clientPropertyAttributes,
        mutate: mutateClientPropertyAttributes,
        isLoading: isLoadingAttributes,
    } = useGetClientAndEntityAttributeValues(
        [
            PropertyDetailAttributeEnum.Amenities,
            PropertyDetailAttributeEnum.NumberOfSpaces,
            PropertyDetailAttributeEnum.ParkingNotes,
            PropertyDetailAttributeEnum.PurchasePrice,
            PropertyDetailAttributeEnum.PurchaseDate,
            PropertyDetailAttributeEnum.KpiPreferences,
            PropertyDetailAttributeEnum.YearBuilt,
            PropertyDetailAttributeEnum.LastRenovated,
            PropertyDetailAttributeEnum.SiteArea,
            PropertyDetailAttributeEnum.Certifications,
            PropertyDetailAttributeEnum.FinancialYearEnding,
            PropertyDetailAttributeEnum.ValueDebtPeriodTiming,
            PropertyDetailAttributeEnum.GavSource,
            PropertyDetailAttributeEnum.DebtNotes,
            PropertyDetailAttributeEnum.Floors,
            PropertyDetailAttributeEnum.BuildingCount,
            PropertyDetailAttributeEnum.ConstructionType,
            PropertyDetailAttributeEnum.Levered,
            PropertyDetailAttributeEnum.RateType,
            PropertyDetailAttributeEnum.DebtMaturityDate,
            PropertyDetailAttributeEnum.AcquisitionCapRate,
            PropertyDetailAttributeEnum.Lender,
            PropertyDetailAttributeEnum.PortfolioManager,
            PropertyDetailAttributeEnum.AssetManager,
            PropertyDetailAttributeEnum.Analyst,
            PropertyDetailAttributeEnum.ManagementCompany,
            PropertyDetailAttributeEnum.PropertyManager,
            PropertyDetailAttributeEnum.StreetAddress,
            PropertyDetailAttributeEnum.City,
            PropertyDetailAttributeEnum.State,
            PropertyDetailAttributeEnum.PostalCode,
            PropertyDetailAttributeEnum.AssetType,
            PropertyDetailAttributeEnum.InterestRate,
        ],
        true,
    );

    const onChangePeriodSelected = async (date: Date | null) => {
        setPeriodSelected(date);
        await mutateValueAndDebt(valueAndDebt, true);
    };

    const onSetSelectedAmenityValues = (values: string[]) => {
        setSelectedAmenityValues(values.sort((a, b) => stringSort(b, a)));
    };

    const onSetTeamValues = (
        setTeamValues: (value: string[]) => void,
        values: string[],
    ) => {
        setTeamValues(values.sort((a, b) => stringSort(b, a)));
    };

    const onSetSelectedCertificationValues = (values: string[]) => {
        setSelectedCertificationValues(values.sort((a, b) => stringSort(b, a)));
    };

    const extractEntityAttributes = (
        attributes: AttributeValue[],
        entityCode: string,
        attribute: string,
    ) =>
        attributes.filter(
            (a) =>
                a?.attributeDefinition?.attribute_code === attribute &&
                a?.entity_code === entityCode,
        );

    const getExtractedEntityAttributes = (): Map<string, AttributeValue[]> => {
        const data = new Map<string, AttributeValue[]>();
        const attributes = clientPropertyAttributes ?? [];

        for (const key of Object.values(PropertyDetailAttributeEnum)) {
            const propertyValue = extractEntityAttributes(
                attributes,
                entityCode,
                key,
            );

            data.set(key, propertyValue);
        }

        return data;
    };

    const getSortedUniqueValues = (attributes: AttributeValue[]) =>
        [...new Set(attributes.map((ca) => ca.value))].sort((a, b) =>
            stringSort(b, a),
        );

    const extractedData = getExtractedEntityAttributes();

    const setAttributesOnState = () => {
        const amenities = extractedData.get(
            PropertyDetailAttributeEnum.Amenities,
        );

        if (amenities) {
            setEntityAmenities(amenities);
            onSetSelectedAmenityValues(amenities.map((a) => a.value));
        }

        const sortedUniqueClientAmenities = getSortedUniqueValues(
            clientPropertyAttributes?.filter(
                (a) =>
                    a.attributeDefinition?.attribute_code ===
                    PropertyDetailAttributeEnum.Amenities,
            ) ?? [],
        );
        setClientAmenityOptions(sortedUniqueClientAmenities);

        const sortedUniqueClientTeamValues = getSortedUniqueValues(
            clientPropertyAttributes?.filter(
                (a) =>
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.PropertyManager ||
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.AssetManager ||
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.PortfolioManager ||
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.Analyst ||
                    a.attributeDefinition?.attribute_code ===
                        PropertyDetailAttributeEnum.ManagementCompany,
            ) ?? [],
        );

        setClientTeamOptions(sortedUniqueClientTeamValues);

        const stringPreprocess = (value: string) => value ?? '';
        const numberPreprocess = (value: string) =>
            value ? Number(value) : undefined;
        const percentPreprocess = (value: string) =>
            value ? Number(value) * 100 : undefined;
        const booleanPreprocess = (value: string) =>
            value ? value === 'true' : false;

        setValueFromAttribute<number | undefined>(
            setClientParkingSpaces,
            PropertyDetailAttributeEnum.NumberOfSpaces,
            numberPreprocess,
        );
        setValueFromAttribute<string>(
            setClientParkingNotes,
            PropertyDetailAttributeEnum.ParkingNotes,
            stringPreprocess,
        );
        setValueFromAttribute<string>(
            setDebtNotes,
            PropertyDetailAttributeEnum.DebtNotes,
            stringPreprocess,
        );
        setValueFromAttribute<number | undefined>(
            setClientPurchasePrice,
            PropertyDetailAttributeEnum.PurchasePrice,
            numberPreprocess,
        );
        setValueFromAttribute<string>(
            setClientPurchaseDate,
            PropertyDetailAttributeEnum.PurchaseDate,
            stringPreprocess,
        );
        setValueFromAttribute<KPIMode>(
            setClientKpiPreferences,
            PropertyDetailAttributeEnum.KpiPreferences,
            (value) => (value as KPIMode) ?? KPIMode.PSF,
        );
        setValueFromAttribute<string>(
            setClientTimingPreferences,
            PropertyDetailAttributeEnum.ValueDebtPeriodTiming,
            (value) => value ?? 'ytd',
        );
        setValuesFromAttribute<string>(
            setClientYearBuilt,
            PropertyDetailAttributeEnum.YearBuilt,
            stringPreprocess,
        );
        setValuesFromAttribute<string>(
            setClientLastRenovated,
            PropertyDetailAttributeEnum.LastRenovated,
            stringPreprocess,
        );
        setValueFromAttribute<number | undefined>(
            setClientSiteArea,
            PropertyDetailAttributeEnum.SiteArea,
            numberPreprocess,
        );
        setValueFromAttribute<string>(
            setClientFinancialYearEnding,
            PropertyDetailAttributeEnum.FinancialYearEnding,
            stringPreprocess,
        );
        setValueFromAttribute<PropertyProfileGavSources>(
            setGavSource,
            PropertyDetailAttributeEnum.GavSource,
            (value) =>
                (value as PropertyProfileGavSources) ??
                PropertyProfileGavSources.BALANCE_SHEET,
        );
        setValueFromAttribute<number | undefined>(
            setAcquisitionCapRate,
            PropertyDetailAttributeEnum.AcquisitionCapRate,
            percentPreprocess,
        );
        setValueFromAttribute<boolean>(
            setLevered,
            PropertyDetailAttributeEnum.Levered,
            booleanPreprocess,
        );
        setValueFromAttribute<string>(
            setLender,
            PropertyDetailAttributeEnum.Lender,
            stringPreprocess,
        );
        setValueFromAttribute<string>(
            setClientRateType,
            PropertyDetailAttributeEnum.RateType,
            stringPreprocess,
        );
        setValueFromAttribute<string>(
            setInterestRate,
            PropertyDetailAttributeEnum.InterestRate,
            stringPreprocess,
        );
        setValueFromAttribute<number | undefined>(
            setFloors,
            PropertyDetailAttributeEnum.Floors,
            numberPreprocess,
        );
        setValueFromAttribute<number | undefined>(
            setBuildingCount,
            PropertyDetailAttributeEnum.BuildingCount,
            numberPreprocess,
        );
        setValueFromAttribute<string>(
            setConstructionType,
            PropertyDetailAttributeEnum.ConstructionType,
            stringPreprocess,
        );
        setValueFromAttribute<string>(
            setDebtMaturityDate,
            PropertyDetailAttributeEnum.DebtMaturityDate,
            stringPreprocess,
        );

        const certifications = extractedData.get(
            PropertyDetailAttributeEnum.Certifications,
        );

        if (certifications !== undefined) {
            setEntityCertifications(certifications);
            onSetSelectedCertificationValues(
                certifications.map((a) => a.value),
            );
        }

        const sortedUniqueClientCertifications = getSortedUniqueValues(
            clientPropertyAttributes?.filter(
                (a) =>
                    a.attributeDefinition?.attribute_code ===
                    PropertyDetailAttributeEnum.Certifications,
            ) ?? [],
        );
        setClientCertificationOptions(sortedUniqueClientCertifications);

        const portfolioManager = extractedData.get(
            PropertyDetailAttributeEnum.PortfolioManager,
        );

        if (portfolioManager !== undefined) {
            setPortfolioManager(portfolioManager);
            onSetTeamValues(
                setSelectedPortfolioManager,
                portfolioManager.map((a: any) => a.value),
            );
        }

        const assetManager = extractedData.get(
            PropertyDetailAttributeEnum.AssetManager,
        );

        if (assetManager !== undefined) {
            setAssetManager(assetManager);
            onSetTeamValues(
                setSelectedAssetManager,
                assetManager.map((a: any) => a.value),
            );
        }

        const analyst = extractedData.get(PropertyDetailAttributeEnum.Analyst);

        if (analyst !== undefined) {
            setAnalyst(analyst);
            onSetTeamValues(
                setSelectedAnalyst,
                analyst.map((a: any) => a.value),
            );
        }

        const propertyManager = extractedData.get(
            PropertyDetailAttributeEnum.PropertyManager,
        );

        if (propertyManager !== undefined) {
            setPropertyManager(propertyManager);
            onSetTeamValues(
                setSelectedPropertyManager,
                propertyManager.map((a: any) => a.value),
            );
        }

        const managementCompany = extractedData.get(
            PropertyDetailAttributeEnum.ManagementCompany,
        );

        if (managementCompany !== undefined) {
            setManagementCompany(managementCompany);
            onSetTeamValues(
                setSelectedManagementCompany,
                managementCompany.map((a: any) => a.value),
            );
        }
    };

    function setValueFromAttribute<T>(
        setter: Dispatch<SetStateAction<T>>,
        attribute: PropertyDetailAttributeEnum,
        preprocess?: (value: string) => T,
    ) {
        const attributeData = extractedData.get(attribute);

        if (attributeData?.length) {
            setter(
                preprocess
                    ? preprocess(attributeData[0].value)
                    : (attributeData[0].value as T),
            );
        }
    }

    function setValuesFromAttribute<T>(
        setter: Dispatch<SetStateAction<T[]>>,
        attribute: PropertyDetailAttributeEnum,
        preprocess?: (value: string) => T,
    ) {
        const attributeData = extractedData.get(attribute);

        if (attributeData?.length) {
            setter(
                preprocess
                    ? attributeData.map((data) => preprocess(data.value))
                    : (attributeData.map((data) => data.value) as T[]),
            );
        }
    }

    useEffect(() => {
        setAttributesOnState();
    }, [clientPropertyAttributes, propertyDetailsModalIsOpen]);

    const { data: allAttributes } = useGetAttributes([entityCode]);

    const { data: leasesData, isLoading: isLoadingPropertyDetails } =
        useGetLeasesPerformanceOverview([entityCode], selectedDataLevel);

    useEffect(() => {
        setLeasePropertyData(
            leasesData?.find((property) => property.entity_code === entityCode),
        );
    }, [
        leasesData?.length,
        entityCode,
        selectedDataLevel.percentageType,
        selectedDataLevel.stakeholder,
    ]);

    useEffect(() => {
        const isValidImage = async () => {
            const photoUrl = propertyInfo?.entityPhoto;
            const photo = await checkIfImageIsCorrect(photoUrl ?? '');

            if (!photo) {
                setImageError(true);
            }

            if (!entityPhoto) {
                setEntityPhoto(photoUrl ?? null);
            }
        };

        if (propertyInfo?.entityPhoto) {
            isValidImage();
        }
    }, [propertyInfo]);

    const checkIfImageIsCorrect = (url: string) => {
        if (!url) {
            return Promise.resolve(false);
        }

        return new Promise((resolve) => {
            const imgElement = new Image();

            imgElement.addEventListener('load', () => resolve(true));
            imgElement.addEventListener('error', () => resolve(false));

            imgElement.src = url;
        });
    };

    const entity =
        allAttributes?.entities?.find((x) => x.entityCode === entityCode) ??
        null;

    const fullAddress = propertyInfo
        ? [
              propertyInfo.street_address,
              propertyInfo.city,
              propertyInfo.state_abbr,
          ]
              .filter((x) => x?.trim().length)
              .join(', ') + ` ${propertyInfo.postal_code ?? ''}`
        : '';

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files ?? [];

        const isJpg =
            file[0].type === 'image/jpeg' || file[0].type === 'image/jpg';
        const isLighterThan20M =
            file[0].size && file[0].size / 1024 / 1024 < 25;

        if (!isJpg) {
            message.error('Wrong format \n You can only upload JPG/JPEG files');

            return;
        }
        if (!isLighterThan20M) {
            message.error('File size too large \n Must be less than 25MB');
            return;
        }

        setPhotoIsUploading(true);

        const photo = new FormData();
        photo.append('upload', file[0]);
        photo.append('name', file[0].name);

        try {
            await uploadEntityPhoto(entityCode, photo);
            const img = file[0];
            setLocalImage(URL.createObjectURL(img));
            message.success('Photo uploaded successfully');
        } catch (error) {
            message.error('Something went wrong uploading the picture');
        }

        setImageError(false);
        setPhotoIsUploading(false);
    };

    const filterByAttributeCode = (attributeCode: string): AttributeValue[] => {
        return (
            clientPropertyAttributes?.filter(
                (a) =>
                    a.attributeDefinition?.attribute_code === attributeCode &&
                    a.entity_code === entityCode,
            ) ?? []
        );
    };

    const propertyDetails = {
        streetAddress: filterByAttributeCode(
            PropertyDetailAttributeEnum.StreetAddress,
        ),
        city: filterByAttributeCode(PropertyDetailAttributeEnum.City),
        state: filterByAttributeCode(PropertyDetailAttributeEnum.State),
        postalCode: filterByAttributeCode(
            PropertyDetailAttributeEnum.PostalCode,
        ),
        assetType: filterByAttributeCode(PropertyDetailAttributeEnum.AssetType),
        parkingSpaces: filterByAttributeCode(
            PropertyDetailAttributeEnum.NumberOfSpaces,
        ),
        parkingNotes: filterByAttributeCode(
            PropertyDetailAttributeEnum.ParkingNotes,
        ),
        clientAmenities: filterByAttributeCode(
            PropertyDetailAttributeEnum.Amenities,
        ),
        purchasePrice: filterByAttributeCode(
            PropertyDetailAttributeEnum.PurchasePrice,
        ),
        purchaseDate: filterByAttributeCode(
            PropertyDetailAttributeEnum.PurchaseDate,
        ),
        yearBuilt: filterByAttributeCode(PropertyDetailAttributeEnum.YearBuilt),
        lastRenovated: filterByAttributeCode(
            PropertyDetailAttributeEnum.LastRenovated,
        ),
        siteArea: filterByAttributeCode(PropertyDetailAttributeEnum.SiteArea),
        clientCertifications: filterByAttributeCode(
            PropertyDetailAttributeEnum.Certifications,
        ),
        financialYearEnding: filterByAttributeCode(
            PropertyDetailAttributeEnum.FinancialYearEnding,
        ),
        timingPreference: filterByAttributeCode(
            PropertyDetailAttributeEnum.ValueDebtPeriodTiming,
        ),
        gav: filterByAttributeCode(PropertyDetailAttributeEnum.GavSource),
    };

    const valueAndDebtData = {
        ...valueAndDebt,
        gross_value:
            gavSource === PropertyProfileGavSources.BALANCE_SHEET
                ? valueAndDebt?.gross_value
                : selectedValuation,
    };

    return {
        ...propertyDetails,
        isLoadingValueAndDebt,
        isLoadingAttributes,
        valueAndDebtData,
        clientPurchasePrice,
        setClientPurchasePrice,
        clientPurchaseDate,
        setClientPurchaseDate,
        clientParkingSpaces,
        setClientParkingSpaces,
        clientParkingNotes,
        setClientParkingNotes,
        debtNotes,
        setDebtNotes,
        isImageError,
        isImageFocused,
        setImageFocused,
        entityPhoto,
        photoIsUploading,
        localImage,
        isLoadingPropertyDetails,
        entity,
        fullAddress,
        handleChange,
        leasePropertyData,
        allAttributes,
        checkIfImageIsCorrect,
        clientPropertyAttributes,
        mutateClientPropertyAttributes,
        selectedAmenityValues,
        onSetSelectedAmenityValues,
        onSetTeamValues,
        clientAmenityOptions,
        setClientAmenityOptions,
        clientTeamOptions,
        selectedPortfolioManager,
        selectedAssetManager,
        selectedAnalyst,
        selectedPropertyManager,
        selectedManagementCompany,
        setSelectedPortfolioManager,
        setSelectedAssetManager,
        setSelectedAnalyst,
        setSelectedPropertyManager,
        setSelectedManagementCompany,
        entityAmenities,
        acquisitionCapRate,
        setAcquisitionCapRate,
        levered,
        setLevered,
        lender,
        setLender,
        clientRateType,
        setClientRateType,
        interestRate,
        setInterestRate,
        floors,
        setFloors,
        buildingCount,
        setBuildingCount,
        constructionType,
        setConstructionType,
        debtMaturityDate,
        setDebtMaturityDate,
        portfolioManager,
        setPortfolioManager,
        assetManager,
        setAssetManager,
        analyst,
        setAnalyst,
        propertyManager,
        setPropertyManager,
        managementCompany,
        setManagementCompany,
        propertyDetailsModalIsOpen,
        setPropertyDetailsModalIsOpen,
        setClientKpiPreferences,
        clientKpiPreferences,
        setClientYearBuilt,
        clientYearBuilt,
        clientLastRenovated,
        setClientLastRenovated,
        clientSiteArea,
        setClientSiteArea,
        selectedCertificationValues,
        onSetSelectedCertificationValues,
        entityCertifications,
        setEntityCertifications,
        clientCertificationOptions,
        setClientCertificationOptions,
        clientFinancialYearEnding,
        setClientFinancialYearEnding,
        clientTimingPreferences,
        setClientTimingPreferences,
        asOfDate,
        onChangePeriodSelected,
        periodSelected,
        mutateValueAndDebt,
        gavSource,
        setGavSource,
    };
};
