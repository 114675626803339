import { API_URL } from 'config/constants';
import { Entity } from 'waypoint-types';

import { AttributeDefinition } from 'shared-types';

export type GetAttributesV2Response = {
    attributeDefinitions: AttributeDefinition[];
    entities: Entity[];
};

export async function getAttributesV2(
    entityCodes: string[],
): Promise<GetAttributesV2Response> {
    const response = await fetch(`${API_URL}/attributes/v2`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            entity_codes: entityCodes,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return {
        attributeDefinitions: data.attributeDefinitions,
        entities: data.entities,
    };
}
