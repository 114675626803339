import React, { useCallback, useState } from 'react';
import { Modal, Radio, Space, message } from 'antd';
import { DataGrid } from 'devextreme-react';
import { Column, Paging, Selection } from 'devextreme-react/data-grid';
import { downloadAttributes } from 'waypoint-requests';
import { saveAs } from 'file-saver';
import { AttributeDefinition } from 'shared-types';

interface AttributesDownloadModalParams {
    attributes: AttributeDefinition[];
    entityCodes: string[];
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
}

enum AttributesDownloadType {
    ALL = 'all',
    SELECTED = 'selected',
}

const AttributesDownloadModal = ({
    attributes,
    entityCodes,
    isModalOpen,
    setIsModalOpen,
}: AttributesDownloadModalParams) => {
    const [downloadType, setDownloadType] = useState<AttributesDownloadType>(
        AttributesDownloadType.ALL,
    );
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

    const existingAttributeIds = new Set();
    const gridData = attributes
        ?.map((att) => {
            const attributeDefinitionId = att.id;
            if (existingAttributeIds.has(attributeDefinitionId)) {
                return;
            }
            existingAttributeIds.add(attributeDefinitionId);
            return {
                id: attributeDefinitionId,
                category: att.category,
                code: att.attribute_code,
                name: att.name,
            };
        })
        .filter((row) => !!row);

    const onSelectionChanged = useCallback(async ({ selectedRowKeys }: any) => {
        const rowIds = selectedRowKeys.map((row: any) => row.id);
        setSelectedRowIds(rowIds);
    }, []);

    const onExportingCsv = async () => {
        const filteredAttributeIds =
            downloadType === AttributesDownloadType.SELECTED
                ? selectedRowIds
                : [];
        try {
            const fileBlob = await downloadAttributes(
                filteredAttributeIds,
                entityCodes,
            );
            saveAs(fileBlob, 'waypoint_custom_attribute_template.csv');
            setIsModalOpen(false);
        } catch (e) {
            message.error('Error downloading attributes');
        }
    };

    return (
        <Modal
            title={'Template Download Options'}
            open={isModalOpen}
            okText={'Download'}
            centered
            onCancel={() => setIsModalOpen(false)}
            onOk={onExportingCsv}
            okButtonProps={
                downloadType === AttributesDownloadType.SELECTED &&
                !selectedRowIds.length
                    ? { disabled: true }
                    : {}
            }
        >
            <div style={{ marginBottom: '10px' }}>
                Select attributes you want to include in the template:
            </div>
            <Radio.Group
                value={downloadType}
                onChange={(e) => setDownloadType(e.target.value)}
            >
                <Space direction="vertical" style={{ marginBottom: '25px' }}>
                    <Radio value={AttributesDownloadType.ALL}>
                        All Attributes
                    </Radio>
                    <Radio value={AttributesDownloadType.SELECTED}>
                        Selected Attributes
                    </Radio>
                </Space>
            </Radio.Group>
            <DataGrid
                dataSource={gridData}
                showBorders={true}
                showColumnLines={true}
                visible={downloadType === AttributesDownloadType.SELECTED}
                onSelectionChanged={onSelectionChanged}
                style={{ maxHeight: '298px' }} // <- height of ten rows
                scrolling={{ mode: 'standard' }}
            >
                <Column caption="Category" dataField="category" />
                <Column caption="Name" dataField="name" />
                <Selection
                    mode="multiple"
                    showCheckBoxesMode={'always'}
                    allowSelectAll={false}
                />
                <Paging enabled={false} />
            </DataGrid>
        </Modal>
    );
};

export default AttributesDownloadModal;
