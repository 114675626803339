import { TransformedUnitMix, SelectedDataLevel } from 'waypoint-types';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getUnitMix } from 'waypoint-requests';

export const useGetUnitMix = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel,
): DataHookResponse<TransformedUnitMix[]> => {
    const rentRollCacheKey = `/leases/unit-mix/${entityCodes.join(',')}/${
        selectedDataLevel.percentageType
    }/${selectedDataLevel.stakeholder}`;

    const { data, error, mutate, isValidating } = useSWR(
        entityCodes.length ? rentRollCacheKey : null,
        () => getUnitMix(entityCodes, selectedDataLevel),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );
    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data: data,
        mutate,
    };
};
