import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import ResponsiveBox, { Item } from 'devextreme-react/responsive-box';
import theme from 'config/theme';
import DataGrid, {
    DataGridRef,
    Column,
    ColumnChooser,
    Export,
    GroupPanel,
    Grouping,
    HeaderFilter,
    Paging,
    RequiredRule,
    SearchPanel,
    Sorting,
    Pager,
    Format,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { ExportingEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { useMemo, useState } from 'react';
import { KeyedMutator } from 'swr';
import {
    createValuation,
    deleteValuation,
    updateValuation,
} from 'waypoint-requests';
import { Valuation } from 'waypoint-types/valuations';
import {
    CellInfoType,
    PropertyDetailsProps,
    SelectedDataLevel,
} from 'waypoint-types';
import { EditValuationModal } from './EditValuationModal';
import { usePropertyInformation } from 'waypoint-hooks';
import { Dictionary } from 'ts-essentials';
import { PropertyType } from 'waypoint-types';
import { ValuationsWithMethodOptions } from 'waypoint-types/valuations/types';
import moment from 'moment';
import exportExcelFromDevExtremeDataGrid, {
    CustomizeCell,
} from 'waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import { currencyRenderer } from 'utils/tables/renderers';
import { safeDivision } from 'shared-types';
import { getPropertyOptions } from 'waypoint-utils/entity';
import { capitalize } from 'lodash';
import { dateSort } from 'components/tables/sorts';

export const valuationTableTabsKey = {
    valuationTable: 'valuationTable',
};

export const VALUATIONS_CARD_TYPE = 'valuations';

interface ClientSelectableOptions {
    value: string;
    label: string;
}

interface ValuationsTableProps {
    entityCode: string;
    selectedDataLevel: SelectedDataLevel;
    properties: Dictionary<PropertyType>;
    data: ValuationsWithMethodOptions;
    mutate: KeyedMutator<ValuationsWithMethodOptions>;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    isPropertyProfilePage: boolean;
    booleanColumnNames?: string[];
}

export const ValuationsTable = ({
    entityCode,
    selectedDataLevel,
    data,
    dataGridRef,
    properties,
    mutate,
    isPropertyProfilePage,
}: ValuationsTableProps) => {
    const { leasePropertyData } = usePropertyInformation({
        entityCode,
        selectedDataLevel,
    });

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] =
        useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState<Valuation | null>(
        null,
    );
    const [methodOptions, setMethodOptions] = useState<
        ClientSelectableOptions[]
    >([]);

    const { valuations, methods } = data;

    const sourceFilterData = [
        {
            text: 'Internal',
            value: 'internal',
        },
        {
            text: 'External',
            value: 'external',
        },
    ];

    useMemo(() => {
        if (methods) {
            const methodOptions = methods.map((type) => {
                return {
                    value: type,
                    label: type,
                };
            });
            setMethodOptions(methodOptions);
        }
    }, [methods]);

    const onRowPrepared = (e: RowPreparedEvent) => {
        const isGrouped = e.rowType === 'group';
        const isHeader = e.rowType === 'header';
        if (isGrouped) {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (isHeader) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.textDecorationColor = theme.colors.grays.text;
            e.rowElement.style.color = theme.colors.grays.text;
        }
    };

    const onRowRemoved = async (params: Valuation) => {
        const updatedValuations: Valuation[] = valuations
            ? valuations
                  .filter((valuation: Valuation) => valuation.id !== params.id)
                  .sort((a, b) => dateSort(a.date, b.date))
            : [];

        mutate(
            async () => {
                try {
                    await deleteValuation(entityCode, params.id);
                    message.success(`Valuation deleted successfully`);
                    return {
                        valuations: updatedValuations,
                        methods,
                    };
                } catch (e) {
                    message.error(`Failed to delete valuation`);
                    return {
                        valuations,
                        methods,
                    };
                }
            },
            {
                optimisticData: {
                    valuations: updatedValuations,
                    methods,
                },
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            },
        );
    };

    const onSaved = async (valuation: Valuation) => {
        valuation.value = Number(valuation.value);
        valuation.date = moment
            .utc(valuation.date)
            .endOf('month')
            .format('YYYY-MM-DD hh:mm:ss');

        const updatedValuations: Valuation[] = valuation
            ? [
                  valuation,
                  ...valuations.filter(
                      (valuation: Valuation) =>
                          valuation.id !== selectedRowData?.id,
                  ),
              ].sort((a, b) => dateSort(new Date(a.date), new Date(b.date)))
            : valuations;

        mutate(
            async () => {
                try {
                    selectedRowData
                        ? await updateValuation(selectedRowData.id, valuation)
                        : await createValuation(valuation);

                    message.success(
                        `Valuation ${
                            selectedRowData ? 'updated' : 'added'
                        } successfully`,
                    );

                    return {
                        valuations: updatedValuations,
                        methods,
                    };
                } catch (e) {
                    message.error(
                        `Failed to ${
                            selectedRowData ? 'update' : 'add'
                        } valuation`,
                    );
                    return {
                        valuations,
                        methods,
                    };
                } finally {
                    setIsEditing(false);
                }
            },
            {
                optimisticData: {
                    valuations: updatedValuations,
                    methods: data.methods,
                },
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            },
        );
    };

    const getValuePerField = (
        value: number | null,
        field: keyof PropertyDetailsProps,
    ) => {
        if (value === null || leasePropertyData?.rentable_sq_ft === undefined) {
            return '';
        }

        return currencyRenderer(
            safeDivision(Number(value), leasePropertyData[field] as number),
        );
    };

    const renderEditorLink = (e: { data: Valuation }) => {
        return (
            <div style={{ display: 'flex' }}>
                <i
                    style={{
                        marginRight: '10px',
                        color: theme.colors.blues.linkBlue,
                        cursor: 'pointer',
                        fontSize: '14px',
                    }}
                    className="fa-solid fa-pencil"
                    onClick={() => {
                        setSelectedRowData(e.data);
                        setIsEditModalVisible(true);
                    }}
                />

                <Popconfirm
                    placement="topRight"
                    title={`Are you sure you want to delete this valuation?`}
                    onConfirm={() => onRowRemoved(e.data)}
                    okType="danger"
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <i
                        style={{
                            color: theme.colors.blues.linkBlue,
                            cursor: 'pointer',
                            fontSize: '14px',
                        }}
                        className="fa-solid fa-trash"
                    />
                </Popconfirm>
            </div>
        );
    };

    const onExporting = async (e: ExportingEvent) => {
        const customizeCell: CustomizeCell = ({ gridCell, excelCell }) => {
            if (gridCell?.rowType === 'data') {
                switch (gridCell?.column?.dataField) {
                    case 'date': {
                        const date = moment(gridCell?.value).format(
                            'MM/DD/YYYY',
                        );
                        if (date !== 'Invalid date') {
                            excelCell.value = moment(gridCell?.value).format(
                                'MM/DD/YYYY',
                            );
                        }
                        break;
                    }
                    case 'value_sf':
                        excelCell.value = getValuePerField(
                            gridCell.data.value,
                            'rentable_sq_ft',
                        );
                        break;
                    case 'value_per_unit':
                        excelCell.value = getValuePerField(
                            gridCell.data.value,
                            'total_units',
                        );
                        break;
                }
            }
        };
        await exportExcelFromDevExtremeDataGrid(
            e,
            {
                worksheetName: 'Valuations',
                filename: 'valuations.xlsx',
            },
            customizeCell,
        );
    };

    return (
        <>
            <div
                style={{
                    left: '10px',
                    right: '10px',
                    top: '140px',
                    bottom: 0,
                }}
            >
                <ResponsiveBox>
                    <Item>
                        <div style={{ padding: 8, margin: '0 0 0 auto' }}>
                            <Button
                                style={{ marginRight: 10, marginLeft: 10 }}
                                onClick={() => {
                                    setIsEditModalVisible(true);
                                }}
                                type="primary"
                                icon={<PlusOutlined />}
                            >
                                Add Valuation
                            </Button>
                        </div>
                    </Item>
                </ResponsiveBox>
            </div>
            {isEditModalVisible && (
                <EditValuationModal
                    selectedRowData={selectedRowData}
                    isVisible={isEditModalVisible}
                    setIsVisible={setIsEditModalVisible}
                    propertyOptions={getPropertyOptions(properties, [
                        entityCode,
                    ])}
                    methodOptions={methodOptions}
                    onClose={() => {
                        setSelectedRowData(null);
                        setIsEditModalVisible(false);
                    }}
                    onSaved={onSaved}
                />
            )}
            <DataGrid
                ref={dataGridRef}
                id="valuations-table"
                dataSource={valuations}
                showBorders={true}
                allowColumnReordering={true}
                wordWrapEnabled={true}
                onExporting={onExporting}
                width={'100%'}
                height={500}
                hoverStateEnabled={true}
                onRowPrepared={onRowPrepared}
                onKeyDown={(e) => {
                    if (e?.event?.key === 'Enter') {
                        e.event.preventDefault();
                    }
                }}
            >
                <ColumnChooser
                    enabled={!isEditing}
                    mode={'select'}
                    height={350}
                    allowSearch={true}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <HeaderFilter allowSelectAll={true} visible={!isEditing} />
                <SearchPanel
                    visible={!isEditing}
                    width={240}
                    placeholder="Search..."
                />
                <Sorting mode="single" />
                <Paging enabled={!isEditing} defaultPageSize={20} />
                <Pager
                    visible={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={[20, 40, 60, 80, 100]}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} items)"
                />
                <Export enabled={!isEditing} allowExportSelectedData={false} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={'auto'} />
                {!isPropertyProfilePage && (
                    <Column
                        dataField="property"
                        caption="Property"
                        alignment={'center'}
                        fixed={true}
                        fixedPosition={'left'}
                    >
                        <RequiredRule />
                    </Column>
                )}
                <Column
                    allowGrouping={false}
                    dataField="date"
                    dataType="date"
                    caption="Date"
                    alignment={'center'}
                    minWidth={90}
                    allowHeaderFiltering={false}
                >
                    <Format type="shortDate" />
                </Column>
                <Column
                    dataField="source"
                    caption="Source"
                    alignment={'center'}
                    minWidth={115}
                    cellRender={(gridData: CellInfoType) =>
                        capitalize(gridData.value as string)
                    }
                >
                    <HeaderFilter dataSource={sourceFilterData} />
                </Column>
                <Column
                    dataField="method"
                    caption="Method"
                    alignment={'center'}
                    minWidth={115}
                ></Column>
                <Column
                    allowGrouping={false}
                    dataField="value"
                    dataType="number"
                    caption="Value"
                    alignment={'center'}
                    minWidth={90}
                    allowHeaderFiltering={false}
                >
                    <Format type="currency" precision={0} />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="value_sf"
                    dataType="number"
                    caption="Value per SF"
                    minWidth={90}
                    alignment={'center'}
                    cellRender={(params) =>
                        getValuePerField(params.data.value, 'rentable_sq_ft')
                    }
                >
                    <Format type="currency" precision={0} />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="value_per_unit"
                    dataType="number"
                    caption="Value per Unit"
                    minWidth={90}
                    alignment={'center'}
                    cellRender={(params) =>
                        getValuePerField(params.data.value, 'total_units')
                    }
                >
                    <Format type="currency" precision={0} />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="notes"
                    minWidth={350}
                    caption="Notes"
                    alignment={'left'}
                    allowHeaderFiltering={false}
                />
                <Column
                    fixed={true}
                    fixedPosition="right"
                    width={85}
                    allowExporting={false}
                    showInColumnChooser={false}
                    cellRender={(e) => renderEditorLink(e)}
                />
            </DataGrid>
        </>
    );
};
