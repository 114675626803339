import React, { CSSProperties } from 'react';
import {
    AddItemButtonLocationOptions,
    EntityProfileCards,
    EntityProfileCardsGroupInputsType,
    EntityProfileCardsGroupReduceType,
} from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import {
    EntityProfileCard,
    EntityProfileCardYearMetadata,
} from 'waypoint-types/properties';
import { Select } from 'antd';
import { SectionType } from 'waypoint-types';
import { YearWithMetadata } from 'waypoint-types/properties/types';
import {
    containerSort,
    getRows,
    getUniqueYears,
} from 'waypoint-react/entity-profile-cards/EntityProfileCardUtils';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { AppFeaturePermissions } from 'shared-types';

interface HoldSellAnalysisProps {
    entityCode: string;
    title?: string;
    enableYearFilter?: boolean;
    enableYearMetadata?: boolean;
    selectedYear?: number[];
    isCollapse?: boolean;
    enableAddYearButton?: boolean;
    isRollupPage?: boolean;
    showReportWidgetOnly?: boolean;
    onYearMetadataUpdated?: (
        year: number,
        updatedYearMetadata: EntityProfileCardYearMetadata | null,
    ) => void;
    isPDFExport?: boolean;
    includeNotesInPdf?: boolean;
    setIsReadyToExport?: (value: boolean) => void;
}

export const HOLD_SELL_CARD_TYPE = 'holdSell';

const sections: SectionType = {
    hold: {
        title: 'Hold Rationale',
    },
    sell: {
        title: 'Sell Rationale',
    },
    summary: {
        title: 'Recommendation Notes',
    },
};

export const holdSellOptions: {
    value: string;
    label: string;
    color: string;
}[] = [
    { value: 'hold', label: 'Hold', color: 'red' },
    { value: 'sell', label: 'Sell', color: 'green' },
    { value: 'undecided', label: 'Undecided', color: 'gray' },
];

export const HoldSellAnalysis = ({
    entityCode,
    title,
    enableYearFilter = true,
    enableYearMetadata = true,
    selectedYear,
    isCollapse = true,
    enableAddYearButton = false,
    isRollupPage = false,
    showReportWidgetOnly = false,
    onYearMetadataUpdated,
    isPDFExport,
    includeNotesInPdf = false,
    setIsReadyToExport,
}: HoldSellAnalysisProps): JSX.Element => {
    const getDefaultYearMetadata = (year: number): YearWithMetadata => {
        return {
            year,
            metadata: [{ key: 'recommendation', value: 'undecided' }],
        };
    };

    const getContainerConfig = (
        yearMetadata: EntityProfileCardYearMetadata[],
    ) => {
        const uniqueYears = getUniqueYears(yearMetadata);

        return uniqueYears.map((year) => {
            return {
                title: `${year} Hold/Sell Analysis`,
                column: 0,
                deleteStrategy: {
                    type: 'yearMetadata',
                    get: (
                        items: EntityProfileCard[],
                        yearMetadata: EntityProfileCardYearMetadata[],
                    ) => {
                        return yearMetadata.find((ym) => ym.year === year);
                    },
                },
                getGroupGrid: () => {
                    return {
                        columns: 2,
                        rows: 2,
                    };
                },
                getGroupStyle: (
                    groupDef: EntityProfileCardsGroupInputsType,
                ): CSSProperties => {
                    const marginValue = '5px';

                    if (
                        groupDef.column === 0 &&
                        groupDef.row === 1 &&
                        groupDef.colSpan === 1
                    ) {
                        return {
                            marginRight: marginValue,
                        };
                    }

                    if (
                        groupDef.column === 1 &&
                        groupDef.row === 1 &&
                        groupDef.colSpan === 1
                    ) {
                        return {
                            marginLeft: marginValue,
                        };
                    }

                    return {};
                },
                shouldHideContainerIfEmpty: (
                    yearsFilter: number[],
                ): boolean => {
                    return !yearsFilter.includes(year);
                },
                getEmptyGroupState: (): EntityProfileCardsGroupReduceType => {
                    const result: EntityProfileCardsGroupReduceType = {};

                    Object.keys(sections).forEach((sectionKey) => {
                        const { title } = sections[sectionKey];

                        result[sectionKey] = {
                            title,
                            row: sectionKey === 'summary' ? 0 : 1,
                            column: sectionKey === 'sell' ? 1 : 0,
                            colSpan: sectionKey === 'summary' ? 2 : 1,
                            items: [],
                            addItemButton: {
                                addItemTitleLabel:
                                    sectionKey === 'summary' ? 'Notes' : title,
                                addItemTooltip: `Add ${title}`,
                                getDefaultCard: () => {
                                    return {
                                        id: '',
                                        title: '',
                                        section: sectionKey,
                                        notes: '',
                                        sort_index: 0,
                                        year: year,
                                        type: HOLD_SELL_CARD_TYPE,
                                    };
                                },
                            },
                        };
                    });

                    return result;
                },
                group: (
                    result: EntityProfileCardsGroupReduceType,
                    item: EntityProfileCard,
                ) => {
                    result[item.section].items.push(item);

                    return result;
                },
                filter: (item: EntityProfileCard) => {
                    return item.year === year;
                },
                sort: (groupA: EntityProfileCardsGroupInputsType) => {
                    if (groupA.title === sections['summary'].title) {
                        return -1;
                    }

                    if (groupA.title === sections['hold'].title) {
                        return 0;
                    }

                    if (groupA.title === sections['sell'].title) {
                        return 1;
                    }

                    return 0;
                },
                addItemButtonLocations: [
                    'group',
                ] as AddItemButtonLocationOptions,
                renderYearMetadataOptions: (key: string) => {
                    if (key === 'recommendation') {
                        return holdSellOptions.map((holdSellOption) => {
                            return (
                                <Select.Option value={holdSellOption.value}>
                                    {holdSellOption.label}
                                </Select.Option>
                            );
                        });
                    }

                    return [];
                },
                getYearMetadataValue: (
                    yearMetadata: EntityProfileCardYearMetadata[],
                ): EntityProfileCardYearMetadata | undefined => {
                    return yearMetadata.find((ym) => ym.year === year);
                },
            };
        });
    };

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.HoldSell}>
            <EntityProfileCards
                enableExport
                title={title ?? 'Hold/Sell Analysis'}
                type={HOLD_SELL_CARD_TYPE}
                entityCode={entityCode}
                isCollapse={isCollapse}
                columns={1}
                rows={getRows}
                containerSort={containerSort}
                getContainerConfig={getContainerConfig}
                enabledFields={['title']}
                enableYearFilter={enableYearFilter}
                enableYearMetadata={enableYearMetadata}
                onYearMetadataUpdated={onYearMetadataUpdated}
                getDefaultYearMetadata={getDefaultYearMetadata}
                selectedYear={selectedYear}
                enableAddYearButton={enableAddYearButton}
                isRollupPage={isRollupPage}
                showReportWidgetOnly={showReportWidgetOnly}
                isPDFExport={isPDFExport}
                includeNotesInPdf={includeNotesInPdf}
                setIsReadyToExport={setIsReadyToExport}
            />
        </PermissionedWrapper>
    );
};
