import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getClientAndEntityAttributeValues } from 'waypoint-requests';
import { AttributeValue } from 'shared-types';
import { message } from 'antd';

export const useGetClientAndEntityAttributeValues = (
    attributeCodes: string[],
    isStandardAttribute?: boolean,
): DataHookResponse<AttributeValue[]> => {
    const { data, error, mutate } = useSWR(
        `/api/attributes/attribute-values/${attributeCodes.join(
            ',',
        )}_${isStandardAttribute}`,
        () =>
            getClientAndEntityAttributeValues(
                attributeCodes,
                isStandardAttribute,
            ),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch client attributes');
            },
            shouldRetryOnError: false,
        },
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
