import React from 'react';
import { css } from 'emotion';
import IncomeStatementContainer from 'components/financials/comparative-income-statement/card/IncomeStatementContainer';
import { useSelectedDataLevel } from 'waypoint-hooks';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const gridStyle = css`
    position: relative;
    height: 1520px;
    left: 0;
    right: 0;
    bottom: 0;

    & .grid_layout {
        padding: 0;
    }
`;

const ComparativeIncomeStatementGrid = () => {
    const selectedDataLevel = useSelectedDataLevel();

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.IncomeStatement}
            showDisabledView={true}
        >
            <div className={gridStyle}>
                <IncomeStatementContainer
                    isVarianceReporting={false}
                    isPropertyProfile={false}
                    selectedDataLevel={selectedDataLevel}
                />
            </div>
        </PermissionedWrapper>
    );
};

export default ComparativeIncomeStatementGrid;
