import React from 'react';
import theme from 'config/theme';
import { css } from 'emotion';
import LeasesExpirationCard from 'components/leases/expirations/cards/leases-expiration/LeasesExpirationCard';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const pageStyle = css`
    display: flex;
    background-color: ${theme.colors.grays.background};
    overflow-y: scroll;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
`;

const LeasesExpirationDashboard = () => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.ExpirationSchedule}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                <div style={{ padding: '12px 12px 44px 12px' }}>
                    <LeasesExpirationCard entityCodes={entityCodes} />
                </div>
            </div>
        </PermissionedWrapper>
    );
};

export default LeasesExpirationDashboard;
