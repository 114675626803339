import { API_URL } from 'config/constants';
import { SavedConfiguration, SavedConfigurationObject } from 'waypoint-types';

const getSavedConfigurationById = async <
    FiltersType = SavedConfigurationObject,
>(
    configId: string,
): Promise<SavedConfiguration<FiltersType>> => {
    if (!configId) {
        return {} as SavedConfiguration<FiltersType>;
    }
    const response = await fetch(`${API_URL}/global-filters/id/${configId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to get saved configurations');
    }

    const { data } = await response.json();
    return data as SavedConfiguration<FiltersType>;
};

export default getSavedConfigurationById;
