import React from 'react';
import { Descriptions, Row as AntRow, Col as AntCol } from 'antd';
import {
    KPIMode,
    calculateKPI,
    getFormattedLabel,
    renderGrossValue,
    TimingPreference,
} from '../PropertyProfileUtils';
import { DASH_DASH } from 'config/constants';
import { PropertyInformationSectionProps } from '../types';
import {
    balanceSheetGavSource,
    PropertyProfileGavSources,
    valuationsGavSource,
} from '../PropertyProfileConstants';
import { DebtAndValueKpis, PropertyDetailsProps } from 'waypoint-types';
import {
    formatMoney,
    formatNumberToDecimalPlace,
    formatPercentage,
} from 'utils/formatters';
import { safeDivision } from 'shared-types';

interface DescriptionItemProps {
    value: number | undefined;
    formatFunction: (value: number) => string;
    suffix?: string;
}

interface PropertyValueProps extends PropertyInformationSectionProps {
    title?: React.ReactNode;
    periodSelected?: Date | null;
    valueAndDebtData: DebtAndValueKpis;
    clientKpiPreferences: string;
    leasePropertyData: PropertyDetailsProps | undefined;
    gavSource: PropertyProfileGavSources;
    selectedValuation?: number;
    setSelectedValuation?: (selectedValuations: number) => void;
    asOfDate: Date | undefined;
    lender: string;
    levered: boolean;
    debtNotes: string;
    debtMaturityDate: string;
    clientTimingPreferences: string;
    clientRateType: string;
    interestRate: string;
}

const PropertyValue = ({
    entityCode,
    periodSelected,
    propertyInfo,
    valueAndDebtData,
    clientKpiPreferences,
    leasePropertyData,
    gavSource,
    title,
    asOfDate,
    levered,
    lender,
    debtNotes,
    debtMaturityDate,
    clientRateType,
    clientTimingPreferences,
    interestRate,
    selectedValuation,
    setSelectedValuation,
}: PropertyValueProps) => {
    const labelStyle = { width: 200, paddingLeft: 20 };

    const formatDescriptionItem = ({
        value,
        formatFunction,
        suffix = '',
    }: DescriptionItemProps) =>
        value !== undefined ? `${formatFunction(value)}${suffix}` : DASH_DASH;

    const timePreference =
        clientTimingPreferences === TimingPreference.YTD ? 'YTD' : 'T-12';

    const ltvDivisor =
        gavSource === PropertyProfileGavSources.BALANCE_SHEET
            ? valueAndDebtData?.gross_value
            : selectedValuation ?? null;

    const ltvValue =
        ltvDivisor && valueAndDebtData.debt
            ? safeDivision(valueAndDebtData.debt, ltvDivisor)
            : null;

    const navValue =
        Number(valueAndDebtData?.gross_value ?? 0) -
        Number(valueAndDebtData?.debt ?? 0);

    const kpiPreference =
        clientKpiPreferences === KPIMode.PSF ? 'PSF' : 'Per Unit';

    const gavKpiValue = valueAndDebtData?.gross_value
        ? calculateKPI(
              clientKpiPreferences as KPIMode,
              valueAndDebtData?.gross_value,
              leasePropertyData,
          )
        : DASH_DASH;

    const navKpiValue = valueAndDebtData?.gross_value
        ? calculateKPI(
              clientKpiPreferences as KPIMode,
              navValue,
              leasePropertyData,
          )
        : DASH_DASH;

    const gavPeriodSelected =
        periodSelected && periodSelected.toString() !== ''
            ? periodSelected
            : asOfDate ?? new Date();

    const renderGavComponent = renderGrossValue({
        gavSource,
        gavSourceType: PropertyProfileGavSources,
        entityCode,
        propertyInfo,
        periodSelected: gavPeriodSelected,
        asOfDate: undefined,
        valueAndDebtData,
        setSelectedValuation,
        selectedValuation,
    });

    const gavValue = valueAndDebtData?.gross_value ? (
        <>
            {renderGavComponent} | {gavKpiValue} {kpiPreference}
        </>
    ) : (
        renderGavComponent
    );

    const items = [
        {
            label: `GAV ${
                gavSource === PropertyProfileGavSources.VALUATIONS
                    ? `(${valuationsGavSource})`
                    : `(${balanceSheetGavSource})`
            }`,
            value: gavValue,
        },
        {
            label: 'NAV',
            value: valueAndDebtData?.gross_value
                ? `${formatMoney(navValue)} | ${navKpiValue} ${kpiPreference}`
                : DASH_DASH,
        },
        {
            label: 'Levered',
            value: levered ? 'Yes' : 'No',
            span: 4,
        },
    ];

    const debtItems = [
        {
            label: 'Lender',
            value: lender || DASH_DASH,
        },
        {
            label: 'Rate Type',
            value: clientRateType || DASH_DASH,
        },
        {
            label: 'Interest Rate',
            value: interestRate || DASH_DASH,
        },
        {
            label: 'Maturity Date',
            value: debtMaturityDate || DASH_DASH,
        },
        {
            label: 'Balance',
            value: valueAndDebtData?.debt
                ? formatMoney(valueAndDebtData?.debt)
                : DASH_DASH,
        },
        {
            label: `Debt Service (${timePreference})`,
            value: valueAndDebtData.debt_service
                ? formatMoney(valueAndDebtData.debt_service)
                : DASH_DASH,
        },
        {
            label: 'LTV',
            value: ltvValue ? ltvValue * 100 : undefined,
            formatFunction: formatPercentage,
        },
        {
            label: `DSCR (${timePreference})`,
            value:
                valueAndDebtData.debt_service_coverage_ratio !== 0
                    ? valueAndDebtData.debt_service_coverage_ratio
                    : undefined,
            formatFunction: formatNumberToDecimalPlace,
            suffix: 'x',
            span: 2,
        },
        {
            label: 'Debt Notes',
            value: debtNotes || DASH_DASH,
            span: 4,
        },
    ];

    return (
        <>
            <AntRow align="stretch" wrap={true}>
                <AntCol>
                    <Descriptions
                        title={
                            <h2>
                                <div
                                    style={{
                                        padding: '4px 0px',
                                        borderBottom: '2px solid #001628',
                                    }}
                                >
                                    {title}
                                </div>
                            </h2>
                        }
                        size="small"
                        bordered={false}
                        layout="horizontal"
                        column={{
                            xxl: 2,
                            xl: 2,
                            lg: 2,
                            md: 2,
                            sm: 1,
                            xs: 1,
                        }}
                        contentStyle={{
                            textAlign: 'left',
                            marginRight: 15,
                        }}
                        labelStyle={labelStyle}
                    >
                        {items.map(
                            (data, dataIndex) =>
                                data && (
                                    <Descriptions.Item
                                        label={getFormattedLabel(data.label)}
                                        span={data.span}
                                        key={dataIndex}
                                    >
                                        {data.value}
                                    </Descriptions.Item>
                                ),
                        )}
                        {levered &&
                            debtItems.map(
                                (data, dataIndex) =>
                                    data && (
                                        <Descriptions.Item
                                            label={getFormattedLabel(
                                                data.label,
                                            )}
                                            span={data.span}
                                            key={dataIndex}
                                        >
                                            {data.formatFunction
                                                ? formatDescriptionItem({
                                                      value: data.value,
                                                      formatFunction:
                                                          data.formatFunction,
                                                      suffix: data.suffix,
                                                  })
                                                : data.value}
                                        </Descriptions.Item>
                                    ),
                            )}
                    </Descriptions>
                </AntCol>
                <div
                    style={{ paddingTop: 0, paddingBottom: 12, width: '100%' }}
                />
            </AntRow>
        </>
    );
};

export default PropertyValue;
