import React, { useCallback, useEffect, useState } from 'react';
import { CommentList, CommentEditor, CommentFilter } from 'waypoint-react';
import { CommentMention, LocalCommentData } from 'waypoint-types';
import { Divider, Switch } from 'antd';
import { searchAccountGraphForAccount } from 'waypoint-utils';
import { useCommentsPane } from 'contexts/comments/CommentsContext';

interface CommentsBoxProps {
    loading: boolean;
    comments: LocalCommentData[];
    onSubmit: (
        value: string,
        userMentions: number[],
        accountMentions: string[],
        parentId: string | null,
        commentId: string | null,
    ) => void;
    onResolveComment: (
        commentId: string,
        commentThreadId: string,
        is_resolved: boolean,
    ) => void;
    accountFilterSelection: string | null;
}

interface MentionOptions {
    value: string | number;
    label: string;
}

const CommentsBox = ({
    loading,
    comments,
    onSubmit,
    onResolveComment,
    accountFilterSelection,
}: CommentsBoxProps) => {
    const [selectAccountValue, setSelectAccountValue] = useState<string | null>(
        accountFilterSelection,
    );
    const [selectUserValue, setSelectUserValue] = useState<number | undefined>(
        undefined,
    );

    const { userMentionOptions, scrollToCommentId, accountGraph } =
        useCommentsPane();

    const [showResolvedComments, setShowResolvedComments] =
        useState<boolean>(false);

    useEffect(() => {
        if (accountFilterSelection) {
            setSelectAccountValue(accountFilterSelection);
        }
    }, [accountFilterSelection]);

    const getMentionsFromComments = useCallback(
        (comments: LocalCommentData[]) => {
            const mentions: { user: number[]; account: string[] } = {
                user: [],
                account: [],
            };

            comments.forEach((comment) => {
                mentions.user.push(comment.author.id);

                const userMentions = comment.mentions.filter(
                    (mention) => mention.reference_type === 'user',
                );
                userMentions.length > 0 &&
                    mentions.user.push(
                        ...userMentions.map((mention) =>
                            Number(mention.reference_id),
                        ),
                    );

                const accountMentions = comment.mentions.filter(
                    (mention) => mention.reference_type === 'account_mapping',
                );
                accountMentions.length > 0 &&
                    mentions.account.push(
                        ...accountMentions.map(
                            (mention) => mention.reference_id,
                        ),
                    );
            });
            return mentions;
        },
        [],
    );

    const commentList = showResolvedComments
        ? comments.filter((comment) => !comment.is_resolved)
        : comments;

    const userWithCommentsCount: { [key: string]: number } = {};
    getMentionsFromComments(commentList).user.forEach((userId) => {
        userWithCommentsCount[userId] =
            (userWithCommentsCount[userId] || 0) + 1;
    });

    const accountWithCommentsCount: { [key: string]: number } = {};
    getMentionsFromComments(commentList).account.forEach((accountCode) => {
        accountWithCommentsCount[accountCode] =
            (accountWithCommentsCount[accountCode] || 0) + 1;
    });
    const selectUserOptions: MentionOptions[] = Object.keys(
        userWithCommentsCount,
    ).map((userId: string) => {
        const user = userMentionOptions?.find(
            (user) => user.id === Number(userId),
        );
        return {
            value: user?.id ?? 0,
            label: `${user?.text} - ${userWithCommentsCount[userId]}`,
        };
    });

    const selectAccountOptions: MentionOptions[] =
        Object.keys(accountWithCommentsCount).map((accountCode: string) => {
            const acc = searchAccountGraphForAccount(accountGraph, accountCode);
            let accountCodeLabel = '';
            if (acc?.['account_code']) {
                accountCodeLabel = `(${acc?.['account_code']})`;
            }
            return {
                value: acc?.['account_mapping_code'] ?? '',
                label: acc?.['name']
                    ? `${acc?.['name']} ${accountCodeLabel} - ${accountWithCommentsCount[accountCode]}`
                    : `${accountCodeLabel} - ${accountWithCommentsCount[accountCode]}`,
            };
        }) ?? [];

    const filterComments = useCallback(
        (comments: LocalCommentData[]) => {
            const hasBothSelection = !!selectUserValue && !!selectAccountValue;
            return comments.filter((comment) => {
                if (comment.replies && comment?.replies.length) {
                    if (filterComments(comment.replies).length > 0) {
                        return true;
                    }
                }
                const isAuthor = comment.user_id === selectUserValue;
                const isUserMentioned =
                    comment?.mentions.filter((mention: CommentMention) => {
                        return (
                            mention.reference_type === 'user' &&
                            Number(mention.reference_id) === selectUserValue
                        );
                    }).length > 0;
                const isAccountMentioned =
                    comment?.mentions.filter((mention: CommentMention) => {
                        return (
                            mention.reference_type === 'account_mapping' &&
                            mention.reference_id === selectAccountValue
                        );
                    }).length > 0;

                return hasBothSelection
                    ? (isAuthor && isAccountMentioned) ||
                          (isUserMentioned && isAccountMentioned)
                    : isAuthor || isUserMentioned || isAccountMentioned;
            });
        },
        [selectUserValue, selectAccountValue],
    );

    const filteredCommentList =
        selectUserValue || selectAccountValue
            ? filterComments(commentList)
            : commentList;

    return (
        <>
            <div style={{ position: 'relative' }}>
                <CommentEditor
                    onSubmit={onSubmit}
                    showToolbar={false}
                    height={'80px'}
                    placeholder={
                        'Add a comment...type @ to mention someone and # to mention an account.'
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginBottom: 12,
                        marginRight: 6,
                        position: 'absolute',
                        bottom: 0,
                        right: 5,
                    }}
                >
                    <span style={{ marginRight: 8 }}>Hide Resolved</span>

                    <Switch
                        checked={showResolvedComments}
                        loading={loading}
                        onChange={() =>
                            setShowResolvedComments(!showResolvedComments)
                        }
                    />
                </div>
            </div>

            <Divider style={{ margin: 0 }} />

            <CommentFilter
                selectAccountOptions={selectAccountOptions}
                selectAccountValue={selectAccountValue}
                setSelectAccountValue={setSelectAccountValue}
                selectUserOptions={selectUserOptions}
                selectUserValue={selectUserValue}
                setSelectUserValue={setSelectUserValue}
            />

            <CommentList
                loading={loading}
                comments={filteredCommentList}
                onSubmit={onSubmit}
                scrollToCommentId={scrollToCommentId}
                onResolveComment={onResolveComment}
            />
        </>
    );
};

export default CommentsBox;
