import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import { GRAY } from 'config/colors';
import { RootStateOrAny } from 'react-redux';
import Grid from 'components/grids/Grid';
import TenantStatCard from 'components/leases/tenants/cards/TenantStatCard';
import { formatMoney, formatToOneDecimal } from 'components/leases/utils';
import SqftByIndustryCard from 'components/leases/tenants/cards/SqFtByIndustry/Card';
import RentByIndustryCard from 'components/leases/tenants/cards/RentByIndustry/Card';
import WALEByIndustryCard from 'components/leases/tenants/cards/WALEByIndustry/Card';
import TenantSummaryCard from 'components/leases/tenants/cards/TenantSummary/TenantSummaryCard';
import {
    useGetSelectedFilteredEntityCodes,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import { getTenants } from 'waypoint-requests';
import { DisabledDashboard } from 'waypoint-react';
import { connect } from 'react-redux';
import { hasTenantsEnabled } from 'state/user/selectors';
import { selectProperties } from 'state/properties/selectors';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const pageStyle = css`
    display: block;
    background-color: ${GRAY};
`;
const scrollable = css`
    display: block;
    overflow: scroll;
    height: 100vh;
    padding-bottom: 10vh;
`;

interface TenantsDashboardProps {
    tenantsEnabled: boolean;
    properties: any;
}

const TenantsDashboard = ({
    tenantsEnabled,
    properties,
}: TenantsDashboardProps): JSX.Element | null => {
    const [isLoadingTenants, setIsLoadingTenants] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [{ tenants, tenantStats }, setTenantsData] = useState<any>({});

    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const selectedDataLevel = useSelectedDataLevel();

    const gridConfig = {
        cols: 12,
        rowHeight: 16,
    };

    useEffect(() => {
        if (!entityCodes.length) {
            return;
        }
        const requestTenantsData = async () => {
            setIsLoadingTenants(true);

            try {
                const tenantsData = await getTenants(
                    entityCodes,
                    selectedDataLevel,
                );

                setTenantsData({
                    tenants: tenantsData.tenants.map(mapTenants),
                    tenantIds: tenantsData.tenantIds,
                    tenantStats: tenantsData.tenantStats,
                });
            } catch (e) {
                setIsError(true);
                throw e;
            } finally {
                setIsLoadingTenants(false);
            }
        };

        const mapTenants = (tenant: any) => {
            const propertyNames = Object.values(properties)
                .filter((property: any) => {
                    return tenant.propertyIdArr.includes(property.id);
                })
                .map((property: any) => property.name);

            const propertyName = propertyNames.join(', ');

            const {
                id,
                name,
                industry,
                totalAnnualInPlaceRent,
                activeTotalSquareFootage,
                percentOccupiedSqFtOfPortfolioSqFt,
                occupancySqftPercentByGroup,
                weightedAverageLeaseExpiration,
            } = tenant;

            return {
                id,
                name,
                industry,
                propertyName,
                propertyNames,
                occupiedSqft: activeTotalSquareFootage,
                totalOccupiedSqft: activeTotalSquareFootage,
                occupiedPercent: percentOccupiedSqFtOfPortfolioSqFt,
                occupancySqftPercentByGroup,
                percentOccupiedSqFtOfPortfolioSqFt,
                annualInPlaceRent: totalAnnualInPlaceRent,
                totalAnnualInPlaceRent,
                weightedAverageLeaseExpiration,
            };
        };

        requestTenantsData();
    }, [
        entityCodes,
        properties,
        selectedDataLevel.percentageType,
        selectedDataLevel.stakeholder,
    ]);

    if (isLoadingTenants) {
        return <DisabledDashboard text={'Loading...'} />;
    }

    if (!tenantsEnabled) {
        return <DisabledDashboard text={'Tenants module is disabled.'} />;
    }

    if (!entityCodes?.length) {
        return <DisabledDashboard text={'No properties selected.'} />;
    }

    if (isError) {
        return (
            <DisabledDashboard text={'There was an error loading your data.'} />
        );
    }

    const tenantStatsByIndustry = tenantStats.tenantStatsByIndustry;

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.Tenants}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                <div className={scrollable}>
                    <Grid config={gridConfig}>
                        <TenantStatCard
                            suffix={null}
                            statFormatter={null}
                            statistics={tenantStats}
                            title="Total Tenants"
                            dataKey={'portfolioNumTenants'}
                            isLoading={isLoadingTenants}
                            key="0"
                            data-grid={{
                                y: 0,
                                x: 0,
                                w: 4,
                                h: 4,
                                minW: 1,
                                maxW: 12,
                                static: true,
                            }}
                        />
                        <TenantStatCard
                            suffix={null}
                            statistics={tenantStats}
                            title="Total Annual In-Place Rent"
                            dataKey="portfolioAnnualInPlaceMonthlyRent"
                            isLoading={isLoadingTenants}
                            statFormatter={formatMoney}
                            key="1"
                            data-grid={{
                                y: 0,
                                x: 4,
                                w: 4,
                                h: 4,
                                minW: 1,
                                maxW: 12,
                                static: true,
                            }}
                        />
                        <TenantStatCard
                            title="WALE"
                            statistics={tenantStats}
                            dataKey="portfolioWeightedAverageLeaseExpiration"
                            isLoading={isLoadingTenants}
                            suffix="yrs"
                            statFormatter={formatToOneDecimal}
                            key="2"
                            data-grid={{
                                x: 8,
                                y: 0,
                                w: 4,
                                h: 4,
                                minW: 1,
                                maxW: 12,
                                static: true,
                            }}
                        />

                        <SqftByIndustryCard
                            isLoading={isLoadingTenants}
                            statistics={tenantStats}
                            key="3"
                            data-grid={{
                                x: 0,
                                y: 4,
                                w: 4,
                                h: 11,
                                minW: 1,
                                maxW: 12,
                                static: true,
                            }}
                        />

                        <RentByIndustryCard
                            isLoading={isLoadingTenants}
                            tenants={tenants}
                            key="4"
                            data-grid={{
                                x: 4,
                                y: 4,
                                w: 4,
                                h: 11,
                                minW: 1,
                                maxW: 12,
                                static: true,
                            }}
                        />

                        <WALEByIndustryCard
                            isLoading={isLoadingTenants}
                            statistics={tenantStatsByIndustry}
                            key="5"
                            data-grid={{
                                x: 8,
                                y: 4,
                                w: 4,
                                h: 11,
                                minW: 1,
                                maxW: 12,
                                static: true,
                            }}
                        />

                        <TenantSummaryCard
                            isLoading={isLoadingTenants}
                            entityCodes={entityCodes}
                            selectedDataLevel={selectedDataLevel}
                            data={tenants}
                            key="6"
                            title="Tenants"
                            data-grid={{
                                x: 0,
                                y: 15,
                                w: 12,
                                h: 16,
                                minW: 1,
                                maxW: 12,
                                static: true,
                            }}
                        />
                    </Grid>
                </div>
            </div>
        </PermissionedWrapper>
    );
};

const mapState = (state: RootStateOrAny) => {
    const tenantsEnabled = hasTenantsEnabled(state);
    const properties = selectProperties(state);

    return {
        tenantsEnabled,
        properties,
    };
};

export default connect(mapState)(TenantsDashboard);
