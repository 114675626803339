export const safeDivision = (
    dividend: number,
    divisor: number,
    decimalPlace = 4,
) => {
    const result = dividend / divisor;

    if (isFinite(result)) {
        return parseFloat(result.toFixed(decimalPlace));
    }

    return 0;
};

export const formatCurrency = (value: number | null, decimals = 2): string => {
    return value !== null && !isNaN(value)
        ? value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: decimals,
              maximumFractionDigits: decimals,
          })
        : '--';
};

export const formatPercentages = (value: number | null): string => {
    return value
        ? Number(value).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: 2,
          })
        : '--';
};

export const formatNumber = (value: number | null, decimals = 2): string => {
    return value
        ? Number(value).toLocaleString(undefined, {
              minimumFractionDigits: decimals,
              maximumFractionDigits: decimals,
          })
        : '--';
};
