import React, { CSSProperties, useEffect } from 'react';
import theme from 'config/theme';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { Badge } from 'antd';
import { css, cx } from 'emotion';
import { getCommentsThreadById } from 'waypoint-requests';

interface CommentsBadgeProps {
    commentThreadId?: string | null;
    commentsCount?: number;
    onClicked?: (isOpening: boolean) => void;
    badgeStyle?: CSSProperties;
    hideZeroCount?: boolean;
}

const defaultButtonStyle = css`
    cursor: pointer;
`;

const hideBadgeStyle = css`
    display: none;
`;

export const CommentsBadge = ({
    commentThreadId,
    commentsCount,
    onClicked,
    badgeStyle,
    hideZeroCount = false,
}: CommentsBadgeProps) => {
    const [loadedCommentCount, setLoadedCommentCount] =
        React.useState<number>(0);

    const {
        initializeComments,
        openComments,
        isVisible,
        commentCount: commentsCountContext,
    } = useCommentsPane();

    useEffect(() => {
        const fetchCommentsCount = async () => {
            const commentThread = await getCommentsThreadById(
                commentThreadId as string,
            );
            setLoadedCommentCount(commentThread.comments.length ?? 0);
        };
        // Fetch comments count on first load if not provided
        if (commentsCount === undefined && commentThreadId) {
            fetchCommentsCount();
        }
    }, [commentThreadId, commentsCount]);

    const foundCommentCount = commentsCountContext?.find(
        (count) => Object.keys(count)[0] === commentThreadId,
    );

    const commentCount = foundCommentCount
        ? foundCommentCount?.[`${commentThreadId}`]
        : (commentsCount ?? loadedCommentCount);

    return (
        <div
            className={
                hideZeroCount && commentCount === 0
                    ? cx([defaultButtonStyle, hideBadgeStyle])
                    : cx(defaultButtonStyle)
            }
            style={badgeStyle}
            onClick={(e) => {
                e.stopPropagation();

                if (onClicked) {
                    onClicked(!isVisible);
                }
                const shouldForceOpen = !!commentThreadId;

                if (shouldForceOpen) {
                    openComments();
                }

                if (commentThreadId) {
                    initializeComments(commentThreadId);
                }
            }}
        >
            <Badge
                count={commentCount ?? loadedCommentCount}
                color={theme.colors.red}
                size="small"
                offset={[1, 2]}
            >
                <i
                    style={{
                        color: 'black',
                        fontSize: '16px',
                    }}
                    className="fa-regular fa-commenting"
                />
            </Badge>
        </div>
    );
};
