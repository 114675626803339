import { css } from 'emotion';
import { RentRollTable } from 'components/leases/expirations';
import {
    useGetSelectedFilteredEntityCodes,
    useRecurringCharges,
    useGridConfigSettings,
    useSelectedDataLevel,
    useRentSteps,
    useGetUserData,
} from 'waypoint-hooks';
import { Button, Card } from 'antd';
import { DisabledDashboard, TextLoadingBar } from 'waypoint-react';
import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';
import { PieChartOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import theme from 'config/theme';
import { filterRecurringCharges } from 'components/leases/components/recurring-charge/utils';
import { RecurringCharge, SavedConfigFilterType } from 'waypoint-types';
import RentRollRecurringChargeSummary from './RentRollRecurringChargeSummary';
import { FilterReferenceTypes } from 'contexts';
import { AppFeaturePermissions } from 'shared-types';
import {
    SavedConfigEditor,
    SavedConfigManageDropdown,
    SavedGridConfigSelect,
} from 'components/saved-configurations';
import {
    RENT_ROLL_DEFAULT_COLUMN_SELECTION,
    RentRollColumnSelection,
    rentRollDefaultConfigs,
} from './utils';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { useRentRollTotals } from 'waypoint-hooks/data-access/useRentRollTotals';
import { FEATURE_UNIT_MIX } from 'shared-types';

const cardTitle = css`
    padding: 5px 0;
    margin-bottom: 0;
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

const textLoadingStyle = {
    height: '12px',
    width: '150px',
    marginTop: '0px',
};

const chartButtonStyle = css`
    color: ${theme.colors.grays.light} !important;
    border-color: ${theme.colors.grays.light} !important;
    background: ${theme.colors.white} !important;
    text-shadow: none !important;
`;

const chartButtonStyleActive = css`
    border-color: ${theme.colors.blues.focusRow} !important;
    background: ${theme.colors.blues.focusRow} !important;
    color: ${theme.colors.blues.antBlue} !important;
    text-shadow: none !important;
`;

interface RentRollCardProps {
    entityCode?: string;
    hidePropertyColumns?: boolean;
}

const RentRollCard = ({
    entityCode,
    hidePropertyColumns,
}: RentRollCardProps): JSX.Element => {
    const { configJSON } = useGetUserData();
    const unitMixEnabled = configJSON?.[`${FEATURE_UNIT_MIX}`];

    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [selectedChargeCode, setSelectedChargeCode] = useState<string[]>([]);

    const globalEntityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const entityCodes = entityCode ? [entityCode] : globalEntityCodes;

    const [columnSelection, setColumnSelection] =
        useState<RentRollColumnSelection>(RENT_ROLL_DEFAULT_COLUMN_SELECTION);

    const selectedDataLevel = useSelectedDataLevel();

    const { data: charges, isLoading: isRentRollLoading } = useRecurringCharges(
        entityCodes,
        selectedDataLevel,
    );

    const { data: rentSteps } = useRentSteps(entityCodes, selectedDataLevel);

    const { data: rentRollTotals, isLoading: isLoadingTotals } =
        useRentRollTotals(entityCodes, selectedDataLevel);

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        gridConfig,
        setGridConfig,
        setLocalConfig,
        setDefaultConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.RentRoll);

    const onChange = useCallback(
        (value: string[]) => {
            setSelectedChargeCode(value);
        },
        [setSelectedChargeCode],
    );

    useEffect(() => {
        setDefaultConfig(null);
    }, [columnSelection]);

    if (!charges || !savedConfigurationsData) {
        return <DisabledDashboard text={'Loading'} />;
    }
    const { recurringCharges, summary, rollUp } = charges;

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.RentRoll}>
            <Card
                title={
                    isRentRollLoading || isLoadingTotals ? (
                        <div className={cardTitle}>
                            <TextLoadingBar style={textLoadingStyle} />
                        </div>
                    ) : (
                        <h2 className={cardTitle}>
                            <span
                                style={{
                                    marginTop: '5px',
                                    marginRight: '10px',
                                    marginBottom: '3px',
                                }}
                            >
                                Rent Roll
                            </span>

                            <div
                                style={{
                                    marginRight: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <SavedGridConfigSelect
                                    savedConfigurationsData={
                                        savedConfigurationsData
                                    }
                                    selectedConfiguration={
                                        selectedConfiguration
                                    }
                                    setSelectedConfiguration={
                                        setSelectedConfiguration
                                    }
                                    rentRollDefaultConfigs={rentRollDefaultConfigs(
                                        unitMixEnabled,
                                    )}
                                    rentRollColumnSelection={columnSelection}
                                    setRentRollColumnSelection={
                                        setColumnSelection
                                    }
                                />
                                <SavedConfigManageDropdown
                                    onUpdateConfig={onUpdateConfig}
                                    onDeleteConfig={onDeleteConfig}
                                    resetSelectedConfiguration={
                                        resetSelectedConfiguration
                                    }
                                    hasUserAccess={
                                        !isAdmin &&
                                        selectedConfiguration?.reference_type !==
                                            FilterReferenceTypes.USER
                                    }
                                    allowDeleteOnly={saveConfigDisabled}
                                    defaultConfigSelected={
                                        !selectedConfiguration
                                    }
                                    setIsEditorOpen={setIsEditorOpen}
                                />
                            </div>

                            <RecurringChargeSelect
                                key={selectedChargeCode.join(',')}
                                recurringCharges={summary.buckets}
                                selectedChargeCodes={selectedChargeCode}
                                onChange={onChange}
                                style={{
                                    width: 'auto',
                                    minWidth: '250px',
                                    marginRight: 12,
                                }}
                            />

                            <Button
                                type="primary"
                                className={
                                    isCollapsed
                                        ? chartButtonStyle
                                        : chartButtonStyleActive
                                }
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                <PieChartOutlined
                                    style={{ fontSize: '20px' }}
                                />
                            </Button>

                            {isEditorOpen && (
                                <SavedConfigEditor
                                    handleClose={() => setIsEditorOpen(false)}
                                    onSave={onSaveConfig}
                                    isAdmin={isAdmin}
                                    existingConfigNames={existingConfigNames}
                                />
                            )}
                        </h2>
                    )
                }
                id="popup"
                data-testid="rent-roll-card"
                loading={isRentRollLoading}
            >
                <div
                    style={{
                        height: isCollapsed ? '0' : '400px',
                        transition:
                            'height 0.2s ease-in-out, min-height 0.2s ease-in-out, padding 0.2s ease-in-out',
                    }}
                >
                    <RentRollRecurringChargeSummary
                        rollUp={rollUp}
                        selectedChargeCode={selectedChargeCode}
                    />
                </div>
                <RentRollTable
                    key={`${selectedConfiguration?.id ?? ''}_${
                        configKey ?? ''
                    }_${columnSelection}`}
                    entityCodes={entityCodes}
                    columnSelection={columnSelection}
                    setColumnSelection={setColumnSelection}
                    summary={summary}
                    recurringCharges={
                        filterRecurringCharges(
                            recurringCharges,
                            selectedChargeCode,
                        ) as RecurringCharge[]
                    }
                    selectedConfiguration={selectedConfiguration}
                    hidePropertyColumns={hidePropertyColumns}
                    setGridConfig={setGridConfig}
                    gridConfig={gridConfig}
                    setLocalConfig={setLocalConfig}
                    selectedChargeCode={selectedChargeCode}
                    setSelectedChargeCode={setSelectedChargeCode}
                    selectedDataLevel={selectedDataLevel}
                    rentSteps={rentSteps}
                    rentRollTotals={rentRollTotals}
                />
            </Card>
        </PermissionedWrapper>
    );
};

export default RentRollCard;
