import {
    CommentsContext,
    UpdateCommentsOptions,
} from 'contexts/comments/CommentsContext';
import React, { useEffect, useState } from 'react';
import { MentionableDataSource } from 'waypoint-types';
import { AccountGraphObjectType } from 'waypoint-types/account-graph/types';
import { useGetCommentsByThreadId } from 'waypoint-hooks/data-access/useGetCommentsByThreadId';
import { useNotifications } from '@magicbell/magicbell-react';
import { useGetCommentThreadAccountMentions } from 'waypoint-hooks';
import { updateOrInsertEntityProfile } from 'waypoint-requests';
import { useCommentsContextManager } from 'contexts/comments/CommentsManagerContext';

interface CommentsProviderProps {
    commentThreadId?: string;
    heading?: string;
    userMentionOptions?: MentionableDataSource[];
    children?: React.ReactNode;
}

export const CommentsProvider = ({
    commentThreadId: defaultCommentThreadId,
    userMentionOptions: defaultUserMentionOptions,
    heading: defaultHeading,
    children,
}: CommentsProviderProps): JSX.Element => {
    const [commentThreadId, setCommentThreadId] = useState<string>(
        defaultCommentThreadId ?? '',
    );
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [heading, setHeading] = useState<string>(defaultHeading ?? '');
    const [subheading, setSubheading] = useState<string>('');
    const [financialYearEnding, setFinancialYearEnding] = useState<
        string | null
    >();
    const [scrollToCommentId, setScrollToCommentId] = useState<string | null>(
        null,
    );
    const [accountGraph, setAccountGraph] =
        useState<AccountGraphObjectType | null>(null);
    const [
        selectedCommentAccountMappingId,
        setSelectedCommentAccountMappingId,
    ] = useState<string | null>(null);
    const [userMentionOptions, setUserMentionOptions] = useState<
        MentionableDataSource[] | undefined
    >(defaultUserMentionOptions ?? []);
    const [accountMentionOptions, setAccountMentionOptions] = useState<
        MentionableDataSource[] | undefined
    >([]);
    const [accountFilterSelection, setAccountFilterSelection] = useState<
        string | null
    >(null);

    const [commentCount, setCommentCount] = useState<
        { [key: string]: number }[] | undefined
    >(undefined);

    const { commentContexts, closeOtherPanes } = useCommentsContextManager();

    const toggleComments = () => {
        if (isVisible) {
            closeOtherPanes && closeOtherPanes(commentThreadId);
            return closeComments();
        }

        return openComments();
    };

    const closeComments = () => {
        setIsVisible(false);

        setAccountFilterSelection(null);
        setSelectedCommentAccountMappingId(null);
    };

    const openComments = () => {
        if (commentContexts?.size > 1) {
            closeOtherPanes && closeOtherPanes(commentThreadId);
            return setTimeout(() => setIsVisible(true), 0); // small delay for reflex element to catch up on css
        }

        closeOtherPanes && closeOtherPanes(commentThreadId);
        return setIsVisible(true);
    };

    const initializeComments = async (
        commentThreadId: string | undefined,
        entityCode?: string,
    ) => {
        if (!commentThreadId && !entityCode) {
            return;
        }

        if (!commentThreadId && entityCode !== undefined) {
            const entityProfile = await updateOrInsertEntityProfile({
                entity_code: entityCode,
            });
            setCommentThreadId(entityProfile.comment_thread_id);
            return;
        }

        if (commentThreadId) {
            setCommentThreadId(commentThreadId);
        }
    };

    const update = (options: UpdateCommentsOptions) => {
        if (options.heading !== undefined) {
            setHeading(options.heading);
        }

        if (options.subheading !== undefined) {
            setSubheading(options.subheading);
        }

        if (options.financialYearEnding !== undefined) {
            setFinancialYearEnding(options.financialYearEnding);
        }

        if (options.userMentionOptions !== undefined) {
            setUserMentionOptions(options.userMentionOptions);
        }

        if (options.accountMentionOptions !== undefined) {
            setAccountMentionOptions(options.accountMentionOptions);
        }

        if (options.scrollToCommentId !== undefined) {
            setScrollToCommentId(options.scrollToCommentId);
        }

        if (options.accountFilterSelection !== undefined) {
            setAccountFilterSelection(options.accountFilterSelection);
        }

        if (options.accountGraph !== undefined) {
            setAccountGraph(options.accountGraph);
        }

        if (options.selectedCommentAccountMappingId !== undefined) {
            setSelectedCommentAccountMappingId(
                options.selectedCommentAccountMappingId,
            );
        }
    };

    const {
        data: commentThread,
        isLoading,
        isError,
        mutate: mutateCommentThread,
    } = useGetCommentsByThreadId(commentThreadId, useNotifications());

    const { data: commentThreadAccountMentions } =
        useGetCommentThreadAccountMentions(commentThread);

    useEffect(() => {
        if (commentThread?.id) {
            const newComment = {
                [`${commentThread.id}`]: commentThread.comments?.length || 0,
            };
            setCommentCount((prev) => {
                if (!prev) return [newComment];

                const updatedComments = prev.filter(
                    (comment) =>
                        !Object.keys(comment).includes(commentThread.id),
                );
                return [...updatedComments, newComment];
            });
        }
    }, [commentThread]);

    return (
        <CommentsContext.Provider
            value={{
                update,
                closeComments,
                openComments,
                toggleComments,
                initializeComments,
                commentThreadId,
                commentThread,
                isLoading,
                isError,
                mutateCommentThread,
                isVisible,
                heading,
                subheading,
                financialYearEnding,
                scrollToCommentId,
                accountGraph,
                selectedCommentAccountMappingId,
                userMentionOptions,
                accountMentionOptions,
                accountFilterSelection,
                commentThreadAccountMentions,
                commentCount,
            }}
        >
            {children}
        </CommentsContext.Provider>
    );
};
