import { RecurringCharge, TransformedUnitMix } from 'waypoint-types';
import { safeDivision } from 'shared-types';
import { rentRollWithTotalCharges } from 'components/leases/expirations/cards/rent-roll/utils';
import { topTenantsBaseColumns } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/TopTenantsExportableGrid';

export const formatBooleanValues = ({ value }: { value: boolean }) =>
    value ? 'Y' : 'N';

export const customSummaryDataFields = [
    'total_in_legal',
    'group_in_legal',
    'total_collection',
    'group_in_collection',
    'total_status',
    'group_status',
];

export const STATUS_OCCUPIED = 'OCCUPIED';
export const STATUS_VACANT = 'VACANT';
export const STATUS_NOTICE = 'notice';
export const STATUS_NO_NOTICE = 'no notice';
export const STATUS_RENTED = 'rented';
export const STATUS_UNRENTED = 'unrented';

const getExpiringDates = (
    vacDate: Date,
    now: Date,
    totals: Partial<UnitMixTotals>,
) => {
    totals.next30 = totals.next30 ?? 0;
    totals.next60 = totals.next60 ?? 0;
    totals.next90 = totals.next90 ?? 0;

    const daysUntilVacant = Math.ceil(
        (vacDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24),
    );
    if (daysUntilVacant > 0 && daysUntilVacant <= 30) {
        totals.next30 += 1;
    }
    if (daysUntilVacant > 30 && daysUntilVacant <= 60) {
        totals.next60 += 1;
    }
    if (daysUntilVacant > 60 && daysUntilVacant <= 90) {
        totals.next90 += 1;
    }

    return totals;
};

type UnitMixTotals = {
    occupiedUnits: 0;
    occupiedNoticeUnits: 0;
    occupiedNoNoticeUnits: 0;
    occupiedNullNoticeUnits: 0;
    occupiedSf: 0;
    vacantUnits: 0;
    vacantRentedUnits: 0;
    vacantUnrentedUnits: 0;
    noticeAvailable: 0;
    noticeRented: 0;
    next30: 0;
    next60: 0;
    next90: 0;
    totalMonthlyCharges: 0;
    totalAnnualCharges: 0;
    totalMonthlyChargesPSF: 0;
    totalAnnualChargesPSF: 0;
    totalNonRevUnits: 0;
};

export const getUnitMixForGrid = (
    unitMix: TransformedUnitMix[],
    charges: RecurringCharge[],
): TransformedUnitMix[] => {
    const unitMixWithTotalCharges: TransformedUnitMix[] =
        rentRollWithTotalCharges(unitMix, charges);

    const average = (arr: number[]) =>
        safeDivision(
            arr.reduce((a, b) => a + b, 0),
            arr.length,
        );
    const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0);

    const rentRollWithChargesGroupedByBedroomCountAndProperty =
        unitMixWithTotalCharges.reduce(
            (
                acc: Record<string, TransformedUnitMix[]>,
                obj: TransformedUnitMix,
            ) => {
                const groupKey = `${obj.bedroom_count || 'N/A'}_${obj.property_name}_${obj.unit_type || 'N/A'}`;
                acc[groupKey] = acc[groupKey] || [];
                acc[groupKey].push(obj);
                return acc;
            },
            {},
        );

    const { totalUnitsByProperty, totalUnits } = unitMixWithTotalCharges.reduce(
        (
            acc: {
                totalUnitsByProperty: Record<string, number>;
                totalUnits: number;
            },
            obj: TransformedUnitMix,
        ) => {
            acc.totalUnitsByProperty[obj.property_name] =
                (acc.totalUnitsByProperty[obj.property_name] || 0) + 1;
            acc.totalUnits += 1;
            return acc;
        },
        { totalUnitsByProperty: {}, totalUnits: 0 },
    );

    const processedData = Object.keys(
        rentRollWithChargesGroupedByBedroomCountAndProperty,
    ).map((key) => {
        const units = rentRollWithChargesGroupedByBedroomCountAndProperty[key];

        const bedroom = units[0].bedroom_count;
        const property_name = units[0].property_name;
        const bathroom_count = units[0].bathroom_count;
        const unit_type = units[0].unit_type;

        const now = new Date();

        const {
            occupiedUnits,
            occupiedNoticeUnits,
            occupiedNoNoticeUnits,
            occupiedNullNoticeUnits,
            occupiedSf,
            vacantUnits,
            vacantRentedUnits,
            vacantUnrentedUnits,
            noticeAvailable,
            noticeRented,
            next30,
            next60,
            next90,
            totalMonthlyCharges,
            totalAnnualCharges,
            totalMonthlyChargesPSF,
            totalAnnualChargesPSF,
            totalNonRevUnits,
        } = units.reduce(
            (totals: UnitMixTotals, unit: TransformedUnitMix) => {
                const {
                    space_occupancy_status,
                    notice_status,
                    is_revenue_unit,
                    rented_status,
                } = unit;

                if (space_occupancy_status === STATUS_OCCUPIED) {
                    totals.occupiedSf += unit.rentable_sq_ft;
                    totals.occupiedUnits += 1;
                    if (notice_status === STATUS_NOTICE) {
                        if (rented_status === STATUS_UNRENTED) {
                            totals.noticeAvailable += 1;
                        } else if (rented_status === STATUS_RENTED) {
                            totals.noticeRented += 1;
                        }
                        totals.occupiedNoticeUnits += 1;
                    } else if (notice_status === STATUS_NO_NOTICE) {
                        totals.occupiedNoNoticeUnits += 1;
                    } else if (notice_status === null) {
                        totals.occupiedNullNoticeUnits += 1;
                    }
                }

                if (is_revenue_unit === false) {
                    totals.totalNonRevUnits += 1;
                }

                if (space_occupancy_status === STATUS_VACANT) {
                    totals.vacantUnits += 1;
                    if (rented_status === STATUS_RENTED) {
                        totals.vacantRentedUnits += 1;
                    } else if (rented_status === STATUS_UNRENTED) {
                        totals.vacantUnrentedUnits += 1;
                    }
                }

                const vacDate = unit.vacant_date
                    ? new Date(unit.vacant_date)
                    : null;

                if (vacDate) {
                    getExpiringDates(vacDate, now, totals);
                }

                totals.totalMonthlyCharges += unit.total_monthly ?? 0;
                totals.totalAnnualCharges += unit.total_annual ?? 0;
                totals.totalMonthlyChargesPSF +=
                    unit.total_monthly_per_sq_ft ?? 0;
                totals.totalAnnualChargesPSF +=
                    unit.total_annual_per_sq_ft ?? 0;

                return totals;
            },
            {
                occupiedUnits: 0,
                occupiedNoticeUnits: 0,
                occupiedNoNoticeUnits: 0,
                occupiedNullNoticeUnits: 0,
                occupiedSf: 0,
                vacantUnits: 0,
                vacantRentedUnits: 0,
                vacantUnrentedUnits: 0,
                noticeAvailable: 0,
                noticeRented: 0,
                next30: 0,
                next60: 0,
                next90: 0,
                totalMonthlyCharges: 0,
                totalAnnualCharges: 0,
                totalMonthlyChargesPSF: 0,
                totalAnnualChargesPSF: 0,
                totalNonRevUnits: 0,
            },
        );

        const availableToRent = noticeAvailable + vacantUnrentedUnits;

        const leasedUnits =
            occupiedNoNoticeUnits + noticeRented + vacantRentedUnits;

        return {
            entity_code: units[0].entity_code,
            occupied_units: occupiedUnits,
            occupied_no_notice: occupiedNoNoticeUnits,
            occupied_notice: occupiedNoticeUnits,
            occupied_null_notice_units: occupiedNullNoticeUnits,
            notice_rented: noticeRented,
            vacant_units: vacantUnits,
            vacant_rented: vacantRentedUnits,
            vacant_unrented: vacantUnrentedUnits,
            available_to_rent: availableToRent,
            atr_by_unit: safeDivision(availableToRent, units.length),
            notice_available: noticeAvailable,
            occupancy_percentage: safeDivision(occupiedUnits, units.length),
            leased_units: leasedUnits,
            leased_percentage: safeDivision(leasedUnits, units.length),
            bedroom_count: bedroom !== null ? Number(bedroom) : null,
            property_name,
            bathroom_count,
            unit_type,
            next30,
            next60,
            next90,
            non_revenue_units: totalNonRevUnits,
            number_of_units: units.length,
            occupied_sf: occupiedSf,
            avg_sf: average(units.map((unit) => unit.rentable_sq_ft ?? 0)),
            total_sf: sum(units.map((unit) => unit.rentable_sq_ft ?? 0)),
            percent_of_total_units: safeDivision(units.length, totalUnits),
            average_total_monthly: safeDivision(
                totalMonthlyCharges,
                occupiedUnits,
            ),
            average_total_monthly_psf: safeDivision(
                totalMonthlyCharges,
                occupiedSf,
            ),
            percent_of_avg_monthly_charges: safeDivision(
                totalMonthlyCharges,
                sum(
                    unitMixWithTotalCharges.map(
                        (item) => item.total_monthly ?? 0,
                    ),
                ),
            ),
            average_total_annual: average(
                units.map((unit) => unit.total_annual ?? 0),
            ),
            average_total_annual_psf: average(
                units.map((unit) => unit.total_annual_per_sq_ft ?? 0),
            ),
            percent_of_avg_annual_charges: safeDivision(
                totalAnnualCharges,
                sum(
                    unitMixWithTotalCharges.map(
                        (item) => item.total_annual ?? 0,
                    ),
                ),
            ),
            total_monthly: totalMonthlyCharges,
            total_annual: totalAnnualCharges,
            total_monthly_per_sq_ft: totalMonthlyChargesPSF,
            total_annual_per_sq_ft: totalAnnualChargesPSF,
            rentable_sq_ft: units[0].rentable_sq_ft,
        };
    });

    return processedData;
};
