import memoizeOne from 'memoize-one';
import { isEqual } from 'lodash';
import theme from 'config/theme';
import ReactFC from 'react-fusioncharts';
import chartBuilder from 'components/charts/chartBuilder';
import {
    EntityReportWorkflowStatus,
    ReportMetadata,
    ThresholdRule,
    VarianceRule,
} from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import {
    barChartStyle,
    buildThresholdRuleText,
    dataToCharts,
    doughnutChartStyle,
    Scroll2dChartData,
    scroll2dChartStyle,
} from './VarianceReportStatusUtils';
import { format } from 'date-fns';
import { formatPercentage } from 'utils/formatters';
import { safeDivision } from 'shared-types';

const transformDataToCharts = memoizeOne(dataToCharts, isEqual);

const formatDataForStatusDoughnutChart = (
    reportMetadata: ReportMetadata[],
    thresholdRules: ThresholdRule[],
) => {
    const isRequiredThresholdRule = (reportMetadataId: string) => {
        const metadata = reportMetadata.find(
            (rm) => rm.id === reportMetadataId,
        );
        if (!metadata) {
            return false;
        }
        const periodicity =
            JSON.parse(metadata.filter_raw_json)[0].periodicity ?? '';
        const rule = thresholdRules
            ?.find(
                (tr: ThresholdRule) => tr.entity_code === metadata.reference_id,
            )
            ?.variance_rules?.find(
                (vr: VarianceRule) => vr.periodicity === periodicity,
            );
        if (rule && rule.workflow_required) {
            return true;
        }
        return false;
    };

    const reducerBaseObject = {
        open: 0,
        in_progress: 0,
        ready_for_review: 0,
        approved: 0,
    };
    let totalReports = 0;
    const chartDataStepOne = reportMetadata.reduce(
        (dict: Dictionary<number>, item) => {
            const workflowVarianceStatuses =
                item.workflowStatus?.filter(
                    (ws: EntityReportWorkflowStatus) =>
                        ws.workflow_type === 'variance',
                ) ?? [];
            for (const w of workflowVarianceStatuses) {
                if (!isRequiredThresholdRule(w.report_metadata_id)) {
                    continue;
                }
                reducerBaseObject[
                    w.report_status as keyof typeof reducerBaseObject
                ] += 1;
                totalReports += 1;
            }
            return reducerBaseObject;
        },
        reducerBaseObject as Dictionary<number>,
    );

    const thresholdRulesWithoutMetadata = thresholdRules.length - totalReports;
    chartDataStepOne['open'] += thresholdRulesWithoutMetadata;

    const chartDataStepTwo = [
        {
            value: chartDataStepOne['open'],
            metric: 'Open',
            color: theme.colors.workflowReportStatus.open,
        },
        {
            value: chartDataStepOne['in_progress'],
            metric: 'In Progress',
            color: theme.colors.workflowReportStatus.in_progress,
        },
        {
            value: chartDataStepOne['ready_for_review'],
            metric: 'Ready for Review',
            color: theme.colors.workflowReportStatus.ready_for_review,
        },
        {
            value: chartDataStepOne['approved'],
            metric: 'Approved',
            color: theme.colors.workflowReportStatus.approved,
        },
    ];

    const percentApproved = formatPercentage(
        safeDivision(chartDataStepOne['approved'], thresholdRules.length) * 100,
    );

    return {
        primaryChartData: transformDataToCharts(
            chartDataStepTwo,
            thresholdRules.length,
        ),
        percentApproved,
    };
};

const formatDataForUserRoleDoughnutChart = (
    userRoleCounts: Dictionary<number> | null,
    unassignedCount: number | null,
    totalReports: number,
): Scroll2dChartData => {
    const categories: { label: string }[] = [];
    const data: { value: number }[] = [];
    for (const u of Object.entries(userRoleCounts ?? {})) {
        categories.push({
            label: u[0],
        });
        data.push({
            value: u[1],
        });
    }
    if (unassignedCount) {
        categories.push({ label: 'Unassigned' });
        data.push({ value: unassignedCount });
    }

    return {
        categories,
        data,
    };
};

export const buildStatusChartConfigFor = (
    reportMetadata: ReportMetadata[],
    thresholdRules: ThresholdRule[],
    periodEnd: string,
) => {
    const primaryChart = chartBuilder();
    primaryChart.type('doughnut2d');
    primaryChart.height('30%');
    primaryChart.width('100%');
    const { primaryChartData, percentApproved } =
        formatDataForStatusDoughnutChart(reportMetadata, thresholdRules);
    primaryChart.data(primaryChartData);

    const primaryChartStyle = {
        ...doughnutChartStyle,
        defaultCenterLabel: `${percentApproved} Approved for ${format(
            new Date(periodEnd),
            'MMMM yyyy',
        )}`,
    };
    primaryChart.style(primaryChartStyle);

    const { config: primaryChartConfig } = primaryChart;
    return { primaryChartConfig };
};

const buildStatusDoughnutChartConfigFor = memoizeOne(
    buildStatusChartConfigFor,
    isEqual,
);

export const buildUserRoleChartConfigFor = (
    userRoleCounts: Dictionary<number> | null,
    unassigned: number | null,
    totalReports: number,
) => {
    const primaryChart = chartBuilder();
    primaryChart.type('scrollbar2d');
    primaryChart.height('30%');
    primaryChart.width('100%');
    const { categories, data } = formatDataForUserRoleDoughnutChart(
        userRoleCounts,
        unassigned,
        totalReports,
    );

    primaryChart.categories([{ category: categories }]);
    primaryChart.dataset([{ data: data }]);

    primaryChart.style(scroll2dChartStyle);

    const { config: primaryChartConfig } = primaryChart;
    return { primaryChartConfig };
};

const buildUserRoleDoughnutChartConfigFor = memoizeOne(
    buildUserRoleChartConfigFor,
    isEqual,
);

export const getStatusBreakdownDoughnutChartConfig = (
    reportMetadata: ReportMetadata[] | null,
    thresholdRules: ThresholdRule[] | null,
    periodEnd: string,
): JSX.Element | null => {
    if (!reportMetadata || !thresholdRules || !periodEnd) {
        return <></>;
    }

    const { primaryChartConfig } = buildStatusDoughnutChartConfigFor(
        reportMetadata,
        thresholdRules,
        periodEnd,
    );

    return (
        <>
            <ReactFC
                {...primaryChartConfig}
                data-testid="status-doughnut-chart-primary"
            />
        </>
    );
};

export const getUserRoleBreakdownDoughnutChartConfig = (
    userRoleCounts: Dictionary<number> | null,
    unassigned: number | null,
    totalReports: number,
): JSX.Element | null => {
    // Below conditions if the userRoleCounts object is empty, mimic of isEmpty from lodash.
    // This is because the userRoleCounts object is a dictionary and not an array.
    if (
        userRoleCounts &&
        Object.keys(userRoleCounts).length === 0 &&
        userRoleCounts.constructor === Object
    ) {
        return (
            <div
                style={{
                    height: '85%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <p>No Data</p>
            </div>
        );
    }
    const { primaryChartConfig } = buildUserRoleDoughnutChartConfigFor(
        userRoleCounts,
        unassigned,
        totalReports,
    );

    return (
        <>
            <ReactFC
                {...primaryChartConfig}
                data-testid="user-role-doughnut-chart-primary"
            />
        </>
    );
};

const getThresholdChartDataFor = (
    thresholdRules: ThresholdRule[],
    reportMetadata: ReportMetadata[] | null,
) => {
    if (!thresholdRules || !reportMetadata) return null;

    const labels: string[] = [];
    for (const t of thresholdRules) {
        if (t.variance_rules?.length) {
            for (const vr of t.variance_rules) {
                labels.push(buildThresholdRuleText(vr));
            }
        }
    }

    const getThresholdRule = (entityCode: string, periodicity: string) => {
        const rule = thresholdRules
            ?.find((tr: ThresholdRule) => tr.entity_code === entityCode)
            ?.variance_rules?.find(
                (vr: VarianceRule) => vr.periodicity === periodicity,
            );
        if (rule) {
            return buildThresholdRuleText(rule);
        }
        return null;
    };

    const barChartCounts = Array.from(labels).reduce(
        (dict: Dictionary<Dictionary<number>>, label) => {
            dict[label as string] = {
                approved: 0,
                not_approved: 0,
            };
            return dict;
        },
        {},
    );

    const setStatusByEntityCodeThresholdRule = (metadata: ReportMetadata) => {
        const metadataJson = JSON.parse(metadata.filter_raw_json)[0];
        const periodicity = metadataJson.periodicity;
        const entityCode = metadata.reference_id;

        const metadataRule = getThresholdRule(entityCode, periodicity);
        const statusToInclude = metadata.workflowStatus?.find(
            (ws: EntityReportWorkflowStatus) => ws.workflow_type === 'variance',
        );
        if (statusToInclude && metadataRule) {
            if (statusToInclude.report_status === 'approved') {
                barChartCounts[metadataRule].approved += 1;
            } else {
                barChartCounts[metadataRule].not_approved += 1;
            }
        }
    };
    reportMetadata.forEach((rm) => setStatusByEntityCodeThresholdRule(rm));
    thresholdRules.forEach((tr) => {
        const reportMetadataRule = reportMetadata.find(
            (rm) => rm.reference_id === tr.entity_code,
        );
        const rule = buildThresholdRuleText(tr.variance_rules[0]);
        if (reportMetadataRule === undefined) {
            barChartCounts[rule].not_approved += 1;
        }
    });

    for (const c of Object.entries(barChartCounts)) {
        if (c[1].approved === 0 && c[1].not_approved === 0) {
            delete barChartCounts[c[0]];
        }
    }

    return barChartCounts;
};

const formatDataForThresholdColumnChartFor = (
    chartData: Dictionary<Dictionary<number>>,
) => {
    type DataChart = {
        value: number;
        toolText: string;
    };
    const dataset = [
        {
            seriesname: 'Approved',
            data: [] as DataChart[],
        },
        {
            seriesname: 'Not Approved',
            data: [] as DataChart[],
        },
    ];

    for (const cd of Object.entries(chartData)) {
        const totalReports = cd[1].approved + cd[1].not_approved;
        const totalApproved = safeDivision(cd[1].approved, totalReports) * 100;
        const totalNotApproved =
            safeDivision(cd[1].not_approved, totalReports) * 100;
        dataset[0].data.push({
            value: totalApproved,
            toolText: `Approved, ${cd[0]}, (${
                cd[1].approved
            }) ${formatPercentage(totalApproved)}`,
        });
        dataset[1].data.push({
            value: totalNotApproved,
            toolText: `Not Approved, ${cd[0]}, (${
                cd[1].not_approved
            }) ${formatPercentage(totalNotApproved)}`,
        });
    }
    return dataset;
};

const getThresholdChartData = memoizeOne(getThresholdChartDataFor, isEqual);

const formatDataForThresholdColumnChart = memoizeOne(
    formatDataForThresholdColumnChartFor,
    isEqual,
);

export const getByThresholdBarChartConfig = (
    thresholdRules: ThresholdRule[] | null,
    reportMetadata: ReportMetadata[] | null,
) => {
    if (!thresholdRules || !reportMetadata) {
        return <></>;
    }

    const chartData = getThresholdChartData(thresholdRules, reportMetadata);
    if (!chartData) {
        return null;
    }
    const chart = chartBuilder();
    chart.type('scrollstackedcolumn2d');
    chart.height('30%');
    chart.width('100%');
    chart.style(barChartStyle);
    const categories = [
        {
            category: [] as Dictionary<string>[],
        },
    ];
    for (const c of Object.keys(chartData)) {
        categories[0].category.push({ label: c });
    }
    chart.categories(categories);
    chart.dataset(formatDataForThresholdColumnChart(chartData));
    return (
        <div style={{ width: '95%', marginTop: '5%', marginLeft: '5px' }}>
            <ReactFC {...chart.config} />
        </div>
    );
};
