import React, { useMemo, useState } from 'react';
import { useBalanceSheetSettings } from 'waypoint-hooks';
import { Checkbox } from 'waypoint-react';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import {
    getBalanceSheetPrimaryMonthPicker,
    getBalanceSheetSecondaryMonthPicker,
} from 'components/financials/balanceSheet/components/selections/BalanceSheetSelectionUtils';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import { AccountGraphSettingsBuilder } from './utils';
import { FinancialExportOptions } from '../../constants';

interface BalanceSheetSettingsInputs {
    periods: (string | undefined)[];
    is_hide_null: boolean;
    is_hide_notes: boolean;
    export_setting: string;
    account_mapping_selections: string[] | null;
}

const BalanceSheetSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const {
        disabledDate,
        primaryPeriod,
        setPrimaryPeriod,
        secondaryPeriod,
        setSecondaryPeriod,
    } = useBalanceSheetSettings(entityCodes);

    const [hideNull, setHideNull] = useState<boolean>(
        currentSettings?.is_hide_null ?? false,
    );
    const [hideNotes, setHideNotes] = useState<boolean>(
        currentSettings?.is_hide_notes ?? false,
    );
    const [exportSetting, setExportSetting] = useState(
        currentSettings?.export_setting ?? '',
    );
    const [treeSelectVisible, setTreeSelectVisible] = useState(
        currentSettings?.export_setting === FinancialExportOptions.CUSTOM,
    );
    const [accountMappingSelection, setAccountMappingSelection] = useState<
        string[] | null
    >(currentSettings?.account_mapping_selections ?? null);

    useMemo(() => {
        if (
            currentSettings?.periods &&
            currentSettings?.periods?.length === 2
        ) {
            setPrimaryPeriod(currentSettings.periods[0]);
            setSecondaryPeriod(currentSettings.periods[1]);
        }
    }, [currentSettings]);

    const settingsInputs: BalanceSheetSettingsInputs = {
        periods: [],
        is_hide_null: false,
        is_hide_notes: false,
        export_setting: exportSetting,
        account_mapping_selections: accountMappingSelection,
    };

    const buildReportWidgetSettingsInputs = () => {
        settingsInputs.periods = [primaryPeriod, secondaryPeriod];
        settingsInputs.is_hide_null = hideNull;
        settingsInputs.is_hide_notes = hideNotes;
        settingsInputs.export_setting = exportSetting;
        settingsInputs.account_mapping_selections = accountMappingSelection;
        return settingsInputs;
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ marginBottom: '25px' }}>
                {getBalanceSheetPrimaryMonthPicker(
                    disabledDate,
                    primaryPeriod,
                    setPrimaryPeriod,
                )}
            </div>
            <div style={{ marginBottom: '25px' }}>
                {getBalanceSheetSecondaryMonthPicker(
                    disabledDate,
                    secondaryPeriod,
                    setSecondaryPeriod,
                )}
            </div>
            <div style={{ marginTop: '10px' }}>
                <Checkbox
                    checked={hideNull}
                    onChange={(ev) => setHideNull(ev.target.checked)}
                >
                    <span>Hide Zeros/Nulls</span>
                </Checkbox>
            </div>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Checkbox
                    checked={hideNotes}
                    onChange={(ev) => setHideNotes(ev.target.checked)}
                >
                    <span>Hide Notes</span>
                </Checkbox>
            </div>
            <AccountGraphSettingsBuilder
                treeSelectVisible={treeSelectVisible}
                setTreeSelectVisible={setTreeSelectVisible}
                accountMappingSelection={accountMappingSelection}
                setAccountMappingSelection={setAccountMappingSelection}
                accountGraphType={'balance_sheet'}
                exportSetting={exportSetting}
                setExportSetting={setExportSetting}
            />
        </ReportWidgetSettingsModal>
    );
};

export default BalanceSheetSettingsBuilder;
