import { IncomeStatementProvider } from 'components/financials/comparative-income-statement/IncomeStatementProvider';
import ComparativeIncomeStatementCard from 'components/financials/comparative-income-statement/card/ComparativeIncomeStatementCard';
import { AppFeaturePermissions } from 'shared-types';
import React from 'react';
import { CommentsPaneWrapper } from 'waypoint-react/comment/CommentsPaneWrapper';
import { SelectedDataLevel } from 'waypoint-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface IncomeStatementContainerProps {
    entityCodes?: string[];
    selectedDataLevel: SelectedDataLevel;
    isPropertyProfile: boolean;
    isVarianceReporting: boolean;
}

const IncomeStatementContainer = ({
    entityCodes,
    selectedDataLevel,
    isPropertyProfile,
    isVarianceReporting,
}: IncomeStatementContainerProps) => {
    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.IncomeStatement}>
            <IncomeStatementProvider
                entityCodes={entityCodes}
                selectedDataLevel={selectedDataLevel}
            >
                <CommentsPaneWrapper
                    splitterZIndex={isPropertyProfile ? 2 : undefined}
                >
                    <ComparativeIncomeStatementCard
                        isVarianceReporting={isVarianceReporting}
                        isPropertyProfile={isPropertyProfile}
                    />
                </CommentsPaneWrapper>
            </IncomeStatementProvider>
        </PermissionedWrapper>
    );
};

export default IncomeStatementContainer;
