import { RowPreparedEvent } from 'devextreme/ui/data_grid';
import theme from 'config/theme';

export const onContentReady = ({
    e,
    toggleFunc,
}: {
    e: any;
    toggleFunc: (condition: boolean) => void;
}) => {
    // detect group columns applied in columns
    const columns = e.component.getVisibleColumns();
    let groupingIsNotAppliedColumns = true;

    for (let i = 0; i < columns.length; i++) {
        if (columns[i].groupIndex !== undefined) {
            toggleFunc(false);
            groupingIsNotAppliedColumns = false;
            break;
        }
    }

    if (groupingIsNotAppliedColumns) {
        toggleFunc(true);
    }
};

export const applyGroupAndHeaderStylingOnRowPrepared = (
    e: RowPreparedEvent,
) => {
    const isGrouped = e.rowType === 'group';
    const isGroupAndExpanded = e.rowType === 'group' && e.isExpanded;
    const isHeader = e.rowType === 'header';

    if (isGrouped) {
        e.rowElement.style.backgroundColor = theme.colors.white;

        if (isGroupAndExpanded) {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
            e.rowElement.style.fontWeight = 'bolder';
        }
    }

    if (isHeader) {
        e.rowElement.style.fontWeight = 'bold';
        e.rowElement.style.textDecorationColor = theme.colors.grays.text;
        e.rowElement.style.color = theme.colors.grays.text;
    }
};
