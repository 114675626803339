import { css } from 'emotion';
import React, { useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { connect, RootStateOrAny } from 'react-redux';
import { PropertyInfoSummary } from 'waypoint-types';
import PrioritiesObjectivesRollUpView from './PrioritiesObjectivesRollUpView';
import PrioritiesObjectivesItemView from './PrioritiesObjectivesItemView';
import ResponsiveBox, {
    Col,
    Item,
    Location,
    Row,
} from 'devextreme-react/responsive-box';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { getEntityProfileCardBatch } from 'waypoint-requests';
import useSWR from 'swr';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface PlanningPrioritiesObjectivesContainerProps {
    properties: PropertyInfoSummary[];
}

const tabsStyle = css`
    margin: 14px !important;
    background-color: #fff;
    .ant-tabs-nav-wrap {
        margin-left: -2px;
    }
`;

const fixDxItemHeightStyle = css`
    .dx-item.dx-box-item {
        height: 100%;
        background-color: #fff;
    }
`;

const prioritiesObjectivesTabKeys = {
    propertyView: 'propertyView',
    itemView: 'itemView',
};

const PlanningPrioritiesObjectivesContainer = ({
    properties,
}: PlanningPrioritiesObjectivesContainerProps) => {
    const [activeTab, setActiveTab] = useState(
        prioritiesObjectivesTabKeys.propertyView,
    );
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    const profileCardsBatchCacheKey = `/planning/planning-priorities-objectives/entity-profile-cards-batch/${entityCodes.join(
        ',',
    )}`;

    const {
        data: prioritiesObjectivesData,
        mutate: mutatePrioritiesObjectives,
    } = useSWR(
        profileCardsBatchCacheKey,
        () =>
            getEntityProfileCardBatch(
                entityCodes,
                'annualPrioritiesAndObjectives',
            ),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    const tabsItems: TabsProps['items'] = [
        {
            key: prioritiesObjectivesTabKeys.propertyView,
            label: `Property View`,
            children: (
                <PrioritiesObjectivesRollUpView
                    properties={properties}
                    prioritiesObjectivesData={prioritiesObjectivesData}
                    mutatePrioritiesObjectives={mutatePrioritiesObjectives}
                    entityCodes={entityCodes}
                />
            ),
        },
        {
            key: prioritiesObjectivesTabKeys.itemView,
            label: `Item View`,
            children: (
                <PrioritiesObjectivesItemView
                    properties={properties}
                    prioritiesObjectivesData={prioritiesObjectivesData}
                    entityCodes={entityCodes}
                />
            ),
        },
    ];

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.PrioritiesAndObjectives}
            showDisabledView={true}
        >
            <div
                className={fixDxItemHeightStyle}
                style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    height: '97%',
                }}
            >
                <ResponsiveBox>
                    <Row />
                    <Col />
                    <Item>
                        <Location row={0} col={0} colspan={1} />
                        <Tabs
                            type="card"
                            className={tabsStyle}
                            defaultActiveKey={activeTab}
                            onChange={(tabKey) => setActiveTab(tabKey)}
                            items={tabsItems}
                        />
                    </Item>
                </ResponsiveBox>
            </div>
        </PermissionedWrapper>
    );
};

const mapState = (
    s: RootStateOrAny,
): PlanningPrioritiesObjectivesContainerProps => {
    const properties = Object.entries(s.properties).map((property: any) => ({
        name: property[1].name,
        code: property[1].id,
    }));

    return {
        properties,
    };
};

export default connect(mapState)(PlanningPrioritiesObjectivesContainer);
