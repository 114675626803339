import React from 'react';
import {
    AMOUNT_AND_PCT,
    AMOUNT_ONLY,
    COMPARISON_PERIOD,
    COMPARISON_PERIOD_TERTIARY,
    COMPARISON_TYPE,
    DISABLED_VALUE,
    FINANCIAL_YEAR_ENDING,
    NONE_VALUE,
    PCT_ONLY,
    PERIODICITIES_PRIOR_PERIOD_DEFAULT,
    PERIODICITY_FIELD,
    PERIOD_FIELD_NAME_PRIMARY,
    PRIOR_PERIOD_LABEL,
    PRIOR_PERIOD_VALUE,
    VARIANCE_COMPARISON,
    VARIANCE_COMPARISON_TERTIARY,
    VARIANCE_DISPLAY,
    VARIANCE_DISPLAY_TERTIARY,
} from 'components/financials/comparative-income-statement/constants';
import { RangeSelectConfirm, Select } from 'waypoint-react';
import {
    ComparisonSelections,
    SelectOptions,
} from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import moment, { Moment } from 'moment';
import theme from 'config/theme';
import { toEndOfMonth, toISO } from 'components/dates/utils';
import { datePickerStyle } from 'components/financials/common/styles';
import { DatePicker } from 'waypoint-react';
import { monthsAsFinancialYearEndings } from 'components/propertyProfile/PropertyProfileUtils';
import { AttributeValue } from 'shared-types';

const outerDivStyle = {
    display: 'inline-block',
    marginRight: '10px',
};

const modeSelectStyle = {
    display: 'inline-block',
    marginRight: '10px',
    width: '185px',
};

const bannerSelectLabelsStyle = {
    fontSize: '12px',
    fontWeight: 'bold',
    marginRight: '12px',
    marginBottom: '3px',
};

const periodicitySelectStyle = {
    display: 'inline-block',
    marginRight: '10px',
    width: '150px',
};

const comparisonPeriodOptions = [
    { value: 'selected_period', label: 'Selected Period' },
    { value: PRIOR_PERIOD_VALUE, label: PRIOR_PERIOD_LABEL },
    { value: 'prior_year', label: 'Prior Year' },
    { value: 'year_total', label: 'Year Total' },
    { value: 'next_year', label: 'Next Year' },
    { value: 'next_period', label: 'Next Period' },
];

const varianceOptions = [
    { value: AMOUNT_AND_PCT, label: '$ and %' },
    { value: AMOUNT_ONLY, label: '$ Only' },
    { value: PCT_ONLY, label: '% Only' },
    { value: DISABLED_VALUE, label: 'None' },
];

export const PERIODICITIES = {
    ytd: { label: 'YTD', value: 'ytd', key: 'ytd' },
    qtd: { label: 'QTD', value: 'qtd', key: 'qtd' },
    mtd: { label: 'MTD', value: 'mtd', key: 'mtd' },
    trailing_12: {
        label: 'Trailing 12',
        value: 'trailing_12',
        key: 'trailing_12',
    },
};

export const PERIODICITIES_WITH_CUSTOM = {
    ...PERIODICITIES,
    custom: { label: 'Custom', value: 'custom', key: 'custom' },
};

export const PERIODICITIES_PRIOR_PERIOD = {
    month: { label: 'Month', value: 'month', key: 'month' },
    qtd: { label: 'Quarter', value: 'quarter', key: 'quarter' },
};

export const COMPARATIVE_INCOME_STATEMENT_FORM_FIELDS_WITH_CUSTOM = {
    periodicity: {
        options: Object.values(PERIODICITIES_WITH_CUSTOM),
        default: PERIODICITIES_PRIOR_PERIOD_DEFAULT,
    },
};

export const PRIMARY_COLUMN_INDEX = 0;
export const SECONDARY_COLUMN_INDEX = 1;
export const TERTIARY_COLUMN_INDEX = 2;

export const getComparisonTypeSelect = (
    getUserSelectionFor: (field: string) => string,
    comparisonTypeOptions: { value: string; label: string }[],
    handleOnChange: (
        field: string,
        value: string | string[] | Moment[],
    ) => void,
    label?: string,
    indexOfComparisonType?: number,
): JSX.Element => {
    return (
        <>
            <div style={bannerSelectLabelsStyle}>
                {label ?? 'Comparison Type'}
            </div>
            <Select
                disabled={
                    // disable third column select if second column select is none
                    indexOfComparisonType === 2 &&
                    getUserSelectionFor(COMPARISON_TYPE ?? [''])[1] ===
                        NONE_VALUE
                }
                key={
                    getUserSelectionFor(COMPARISON_TYPE ?? [''])[
                        indexOfComparisonType ?? 0
                    ]
                }
                style={{ display: 'inline-block', width: '150px' }}
                options={comparisonTypeOptions}
                value={
                    getUserSelectionFor(COMPARISON_TYPE ?? [''])[
                        indexOfComparisonType ?? 0
                    ]
                }
                defaultValue={
                    getUserSelectionFor(COMPARISON_TYPE ?? [''])[
                        indexOfComparisonType ?? 0
                    ]
                }
                onChange={(value: string) => {
                    // set the third comparison to none if the second one is none
                    if (indexOfComparisonType === 1 && value === NONE_VALUE) {
                        handleOnChange(COMPARISON_TYPE, [
                            ...getUserSelectionFor(
                                COMPARISON_TYPE ?? [''],
                            ).slice(0, indexOfComparisonType ?? 0),
                            value,
                            value,
                        ]);
                    } else {
                        handleOnChange(COMPARISON_TYPE, [
                            ...getUserSelectionFor(
                                COMPARISON_TYPE ?? [''],
                            ).slice(0, indexOfComparisonType ?? 0),
                            value,
                            ...getUserSelectionFor(
                                COMPARISON_TYPE ?? [''],
                            ).slice((indexOfComparisonType ?? 0) + 1),
                        ]);
                    }
                }}
            />
        </>
    );
};

export const getComparisonPeriodSelect = (
    getUserSelectionFor: (field: string) => string,
    localModes: SelectOptions[],
    handleOnChange: (field: string, value: string | Moment[]) => void,
    indexOfComparisonType?: number,
): JSX.Element => {
    const selectionKey = indexOfComparisonType
        ? COMPARISON_PERIOD_TERTIARY
        : COMPARISON_PERIOD;

    const key = getUserSelectionFor(selectionKey);

    if (!key) {
        return <></>;
    }

    let comparisonPeriodOpts: { value: string; label: string }[] =
        comparisonPeriodOptions;
    if (
        getUserSelectionFor(PERIODICITY_FIELD) === 'trailing_12' ||
        getUserSelectionFor(PERIODICITY_FIELD) === 'custom'
    ) {
        comparisonPeriodOpts = comparisonPeriodOpts.filter(
            (o) => o.value !== 'year_total',
        );
    }

    const props = {
        key,
        style: { ...modeSelectStyle, width: '150px' },
        options: comparisonPeriodOpts,
        value: getUserSelectionFor(selectionKey),
        onChange: (value: string) => handleOnChange(selectionKey, value),
    };

    return (
        <div key={key}>
            <div style={{ ...outerDivStyle, width: '90%' }}>
                <div style={bannerSelectLabelsStyle}>Comparison Period</div>
                <Select
                    {...props}
                    disabled={
                        getUserSelectionFor(COMPARISON_TYPE)[
                            indexOfComparisonType ?? 1
                        ] === NONE_VALUE
                    }
                />
            </div>
        </div>
    );
};

export const getVarianceOptions = (
    getUserSelectionFor: (field: string) => string,
    handleOnChange: (field: string, value: string | Moment[]) => void,
    isDisabled: boolean,
    indexOfComparisonType?: number,
): JSX.Element => {
    const selectionKey = indexOfComparisonType
        ? VARIANCE_DISPLAY_TERTIARY
        : VARIANCE_DISPLAY;
    const selectionLabel = 'Variance';

    const props = {
        key: selectionKey,
        style: { ...modeSelectStyle, width: '150px', marginRight: '20px' },
        options: varianceOptions,
        value: getUserSelectionFor(selectionKey),
        onChange: (value: string) => handleOnChange(selectionKey, value),
    };

    return (
        <div key={getUserSelectionFor(selectionKey)}>
            <div style={bannerSelectLabelsStyle}>{selectionLabel}</div>
            <Select {...props} disabled={isDisabled} />
        </div>
    );
};

export const getPeriodicitySelect = (
    comparisonType: string,
    getUserSelectionFor: (field: string) => string,
    handleOnChange: (field: string, value: string | Moment[]) => void,
) => {
    let selectionKey: string;
    selectionKey = PERIODICITY_FIELD;

    const props = {
        key: selectionKey,
        style: periodicitySelectStyle,
        options:
            COMPARATIVE_INCOME_STATEMENT_FORM_FIELDS_WITH_CUSTOM[
                PERIODICITY_FIELD
            ].options,
        value: getUserSelectionFor(selectionKey),
        onChange: (value: string) => handleOnChange(selectionKey, value),
    };

    return (
        <div key={getUserSelectionFor(PERIODICITY_FIELD)}>
            <div style={outerDivStyle}>
                <div style={bannerSelectLabelsStyle}>Period Grouping</div>
                <Select {...props} />
            </div>
        </div>
    );
};

export const getPeriodRangeSelect = (
    getUserSelectionFor: (field: string) => string,
    rangeSelectIsOpen: boolean,
    setRangeSelectIsOpen: (value: boolean) => void,
    handleOnChange: (field: string, value: string | Moment[]) => void,
    handleOnChangeDateRange: (
        value: string | Date,
    ) => Partial<ComparisonSelections>,
): JSX.Element => {
    const initValues = [
        moment(getUserSelectionFor(PERIOD_FIELD_NAME_PRIMARY)[0]),
        moment(getUserSelectionFor(PERIOD_FIELD_NAME_PRIMARY)[1]),
    ];

    return (
        <div style={{ display: 'inline-block' }}>
            {getUserSelectionFor(PERIODICITY_FIELD) === 'custom' ? (
                <>
                    <div style={{ ...bannerSelectLabelsStyle, width: '185px' }}>
                        Period Range
                    </div>
                    <RangeSelectConfirm
                        open={rangeSelectIsOpen}
                        value={initValues}
                        onFocus={() => setRangeSelectIsOpen(true)}
                        onCancel={() => setRangeSelectIsOpen(false)}
                        onConfirm={(values) => {
                            setRangeSelectIsOpen(false);
                            if (values.length === 2) {
                                const [start, end] = values;
                                handleOnChange(PERIOD_FIELD_NAME_PRIMARY, [
                                    start.clone().startOf('month'),
                                    end.clone().endOf('month'),
                                ]);
                            } else {
                                console.warn(
                                    'Comparative income statement date range did not update as there were ',
                                    values.length,
                                    ' values present',
                                );
                            }
                        }}
                        getCalendarContainer={() =>
                            document.getElementById(
                                'comparative-income-statement-range-select-id',
                            )
                        }
                    />
                </>
            ) : (
                <>
                    <div style={bannerSelectLabelsStyle}>Period Ending</div>
                    <DatePicker
                        picker={'month'}
                        allowClear={false}
                        style={{ marginRight: '20px' }}
                        placeholder="Select month"
                        format={'MMMM YYYY'}
                        className={datePickerStyle}
                        onChange={(momentInstance) =>
                            handleOnChangeDateRange(
                                toISO(toEndOfMonth(momentInstance)),
                            )
                        }
                        suffixIcon={
                            <i
                                className="fa-regular fa-calendar"
                                style={{
                                    color: theme.colors.grays.dark,
                                }}
                            />
                        }
                        value={moment(initValues[1])}
                        defaultValue={moment(initValues[1])}
                    />
                </>
            )}
        </div>
    );
};

export const getYearEndingSelect = (
    getUserSelectionFor: (field: string) => string,
    handleOnChange: (
        field: string,
        value: string | string[] | Moment[],
    ) => void,
    standardAttribute?: AttributeValue,
) => {
    const initValue = getUserSelectionFor(FINANCIAL_YEAR_ENDING);
    if (initValue === '') {
        return <></>;
    }

    return (
        <div style={{ display: 'inline-block' }}>
            <div style={bannerSelectLabelsStyle}>Year Ending</div>
            <Select
                key={FINANCIAL_YEAR_ENDING}
                data-testid="year-ending-select"
                style={{ width: '275px' }}
                value={initValue ?? standardAttribute?.value ?? '12/31'}
                onChange={(e: string) =>
                    handleOnChange(FINANCIAL_YEAR_ENDING, e)
                }
                options={monthsAsFinancialYearEndings()}
            />
        </div>
    );
};

export const getVarianceComparisonOptions = (
    getUserSelectionFor: (field: string) => string,
    handleOnChange: (field: string, value: string | Moment[]) => void,
    isDisabled: boolean,
    comparisonTypes: string[],
    indexOfComparisonType: number,
): JSX.Element => {
    const selectionKey =
        indexOfComparisonType === SECONDARY_COLUMN_INDEX
            ? VARIANCE_COMPARISON
            : VARIANCE_COMPARISON_TERTIARY;
    const selectionLabel = 'Variance Comparison';

    const disabledDisplayedValue =
        selectionKey === VARIANCE_COMPARISON
            ? 'Column 1 - Column 2'
            : 'Column 1 - Column 3';
    const props = {
        key: selectionKey,
        style: { ...modeSelectStyle, width: '275px', marginRight: '10px' },
        options: getComparisonTypeOptions(
            comparisonTypes,
            indexOfComparisonType,
        ),
        value: isDisabled
            ? disabledDisplayedValue
            : getUserSelectionFor(selectionKey),
        onChange: (value: string) => handleOnChange(selectionKey, value),
    };

    return (
        <div key={getUserSelectionFor(selectionKey)}>
            <div style={bannerSelectLabelsStyle}>{selectionLabel}</div>
            <Select {...props} disabled={isDisabled} />
        </div>
    );
};

const getComparisonTypeOptions = (
    comparisonTypes: string[],
    indexOfComparisonType: number,
): { value: string; label: string }[] => {
    const optionObjects: { value: string; label: string }[] = [];
    for (const [index, type] of comparisonTypes.entries()) {
        for (const [secondIndex, secondType] of comparisonTypes.entries()) {
            const isAnyIndexBeyondComparisonType =
                index > indexOfComparisonType ||
                secondIndex > indexOfComparisonType;
            const isNeitherIndexComparisonType =
                index !== indexOfComparisonType &&
                secondIndex !== indexOfComparisonType;
            const isAnyTypeNone = type === 'none' || secondType === 'none';
            const notASelectableComparisonOption =
                index === secondIndex ||
                isAnyIndexBeyondComparisonType ||
                isNeitherIndexComparisonType ||
                isAnyTypeNone;
            if (notASelectableComparisonOption) {
                continue;
            }

            optionObjects.push({
                value: `comparison[${index}-${secondIndex}]`,
                label: `Column ${index + 1}  - Column ${secondIndex + 1}`,
            });
        }
    }

    return optionObjects.sort((a, b) => {
        const bParts = b.value.split('[');
        const aParts = a.value.split('[');
        return (
            parseInt(aParts[1][0]) +
            parseInt(aParts[1][2]) -
            (parseInt(bParts[1][0]) + parseInt(bParts[1][2]))
        );
    });
};
