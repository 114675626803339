import { chartBuilder } from 'components/charts';
import theme from 'config/theme';
import { renderToString } from 'react-dom/server';
import { formatNumber, formatPercentage } from 'utils/formatters';
import { ListTooltip } from 'waypoint-react';
import {
    DatasetSeries,
    LeaseExpirationScheduleProps,
    LinesetSeriesProps,
    RentRollProps,
    RootFusionChartsConfigProps,
} from 'waypoint-types';
import { groupBy, isNil, sumBy, uniqBy } from 'lodash';
import {
    getMaxDateInArray,
    getMinDateInArray,
    toCalendarMonth,
    toCalendarMonthAndYearAbbrv,
    toDate,
    toMoment,
} from 'components/dates/utils';
import { dateSort, sortByMonth } from 'utils/tables/sorters';
import moment from 'moment';
import { safeDivision } from 'shared-types';
import { monthDisplayNames } from 'utils/consts';
import { VACANT } from 'components/leasingPlan/LeasingPlanUtils';

export const EXPIRED_KEY = 'Expired/MTM';
export const VACANT_KEY = 'Vacant';

type LeasesData = Omit<RentRollProps, 'expiration_year'>;
interface MappedLeasesData extends LeasesData {
    expiration_year: string | null;
    expiration_month: string | null;
    expiration_month_year: string | null;
    is_expired: boolean;
}

type SortedAndGroupedDataObject = Record<
    typeof EXPIRED_KEY | string,
    MappedLeasesData[]
>;
type SortedAndGroupedData = Record<
    'uniqueLeasesGroupedBySpaceCode' | 'uniqueLeasesGroupedByKey',
    SortedAndGroupedDataObject
>;

export interface ExpirationData {
    cumulative_expiring_area: number;
    cumulative_expiring_area_percentage: number;
    expiration_year: string | null;
    expiration_month: string | null;
    expiration_month_year: string | null;
    expiring_square_footage: number;
    expiring_square_footage_percentage: number;
    total_expiring_leases: number;
    in_place_base_rent: number;
    in_place_base_rent_percentage: number;
    is_expired: boolean;
}
export type ExpirationDataGroupKeys = Pick<
    ExpirationData,
    'expiration_year' | 'expiration_month' | 'expiration_month_year'
>;

export enum expirationGroupingByOptions {
    year = 'year',
    month = 'month',
    monthYear = 'month-year',
}
export const expirationGroupingKeyName = {
    [expirationGroupingByOptions.year]: 'expiration_year',
    [expirationGroupingByOptions.month]: 'expiration_month',
    [expirationGroupingByOptions.monthYear]: 'expiration_month_year',
};
export const expirationLabelsByGroupingOption = {
    [expirationGroupingByOptions.year]: 'Expiration Year',
    [expirationGroupingByOptions.month]: 'Expiration Month',
    [expirationGroupingByOptions.monthYear]: 'Expiration Month/Year',
};
export const expirationGroupingBySelectOptions = [
    {
        label: 'Year',
        value: expirationGroupingByOptions.year,
    },
    {
        label: 'Month',
        value: expirationGroupingByOptions.month,
    },
    {
        label: 'Month/Year',
        value: expirationGroupingByOptions.monthYear,
    },
];

export const yearGroupingOptions = [
    {
        label: '5 Years',
        value: 5,
    },
    {
        label: '6 Years',
        value: 6,
    },
    {
        label: '7 Years',
        value: 7,
    },
    {
        label: '8 Years',
        value: 8,
    },
    {
        label: '9 Years',
        value: 9,
    },
    {
        label: '10 Years',
        value: 10,
    },
];

export const monthsGroupingOptions = [
    {
        label: '12 Months',
        value: 12,
    },
    {
        label: '18 Months',
        value: 18,
    },
    {
        label: '24 Months',
        value: 24,
    },
];

const trendLine = [
    {
        line: [
            {
                startValue: '100',
                parentYAxis: 's',
                color: theme.colors.red,
                thickness: 6,
                displayValue: ' ',
            },
        ],
    },
];

export const defaultYearToGroupValue = yearGroupingOptions[0].value;

export const defaultMonthsToGroupValue = monthsGroupingOptions[1].value;

export const defaultGroupByOptionValue = expirationGroupingByOptions.year;

interface Dictionary<T> {
    [key: string]: T;
}

// Generic function to replace a key to other key e.g. "null" to "Expired"
const replaceKeyWithValue = <T, K extends string>(
    dictionary: Dictionary<T>,
    keyToReplace: K,
    value: string | number,
): Dictionary<T> => {
    const { [keyToReplace]: replacedValue, ...rest } = dictionary;
    return {
        ...rest,
        [value]: replacedValue,
    };
};

export const isYearSelected = (groupByOption: expirationGroupingByOptions) =>
    groupByOption === expirationGroupingByOptions.year;
export const isMonthSelected = (groupByOption: expirationGroupingByOptions) =>
    groupByOption === expirationGroupingByOptions.month;
export const isMonthYearSelected = (
    groupByOption: expirationGroupingByOptions,
) => groupByOption === expirationGroupingByOptions.monthYear;

const getDatesBetween = (startDate: Date, endDate: Date) => {
    const result: string[] = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        result.push(toCalendarMonthAndYearAbbrv(currentDate));

        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return result;
};

const getOrderedExpirationKeysWithMissingDates = (
    expirationKeys: string[] = [],
    groupByOption: expirationGroupingByOptions,
    isVacantLeases: boolean,
) => {
    if (isVacantLeases) {
        return expirationKeys;
    }

    if (expirationKeys.includes(VACANT_KEY)) {
        return [VACANT_KEY];
    }

    if (isMonthYearSelected(groupByOption)) {
        const expirationDates = expirationKeys.map((dateString) =>
            toMoment(dateString),
        );
        const startDate = getMinDateInArray(expirationDates);
        const endDate = getMaxDateInArray(expirationDates);

        return getDatesBetween(startDate, endDate);
    }

    if (isMonthSelected(groupByOption)) {
        for (let i = 0; i < monthDisplayNames.length; i++) {
            if (!expirationKeys.includes(monthDisplayNames[i])) {
                expirationKeys.splice(i, 0, monthDisplayNames[i]);
            }
        }
    }

    return expirationKeys;
};

const getSortedAndGroupedData = (
    activeLeases: MappedLeasesData[],
    groupByOption: expirationGroupingByOptions,
): SortedAndGroupedData => {
    const groupByKey = expirationGroupingKeyName[groupByOption];
    const expiredLeases: MappedLeasesData[] = [];
    const unexpiredLeases: MappedLeasesData[] = activeLeases.filter((lease) => {
        if (lease.is_expired && lease.space_occupancy_status !== VACANT) {
            expiredLeases.push(lease);
            return false;
        }
        return true;
    });

    let sortedUnexpiredLeases = [...unexpiredLeases];

    if (isMonthYearSelected(groupByOption)) {
        sortedUnexpiredLeases = unexpiredLeases
            .sort((a, b) =>
                dateSort(
                    new Date(a.lease_expiration_date),
                    new Date(b.lease_expiration_date),
                ),
            )
            .reverse();
    }

    if (isMonthSelected(groupByOption)) {
        sortedUnexpiredLeases = unexpiredLeases.sort((a, b) =>
            sortByMonth(a.expiration_month, b.expiration_month),
        );
    }

    const allLeases = [...expiredLeases, ...sortedUnexpiredLeases];

    // For expiring_square_footage calculation purpose
    const uniqueLeasesGroupedBySpaceCode = replaceKeyWithValue(
        groupBy(
            uniqBy(allLeases, 'leasable_space_code'),
            groupByKey as keyof RentRollProps,
        ),
        'null',
        EXPIRED_KEY,
    );

    // For total_expiring_leases calculation purpose
    const uniqueLeasesByLeaseCode = uniqBy(allLeases, 'lease_code');

    const uniqueLeasesGroupedByKey = replaceKeyWithValue(
        groupBy(uniqueLeasesByLeaseCode, groupByKey as keyof RentRollProps),
        'null',
        EXPIRED_KEY,
    );

    return {
        uniqueLeasesGroupedBySpaceCode,
        uniqueLeasesGroupedByKey,
    };
};
const getGroupedByLeasesData = (
    activeLeases: MappedLeasesData[],
    totalVacantArea: number,
    groupByOption: expirationGroupingByOptions,
    activeLeasesAdjustedTotalsByLeasableSpaceCode: Dictionary<number>,
    vacantCumulative?: {
        cumulativeExpiringArea: number;
        cumulativeExpiringAreaPercentage: number;
    },
): ExpirationData[] => {
    const expirationsList: ExpirationData[] = [];
    let cumulativeExpiringArea = 0;

    const isVacantLeases = !vacantCumulative;

    const { uniqueLeasesGroupedBySpaceCode, uniqueLeasesGroupedByKey } =
        getSortedAndGroupedData(activeLeases, groupByOption);

    const expirationKeys = Object.keys(uniqueLeasesGroupedBySpaceCode);

    const expiredKeyIndex = expirationKeys.indexOf(EXPIRED_KEY);

    const hasExpired = expiredKeyIndex > -1;

    hasExpired && expirationKeys.splice(expiredKeyIndex, 1);

    const expirationKeysWithMissingDates =
        getOrderedExpirationKeysWithMissingDates(
            expirationKeys,
            groupByOption,
            isVacantLeases,
        );

    expirationKeysWithMissingDates.unshift(EXPIRED_KEY);

    const expKeys = [...expirationKeysWithMissingDates];

    const totalRent = activeLeasesAdjustedTotalsByLeasableSpaceCode.totalRent;

    for (const k of expKeys) {
        const rentableArea = sumBy(
            uniqueLeasesGroupedBySpaceCode[k],
            'rentable_sq_ft',
        );
        cumulativeExpiringArea += rentableArea;

        const expiringRent =
            uniqueLeasesGroupedBySpaceCode[k]?.reduce((acc, uq) => {
                return (
                    acc +
                    activeLeasesAdjustedTotalsByLeasableSpaceCode[
                        uq.leasable_space_code
                    ]
                );
            }, 0) ?? 0;

        const cumulativeVacantPercentage =
            vacantCumulative?.cumulativeExpiringAreaPercentage ?? 0;
        const expirationData: ExpirationData = {
            cumulative_expiring_area:
                cumulativeExpiringArea +
                (vacantCumulative?.cumulativeExpiringArea ?? 0),
            cumulative_expiring_area_percentage: Number(
                (
                    safeDivision(cumulativeExpiringArea, totalVacantArea) *
                        100 +
                    cumulativeVacantPercentage
                ).toFixed(1),
            ),
            expiration_year:
                k === EXPIRED_KEY || !isYearSelected(groupByOption) ? null : k,
            expiration_month:
                k === EXPIRED_KEY || !isMonthSelected(groupByOption) ? null : k,
            expiration_month_year:
                k === EXPIRED_KEY || !isMonthYearSelected(groupByOption)
                    ? null
                    : k,
            expiring_square_footage: rentableArea,
            expiring_square_footage_percentage: safeDivision(
                rentableArea,
                totalVacantArea,
            ),
            total_expiring_leases: uniqueLeasesGroupedByKey[k]?.length ?? 0,
            in_place_base_rent: expiringRent,
            in_place_base_rent_percentage: safeDivision(
                expiringRent,
                totalRent,
            ),
            is_expired: k === EXPIRED_KEY,
        };

        expirationsList.push(expirationData);
    }

    return expirationsList;
};
const createLeaseExpirationLineSeries = (
    leaseExpirations: LeaseExpirationScheduleProps[],
    groupByKey = expirationGroupingKeyName[expirationGroupingByOptions.year],
): LinesetSeriesProps => {
    const expirations = Object.values(leaseExpirations);
    const isGroupedByYear =
        groupByKey ===
        expirationGroupingKeyName[expirationGroupingByOptions.year];

    const data = expirations.map((item) => {
        const areaExpPrc = isNil(item.expiring_square_footage_percentage)
            ? 0
            : formatPercentage(
                  item.expiring_square_footage_percentage * 100,
                  1,
              );

        const tooltipItems = [
            {
                label: isGroupedByYear
                    ? 'Cumulative Expiring Area (sq ft)'
                    : 'Area Expiring (sq ft)',
                value: formatNumber(
                    isGroupedByYear
                        ? item.cumulative_expiring_area
                        : item.expiring_square_footage,
                ),
            },
            {
                label: isGroupedByYear
                    ? 'Cumulative Expiring Area %'
                    : 'Area Expiring %',
                value: isGroupedByYear
                    ? item.cumulative_expiring_area_percentage
                    : areaExpPrc,
            },
        ];

        return {
            value: isGroupedByYear
                ? item.cumulative_expiring_area_percentage
                : areaExpPrc,
            toolText: renderToString(
                <ListTooltip
                    header={item[groupByKey as keyof ExpirationDataGroupKeys]}
                    items={tooltipItems}
                />,
            ),
        };
    });

    return {
        seriesName: 'Cumulative Vacancy',
        renderas: 'Line',
        parentYAxis: 'S',
        color: '#D43434',
        lineThickness: '3',
        drawAnchors: true,
        showValues: false,
        anchorBgColor: '#D43434',
        anchorBgHoverColor: '#D43434',
        data,
    };
};

const getGroupKey = (
    lease: LeaseExpirationScheduleProps,
    groupByKey = expirationGroupingKeyName[expirationGroupingByOptions.year],
) => {
    if (
        lease.expiration_month_year === VACANT_KEY ||
        lease.expiration_month === VACANT_KEY ||
        lease.expiration_year === VACANT_KEY
    ) {
        return VACANT_KEY;
    }

    if (lease.is_expired) {
        return EXPIRED_KEY;
    }

    return lease[groupByKey as keyof ExpirationDataGroupKeys] ?? 'null';
};

const createSeriesForAYear = ([key, value]: [
    string,
    LeaseExpirationScheduleProps[],
]): { value: number | null; toolText: string } => {
    const currentLease: LeaseExpirationScheduleProps = value?.[0];

    const expiringSquareFootagePercentage =
        value?.[0]?.expiring_square_footage_percentage ?? 0;

    const tooltipItems = [
        {
            label: 'Total Expiring Leases',
            value: formatNumber(currentLease?.total_expiring_leases),
        },
        {
            label: 'Expiring Area (sq ft)',
            value: formatNumber(currentLease?.expiring_square_footage),
        },
        {
            label: 'Expiring Area %',
            value: formatPercentage(expiringSquareFootagePercentage * 100, 1),
        },
    ];

    return {
        value: currentLease?.expiring_square_footage,
        toolText: renderToString(
            <ListTooltip header={key} items={tooltipItems} />,
        ),
    };
};

const createSeriesForEachGroupKey = (
    leaseExpirations: LeaseExpirationScheduleProps[],
    groupByKey = expirationGroupingKeyName[expirationGroupingByOptions.year],
): DatasetSeries => {
    const groupedLease = groupBy(
        leaseExpirations,
        groupByKey as keyof RentRollProps,
    );

    const cleanedGroupedLease = replaceKeyWithValue(
        groupedLease,
        'null',
        EXPIRED_KEY,
    );

    const data = [] as { value: number | null; toolText: string }[];

    type HardCodedSortOrderType = {
        [VACANT_KEY]: number;
        [EXPIRED_KEY]: number;
    };
    const hardCodedSortOrder = {
        [VACANT_KEY]: -1,
        [EXPIRED_KEY]: 0,
    };

    Object.entries(cleanedGroupedLease)
        .sort((a, b) => {
            const sortOrderForA =
                hardCodedSortOrder[a[0] as keyof HardCodedSortOrderType] ?? 1;
            const sortOrderForB =
                hardCodedSortOrder[b[0] as keyof HardCodedSortOrderType] ?? 1;

            return sortOrderForA - sortOrderForB;
        })
        .forEach(([key, value]) => {
            data.push(createSeriesForAYear([key, value]));
        });

    return {
        seriesName: 'Expiring Area',
        data,
    };
};

export const buildLeaseExpirationChartConfig = (
    leaseExpirations: LeaseExpirationScheduleProps[],
    isPDFExport?: boolean,
    groupByOption: expirationGroupingByOptions = expirationGroupingByOptions.year,
    isPropertyProfile?: boolean,
): RootFusionChartsConfigProps => {
    const groupByKey = expirationGroupingKeyName[groupByOption];
    // PREP
    const leases = leaseExpirations.filter(
        (v) =>
            !isNil(v[groupByKey as keyof ExpirationDataGroupKeys]) ||
            (isNil(v[groupByKey as keyof ExpirationDataGroupKeys]) &&
                v.is_expired),
    );
    // CATEGORIES AND SERIES
    const categories = leases.map((lease) => ({
        label: getGroupKey(lease, groupByKey),
    }));

    const leaseExpirationScheduleLineSeries = createLeaseExpirationLineSeries(
        leases,
        groupByKey,
    );

    const leaseExpirationScheduleDataSeries = createSeriesForEachGroupKey(
        leases,
        groupByKey,
    );

    // CHART CONFIG
    const chart = chartBuilder();
    chart.type('mscombidy2d');
    chart.data();
    chart.dataset([
        leaseExpirationScheduleDataSeries,
        leaseExpirationScheduleLineSeries,
    ]);
    chart.categories([{ category: categories }]);
    chart.height(isPropertyProfile ? '325' : '400');

    const baseChartStyle = {
        paletteColors: theme.colors.barCharts[0],
        pYaxisName: 'Expiring Area (sq ft)',
        sYaxisName: !isYearSelected(groupByOption)
            ? 'Expiring Area %'
            : 'Cumulative Expiring %',
        xAxisName: expirationGroupingBySelectOptions.find(
            (e) => e.value === groupByOption,
        )?.label,
        exportFormats: 'PNG|PDF|JPG|SVG',
        connectNullData: '1',
        ...(isMonthSelected(groupByOption) ? { sYAxisMaxValue: 50 } : null),
    };

    const chartStyle = isPDFExport
        ? {
              ...baseChartStyle,
              baseFont: 'Arial',
              xAxisNameFont: 'Arial',
              pYAxisNameFont: 'Arial',
              sYAxisNameFont: 'Arial',
              exportEnabled: '0',
              animation: '0',
          }
        : baseChartStyle;

    chart.style(chartStyle);

    if (leases.length === 1) {
        chart.trendlines(trendLine);
    } else {
        chart.lineset(leases.length > 1 && [leaseExpirationScheduleLineSeries]);
    }
    return chart.config;
};

const sumOfLeasesOutOfRange = (
    outOfRangeLeases: LeaseExpirationScheduleProps[],
    groupByOption: expirationGroupingByOptions,
    year?: number,
    currentFullYear?: number,
) => {
    if (isYearSelected(groupByOption) && !(year || currentFullYear)) {
        return null;
    }

    const firstMonthYearOutOfRange =
        outOfRangeLeases?.[0]?.expiration_month_year;
    const plusValue =
        isMonthYearSelected(groupByOption) && firstMonthYearOutOfRange
            ? `${firstMonthYearOutOfRange}+`
            : isYearSelected(groupByOption) && year && currentFullYear
              ? `${currentFullYear + year}+`
              : null;

    return outOfRangeLeases?.reduce(
        (r: LeaseExpirationScheduleProps, a: LeaseExpirationScheduleProps) => {
            r = {
                cumulative_expiring_area: Number(a.cumulative_expiring_area),
                cumulative_expiring_area_percentage: Number(
                    a.cumulative_expiring_area_percentage,
                ),
                expiration_year: isYearSelected(groupByOption)
                    ? plusValue
                    : a.expiration_month_year,
                expiration_month: a.expiration_month,
                expiration_month_year: isMonthYearSelected(groupByOption)
                    ? plusValue
                    : a.expiration_month_year,
                expiring_square_footage:
                    Number(r.expiring_square_footage) +
                    Number(a.expiring_square_footage),
                expiring_square_footage_percentage:
                    Number(r.expiring_square_footage_percentage) +
                    Number(a.expiring_square_footage_percentage),
                in_place_base_rent:
                    Number(r.in_place_base_rent) + Number(a.in_place_base_rent),
                in_place_base_rent_percentage:
                    Number(r.in_place_base_rent_percentage) +
                    Number(a.in_place_base_rent_percentage),
                total_expiring_leases:
                    Number(r.total_expiring_leases) +
                    Number(a.total_expiring_leases),
                is_expired: false,
            };
            return r;
        },
        {
            expiration_year: null,
            expiration_month: null,
            expiration_month_year: null,
            cumulative_expiring_area: null,
            cumulative_expiring_area_percentage: null,
            expiring_square_footage: null,
            expiring_square_footage_percentage: null,
            in_place_base_rent: null,
            in_place_base_rent_percentage: null,
            total_expiring_leases: null,
            is_expired: false,
        },
    );
};
const getLeasesBetweenTheRange = (
    leases: LeaseExpirationScheduleProps[],
    monthsDifference: number,
    currentYearMonth: string,
) => {
    const currentDate = toDate(currentYearMonth);
    const maxDateWithMonths = toDate(
        moment(currentDate).add(monthsDifference, 'months'),
    );

    const outOfRangeLeases: LeaseExpirationScheduleProps[] = [];
    const inRangeLeases = leases.filter((lease) => {
        if (lease.is_expired || lease.expiration_month_year === VACANT_KEY) {
            return true;
        }

        const leaseDate =
            lease.expiration_month_year && toDate(lease.expiration_month_year);

        if (leaseDate && leaseDate > maxDateWithMonths) {
            outOfRangeLeases.push(lease);
        }

        return (
            leaseDate &&
            leaseDate >= currentDate &&
            leaseDate <= maxDateWithMonths
        );
    });

    const outOfRangeLeasesSum =
        outOfRangeLeases.length > 0
            ? sumOfLeasesOutOfRange(
                  outOfRangeLeases,
                  expirationGroupingByOptions.monthYear,
              )
            : null;
    if (outOfRangeLeasesSum) {
        inRangeLeases.push(outOfRangeLeasesSum);
    }
    return inRangeLeases;
};

const getSliceIndexByYear = (
    leases: LeaseExpirationScheduleProps[],
    yearsDifference: number,
    currentYear: number,
): number => {
    return leases.filter(
        (lease) =>
            lease.expiration_year &&
            Number(lease.expiration_year) < currentYear + yearsDifference,
    ).length;
};

export const groupByMonths = (
    leaseExpirations: LeaseExpirationScheduleProps[],
    months?: number,
) => {
    if (!months) return leaseExpirations;
    const todayFormattedDate = toCalendarMonthAndYearAbbrv(new Date());
    return getLeasesBetweenTheRange(
        leaseExpirations,
        months,
        todayFormattedDate,
    );
};
export const groupByYear = (
    leaseExpirations: LeaseExpirationScheduleProps[],
    year?: number,
): LeaseExpirationScheduleProps[] => {
    if (!year) return leaseExpirations;
    const leaseWithYear = leaseExpirations.filter(
        (l) => l.expiration_year && l.expiration_year !== VACANT_KEY,
    );
    const currentFullYear = new Date().getFullYear();
    const rangeMaxDateIndex = getSliceIndexByYear(
        leaseWithYear,
        year,
        currentFullYear,
    );

    if (rangeMaxDateIndex === leaseWithYear.length) return leaseExpirations;

    const outOfRangeLeases = leaseWithYear.slice(rangeMaxDateIndex);
    const outOfRangeLeasesSum = sumOfLeasesOutOfRange(
        outOfRangeLeases,
        expirationGroupingByOptions.year,
        year,
        currentFullYear,
    );

    const hasLeaseWithoutYear = leaseExpirations.find(
        (l) => !l.expiration_year,
    );

    const hasLeaseVacant = leaseExpirations.find(
        (l) => l.expiration_year === VACANT_KEY,
    );

    const inRangeLeases = leaseWithYear.slice(0, rangeMaxDateIndex);
    if (hasLeaseWithoutYear) {
        inRangeLeases.unshift(hasLeaseWithoutYear);
    }

    if (hasLeaseVacant) {
        inRangeLeases.push(hasLeaseVacant);
    }

    if (outOfRangeLeasesSum) {
        inRangeLeases.push(outOfRangeLeasesSum);
    }
    return inRangeLeases;
};

const addExpirationMonthAndYear = (
    rentRoll: RentRollProps,
): MappedLeasesData => {
    const leaseExpirationDate = rentRoll.lease_expiration_date;

    if (!leaseExpirationDate) {
        return {
            ...rentRoll,
            is_expired: true,
            expiration_year: rentRoll.expiration_year
                ? rentRoll.expiration_year.toString()
                : null,
            expiration_month: null,
            expiration_month_year: null,
        };
    }

    const expirationDate = toDate(leaseExpirationDate);
    const isExpired = moment(expirationDate).isBefore(new Date());

    return {
        ...rentRoll,
        is_expired: isExpired,
        expiration_year: isExpired ? null : rentRoll.expiration_year.toString(),
        expiration_month: isExpired
            ? null
            : toCalendarMonth(leaseExpirationDate),
        expiration_month_year: isExpired
            ? null
            : toCalendarMonthAndYearAbbrv(leaseExpirationDate),
    };
};
export function getFormattedLeaseExpirationByGroupingOption(
    rentRoll: RentRollProps[],
    activeLeasesAdjustedTotalsByLeasableSpaceCode: Dictionary<number>,
    groupByOption: expirationGroupingByOptions = expirationGroupingByOptions.year,
    vacantByLeasableSpaceCode: Dictionary<number>,
): ExpirationData[] {
    const activeLeases: MappedLeasesData[] = rentRoll
        .filter((rentRoll) => rentRoll.space_occupancy_status === 'OCCUPIED')
        .map(addExpirationMonthAndYear);

    const uniqueRentRollBySpaceCode = uniqBy(rentRoll, 'leasable_space_code');
    const totalVacantArea = sumBy(uniqueRentRollBySpaceCode, 'rentable_sq_ft');

    const vacantLeases: MappedLeasesData[] = rentRoll
        .filter((rentRoll) => rentRoll.space_occupancy_status === VACANT)
        .map(addExpirationMonthAndYear);

    const vacant = getGroupedByLeasesData(
        vacantLeases,
        totalVacantArea,
        groupByOption,
        vacantByLeasableSpaceCode,
    );

    const vacantLeasesSum = sumVacantLeases(vacant);

    const active = getGroupedByLeasesData(
        activeLeases,
        totalVacantArea,
        groupByOption,
        activeLeasesAdjustedTotalsByLeasableSpaceCode,
        {
            cumulativeExpiringArea: vacantLeasesSum.cumulative_expiring_area,
            cumulativeExpiringAreaPercentage:
                vacantLeasesSum.cumulative_expiring_area_percentage,
        },
    );

    return [...[vacantLeasesSum], ...active];
}

export const sumVacantLeases = (vacantLeases: ExpirationData[]) => {
    return vacantLeases.reduce(
        (acc, curr) => {
            acc.cumulative_expiring_area += curr.cumulative_expiring_area;
            acc.cumulative_expiring_area_percentage +=
                curr.cumulative_expiring_area_percentage;
            acc.expiring_square_footage += curr.expiring_square_footage;
            acc.expiring_square_footage_percentage +=
                curr.expiring_square_footage_percentage;
            acc.in_place_base_rent =
                (acc.in_place_base_rent || 0) + (curr.in_place_base_rent || 0);
            acc.in_place_base_rent_percentage =
                (acc.in_place_base_rent_percentage || 0) +
                (curr.in_place_base_rent_percentage || 0);
            acc.total_expiring_leases += curr.total_expiring_leases;
            acc.is_expired = acc.is_expired && curr.is_expired;

            return acc;
        },
        {
            cumulative_expiring_area: 0,
            cumulative_expiring_area_percentage: 0,
            expiring_square_footage: 0,
            expiring_square_footage_percentage: 0,
            in_place_base_rent: 0,
            in_place_base_rent_percentage: 0,
            total_expiring_leases: 0,
            expiration_year: 'Vacant',
            expiration_month_year: 'Vacant',
            expiration_month: 'Vacant',
            is_expired: true,
        },
    );
};
