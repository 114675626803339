import { get, isUndefined } from 'lodash';
import {
    FEATURE_NATIVE_ANALYTICS,
    FEATURE_PORTFOLIO_SEGMENTATION,
    FEATURE_CROSSTAB,
    FEATURE_BALANCE_SHEET,
    FEATURE_BALANCE_SHEET_OVERVIEW,
    FEATURE_TENANTS,
    FEATURE_OCCUPANCY_TREND,
    FEATURE_VARIANCE_REPORT_DASHBOARD,
    FEATURE_CAPITAL_TRACKER,
    FEATURE_REPORT_TEMPLATE,
    FEATURE_PLANNING_SECTION,
    LEASES_FEATURE_FLAG,
    FEATURE_PROPERTY_ATTRIBUTES,
    FEATURE_EXPIRATION_SCHEDULE,
    FEATURE_RENT_ROLL,
    FEATURE_UNIT_MIX,
    FEATURE_AGED_RECEIVABLES,
    FEATURE_BUDGET_UPLOAD,
    FEATURE_VALUE_AND_DEBT,
    FEATURE_OWNERSHIP_PERCENTAGE,
    FEATURE_DOCUMENTS,
    FEATURE_KPIS,
} from 'shared-types';

export const selectCurrentUser = (state: any) => get(state, 'user');

export const selectClientId = (state: any) => get(state, 'user.clientObj.id');

export const selectClientConfigurations = (state: any) => {
    return get(state, 'user.configuration');
};

export const selectClientDashboardConfiguration = (state: any) => {
    return state.user?.clientObj?.config_json?.DASHBOARDS;
};

export const hasNativeAnalyticsFeature = (state: any) =>
    get(state, `user.configuration.${FEATURE_NATIVE_ANALYTICS}`) === true ||
    isUndefined(get(state, `user.configuration.${FEATURE_NATIVE_ANALYTICS}`));

export const hasSegmentationDashboard = (state: any) =>
    get(state, `user.configuration.${FEATURE_PORTFOLIO_SEGMENTATION}`, false);

export const hasBalanceSheetEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_BALANCE_SHEET], false);

export const hasCrosstabEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_CROSSTAB], false);

export const hasBalanceSheetOverviewEnabled = (state: any) =>
    get(
        state,
        ['user', 'configuration', FEATURE_BALANCE_SHEET_OVERVIEW],
        false,
    );

export const hasTenantsEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_TENANTS], false);

export const hasOccupancyTrendEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_OCCUPANCY_TREND], false);

export const hasVarianceReportDashboardEnabled = (state: any) =>
    get(
        state,
        ['user', 'configuration', FEATURE_VARIANCE_REPORT_DASHBOARD],
        false,
    );

export const hasCapitalTrackerEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_CAPITAL_TRACKER], false);

export const hasReportTemplateEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_REPORT_TEMPLATE], false);

export const hasPlanningSectionEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_PLANNING_SECTION], true);

export const hasLeasesEnabled = (state: any) =>
    get(state, ['user', 'configuration', LEASES_FEATURE_FLAG], false);

export const hasAttributesEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_PROPERTY_ATTRIBUTES], false);

export const hasRentRollEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_RENT_ROLL], false);

export const hasLeasesExpirationEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_EXPIRATION_SCHEDULE], false);

export const hasUnitMixEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_UNIT_MIX], false);

export const hasAgedReceivablesEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_AGED_RECEIVABLES], false);

export const hasBudgetUploadEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_BUDGET_UPLOAD], false);

export const hasDebtAndValuePropertyInfo = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_VALUE_AND_DEBT], false);

export const hasOwnershipPercentageEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_OWNERSHIP_PERCENTAGE], false);

export const hasDocumentsEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_DOCUMENTS], false);

export const hasKPIsEnabled = (state: any) =>
    get(state, ['user', 'configuration', FEATURE_KPIS], false);
