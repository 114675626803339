import {
    getRecurringChargeRollupByEntityCode,
    getRecurringChargeTotals,
} from 'components/leases/components/recurring-charge/utils';
import { ITotalItemProps } from 'devextreme-react/data-grid';
import {
    PropertyDetailsProps,
    PropertyDetailsPropsWithTotals,
    RecurringCharge,
} from 'waypoint-types';
import { safeDivision } from 'shared-types';
import { calculateWeightedAverage } from 'waypoint-utils/analytics/WeightedAverageUtils';
import { DevExtremeGridWeightedAverageSummaryItem } from 'waypoint-utils/dev-extreme/weighted-averages';
import { AttributeDefinition } from 'shared-types';

interface PropertyDetailsWithPropertyCountProps extends PropertyDetailsProps {
    total_properties: number;
    entity_codes: string[];
}

const sumBy = (
    rows: PropertyDetailsProps[],
    getValue: (row: PropertyDetailsProps) => number,
): number => {
    return rows.reduce(
        (total: number, row: PropertyDetailsProps) => total + getValue(row),
        0,
    );
};

export const groupByAttribute = (
    entityCodes: string[],
    propertyLeaseDetails: PropertyDetailsProps[],
    selectedAttribute: AttributeDefinition,
): PropertyDetailsWithPropertyCountProps[] => {
    if (!selectedAttribute.attributeValues) {
        return [];
    }

    const entityGroupsByAttributeValue: { [key: string]: string[] } =
        entityCodes.reduce(
            (groups: { [key: string]: string[] }, entityCode) => {
                const values = selectedAttribute.attributeValues?.filter(
                    (attr) => attr.entity_code === entityCode,
                );

                const valueKey = values?.length
                    ? values.map((val) => val.value).join(', ')
                    : 'Unassigned';

                groups[valueKey] = groups[valueKey] || [];
                groups[valueKey].push(entityCode);

                return groups;
            },
            {},
        );

    return Object.keys(entityGroupsByAttributeValue).map(
        (attributeValue: string) => {
            const leaseDetailRowsForAttribute = propertyLeaseDetails.filter(
                (p) =>
                    entityGroupsByAttributeValue[attributeValue].includes(
                        p.entity_code,
                    ),
            );

            return {
                entity_code: '',
                entity_codes: leaseDetailRowsForAttribute.map(
                    (l) => l.entity_code,
                ),
                in_place_base_rent: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.in_place_base_rent,
                ),
                in_place_base_rent_sq_ft: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.in_place_base_rent_sq_ft,
                ),
                in_place_base_rent_punit:
                    calculateWeightedAverage<PropertyDetailsProps>(
                        leaseDetailRowsForAttribute,
                        (data) => data.total_units,
                        (data) => data.in_place_base_rent_punit,
                    ),
                in_place_base_rent_pounit:
                    calculateWeightedAverage<PropertyDetailsProps>(
                        leaseDetailRowsForAttribute,
                        (data) => data.occupied_units,
                        (data) => data.in_place_base_rent_pounit,
                    ),
                in_place_base_rent_per_sq_ft: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.in_place_base_rent_per_sq_ft,
                ),
                in_place_base_rent_occupied_sq_ft:
                    calculateWeightedAverage<PropertyDetailsProps>(
                        leaseDetailRowsForAttribute,
                        (data) => data.occupied_sq_ft,
                        (data) => data.in_place_base_rent_occupied_sq_ft,
                    ),
                key: '',
                lease_count: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.lease_count,
                ),
                monthly_in_place_base_rent: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.monthly_in_place_base_rent,
                ),
                monthly_in_place_base_rent_punit:
                    calculateWeightedAverage<PropertyDetailsProps>(
                        leaseDetailRowsForAttribute,
                        (data) => data.total_units,
                        (data) => data.monthly_in_place_base_rent_punit,
                    ),
                monthly_in_place_base_rent_pounit:
                    calculateWeightedAverage<PropertyDetailsProps>(
                        leaseDetailRowsForAttribute,
                        (data) => data.occupied_units,
                        (data) => data.monthly_in_place_base_rent_pounit,
                    ),
                monthly_in_place_base_rent_per_sq_ft: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.monthly_in_place_base_rent_per_sq_ft,
                ),
                monthly_in_place_base_rent_occupied_sq_ft:
                    calculateWeightedAverage<PropertyDetailsProps>(
                        leaseDetailRowsForAttribute,
                        (data) => data.occupied_sq_ft,
                        (data) =>
                            data.monthly_in_place_base_rent_occupied_sq_ft,
                    ),
                occupancy_rate: calculateWeightedAverage<PropertyDetailsProps>(
                    leaseDetailRowsForAttribute,
                    (data) => data.rentable_sq_ft,
                    (data) => data.occupancy_rate,
                ),
                occupied_sq_ft: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.occupied_sq_ft,
                ),
                occupied_units: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.occupied_units,
                ),
                property_name: attributeValue,
                rentable_sq_ft: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.rentable_sq_ft,
                ),
                total_units: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.total_units,
                ),
                total_properties: leaseDetailRowsForAttribute.length,
                vacant_area: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.vacant_area,
                ),
                vacant_units: sumBy(
                    leaseDetailRowsForAttribute,
                    (row) => row.vacant_units,
                ),
                wale: calculateWeightedAverage<PropertyDetailsProps>(
                    leaseDetailRowsForAttribute,
                    (data) => data.occupied_sq_ft,
                    (data) => data.wale,
                ),
            };
        },
    );
};

export const weightedAverageConfigs: DevExtremeGridWeightedAverageSummaryItem<
    ITotalItemProps,
    PropertyDetailsPropsWithTotals
>[] = [
    // Leasing & Occupancy
    {
        metricKey: 'occupancy_rate',
        scaleKey: 'rentable_sq_ft',
        options: {
            valueFormat: { type: 'percent', precision: 1 },
        },
    },
    {
        metricKey: 'wale',
        scaleKey: 'occupied_sq_ft',
        options: {
            valueFormat: { type: 'fixedPoint', precision: 1 },
        },
    },
    // Annual In-Place Base Rent
    {
        metricKey: 'total_annual',
        scaleKey: 'occupied_sq_ft',
        options: {
            showInColumn: 'total_annual_per_sq_ft',
            valueFormat: { type: 'currency', precision: 2 },
        },
    },
    {
        metricKey: 'total_annual_per_unit',
        scaleKey: 'total_units',
        options: {
            valueFormat: { type: 'currency', precision: 2 },
        },
    },
    {
        metricKey: 'total_annual_per_occupied_unit',
        scaleKey: 'occupied_units',
        options: {
            valueFormat: { type: 'currency', precision: 2 },
        },
    },
    // Monthly In-Place Base Rent
    {
        metricKey: 'total_monthly',
        scaleKey: 'occupied_sq_ft',
        options: {
            showInColumn: 'total_monthly_per_sq_ft',
            valueFormat: { type: 'currency', precision: 2 },
        },
    },
    {
        metricKey: 'total_monthly_per_unit',
        scaleKey: 'total_units',
        options: {
            valueFormat: { type: 'currency', precision: 2 },
        },
    },
    {
        metricKey: 'total_monthly_per_occupied_unit',
        scaleKey: 'occupied_units',
        options: {
            valueFormat: { type: 'currency', precision: 2 },
        },
    },
];

export const joinPerformanceOverviewWithTotalCharges = (
    data: (PropertyDetailsProps | undefined)[],
    recurringCharges: RecurringCharge[],
) => {
    const filteredData = data.filter((d) => d) as PropertyDetailsProps[];
    return filteredData.map((d) => {
        const charges = getRecurringChargeRollupByEntityCode(
            recurringCharges ?? [],
            d.entity_codes ?? [d.entity_code],
            d.occupied_sq_ft,
            d.total_units,
        );
        const { totalAnnual, totalMonthly } = getRecurringChargeTotals(charges);

        return {
            ...d,
            total_monthly: totalMonthly,
            total_annual: totalAnnual,
            total_monthly_per_sq_ft: safeDivision(
                totalMonthly,
                d.occupied_sq_ft,
            ),
            total_monthly_per_unit: safeDivision(totalMonthly, d.total_units),
            total_monthly_per_occupied_unit: safeDivision(
                totalMonthly,
                d.occupied_units,
            ),
            total_annual_per_sq_ft: safeDivision(totalAnnual, d.occupied_sq_ft),
            total_annual_per_unit: safeDivision(totalAnnual, d.total_units),
            total_annual_per_occupied_unit: safeDivision(
                totalAnnual,
                d.occupied_units,
            ),
        };
    });
};

export const performanceOverviewSortByGroupingOptions = [
    {
        value: 'entity_display_code',
        label: 'Properties',
    },
    {
        value: 'occupied_units',
        label: 'Occupied Units',
    },
    {
        value: 'vacant_units',
        label: 'Vacant Units',
    },
    {
        value: 'total_units',
        label: 'Total Units',
    },
    {
        value: 'occupied_sq_ft',
        label: 'Occupied SF.',
    },
    {
        value: 'vacant_area',
        label: 'Vacant SF',
    },
    {
        value: 'lease_count',
        label: 'Leases',
    },
    {
        value: 'occupancy_rate',
        label: '% Occupied',
    },
    {
        value: 'wale',
        label: 'WALE (yrs)',
    },
    {
        value: 'total_monthly',
        label: 'Total Monthly Charges',
    },
    {
        value: 'total_annual',
        label: 'Total Annual Charges',
    },
    {
        value: 'total_monthly_per_sq_ft',
        label: 'Monthly / Occ. SF',
    },
    {
        value: 'total_annual_per_sq_ft',
        label: 'Annual / Occ. SF',
    },
    {
        value: 'total_monthly_per_unit',
        label: 'Monthly / Unit',
    },
    {
        value: 'total_annual_per_unit',
        label: 'Annual / Unit',
    },
    {
        value: 'total_monthly_per_occupied_unit',
        label: 'Monthly / Occ. Unit',
    },
    {
        value: 'total_annual_per_occupied_unit',
        label: 'Annual / Occ. Unit',
    },
];
