import { isEmpty } from 'lodash';
import {
    AccountGraphNode,
    BudgetUpload,
    BudgetUploadEntry,
    SelectOptions,
} from 'waypoint-types';
import { textRenderer, currencyRenderer } from 'utils/tables/renderers';
import { DASH_DASH } from 'config/constants';
import { formatMoney, formatPercentage } from 'utils/formatters';
import { safeDivision } from 'shared-types';
import { css } from 'emotion';

export const container = css`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export interface BudgetUploadSelectData {
    title: string;
    key: string;
    options: SelectOptions[];
    placeholder: string;
    defaultValue?: string;
}

interface BudgetUploadAccountGraphNode extends AccountGraphNode {
    key?: string;
    isRootNode?: boolean;
}

export const budgetUploadSelectOption = (
    propertyOptions: SelectOptions[],
    sourceCodes: SelectOptions[],
): BudgetUploadSelectData[] => {
    return [
        {
            title: 'File Upload',
            key: 'proposedBudget',
            options: [{ label: 'Proposed Budget', value: 'proposed_budget' }],
            placeholder: 'Select File',
            defaultValue: 'proposed_budget',
        },
        {
            title: 'Source',
            key: 'source',
            options: [{ label: 'Kardin', value: 'kardin' }],
            placeholder: 'Select Source',
            defaultValue: 'kardin',
        },
        {
            title: 'Property',
            key: 'entityCode',
            options: propertyOptions,
            placeholder: 'Select Property',
        },
        {
            title: 'Code',
            key: 'sourceCode',
            options: sourceCodes,
            placeholder: 'Select Code',
        },
    ];
};

export const decorateBudgetUploadForAntD = (
    accountGraph: BudgetUploadAccountGraphNode[] | null,
    currentBudgetUploadEntry: BudgetUploadEntry[] | null,
    priorBudgetUploadEntry: BudgetUploadEntry[] | null,
) => {
    if (!accountGraph || !currentBudgetUploadEntry || !priorBudgetUploadEntry) {
        return [];
    }

    const addIsRootNodeKeyToNode = (node: BudgetUploadAccountGraphNode) => {
        return (node.isRootNode = !node.parent_account_mapping_code);
    };
    const addKeyFieldToNode = (node: BudgetUploadAccountGraphNode) =>
        (node.key = node.account_mapping_code);

    return accountGraph.map((node: BudgetUploadAccountGraphNode) => {
        if (node.children && node.children?.length > 0) {
            decorateBudgetUploadForAntD(
                node.children,
                currentBudgetUploadEntry,
                priorBudgetUploadEntry,
            );

            addIsRootNodeKeyToNode(node);
        }
        if (isEmpty(node.children)) {
            node.children = null;
        }

        const currentUploadActivity =
            currentBudgetUploadEntry &&
            currentBudgetUploadEntry.find(
                (entry: BudgetUploadEntry) =>
                    entry.accountCode === node.account_code,
            )?.activity;

        const priorUploadActivity =
            priorBudgetUploadEntry &&
            priorBudgetUploadEntry.find(
                (entry: BudgetUploadEntry) =>
                    entry.accountCode === node.account_code,
            )?.activity;

        node.currentUpload = currentUploadActivity
            ? Number(currentUploadActivity)
            : undefined;
        node.priorUpload = priorBudgetUploadEntry
            ? Number(priorUploadActivity)
            : undefined;

        addKeyFieldToNode(node);
        return node;
    });
};

export const getBudgetColumns = (
    priorBudgetUpload: BudgetUpload,
    currentUploadBudgetId: string | null,
) => {
    const comparisonColumns = [
        {
            title: 'Prior\nUpload',
            dataIndex: 'priorUpload',
            render: currencyRenderer,
            ellipsis: false,
            width: '75px',
        },
        {
            title: <span>$</span>,
            align: 'right',
            width: '125px',
            render: (_: null, node: AccountGraphNode) => {
                if (!node.currentUpload || !node.priorUpload) {
                    return DASH_DASH;
                }

                const calculation = node.priorUpload - node.currentUpload;
                return formatMoney(calculation);
            },
        },
        {
            title: <span>%</span>,
            align: 'right',
            width: '125px',
            render: (_: null, node: AccountGraphNode) => {
                if (!node.currentUpload || !node.priorUpload) {
                    return DASH_DASH;
                }

                const calculation = safeDivision(
                    node.priorUpload - node.currentUpload,
                    node.priorUpload,
                );
                return formatPercentage(calculation * 100);
            },
        },
    ];

    const defaultColumns = [
        {
            title: 'Account',
            dataIndex: 'name',
            render: textRenderer,
            ellipsis: true,
            width: '300px',
            fixed: 'left',
        },
        {
            title: 'Account\nCode',
            dataIndex: 'account_code',
            render: textRenderer,
            ellipsis: false,
            width: '75px',
        },
        {
            title: 'Current\nUpload',
            dataIndex: 'currentUpload',
            render: currencyRenderer,
            ellipsis: false,
            width: '75px',
        },
    ];

    return !priorBudgetUpload.entityBudgetUpload.id ||
        currentUploadBudgetId === priorBudgetUpload.entityBudgetUpload.id
        ? [...defaultColumns]
        : [...defaultColumns, ...comparisonColumns];
};
