import { useEffect, useState } from 'react';
import {
    AccountMappingSelection,
    DataGridStyleProps,
    OccupancyHistoryProps,
    OvertimeType,
} from 'waypoint-types';
import {
    page,
    emptyStateContainer,
    actionButtons,
    dateRangeContainer,
    accountSelectContainer,
} from './occupancyHistoryStyle';
import OccupancyTrendChart from './OccupancyTrendChart';
import EmptyState from 'components/style/EmptyState';
import { AccountSelect, RangeSelectConfirm } from 'waypoint-react';
import { getAccountGraph, getAsOfDates } from 'waypoint-requests';
import { message } from 'antd';
import {
    decorateAccountGraphForAntDesign,
    getDateRangeForPeriod,
} from 'waypoint-utils';
import { Moment } from 'moment';
import getActualsGrossMonthlyData from 'waypoint-requests/leases/getActualsGrossMonthlyData';
import moment from 'moment';
import Card from 'antd/lib/card';
import { Skeleton } from 'antd';
import { AccountGraphObjectType } from 'waypoint-types/account-graph/types';
import { AccountingAsOfDateType } from 'components/app/as-of-date';
import { OccupancyTrendGrid } from './OccupancyTrendGrid';
import { useSelectedDataLevel } from 'waypoint-hooks';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const occupancy_trend_range_select_id = 'occupancy-trend-select-id';

interface OccupancyTrendCardProps {
    entityCode: string;
    occupancyHistory: OccupancyHistoryProps[];
    isDataLoading: boolean;
    dataLoadError: boolean;
    style?: DataGridStyleProps;
    className?: string;
}

const OccupancyTrendCard = (props: OccupancyTrendCardProps) => {
    const { entityCode, occupancyHistory, isDataLoading, dataLoadError } =
        props;
    const [isError, setIsError] = useState<boolean>(false);
    const [rangeSelectIsOpen, setRangeSelectIsOpen] = useState<boolean>(false);
    const defaultAccountMappingSelection: AccountMappingSelection = {
        code: `${entityCode}_default_rev`,
        name: 'REVENUE',
    };
    const [accountGraph, setAccountGraph] =
        useState<AccountGraphObjectType>(null);
    const [loadingAccountGraph, setIsLoadingAccountGraph] =
        useState<boolean>(true);
    const [accountMappingSelection, setAccountMappingSelection] =
        useState<AccountMappingSelection>(defaultAccountMappingSelection);
    const [actualsData, setActualsData] = useState<OvertimeType[]>();
    const [isLoadingActualsData, setIsLoadingActualsData] =
        useState<boolean>(true);
    const [isLoadingPeriodRange, setIsLoadingPeriodRange] =
        useState<boolean>(true);
    const [periodRange, setPeriodRange] = useState<[Moment, Moment] | null>(
        null,
    );

    const selectedDataLevel = useSelectedDataLevel();

    useEffect(() => {
        if (!entityCode.length) return;
        const fetchDependencies = async () => {
            await fetchAccountGraph();
            await loadAsOfDate();
        };
        const fetchAccountGraph = async () => {
            setIsLoadingAccountGraph(true);

            try {
                const data = await getAccountGraph(
                    undefined,
                    selectedDataLevel,
                );

                const accountGraph = decorateAccountGraphForAntDesign(
                    data.children,
                );

                setAccountGraph(accountGraph);

                const accountMapping = {
                    code: accountGraph[0].account_mapping_code,
                    name: accountGraph[0].name,
                };

                setAccountMappingSelection(accountMapping);
            } catch (e) {
                setIsError(true);

                message.error(
                    'Request Failed! An error occurred while requesting accounts',
                );
            } finally {
                setIsLoadingAccountGraph(false);
            }
        };
        const getDefaultGlobalPeriodForEntity = (
            periodEnd: Date,
        ): [Moment, Moment] => {
            const [startDate, endDate] = getDateRangeForPeriod(
                'trailing_12',
                periodEnd,
            );
            return [moment(startDate), moment(endDate)];
        };
        const findLatestActualDate = (
            dates: AccountingAsOfDateType[],
        ): string | undefined => {
            const actual = dates.find((item) => item.label === 'Actual');
            if (actual) {
                return actual.period_end;
            }
            return undefined;
        };
        const loadAsOfDate = async () => {
            try {
                setIsLoadingPeriodRange(true);

                const { accounting } = await getAsOfDates([entityCode]);
                const latestActualPeriodEnd = findLatestActualDate(accounting);
                if (!latestActualPeriodEnd) {
                    message.error(
                        'Request Failed! An error occurred while loading as of date',
                    );
                    return;
                }
                const period = getDefaultGlobalPeriodForEntity(
                    new Date(latestActualPeriodEnd),
                );
                setPeriodRange(period);
            } catch (e) {
                console.error('loading as of date failed', e);
            } finally {
                setIsLoadingPeriodRange(false);
            }
        };
        fetchDependencies();
    }, []);

    useEffect(() => {
        const fetchDependencies = async () => {
            await fetchActualsData();
        };
        const fetchActualsData = async () => {
            setIsLoadingActualsData(true);
            try {
                if (periodRange) {
                    const data = await getActualsGrossMonthlyData({
                        entity_codes: [entityCode],
                        account_mapping_code: accountMappingSelection.code,
                        account_mapping_name: accountMappingSelection.name,
                        start_date: periodRange
                            ? periodRange[0].format('YYYY-MM-DD')
                            : null,
                        end_date: periodRange
                            ? periodRange[1].endOf('month').format('YYYY-MM-DD')
                            : null,
                        display_type: 'gross',
                        periodicity: 'monthly',
                        selected_data_level: selectedDataLevel,
                    });
                    setActualsData(data);
                    setIsLoadingActualsData(false);
                }
            } catch (e) {
                setIsError(true);
                message.error(
                    'Request Failed! An error occurred while requesting financial data',
                );
            } finally {
                setIsLoadingActualsData(false);
            }
        };
        fetchDependencies();
    }, [
        periodRange,
        accountMappingSelection,
        selectedDataLevel.stakeholder,
        selectedDataLevel.percentageType,
    ]);

    if (dataLoadError || isError) {
        return (
            <div className={page}>
                <div className={emptyStateContainer}>
                    <EmptyState
                        headerText="Error loading Occupancy Trend data"
                        icon="assets/img/Analytics-Icon-Gray@2x.png"
                    />
                </div>
            </div>
        );
    }

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.OccupancyTrend}>
            <div
                data-testid="occupancy-trend-wrapper"
                id="occupancy-trend-card-wrapper"
            >
                <Card
                    title={'Occupancy Trend'}
                    style={{ marginTop: '20px' }}
                    id="occupancy-trend"
                >
                    <div style={{ border: '1px solid lightgrey' }}>
                        <div
                            id="occupancy-trend-controls"
                            className={actionButtons}
                            style={{ marginTop: 24 }}
                        >
                            <div
                                className={accountSelectContainer}
                                id="occupancy_trend_account_select"
                            >
                                {!accountGraph ? (
                                    <Skeleton
                                        loading={true}
                                        active={true}
                                        paragraph={{ rows: 1 }}
                                    />
                                ) : (
                                    <AccountSelect
                                        value={accountMappingSelection.code}
                                        accountGraph={accountGraph}
                                        onChange={(
                                            code: string,
                                            name: string[],
                                        ) =>
                                            setAccountMappingSelection({
                                                code,
                                                name: name[0],
                                            })
                                        }
                                        loading={loadingAccountGraph}
                                    />
                                )}
                            </div>

                            <div
                                className={dateRangeContainer}
                                id={occupancy_trend_range_select_id}
                            >
                                <RangeSelectConfirm
                                    open={rangeSelectIsOpen}
                                    value={periodRange}
                                    onFocus={() => setRangeSelectIsOpen(true)}
                                    onCancel={() => setRangeSelectIsOpen(false)}
                                    onConfirm={(values) => {
                                        setRangeSelectIsOpen(false);
                                        if (values.length === 2) {
                                            const [start, end] = values;
                                            setPeriodRange([start, end]);
                                        } else {
                                            console.warn(
                                                'Occupancy trend date range did not update as there were ',
                                                values.length,
                                                ' values present',
                                            );
                                        }
                                    }}
                                    getCalendarContainer={() =>
                                        document.getElementById(
                                            occupancy_trend_range_select_id,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div style={{ padding: 24 }}>
                            {!periodRange && actualsData ? (
                                <Skeleton
                                    loading={true}
                                    active={true}
                                    paragraph={{ rows: 1 }}
                                />
                            ) : (
                                <OccupancyTrendChart
                                    occupancyHistory={occupancyHistory ?? []}
                                    actualsData={actualsData ?? []}
                                    periodRange={periodRange}
                                    accountName={accountMappingSelection.name}
                                    isDataLoading={
                                        isDataLoading || isLoadingActualsData
                                    }
                                />
                            )}
                            {occupancyHistory.length ? (
                                <OccupancyTrendGrid
                                    occupancyHistory={occupancyHistory ?? []}
                                    periodRange={periodRange}
                                />
                            ) : null}
                        </div>
                    </div>
                </Card>
            </div>
        </PermissionedWrapper>
    );
};

export default OccupancyTrendCard;
