import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { SavedConfiguration, SavedConfigurationObject } from 'waypoint-types';
import { getSavedConfigurationById } from 'waypoint-requests';

export const useGetSavedConfigurationById = <
    FiltersType = SavedConfigurationObject,
>(
    configId: string | null,
): DataHookResponse<SavedConfiguration<FiltersType>> => {
    const cacheKey = configId
        ? `api/saved-configurations/${configId}/configId`
        : null;
    const { data, error, mutate, isValidating } = useSWR(
        cacheKey,
        () => getSavedConfigurationById<FiltersType>(configId as string),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch saved configuration');
            },
            shouldRetryOnError: false,
        },
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
