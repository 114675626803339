import { css } from 'emotion';
import {
    useGetSelectedFilteredEntityCodes,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import theme from 'config/theme';
import AgedReceivablesCard from './AgedReceivablesCard';
import { DisabledDashboard } from 'waypoint-react';
import { AppFeaturePermissions } from 'shared-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const pageStyle = css`
    display: flex;
    background-color: ${theme.colors.grays.background};
    overflow-y: scroll;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
`;

const AgedReceivablesContainer = (): JSX.Element => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    const selectedDataLevel = useSelectedDataLevel();

    if (entityCodes.length === 0) {
        return (
            <div className={pageStyle}>
                <DisabledDashboard text={'Loading'} />;
            </div>
        );
    }

    return (
        <div className={pageStyle}>
            <PermissionedWrapper
                featureKey={AppFeaturePermissions.AgedReceivables}
                showDisabledView={true}
            >
                <div style={{ padding: '12px' }}>
                    <AgedReceivablesCard
                        selectedDataLevel={selectedDataLevel}
                        entityCodes={entityCodes}
                        isPropertyProfilePage={false}
                    />
                </div>
            </PermissionedWrapper>
        </div>
    );
};

export default AgedReceivablesContainer;
