import React, { useEffect, useState } from 'react';
import { CheckCircleFilled, LeftCircleOutlined } from '@ant-design/icons';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Link } from 'react-router-dom';
import {
    useEntityReportApprovals,
    useGetDataAvailabilityDate,
    useGetFilteredEntityCodes,
    useGetMentionableUsers,
    useGetQueryParam,
} from 'waypoint-hooks';
import {
    EntityReport,
    EntityReportWidgetListItem,
    Report,
    EntityReportWidget,
    PropertyType,
    MentionableDataSource,
    WorkflowRoleList,
} from 'waypoint-types';
import { roles as userRoles } from 'config/constants';
import theme from 'config/theme';
import { SelectedEntityReportWidget } from './entity-report-widgets/EntityReportWidgetUtils';
import {
    Button,
    Progress,
    message,
    Divider,
    Tooltip,
    Empty,
    Skeleton,
    Spin,
    Popconfirm,
    Steps,
    StepProps,
    Avatar,
    Tag,
    Badge,
} from 'antd';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { CommentsButton } from 'waypoint-react/comment/CommentsButton';
import { CommentsBadge } from 'waypoint-react/comment/CommentsBadge';
import {
    AntdStepsStatus,
    COMPLETE,
    EntityReportReviewStatus,
    IN_PROGRESS,
    NarrativePositions,
    OPEN,
    PDFExportOptions,
    ReportWorkflowReviewStatus,
    WidgetTypes,
    reportStatus,
} from '../constants';
import { safeDivision } from 'shared-types';
import EntityReportWorkflowStatus from './EntityReportWorkflowStatus';
import {
    exportReportById,
    submitEntityReport,
    submitEntityReportReview,
    updateEntityReportWidget,
} from 'waypoint-requests';
import { DisabledDashboard, HtmlEditorContainer } from 'waypoint-react';
import { selectCurrentUser } from 'state/user/selectors';
import { connect, RootStateOrAny } from 'react-redux';
import {
    COMMENT_ID_QUERY_KEY,
    createCoverPageWidget,
    ENTITY_CODE_KEY,
    filterWidgetsWithPermissions,
    FROM_MY_REPORTS_KEY,
    REPORT_ID_KEY,
    userWorkflowRole,
    WIDGET_ID_KEY,
    publishedReportStausColorMap,
    getHierarchyIndexOfMostRecentReviewer,
    getWorkflowRole,
    formatDate,
    REPORT_COMPLETE,
    ENTITY_REPORT_PUBLISHED_ID_KEY,
    getNarrativeText,
} from '../ReportUtils';
import { selectPropertiesById } from 'state/properties/selectors';
import { clearQueryParam } from 'waypoint-hooks/useGetQueryParam';
import EntityReportPDFExportModal from './EntityReportPDFExportModal';
import { format } from 'date-fns';
import { css } from 'emotion';
import { selectUsers } from 'state/users/selectors';
import { numericalSort } from 'utils/tables/sorters';
import saveAs from 'file-saver';
import { PermissionsGroupsProvider, usePermissions } from 'contexts';
import { allAccessPermissions } from 'shared-types';
import { EntityReportSubmissionHistory } from './EntityReportSubmissionHistory';
import {
    REPORT_APPROVAL_TYPES,
    SettingsTabKeys,
} from './report-settings/constants';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import {
    getAvatarInitials,
    getReviewerApprovalIcon,
} from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import EntityReportDrawerView from './EntityReportDrawerView';
import { DocumentUploadReferenceType } from 'components/uploads/DocumentUploadsUtils';

const reportNameFont = css`
    font-weight: bold;
    font-size: 26px;
    margin: 0;
`;

const propertyNameFont = css`
    font-size: 20px;
`;

const gridHeaderStyle = {
    fontWeight: 'bold',
    fontSize: '22px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    paddingLeft: '15px',
    paddingRight: '25px',
};

interface EntityReportEditorProps {
    userId: number;
    property: PropertyType;
    isAdmin: boolean;
}

const EntityReportEditor = ({
    userId,
    property,
    isAdmin,
}: EntityReportEditorProps) => {
    const { featurePermissions: baseUserPermissions } = usePermissions();
    const reportId = useGetQueryParam(REPORT_ID_KEY) ?? '';
    const entityCode = useGetQueryParam(ENTITY_CODE_KEY) ?? '';
    const commentIdQueryParam = useGetQueryParam(COMMENT_ID_QUERY_KEY);
    const widgetIdQueryParam = useGetQueryParam(WIDGET_ID_KEY);
    const fromMyReportParam = useGetQueryParam(FROM_MY_REPORTS_KEY) ?? 0;
    const entityReportPublishedIdQueryParam = useGetQueryParam(
        ENTITY_REPORT_PUBLISHED_ID_KEY,
    );
    const propertyName = property?.display_name ?? '';
    const entityCodes = useGetFilteredEntityCodes();

    const [userEntityReportPermissions, setUserEntityReportPermissions] =
        useState<string[]>(baseUserPermissions);
    const [workflowRole, setWorkflowRole] = useState<WorkflowRoleList>([]);

    const [entityReport, setEntityReport] = useState<EntityReport | null>(null);

    const [selectedEntityReportWidget, setSelectedEntityReportWidget] =
        useState<EntityReportWidgetListItem | null>(null);

    const [showInstructions, setShowInstructions] = useState<boolean>(false);
    const [pdfExportModalIsOpen, setPdfExportModalIsOpen] =
        useState<boolean>(false);
    const [selectedExportOption, setSelectedExportOption] = useState<string>(
        PDFExportOptions.ENTIRE_REPORT,
    );
    const [selectedExportComponentIds, setSelectedExportComponentIds] =
        useState<string[]>([]);
    const [widgetExportList, setWidgetExportList] = useState<
        EntityReportWidgetListItem[]
    >([]);

    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [showAccessError, setShowAccessError] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [userMentionOptions, setUserMentionOptions] = useState<
        MentionableDataSource[] | undefined
    >(undefined);

    const {
        update: updateCommentsPane,
        initializeComments,
        openComments,
        closeComments,
    } = useCommentsPane();

    const {
        publishedEntityReports,
        mutatePublishedEntityReports,
        selectedEntityReportPublished,
        setSelectedEntityReportPublished,
        selectedEntityReportPublishedReportMetadata,
        setSelectedEntityReportPublishedReportMetadata,
        currentEntityReportPublished,
        currentEntityReportPublishedReportMetadata,
        isSubmitting,
        setIsSubmitting,
        entityReportApprovals,
        reviewerViewDrawerIsOpen,
        setReviewerViewDrawerIsOpen,
        currentPublishedPDF,
        currentDocumentFetchError,
        currentPublishedEntityReportPDFError,
        entityReportApprovalStatus,
        report,
        isValidating,
        reportWidgetList,
        setReportWidgetList,
        currentEntityReportPublishedReviews,
        setPublishedPDFUpload,
        hasOneApprovalStatus,
    } = useEntityReportApprovals({
        entityCode,
        entityReportId: entityReport?.id ?? '',
        reportId: reportId ?? '',
        propertyName,
    });

    const userHasApprovalsAccess = workflowRole.length > 0 || isAdmin;
    const hasRejectedSubmissions =
        !!publishedEntityReports &&
        !!entityReportApprovals &&
        publishedEntityReports.entityReportPublished.filter(
            (erp) =>
                entityReportApprovals[erp.id]?.status ===
                    ReportWorkflowReviewStatus.Rejected ||
                entityReportApprovals[erp.id]?.status ===
                    ReportWorkflowReviewStatus.Cancelled,
        ).length > 0;

    const showSubmissionHistory =
        hasRejectedSubmissions && userHasApprovalsAccess;

    const showCancelReviewButton =
        workflowRole.includes(WORKFLOW_ASSIGNEE) && !hasOneApprovalStatus;

    const { data: asOfDate } = useGetDataAvailabilityDate([entityCode]);

    const userMentionOptionsForEntity = useGetMentionableUsers([entityCode]);

    const coverPageSettings = report?.reportSettings.find(
        (setting) => setting.settings_type === WidgetTypes.CoverPage,
    );

    const includeTableOfContents =
        report?.reportSettings.find(
            (setting) => setting.settings_type === WidgetTypes.TableOfContents,
        )?.enabled && coverPageSettings?.enabled;

    useEffect(() => {
        if (userMentionOptionsForEntity && entityReport) {
            const workflowRoleIds =
                entityReport.workflowRole?.map((wr) => wr.user_id) ?? [];
            const filteredMentionableUsers = userMentionOptionsForEntity.filter(
                (user) =>
                    workflowRoleIds.includes(Number(user.id)) || user.isAdmin,
            );
            setUserMentionOptions(filteredMentionableUsers);
        }
    }, [userMentionOptionsForEntity, entityReport]);

    const getExportWidgetIndices = () => {
        const widgetsToExport =
            selectedExportOption === PDFExportOptions.ENTIRE_REPORT
                ? reportWidgetList
                : widgetExportList;

        return widgetsToExport.map((w) => w.sort_index);
    };

    const onSubmitEntityReport = async () => {
        if (!report || !entityReport) {
            return;
        }

        setIsSubmitting(true);
        const widgetIndices = getExportWidgetIndices();

        const updatedPublishedEntityReports = !!publishedEntityReports
            ? { ...publishedEntityReports }
            : {
                  entityReportPublished: [],
                  reportsMetadata: [],
                  entityReportReviews: [],
              };

        mutatePublishedEntityReports(
            async () => {
                try {
                    const { entityReportPublished, reportsMetadata } =
                        await submitEntityReport({
                            reportId: report.id,
                            entityCode,
                            entityReportId: entityReport?.id,
                            indices: widgetIndices,
                        });

                    updatedPublishedEntityReports.entityReportPublished.push(
                        entityReportPublished,
                    );
                    updatedPublishedEntityReports.reportsMetadata.push(
                        reportsMetadata,
                    );
                } catch (e) {
                    message.error('Failed to submit entity report');
                } finally {
                    setIsSubmitting(false);
                    return updatedPublishedEntityReports;
                }
            },
            {
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            },
        );
    };

    useEffect(() => {
        updateCommentsPane({
            userMentionOptions,
        });
    }, [userMentionOptions]);

    const onSelectPublishedEntityReport = (entityReportPublishedId: string) => {
        if (reviewerViewDrawerIsOpen) {
            return;
        }
        const entityReportPublishedReportMetadata =
            publishedEntityReports?.reportsMetadata?.find(
                (rm) => rm.filter_hash === entityReportPublishedId,
            );

        if (!!entityReportPublishedReportMetadata) {
            setSelectedEntityReportPublishedReportMetadata(
                entityReportPublishedReportMetadata,
            );
        }

        const entityReportPublished =
            publishedEntityReports?.entityReportPublished?.find(
                (erp) => erp.id === entityReportPublishedId,
            );

        if (!!entityReportPublished) {
            setSelectedEntityReportPublished(entityReportPublished);
            setReviewerViewDrawerIsOpen(true);
        }
    };

    useEffect(() => {
        if (
            entityCodes.filteredEntityCodes.length &&
            !entityCodes.filteredEntityCodes.includes(entityCode)
        ) {
            setShowAccessError(true);
            return;
        }

        if (commentIdQueryParam) {
            const reportWidget = reportWidgetList.find(
                (widget) => widget.id === widgetIdQueryParam,
            );
            if (reportWidget) {
                setSelectedEntityReportWidget(reportWidget);
                initializeComments(reportWidget.comment_thread_id);
                openComments();
                updateCommentsPane({
                    heading: report?.name ?? '',
                    subheading: `${propertyName} - ${reportWidget.name}`,
                    scrollToCommentId: commentIdQueryParam,
                });
            }
        }
    }, [commentIdQueryParam, selectedEntityReportWidget]);

    useEffect(() => {
        if (
            !currentEntityReportPublished ||
            !currentEntityReportPublishedReportMetadata ||
            !commentIdQueryParam ||
            !entityReportPublishedIdQueryParam
        ) {
            return;
        }

        if (
            entityReportPublishedIdQueryParam ===
                currentEntityReportPublished.id &&
            entityReportApprovalStatus === ReportWorkflowReviewStatus.InReview
        ) {
            openComments();
            initializeComments(
                currentEntityReportPublishedReportMetadata?.comment_thread_id ??
                    '',
            );
            updateCommentsPane({
                heading: report?.name ?? '',
                subheading: `${propertyName}`,
                scrollToCommentId: commentIdQueryParam,
            });
            return;
        }
        onSelectPublishedEntityReport(entityReportPublishedIdQueryParam);
    }, [
        commentIdQueryParam,
        entityReportPublishedIdQueryParam,
        currentEntityReportPublished,
        currentEntityReportPublishedReportMetadata,
    ]);

    const [isNarrativeTextEditorOpen, setIsNarrativeTextEditorOpen] =
        useState<boolean>(false);

    const setEntityReportAndWidgetList = (data: Report) => {
        const entityReportByEntityCode =
            data.entityReports?.find(
                (entityReport) => entityReport.entity_code === entityCode,
            ) ?? null;

        setEntityReport(entityReportByEntityCode ?? null);

        const widgetList = [
            ...data.reportWidgets
                .filter((reportWidget) =>
                    filterWidgetsWithPermissions(
                        userEntityReportPermissions,
                        reportWidget,
                    ),
                )
                .map((reportWidget) => {
                    const entityReportWidget =
                        entityReportByEntityCode?.entityReportWidgets?.find(
                            (widget) =>
                                widget.report_widget_id === reportWidget.id,
                        );
                    return {
                        ...reportWidget,
                        entityReportWidgetId: entityReportWidget?.id ?? '',
                        narrative_text: getNarrativeText(
                            reportWidget,
                            entityReportWidget,
                        ),
                        comment_thread_id:
                            entityReportWidget?.reportMetadata
                                .comment_thread_id ?? '',
                        reportMetadata: entityReportWidget?.reportMetadata,
                        status: entityReportWidget?.status,
                    };
                }),
        ].sort((a, b) => numericalSort(a.sort_index, b.sort_index));

        if (coverPageSettings?.enabled) {
            const coverPageWidget = createCoverPageWidget(
                coverPageSettings?.settings?.paperOrientation,
                coverPageSettings?.settings?.paperSize,
                includeTableOfContents ?? false,
            );
            widgetList.unshift(coverPageWidget);
        }

        if (widgetList.length) {
            setReportWidgetList(widgetList);
            setSelectedEntityReportWidget(widgetList[0]);
            updateCommentsPane({
                heading: data.name,
                subheading: `${propertyName} - ${widgetList[0].name}`,
            });
        }
    };

    useEffect(() => {
        if (!report) {
            return;
        }

        setEntityReportAndWidgetList(report);
    }, [report, userEntityReportPermissions]);

    useEffect(() => {
        if (entityReport) {
            const roles = userWorkflowRole({ userId, entityReport });
            setWorkflowRole(roles);
            const hasAllAccess = roles.length > 0 || isAdmin;
            setUserEntityReportPermissions(
                hasAllAccess ? allAccessPermissions : baseUserPermissions,
            );
        }
    }, [entityReport, baseUserPermissions]);

    useEffect(() => {
        if (selectedExportOption === PDFExportOptions.CURRENT_SECTION) {
            setWidgetExportList([
                selectedEntityReportWidget ?? reportWidgetList[0],
            ]);
            return;
        }
        setWidgetExportList(
            reportWidgetList.filter((w) =>
                selectedExportComponentIds.includes(w.id),
            ),
        );
    }, [selectedExportOption, selectedExportComponentIds]);

    const resetExportModalSettings = () => {
        setWidgetExportList([]);
        setSelectedExportComponentIds([]);
        setSelectedExportOption(PDFExportOptions.ENTIRE_REPORT);
    };

    const onCancelPdfExportModal = () => {
        resetExportModalSettings();
        setPdfExportModalIsOpen(false);
    };

    const exportDocument = async () => {
        try {
            setIsExporting(true);

            const widgetIndices = getExportWidgetIndices();
            const fullExport =
                selectedExportOption === PDFExportOptions.ENTIRE_REPORT;
            const download = await exportReportById({
                reportId: reportId,
                entityCode,
                widgetIndices,
                fullExport,
            });

            const formattedDate = format(new Date(), 'yyyy-MM-dd');
            const blob = await download.blob();
            saveAs(
                blob,
                `${propertyName}_${
                    report?.name ?? 'report'
                }_${formattedDate}.pdf`,
            );

            setPdfExportModalIsOpen(false);
            resetExportModalSettings();
        } catch (e) {
            console.error(e);
            message.error('Download Failed');
        } finally {
            setIsExporting(false);
        }
    };

    const onStatusChanged = (status: string | null) => {
        if (!status) {
            return;
        }

        const updatedReportWidgetList = reportWidgetList
            .map((widget) => {
                if (widget.id === selectedEntityReportWidget?.id) {
                    return {
                        ...widget,
                        status: status,
                    };
                }
                return widget;
            })
            .filter((reportWidget) =>
                filterWidgetsWithPermissions(
                    userEntityReportPermissions,
                    reportWidget,
                ),
            );

        selectedEntityReportWidget &&
            setSelectedEntityReportWidget({
                ...selectedEntityReportWidget,
                status: status,
            });

        setReportWidgetList(updatedReportWidgetList);
    };

    const calculatedProgress = () => {
        const widgetsWithoutCoverPage = reportWidgetList.filter(
            (widget) => widget.widget_type !== WidgetTypes.CoverPage,
        );

        const complete = widgetsWithoutCoverPage.filter(
            (item) => item.status === COMPLETE,
        ).length;
        const success =
            safeDivision(complete, widgetsWithoutCoverPage.length) * 100;
        const inProgress = widgetsWithoutCoverPage.filter(
            (item) => item.status === IN_PROGRESS,
        ).length;
        const percentage =
            safeDivision(
                complete + inProgress,
                widgetsWithoutCoverPage.length,
            ) * 100;
        return {
            success: Number(success.toFixed(1)),
            percentage: Number(percentage.toFixed(1)),
        };
    };

    const toggleInstructions = () => {
        setShowInstructions(!showInstructions);
    };

    const onClickSelectedWidget = (widget: EntityReportWidgetListItem) => {
        if (widget.id !== selectedEntityReportWidget?.id) {
            setSelectedEntityReportWidget(widget);
            setShowInstructions(false);

            clearQueryParam(COMMENT_ID_QUERY_KEY);
            clearQueryParam(WIDGET_ID_KEY);
        }
    };

    const widgetListHeightOffset = showSubmissionHistory ? 450 : 225;

    const WIDGET_LIST_HEIGHT = window.innerHeight - widgetListHeightOffset;

    const widgetHeight = window.innerHeight - 164;

    const styleNarrative = {
        border: isNarrativeTextEditorOpen ? 'none' : '1px dashed lightgrey',
        borderRadius: 5,
        padding: isNarrativeTextEditorOpen ? 0 : 16,
    };

    const narrativeTextBox = () => (
        <div
            style={{ marginTop: 12 }}
            key={selectedEntityReportWidget?.id ?? ''}
        >
            <HtmlEditorContainer
                disabled={!userWorkflowRole({ userId, entityReport }).length}
                referenceType={
                    DocumentUploadReferenceType.EntityReportNarrative
                }
                style={
                    selectedEntityReportWidget?.narrative_position === 'above'
                        ? {
                              ...styleNarrative,
                              position: 'relative',
                              marginBottom: 16,
                          }
                        : {
                              ...styleNarrative,
                              position: 'relative',
                              marginTop: 16,
                          }
                }
                height={300}
                items={{
                    notes: selectedEntityReportWidget?.narrative_text,
                }}
                title={'narrative'}
                onHtmlEditorSubmit={(value) =>
                    selectedEntityReportWidget &&
                    updateNarrativeTexts({
                        ...selectedEntityReportWidget,
                        narrative_text: value.notes,
                    })
                }
                onEditCallback={() =>
                    setIsNarrativeTextEditorOpen(!isNarrativeTextEditorOpen)
                }
            />
        </div>
    );

    const updateNarrativeTexts = async (
        widget: EntityReportWidget | EntityReportWidgetListItem,
    ) => {
        const updatedEntityReportWidgetList = reportWidgetList
            .map((reportWidget) => {
                if (reportWidget.id === widget.id) {
                    return {
                        ...reportWidget,
                        narrative_text: widget.narrative_text,
                    };
                }
                return reportWidget;
            })
            .filter((reportWidget) =>
                filterWidgetsWithPermissions(
                    userEntityReportPermissions,
                    reportWidget,
                ),
            );
        setReportWidgetList(updatedEntityReportWidgetList);
        const updatedEntityReportWidget = updatedEntityReportWidgetList.find(
            (w) => w.id === widget.id,
        );
        try {
            await updateEntityReportWidget(
                updatedEntityReportWidget?.entityReportWidgetId ?? '',
                updatedEntityReportWidget?.narrative_text ?? '',
                [entityCode],
            );
            updatedEntityReportWidget &&
                setSelectedEntityReportWidget(updatedEntityReportWidget);
            message.success('Narrative updated successfully');
        } catch (e) {
            message.error('Error updating narrative');
        }
    };

    const reportNavItem = (
        title: string,
        iconName: string,
        status: string,
        widget: EntityReportWidgetListItem,
        color: string,
    ) => {
        if (widget.status !== status) {
            return null;
        }

        return (
            <Tooltip title={title} placement={'right'} arrow>
                <i
                    className={iconName}
                    style={{
                        color,
                        marginRight: 5,
                    }}
                />
            </Tooltip>
        );
    };

    const isCoverPageSelected =
        selectedEntityReportWidget?.widget_type === WidgetTypes.CoverPage;
    const isNarrativeBelow =
        selectedEntityReportWidget?.narrative_position ===
        NarrativePositions.Below;
    const isNarrativeAbove =
        selectedEntityReportWidget?.narrative_position ===
        NarrativePositions.Above;

    const getWidgetStyles = (
        widget: EntityReportWidgetListItem,
        selectedEntityReportWidget: EntityReportWidgetListItem | null,
    ) => {
        const baseStyles = {
            fontSize: '14px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            backgroundColor:
                widget.id === selectedEntityReportWidget?.id
                    ? theme.colors.blues.focusRow
                    : '',
        };

        const coverPageStyles = {
            borderBottom: '1px solid lightgrey',
            marginLeft: '-10px',
            padding: '10px',
            fontWeight: 'bold',
        };

        const otherWidgetStyles = {
            marginBottom: '5px',
            marginRight: '10px',
            padding: '5px',
        };

        return widget.id === WidgetTypes.CoverPage
            ? { ...baseStyles, ...coverPageStyles }
            : { ...baseStyles, ...otherWidgetStyles };
    };

    const { percentage, success } = calculatedProgress();
    const submitDisabled = success !== 100;

    const isReviewerView =
        (entityReportApprovalStatus === ReportWorkflowReviewStatus.InReview ||
            entityReportApprovalStatus ===
                ReportWorkflowReviewStatus.Approved) &&
        userHasApprovalsAccess;
    const showDiasbledDashboard =
        !report ||
        !entityReport ||
        !propertyName ||
        !asOfDate ||
        !entityReportApprovals;

    if (showDiasbledDashboard) {
        return <DisabledDashboard text={'Loading'} />;
    }

    if (showAccessError) {
        return (
            <Empty
                style={{ marginTop: '150px' }}
                description="You do not have access to this report."
            />
        );
    }

    if (isSubmitting) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '25%',
                }}
            >
                <div style={{ marginBottom: '10px' }}>
                    Preparing your report for review, please wait...
                </div>
                <Spin size="large" />
            </div>
        );
    }

    const approvalSettingsType =
        report?.reportSettings.find(
            (setting) => setting.settings_type === SettingsTabKeys.Approvals,
        )?.settings?.type ?? '';

    const isHierarchicalReview =
        approvalSettingsType === REPORT_APPROVAL_TYPES.HierarchicalApproval;

    const reviewers = entityReport?.workflowRole?.filter(
        (wr) => wr.report_role === WORKFLOW_REVIEWER,
    );

    const isAssignee = workflowRole.includes(WORKFLOW_ASSIGNEE);
    const isActiveReport = report.state === reportStatus.active;

    const showSubmitForReviewButton = isAssignee && isActiveReport;

    const getHierarchicalReviewItems = () => {
        const getReviewStatusAndDate = (
            reviewerId: number,
        ): { reviewStatus: StepProps['status']; reviewDate: string } => {
            const userReview = currentEntityReportPublishedReviews?.find(
                (rev) => rev.user_id === reviewerId,
            );
            if (!userReview) {
                return {
                    reviewStatus: AntdStepsStatus.Process,
                    reviewDate: '',
                };
            }
            const reviewStatus =
                userReview.status === ReportWorkflowReviewStatus.Approved
                    ? AntdStepsStatus.Finish
                    : AntdStepsStatus.Error;

            const reviewDate = formatDate(userReview.timestamps.created_at);
            return {
                reviewStatus,
                reviewDate,
            };
        };

        const items: StepProps[] = [
            {
                title: <span>Submitted for Approval</span>,
                description: currentEntityReportPublished
                    ? formatDate(
                          currentEntityReportPublished.timestamps.created_at,
                      )
                    : '',
                status: AntdStepsStatus.Finish,
            },
        ];
        if (!userMentionOptions) {
            return;
        }
        for (const reviewer of reviewers) {
            const reviewerUser = userMentionOptions.find(
                (user) => user.id === reviewer.user_id,
            );
            const reviewerName = reviewerUser?.text ?? '';
            const { reviewStatus, reviewDate } = getReviewStatusAndDate(
                reviewer.user_id,
            );
            const reviewerItem: StepProps = {
                title: <span>{reviewerName}</span>,
                description: reviewDate,
                status: reviewStatus,
            };
            const reviewerApproved = reviewStatus === AntdStepsStatus.Finish;
            if (reviewerApproved) {
                reviewerItem.icon = (
                    <CheckCircleFilled
                        style={{
                            color: theme.colors.workflowReportStatus.approved,
                        }}
                    />
                );
            }
            items.push(reviewerItem);
        }
        return items;
    };

    const onSubmitEntityReportReview = async (
        status: EntityReportReviewStatus,
    ) => {
        if (!entityReport || !currentEntityReportPublished) {
            return;
        }
        const updatedPublishedEntityReports = !!publishedEntityReports
            ? { ...publishedEntityReports }
            : {
                  entityReportPublished: [],
                  reportsMetadata: [],
                  entityReportReviews: [],
              };

        mutatePublishedEntityReports(
            async () => {
                try {
                    const submittedReview = await submitEntityReportReview({
                        entityCode: entityReport.entity_code,
                        entityReportId: entityReport.id,
                        entityReportPublishedId:
                            currentEntityReportPublished.id,
                        status,
                    });
                    updatedPublishedEntityReports.entityReportReviews.push(
                        submittedReview,
                    );
                    return updatedPublishedEntityReports;
                } catch (e) {
                    message.error('Failed to submit entity report review');
                }
            },
            {
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            },
        );
    };

    const showReviewSubmitButtons = () => {
        if (!isActiveReport) {
            return false;
        }

        const entityReportIsApproved =
            entityReportApprovalStatus === ReportWorkflowReviewStatus.Approved;
        if (entityReportIsApproved) {
            return false;
        }

        const currentUserHasReviewed =
            !!currentEntityReportPublishedReviews?.find(
                (review) => review.user_id === userId,
            );
        if (currentUserHasReviewed) {
            return false;
        }

        if (workflowRole.includes(WORKFLOW_REVIEWER)) {
            if (isHierarchicalReview) {
                const currentReviewHierarchyIndex =
                    getHierarchyIndexOfMostRecentReviewer(
                        currentEntityReportPublishedReviews ?? [],
                        getWorkflowRole(
                            entityReport,
                            userMentionOptions,
                            WORKFLOW_REVIEWER,
                        ),
                    );
                const userHierarchyIndex =
                    reviewers.find((wr) => wr.user_id === userId)
                        ?.hierarchy_index ?? 0;
                return userHierarchyIndex === currentReviewHierarchyIndex + 1;
            }

            // if not hierarchical, we've already checked that user hasn't already reviewed
            return true;
        }

        // default
        return false;
    };

    const renderCancelRequestButton = () => {
        return (
            <Popconfirm
                placement="topRight"
                title={`Are you sure you want to cancel the review request for this entity report?`}
                onConfirm={() =>
                    onSubmitEntityReportReview(
                        ReportWorkflowReviewStatus.Cancelled,
                    )
                }
                okType="danger"
                okText="Submit"
                cancelText="Cancel"
            >
                <Button danger>Cancel Request</Button>
            </Popconfirm>
        );
    };

    const renderReviewButton = (action: EntityReportReviewStatus) => {
        const isApprovalButton = action === ReportWorkflowReviewStatus.Approved;
        const actionText = isApprovalButton ? 'Approve' : 'Reject';

        return (
            <Popconfirm
                placement="topRight"
                title={`Are you sure you want to ${actionText.toLowerCase()} this entity report?`}
                onConfirm={() => onSubmitEntityReportReview(action)}
                okType="danger"
                okText="Submit"
                cancelText="Cancel"
            >
                <Button
                    style={{
                        color: theme.colors.white,
                        backgroundColor: isApprovalButton
                            ? theme.colors.green
                            : theme.colors.red,
                        border: theme.colors.grays.medium,
                        marginRight: isApprovalButton ? '10px' : '0px',
                    }}
                >
                    {actionText}
                </Button>
            </Popconfirm>
        );
    };

    const renderReviewRequirements = () => {
        const hideReviewText =
            (isHierarchicalReview && !!approvalSettingsType) ||
            entityReportApprovalStatus === ReportWorkflowReviewStatus.Approved;
        if (hideReviewText) {
            return <></>;
        }
        return (
            <div
                style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    color: theme.colors.red,
                }}
            >
                {approvalSettingsType === REPORT_APPROVAL_TYPES.AllReviewers
                    ? '* Approvals are required from all reviewers'
                    : '* Approval is required from one reviewer'}
            </div>
        );
    };

    const renderSidebarSection = () => {
        if (!userMentionOptions) {
            return <></>;
        }
        if (isReviewerView) {
            return isHierarchicalReview ? (
                <Steps
                    direction="vertical"
                    style={{ marginTop: '10px' }}
                    size="small"
                    current={1}
                    items={getHierarchicalReviewItems()}
                />
            ) : (
                <div>
                    {renderReviewRequirements()}
                    {reviewers.map((reviewer) => {
                        const reviewerUser = userMentionOptions.find(
                            (user) => user.id === reviewer.user_id,
                        );
                        const reviewerName = reviewerUser?.text ?? '';
                        const userReview =
                            currentEntityReportPublishedReviews?.find(
                                (rev) => rev.user_id === reviewer.user_id,
                            );
                        return (
                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                <Tooltip
                                    placement="top"
                                    trigger="hover"
                                    arrow
                                    title={
                                        userReview?.status
                                            ? `${
                                                  userReview?.status
                                              } by ${reviewerName} on ${formatDate(
                                                  userReview.timestamps
                                                      .created_at,
                                              )}`
                                            : reviewerName
                                    }
                                >
                                    <span
                                        style={{
                                            textOverflow: 'ellipsis',
                                            overflowX: 'hidden',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <Badge
                                            count={getReviewerApprovalIcon(
                                                userReview?.status,
                                            )}
                                            style={{
                                                marginRight: '16px',
                                                marginTop: '5px',
                                            }}
                                        >
                                            <Avatar
                                                style={{
                                                    marginRight: '15px',
                                                    fontSize: '12px',
                                                }}
                                                src={
                                                    reviewerUser?.profile_image_url ??
                                                    null
                                                }
                                            >
                                                {getAvatarInitials(
                                                    reviewerName,
                                                )}
                                            </Avatar>
                                        </Badge>
                                        {reviewerName}
                                    </span>
                                </Tooltip>
                            </div>
                        );
                    })}
                </div>
            );
        }

        return reportWidgetList.map((widget) => {
            return (
                <div
                    onClick={() => {
                        onClickSelectedWidget(widget);
                        setIsNarrativeTextEditorOpen(false);
                        initializeComments(widget.comment_thread_id);
                        updateCommentsPane({
                            heading: report.name,
                            subheading: `${propertyName} - ${widget.name}`,
                        });
                        closeComments();
                    }}
                    style={getWidgetStyles(widget, selectedEntityReportWidget)}
                >
                    {reportNavItem(
                        REPORT_COMPLETE,
                        'fa-solid fa-check-circle',
                        COMPLETE,
                        widget,
                        theme.colors.workflowReportStatus.approved,
                    )}
                    {reportNavItem(
                        ReportWorkflowReviewStatus.InProgress,
                        'fa-solid fa-pencil-square',
                        IN_PROGRESS,
                        widget,
                        theme.colors.workflowReportStatus.in_progress,
                    )}
                    {reportNavItem(
                        ReportWorkflowReviewStatus.Open,
                        'fa-solid fa-ban',
                        OPEN,
                        widget,
                        theme.colors.workflowReportStatus.open,
                    )}
                    <span
                        style={{
                            flex: 1,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'pre',
                        }}
                    >
                        {widget.name}
                    </span>
                    <span
                        style={{
                            marginLeft: 'auto',
                        }}
                    >
                        <CommentsBadge
                            commentThreadId={widget.comment_thread_id}
                            onClicked={() => {
                                onClickSelectedWidget(widget);
                                updateCommentsPane({
                                    heading: report.name,
                                    subheading: `${propertyName} - ${widget.name}`,
                                });
                            }}
                            badgeStyle={{
                                marginLeft: '10px',
                            }}
                            hideZeroCount={true}
                        />
                    </span>
                </div>
            );
        });
    };

    const renderTopbarSection = () => {
        if (isReviewerView) {
            return (
                <div style={gridHeaderStyle}>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <Tag
                                color={
                                    publishedReportStausColorMap[
                                        entityReportApprovalStatus as keyof typeof publishedReportStausColorMap
                                    ]
                                }
                                style={{
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    alignContent: 'center',
                                    height: '33px',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                }}
                            >
                                {entityReportApprovalStatus}
                            </Tag>

                            <CommentsButton
                                onClicked={() => {
                                    openComments();
                                    initializeComments(
                                        currentEntityReportPublishedReportMetadata?.comment_thread_id ??
                                            '',
                                    );
                                    updateCommentsPane({
                                        heading: report.name,
                                        subheading: `${propertyName}`,
                                    });
                                }}
                                commentThreadId={
                                    currentEntityReportPublishedReportMetadata?.comment_thread_id ??
                                    ''
                                }
                            />
                        </div>
                        {showReviewSubmitButtons() && (
                            <div>
                                {renderReviewButton(
                                    ReportWorkflowReviewStatus.Approved,
                                )}
                                {renderReviewButton(
                                    ReportWorkflowReviewStatus.Rejected,
                                )}
                            </div>
                        )}
                        {showCancelReviewButton && (
                            <div>{renderCancelRequestButton()}</div>
                        )}
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div style={gridHeaderStyle}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div>{selectedEntityReportWidget?.name ?? ''}</div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginLeft: '30px',
                        }}
                    >
                        <EntityReportWorkflowStatus
                            entityReportWidget={selectedEntityReportWidget}
                            entityReportWorkflowRows={
                                entityReport?.workflowRole ?? []
                            }
                            onStatusChanged={onStatusChanged}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '3px',
                            marginLeft: '30px',
                        }}
                    >
                        {selectedEntityReportWidget?.widget_type !==
                            WidgetTypes.CoverPage && (
                            <CommentsButton
                                onClicked={() => {
                                    initializeComments(
                                        selectedEntityReportWidget?.comment_thread_id ??
                                            '',
                                    );
                                    updateCommentsPane({
                                        heading: report.name,
                                        subheading: `${propertyName} - ${selectedEntityReportWidget?.name}`,
                                    });
                                }}
                                commentThreadId={
                                    selectedEntityReportWidget?.comment_thread_id
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const PDF_VIEWER_HEIGHT = `${window.innerHeight - 175}px`;
    const SIDEBAR_WIDTH = isReviewerView ? 325 : 400;

    const renderMainSection = () => {
        if (isReviewerView) {
            return (
                <div>
                    {currentDocumentFetchError ||
                    currentPublishedEntityReportPDFError ? (
                        <DisabledDashboard
                            text={'Could not load entity report PDF'}
                        />
                    ) : (
                        <iframe
                            src={currentPublishedPDF}
                            width="100%"
                            height={PDF_VIEWER_HEIGHT}
                        />
                    )}
                </div>
            );
        }
        return (
            <>
                <div
                    style={{
                        display: 'inline-block',
                        fontSize: '14px',
                        fontWeight: 'normal',
                    }}
                >
                    {selectedEntityReportWidget?.instructions &&
                        selectedEntityReportWidget.instructions.length && (
                            <Button
                                style={{
                                    padding: '0',
                                    marginLeft: '3px',
                                }}
                                type="link"
                                onClick={toggleInstructions}
                            >
                                <p
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                >
                                    {!showInstructions
                                        ? 'Show Instructions'
                                        : 'Hide Instructions'}
                                </p>
                            </Button>
                        )}
                </div>
                {selectedEntityReportWidget?.instructions &&
                    selectedEntityReportWidget.instructions.length &&
                    showInstructions && (
                        <div
                            style={{
                                fontSize: '14px',
                            }}
                        >
                            <div
                                style={{
                                    fontSize: '13px',
                                    margin: '10px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: '5px',
                                    padding: '10px',

                                    boxShadow:
                                        'rgba(151, 151, 160, 0.63) 0px 0px 0px 2px',

                                    backgroundColor: 'lightyellow',
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        marginBottom: '5px',
                                    }}
                                >
                                    Instructions:
                                </div>
                                {selectedEntityReportWidget?.instructions}
                            </div>
                            <Divider
                                style={{
                                    margin: '15px auto',
                                }}
                            />
                        </div>
                    )}

                {!isCoverPageSelected && isNarrativeAbove
                    ? narrativeTextBox()
                    : null}
                <div
                    style={{
                        marginTop: '10px',
                        height: isCoverPageSelected ? '100%' : 'auto',
                    }}
                >
                    <PermissionsGroupsProvider
                        userPermissions={userEntityReportPermissions}
                    >
                        <SelectedEntityReportWidget
                            key={
                                selectedEntityReportWidget?.entityReportWidgetId ??
                                ''
                            }
                            asOfDate={asOfDate}
                            entityCode={entityCode}
                            widgetId={
                                selectedEntityReportWidget?.entityReportWidgetId ??
                                ''
                            }
                            widgetType={
                                selectedEntityReportWidget?.widget_type ?? ''
                            }
                            widgetSettings={
                                selectedEntityReportWidget?.settings_inputs ??
                                {}
                            }
                            setIsUploading={setIsUploading}
                            isUploading={isUploading}
                            propertyInfo={property}
                            entityReport={entityReport}
                            report={report}
                        />
                    </PermissionsGroupsProvider>
                </div>
            </>
        );
    };

    return (
        <>
            {!isValidating && (
                <EntityReportPDFExportModal
                    key={pdfExportModalIsOpen.toString()}
                    isModalOpen={pdfExportModalIsOpen}
                    isExporting={isExporting}
                    exportDocument={exportDocument}
                    reportWidgetList={reportWidgetList}
                    selectedEntityReportWidgetName={
                        selectedEntityReportWidget?.name ?? ''
                    }
                    selectedExportOption={selectedExportOption}
                    setSelectedExportOption={setSelectedExportOption}
                    selectedExportComponentIds={selectedExportComponentIds}
                    setSelectedExportComponentIds={
                        setSelectedExportComponentIds
                    }
                    onCancel={onCancelPdfExportModal}
                />
            )}

            <ReflexContainer orientation="horizontal">
                <ReflexElement>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement
                            size={SIDEBAR_WIDTH}
                            minSize={200}
                            maxSize={700}
                        >
                            <div
                                style={{
                                    height: '75px',
                                    paddingTop: '15px',
                                    paddingLeft: '20px',
                                }}
                            >
                                {fromMyReportParam ? (
                                    <Link to="/reports">
                                        <LeftCircleOutlined
                                            style={{ marginRight: 5 }}
                                        />
                                        Back to My Reports
                                    </Link>
                                ) : (
                                    <Link to={`/report/manager/${reportId}`}>
                                        <LeftCircleOutlined
                                            style={{ marginRight: 5 }}
                                        />
                                        Back to Report
                                    </Link>
                                )}
                            </div>
                            <div>
                                <div style={gridHeaderStyle}>
                                    {isReviewerView
                                        ? 'Reviewers'
                                        : 'Report Sections'}
                                </div>

                                {!isValidating && (
                                    <div
                                        style={{
                                            marginTop: '2px',
                                            paddingLeft: '10px',
                                            paddingBottom: '20px',
                                            height: WIDGET_LIST_HEIGHT,
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {renderSidebarSection()}
                                    </div>
                                )}
                                {isValidating && (
                                    <div style={{ padding: 15 }}>
                                        <Skeleton paragraph={{ rows: 10 }} />
                                    </div>
                                )}
                                {!isReviewerView && (
                                    <div
                                        style={{
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            padding: '25px 25px 5px 25px',
                                            borderTop: '2px solid lightgrey',
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                        }}
                                    >
                                        <span style={{ marginRight: '5px' }}>
                                            Status
                                        </span>
                                        <Progress
                                            percent={percentage}
                                            success={{
                                                percent: success,
                                            }}
                                            format={(_, successPercentage) =>
                                                `${successPercentage}%`
                                            }
                                        />
                                        {showSubmitForReviewButton ? (
                                            <Tooltip
                                                placement="top"
                                                trigger="hover"
                                                arrow
                                                title={
                                                    submitDisabled
                                                        ? 'Report must be at 100% to submit for review.'
                                                        : null
                                                }
                                            >
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Are you sure you want to submit for review?`}
                                                    onConfirm={
                                                        onSubmitEntityReport
                                                    }
                                                    okType="danger"
                                                    okText="Submit"
                                                    cancelText="Cancel"
                                                    disabled={submitDisabled}
                                                >
                                                    <Button
                                                        size="small"
                                                        style={{
                                                            marginLeft: '10px',
                                                        }}
                                                        disabled={
                                                            submitDisabled
                                                        }
                                                    >
                                                        Submit for Review
                                                    </Button>
                                                </Popconfirm>
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                            {showSubmissionHistory &&
                                !!publishedEntityReports && (
                                    <EntityReportSubmissionHistory
                                        publishedEntityReports={
                                            publishedEntityReports
                                        }
                                        onSelectPublishedEntityReport={
                                            onSelectPublishedEntityReport
                                        }
                                        entityReportApprovals={
                                            entityReportApprovals
                                        }
                                    />
                                )}
                        </ReflexElement>
                        <ReflexSplitter />
                        <ReflexElement>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    height: '75px',
                                }}
                            >
                                <div></div>
                                <div
                                    style={{
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        flexGrow: '1',
                                    }}
                                >
                                    <div className={reportNameFont}>
                                        {propertyName}{' '}
                                    </div>
                                    <div className={propertyNameFont}>
                                        {report?.name}{' '}
                                    </div>
                                </div>
                                {!isReviewerView && (
                                    <div
                                        style={{
                                            top: 10,
                                            right: 25,
                                            position: 'absolute',
                                            zIndex: 1,
                                        }}
                                    >
                                        <Button
                                            disabled={isUploading}
                                            style={{
                                                color: theme.colors.white,
                                                backgroundColor:
                                                    theme.colors.oranges.bright,
                                                border: theme.colors.grays
                                                    .medium,
                                            }}
                                            onClick={() =>
                                                setPdfExportModalIsOpen(true)
                                            }
                                        >
                                            Export to PDF
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {!isValidating && renderTopbarSection()}
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    height: `${widgetHeight}px`,
                                    overflowY:
                                        selectedEntityReportWidget?.widget_type ===
                                        'attachment'
                                            ? 'hidden'
                                            : 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {reviewerViewDrawerIsOpen && (
                                    <EntityReportDrawerView
                                        userMentionOptions={userMentionOptions}
                                        reportName={report.name}
                                        entityReport={entityReport}
                                        entityReportApprovals={
                                            entityReportApprovals
                                        }
                                        selectedEntityReportPublished={
                                            selectedEntityReportPublished
                                        }
                                        selectedEntityReportPublishedReportMetadata={
                                            selectedEntityReportPublishedReportMetadata
                                        }
                                        reviewerViewDrawerIsOpen={
                                            reviewerViewDrawerIsOpen
                                        }
                                        setReviewerViewDrawerIsOpen={
                                            setReviewerViewDrawerIsOpen
                                        }
                                        approvalSettingsType={
                                            approvalSettingsType
                                        }
                                        setPublishedPDFUpload={
                                            setPublishedPDFUpload
                                        }
                                        commentIdQueryParam={
                                            commentIdQueryParam
                                        }
                                    />
                                )}
                                {isValidating && (
                                    <Skeleton paragraph={{ rows: 6 }} />
                                )}
                                {!isValidating && renderMainSection()}
                                {!isCoverPageSelected && isNarrativeBelow
                                    ? narrativeTextBox()
                                    : null}
                            </div>
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
            </ReflexContainer>
        </>
    );
};

const mapState = (s: RootStateOrAny) => {
    const { roles, id: userId } = selectCurrentUser(s);

    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);

    const entityCode = new URLSearchParams(
        s.routing.locationBeforeTransitions.search,
    ).get(ENTITY_CODE_KEY);
    const properties = selectPropertiesById(s, { propertyIds: [entityCode] });
    const property = Object.values(properties);
    return {
        userId,
        isAdmin,
        property: property[0] as PropertyType,
    };
};

export default connect(mapState)(EntityReportEditor);
