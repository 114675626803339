import React, { useCallback, useEffect, useState } from 'react';
import theme from 'config/theme';
import { DataGrid } from 'devextreme-react';
import {
    Column,
    ColumnChooser,
    Export,
    Format,
    Paging,
    Scrolling,
    Summary,
    TotalItem,
    SearchPanel,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { connect, RootStateOrAny } from 'react-redux';
import { ExportingEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import {
    arrayToCapitalizedString,
    exportExcelFromDevExtremeDataGrid,
} from 'waypoint-utils';
import {
    useRecurringCharges,
    useRentSteps,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import { useRentRollTotals } from 'waypoint-hooks/data-access/useRentRollTotals';
import { safeDivision } from 'shared-types';
import { hasTenantsEnabled } from 'state/user/selectors';
import { TenantData } from './topTenantsUtils';
import { CalculateCustomSummaryOptions } from 'waypoint-utils/dev-extreme/weighted-averages';
import { RecurringChargeData, RentRollProps } from 'waypoint-types';
import {
    customCalculationFields,
    RentRollColumnSelection,
} from 'components/leases/expirations/cards/rent-roll/utils';
import { Dictionary } from 'ts-essentials';
import { TopTenantExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/TopTenantsExportableGrid';
import { Drawer, Dropdown, Skeleton } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { RentRollTable } from 'components/leases/expirations';

interface TopTenantsTableParams {
    data: TenantData[];
    sqFtCalculations: Dictionary<number>;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    tenantsEnabled: boolean;
    isPDFExport?: boolean;
    isPropertyProfilePage?: boolean;
    entityCodes?: string[];
}

interface SelectedTenant {
    tenantCode: string;
    tenantName: string;
}

const TopTenantsDrawer = ({
    isVisible,
    setIsVisible,
    selectedTenant,
    entityCodes = [],
    charges,
}: {
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
    selectedTenant: SelectedTenant;
    entityCodes?: string[];
    charges?: RecurringChargeData;
}) => {
    const selectedDataLevel = useSelectedDataLevel();

    const queryString = ['tenant_name', '=', selectedTenant.tenantName];
    const customFilter = selectedTenant.tenantCode
        ? `${JSON.stringify(queryString)}`
        : null;

    const { data: rentRollTotals } = useRentRollTotals(
        entityCodes,
        selectedDataLevel,
    );

    const { data: rentSteps } = useRentSteps([], selectedDataLevel);

    return (
        <Drawer
            title={`${selectedTenant.tenantName} - Tenant Detail`}
            open={isVisible}
            onClose={() => setIsVisible(false)}
            width="100VW"
            footer={null}
        >
            {charges ? (
                <RentRollTable
                    key={selectedTenant.tenantCode ?? ''}
                    entityCodes={entityCodes}
                    recurringCharges={charges.recurringCharges}
                    columnSelection={
                        RentRollColumnSelection.ConsolidatedCharges
                    }
                    selectedDataLevel={selectedDataLevel}
                    rentSteps={rentSteps}
                    summary={charges.summary}
                    rentRollTotals={rentRollTotals}
                    customFilter={customFilter}
                    groupByProperty={true}
                />
            ) : (
                <Skeleton />
            )}
        </Drawer>
    );
};

const TopTenantsTable = ({
    data,
    sqFtCalculations,
    dataGridRef,
    tenantsEnabled,
    isPDFExport,
    isPropertyProfilePage,
    entityCodes,
}: TopTenantsTableParams) => {
    const [tableHeight, setTableHeight] = useState<number>(
        window.innerHeight - 510,
    );

    const [isHistoryModalVisible, setIsHistoryModalVisible] =
        useState<boolean>(false);
    const [selectedTenant, setSelectedTenant] = useState<SelectedTenant>();

    const { data: charges } = useRecurringCharges(entityCodes ?? [], {
        stakeholder: null,
        percentageType: null,
    });

    useEffect(() => {
        const handleResize = () => {
            setTableHeight(
                window.innerHeight - (isPropertyProfilePage ? 660 : 610),
            );
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderActionCell = (data: TenantData) => {
        const openHistoryModal = () => {
            setSelectedTenant({
                tenantCode: data.tenant_code,
                tenantName: data.tenant,
            });
            setIsHistoryModalVisible(!isHistoryModalVisible);
        };

        const items = [
            {
                key: '1',
                label: 'Show Tenant Detail',
                onClick: openHistoryModal,
            },
        ];

        return (
            <div>
                <Dropdown
                    trigger={['click']}
                    placement={'bottomRight'}
                    menu={{ items }}
                >
                    <span
                        style={{
                            fontSize: '13px',
                            fontWeight: 'normal',
                            textTransform: 'none',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        <MenuOutlined />
                    </span>
                </Dropdown>
            </div>
        );
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        if (e.rowType === 'group') {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (e.rowType === 'header') {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.color = '#000';
        }
        if (e.rowType === 'totalFooter') {
            e.rowElement.style.fontWeight = 'bold';
        }
    };

    const calculateCustomSummary = (
        options: CalculateCustomSummaryOptions<RentRollProps, any>,
    ) => {
        if (customCalculationFields.includes(options.name)) {
            switch (options.summaryProcess) {
                case 'start': {
                    options.totalValue = {
                        rentable_sq_ft: 0,
                        [options.name]: 0,
                    };
                    break;
                }
                case 'calculate': {
                    options.totalValue.rentable_sq_ft +=
                        options.value.rentable_sq_ft;
                    options.totalValue[options.name] +=
                        options.name === 'total_monthly_per_sq_ft'
                            ? options.value.total_monthly
                            : options.value.total_annual;
                    break;
                }
                case 'finalize': {
                    options.totalValue = safeDivision(
                        Number(
                            options.totalValue[
                                options.name as keyof RentRollProps
                            ],
                        ),
                        options.totalValue.rentable_sq_ft,
                    );
                    break;
                }
            }
        } else if (options.name === 'percentage_rentable_sq_ft') {
            if (options.summaryProcess === 'finalize') {
                options.totalValue = safeDivision(
                    data.reduce(
                        (acc, tenant) => acc + tenant.rentable_sq_ft,
                        0,
                    ),
                    sqFtCalculations.totalRentableSqFt,
                );
            }
        }
    };

    const onExporting = useCallback(async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Recurring Charges',
            filename: 'Recurring_Charges.xlsx',
        });
    }, []);

    const dataWithIndustryPrettified = data.map((tenant) => ({
        ...tenant,
        suites: tenant.suites.length,
        properties: tenant.properties.length,
        industry: arrayToCapitalizedString(tenant.industry),
    }));

    const isGroupingAndExcelExportEnabled = !isPDFExport;

    if (isPDFExport) {
        return (
            <TopTenantExportableGrid
                data={dataWithIndustryPrettified}
                tenantsEnabled={tenantsEnabled}
            />
        );
    }
    return (
        <>
            {isHistoryModalVisible && selectedTenant && (
                <TopTenantsDrawer
                    isVisible={isHistoryModalVisible}
                    setIsVisible={setIsHistoryModalVisible}
                    selectedTenant={selectedTenant}
                    entityCodes={entityCodes}
                    charges={charges}
                />
            )}
            <DataGrid
                id="top-tenants-data-grid"
                ref={dataGridRef}
                dataSource={dataWithIndustryPrettified}
                height={isPDFExport ? 400 : tableHeight}
                allowColumnReordering={true}
                showBorders={true}
                onRowPrepared={onRowPrepared}
                wordWrapEnabled={false}
                hoverStateEnabled={true}
                onExporting={onExporting}
                columnMinWidth={50}
                style={{
                    width: '100%',
                    minHeight: isPropertyProfilePage ? '342px' : '375px',
                }}
                scrolling={{ mode: 'virtual' }}
            >
                <Export
                    enabled={isGroupingAndExcelExportEnabled}
                    allowExportSelectedData={false}
                />
                <ColumnChooser
                    enabled={isGroupingAndExcelExportEnabled}
                    mode={'select'}
                    height={350}
                    allowSearch={true}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <Summary calculateCustomSummary={calculateCustomSummary}>
                    <TotalItem
                        column="tenant"
                        name="tenant"
                        summaryType="count"
                        displayFormat="Total: {0} Tenants"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    />
                    <TotalItem
                        column="suites"
                        name="suites"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    />
                    <TotalItem
                        column="rentable_sq_ft"
                        name="SF"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'fixedPoint', precision: 2 }}
                    />
                    <TotalItem
                        column="percentage_occ_sq_ft"
                        name="% of Occ. Sf."
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'percent', precision: 2 }}
                    />
                    <TotalItem
                        showInColumn="percentage_rentable_sq_ft"
                        name="percentage_rentable_sq_ft"
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={{ type: 'percent', precision: 2 }}
                    />
                    <TotalItem
                        column="total_annual"
                        name="Total Annual"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                    <TotalItem
                        column="total_annual_percentage"
                        name="Total Annual"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'percent', precision: 2 }}
                    />
                    <TotalItem
                        name="total_annual_per_sq_ft"
                        showInColumn="total_annual_per_sq_ft"
                        summaryType="custom"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        displayFormat="{0}"
                    />
                </Summary>
                <Column
                    dataField="tenant"
                    caption="Tenant"
                    width={'auto'}
                    fixed={true}
                />
                {!isPropertyProfilePage ? (
                    <Column
                        dataField="properties"
                        caption="Properties"
                        alignment={'center'}
                        width={120}
                    />
                ) : null}
                <Column
                    dataField="suites"
                    caption="Suites"
                    alignment={'center'}
                    width={120}
                />
                <Column
                    dataField="rentable_sq_ft"
                    caption="Rentable SF"
                    alignment={'center'}
                    width={120}
                >
                    <Format type="fixedPoint" precision={2} />
                </Column>
                <Column
                    dataField="percentage_occ_sq_ft"
                    caption="% of Occ. SF"
                    alignment={'center'}
                    width={120}
                >
                    <Format type="percent" precision={2} />
                </Column>
                <Column
                    dataField="percentage_rentable_sq_ft"
                    caption={'% of RSF'}
                    alignment={'center'}
                    width={120}
                >
                    <Format type="percent" precision={2} />
                </Column>
                <Column
                    dataField="total_annual"
                    caption={'Annual Charges'}
                    alignment={'center'}
                    width={120}
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    dataField="total_annual_percentage"
                    caption={'% of Charges'}
                    alignment={'center'}
                    width={120}
                >
                    <Format type="percent" precision={2} />
                </Column>
                <Column
                    dataField="total_annual_per_sq_ft"
                    caption={'$/SF'}
                    alignment={'center'}
                    width={120}
                >
                    <Format type="currency" precision={2} />
                </Column>
                {tenantsEnabled ? (
                    <Column
                        dataField="industry"
                        caption={'Industry'}
                        allowGrouping={false}
                        visible={true}
                        width={'auto'}
                    />
                ) : null}
                <Column
                    caption=""
                    name={'actions'}
                    fixed={true}
                    fixedPosition="right"
                    allowExporting={false}
                    showInColumnChooser={false}
                    cellRender={(e) => {
                        return renderActionCell(e.data);
                    }}
                    width={40}
                    minWidth={40}
                    alignment={'center'}
                    allowGrouping={false}
                />
                <Paging enabled={false} />
                <Scrolling />
                <SearchPanel
                    visible={true}
                    highlightCaseSensitive={false}
                    width={250}
                />
            </DataGrid>
        </>
    );
};

const mapState = (state: RootStateOrAny) => {
    const tenantsEnabled = hasTenantsEnabled(state);

    return {
        tenantsEnabled,
    };
};

export default connect(mapState)(TopTenantsTable);
