import { roles as userRoles } from 'config/constants';
import store from '../state/store';

type ConfigJSON = {
    FEATURE_UNIT_MIX: boolean | undefined;
    FEATURE_KPIS: boolean | undefined;
};

interface userData {
    userId: number;
    clientId: number;
    isAdmin: boolean;
    configJSON: ConfigJSON | undefined;
}

export const useGetUserData = (): userData => {
    const { id, client_id, roles } = (store.getState() as any).user.userObj;
    const { clientObj } = (store.getState() as any).user;

    return {
        userId: id,
        clientId: client_id,
        isAdmin: roles?.includes(userRoles.CLIENT_ADMIN) ?? false,
        configJSON: clientObj?.config_json,
    };
};
